// Case All
// Cape Morris

.section {
  -webkit-transform: translate3d(0, 0, 0);
}

.intro {
  width: 100%;
  height: 100vh;
  padding: 75px 0 0 0;
  position: relative;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  &:after {
    content: "";
    display: none;
    background: #fff;
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    @include phone {
      display: block;
    }
  }
}

// Navigation

#fullpage-menu {
  transform: translateY(-50%);
  position: fixed;
  z-index: 10;
  right: 17px;
  top: 50%;
  opacity: 1;
  display: block;
  ul {
    margin: 0;
    padding: 0;
    margin-right: 30px;
    li {
      width: 5px;
      height: 5px;
      //margin-bottom: 80px;
      margin-bottom: 40px;
      display: block;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        a::after {
          display: none;
        }
      }
      &:hover {
        .fp-tooltip {
          transition: opacity 0.2s ease-in;
          width: auto;
          opacity: 1;
        }
      }
      a {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none;
        &:after {
          content: "....................................";
          //width: 68px;
          width: 30px;
          height: 11px;
          overflow: hidden;
          color: #cdd1e1;
          font-size: 11px;
          letter-spacing: 4px;
          position: absolute;
          left: 13px;
          top: -7px;
          transform-origin: left top;
          transform: rotate(90deg) translateX(-100%);
          pointer-events: none;
        }
        &:hover {
          span {
            width: 8px;
            height: 8px;
            margin: -5px 0 0 -4px;
            background: #5799fc;
          }
        }
        &.active + .fp-tooltip {
            transition: opacity 0.2s ease-in;
            width: auto;
            opacity: 1;
          }

        span {
          top: 0;
          background: #cdd1e1;
          z-index: 1;
          width: 5px;
          height: 5px;
          transition: all 0.1s ease-in-out;
          margin: -2px 0 0 -2px;
          left: 50%;
          border: 0;
          border-radius: 50%;
          position: absolute;
        }
      }
      .fp-tooltip {
        position: absolute;
        white-space: nowrap;
        max-width: 240px;
        overflow: hidden;
        display: block;
        width: 0;
        cursor: pointer;
        font-family: 'Work Sans', sans-serif;
        color: #000;
        text-transform: uppercase !important;
        font-size: 12px;
        padding-right: 15px;
        top: -8px;
        right: 8px!important;
        mix-blend-mode: darken;
        opacity: 0;

        &--bdh, &--bpn, &--velo, &--morliny {
          color: white;
        }

      }
      &.visited {
        a {
          span {
            width: 5px;
            height: 5px;
            margin: -2px 0 0 -2px;
            background: #5799fc;
            &:hover {
              width: 10px;
              height: 10px;
              margin: -5px 0 0 -5px;
            }
          }
        }
        a::after {
          content: "....................................";
          //width: 68px;
          width: 30px;
          height: 11px;
          overflow: hidden;
          color: #cdd1e1;
          font-size: 11px;
          letter-spacing: 4px;
          position: absolute;
          left: 13px;
          top: -7px;
          transform-origin: left top;
          transform: rotate(90deg) translateX(-100%);
          pointer-events: none;
        }
      }
    }
    li a.active,
    li:hover a.active {
      span {
        width: 10px;
        height: 10px;
        margin: -5px 0 0 -5px;
        background: #5799fc;
      }
    }
  }
}

// Footer
.footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 0 0 0 0;
  a {
    color: #a2bb3f;
    margin-left: 0;
    transition: 0.3s;
    &:hover {
      margin-left: 5px;
    }
  }
  .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include tablet {
      text-align: center;
      padding: 80px 20px 100px 20px;
    }
    .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px;
      @include phone {
        font-size: 22px;
        line-height: 30px;
      }
    }
    .col1 {
      position: relative;
      &:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px;
      }
      @include tablet {
        &:after {
          display: none;
        }
      }
    }
    .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0;
      @include tablet {
        font-size: 18px;
      }

      @include phone {
        font-size: 16px;
      }
      .position {
        font-size: 12px;
      }
    }
  }
}

.fp-tooltip {
  opacity: 1;
  visibility: visible;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}