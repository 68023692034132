// Case 2 subpage

.case2.subpage {
    height: auto;
    padding: 0;
    overflow: hidden;

    a {
      color: #ff3d00;
    }

    .arrow-line {
      stroke: #fff;
    }

    .main-content {
      background-color: #1c1713;
    }

    .main-photo {
      text-align: center;
      width: 1190px;
      max-width: 100%;
      position: absolute;
      left: 50%;
      bottom: -150px;
      transform: translateX(-50%);
      @include tablet {
        padding: 0 20px;
        bottom: -100px;
      }
      @include phone {
        bottom: 0;
      }
    }

    .special {
      color: #ff4f00;
      font-weight: inherit;
    }

    .small-title {
      font-size: 13px;
      text-transform: uppercase;
      color: #a28e86;
      font-weight: 300;
      letter-spacing: 1px;
      display: block;
    }

    .lead {
      font-size: 45px;
      color: #fff;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 62px;
      @include tablet {
        font-size: 28px;
        color: #fff;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 39px;
      }
    }

    .default-text {
      font-size: 18px;
      color: #fff;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 30px;
    }

    .section {
      max-width: 850px;
      margin: 0 auto;
      position: relative;
      @include tablet {
        padding: 0 30px;
      }
    }

    .intro {
      height: 100vh;
      &:after {
        background: #1c1713;
      }
    }

    ul.blonia-list {
      margin: 50px 0 0 0;
      li {
        background: url('../img/blonia_dot.png');
        background-repeat: no-repeat;
        background-position: left;
        padding: 8px 0 8px 45px;
        font-size: 18px;
        font-weight: 300;
        color: #a28e86;
      }
    }

    .brief {
      padding: 80px 0 0 0;
      .lead {
        margin: 50px 0 60px 0;
      }
    }

    .blonia-photo {
      position: relative;
      margin-top: -150px;
      @include tablet {
        margin-top: -100px;
      }
      @include phone {
        margin-top: -20px;
      }
      img {
          width: 100%;
      }
      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .blonia-lines {
        fill: none;
        stroke: #ff3d00;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
        fill-rule: evenodd;
      }
    }

    .blonia-analiza-rynku {
      padding: 250px 0 250px 0;
      @include tablet {
        padding: 150px 0 100px 0;
        text-align: center;
        .blonia-analiza-img {
          width: 60%;
        }
      }
      .small-title {
        margin: 0 0 20px 0;
      }
      .analiza-row2 {
        margin: 100px 0 0 0;
        .no-padding {
          @include phone {
            padding-bottom: 30px;
          }
        }
      }
      .circle {
        width: 112px;
        height: 112px;
        border: solid 2px #ff3d00;
        border-radius: 50%;
        display: table;
        text-align: center;
        @include tablet {
          margin: 0 auto;
        }
        .lokale {
          display: table-cell;
          vertical-align: middle;
          color: #ff3d00;
          font-size: 28px;
        }
      }
      .analiza-title {
        font-size: 24px;
        font-weight: 300;
        margin: 10px 0 0 0;
      }
      .analiza-desc {
        font-size: 18px;
        font-weight: 300;
        color: #a28e86;
        margin: 20px 0 0 0;
        @include tablet {
          margin: 0 0 20px 0;
        }
      }
    }

    .blonia-analiza-inwestycji {
      padding: 0 20px;
      .blonia-lines2 {
          fill: none;
          stroke: #EF4423;
          stroke-miterlimit: 10;
      }
      .small-title {
        margin-top: 40px;
      }
      .lead {
          margin: 40px 0 0 0;
      }
    }

    .blonia-strategia-marketingowa {
      padding: 200px 0;
      @include tablet {
        padding: 100px 0;
      }
      .lead {
        margin: 40px 0 0 0;
      }
      img {
        max-width: none;
        margin: 120px 0 0 0;
        @include tablet {
          width: 43%;
          margin: 40px auto 0 auto;
          display: block;
        }
      }
    }

    .blonia-linia-kreatywna-container {
      width: 100%;
      padding: 200px 0;
      background: #241c19;
      @include tablet {
        padding: 80px 0;
      }
    }

    .blonia-linia-kreatywna {
      .lead {
        margin: 40px 0 0 0;
      }
      .default-text {
        color: #a28e86;
        margin: 50px 0 0 0;
      }
      .logo-steps {
        margin: 160px 0 0 0;
        @include tablet {
          margin: 50px 0 0 0;
        }
        .logo-wrapper-step {
            height: 200px;
            position: relative;
            display: table;
            @include tablet {
              height: 150px;
              text-align: center;
              margin: 0 auto;
            }
            &.center-step {
              text-align: center;
              &:before {
                content: url('../img/blonia_steps_dot.png');
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
              &:after {
                content: url('../img/blonia_steps_dot.png');
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              }
          }
          &.last-step {
            text-align: right;
          }
          .helper {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      .slogan {
        margin: 160px 0 0 0;
        @include tablet {
          margin: 60px 0 0 0;
        }
        .haslo {
          font-size: 39px;
          font-weight: 300;
          padding: 36px 0 0 0;
          display: block;
          @include tablet {
            padding: 0 0 20px 0;
          }
        }
        .slogan-wrapper {
          border-left: solid 1px #836a44;
          padding-left: 80px;
          @include tablet {
            padding-top: 20px;
          }
          @include phone {
            border: 0;
            border-top: solid 1px #836a44;
            padding-left: 0;
            padding-top: 20px;
          }
        }
      }
    }

    .blonia-akcja-promocyjna {
      padding: 180px 0;
      @include tablet {
        padding: 90px 0;
      }
      .teaser-title {
        font-size: 30px;
        font-weight: 300;
        margin: 150px 0 55px 0;
        @include tablet {
          margin: 75px 0 30px 0;
        }
      }
      .teaser-desc {
        font-size: 18px;
        color: #a28e86;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 1px;
        margin-bottom: 100px;
      }
      .title {
        text-align: center;
        font-weight: 300;
        margin: 270px 0 200px 0;
        @include tablet {
          margin: 100px 0 100px 0;
          font-size: 36px;
        }
      }
    }

    .blonia-serwis-internetowy {
      background: #241c19;
      text-align: center;
      position: relative;
      height: 290px;
      @include phone {
        height: unset;
      }
      .serwis {
        position: absolute;
        top: -250px;
        left: 50%;
        transform: translateX(-50%);
        @include tablet {
          top: -150px;
        }
        @include phone {
          height: unset;
          padding: 0 20px;
        }
      }
      .btn-fill {
        margin: 400px auto 220px auto;
        color: #241c19;
        &:hover {
          background: rgba(255, 77, 29, 0.8);
        }
        @include tablet {
          margin: 100px auto 0 auto;
        }
      }
    }

    .footer-wrapper {
      width: 100%;
      background: #1c1713;
      position: relative;
      margin: 100px 0 0 0;
      .case-footer {
        padding: 160px 20px 200px 20px;
        max-width: 600px;
        @include tablet {
          text-align: center;
          padding: 80px 20px 100px 20px;
        }
        .footer-title {
          font-size: 30px;
          color: #fff;
          font-weight: 300;
          letter-spacing: 1px;
          text-align: center;
          margin: 0 0 90px 0;
          line-height: 48px;
          @include phone {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .col1 {
          position: relative;
          &:after {
              content: " ";
              display: block;
              height: 100px;
              width: 1px;
              background: #2f2b28;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -50px;
          }
          @include tablet {
            &:after {
              display: none;
            }
          }
        }
        .contact {
          color: #fff;
          font-size: 21px;
          margin: 20px 0 0 0;
          @include tablet {
            font-size: 18px;
          }
          .position {
              font-size: 12px;
          }
        }
      }
    }
}
