#main-error {
  width: 100%;

  @media screen and (min-width: 1600px) {
    height: 100vh;
  }

  .error {
    &__wrapper {
      width: 100%;
      padding: 5em 0;
      font-family: 'Work Sans', sans-serif;

      @media screen and (min-width: 1600px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        padding: 0;
      }
    }
  }

  .ship {
    padding-bottom: 4em;

    &__img {
      display: block;
      margin: auto;
    }
  }


  .mayday {
    padding-bottom: 6em;

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__img {
      img {
        display: block;
      }
    }

    &__text {
      font-size: 1em;
      font-weight: bold;
      letter-spacing: 7px;
      text-transform: uppercase;
      margin: 0 1em;

      @media screen and (min-width: 768px) {
        font-size: 1.4em;
        margin: 0 3em;
      }
    }
  }

  .mainText {
    padding-bottom: 3.5em;

    &__text {
      font-size: 9em;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 0.8;
      text-align: center;

      @media screen and (min-width: 768px) {
        font-size: 13em;
      }
    }
  }

  .sosText {
    padding-bottom: 4em;

    &__wrapper {
      font-weight: 500;
      text-align: center;
      padding-left: 1.6em;
    }

    &__text {
      position: relative;
      font-size: 4.5em;
      letter-spacing: 30px;

      &:before {
        content: 'sos';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: 0.25em;
        letter-spacing: 28px;
      }
    }
  }

  .btn {
    &__center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--first {
      position: relative;
      display: inline-block;
      margin: 0 auto;
      border: 1px solid #fff;
      padding: 20px 35px;
      text-transform: uppercase;
      font-size: 1.2em;
      color: #fff;
      transition: .3s ease;
      overflow: hidden;

      @media screen and (min-width: 1280px) {
        padding: 28px 55px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        transition: .3s ease;
        transform: translateY(100%);
        z-index: -1;
      }

      &:hover {
        color: #000;

        &:before {
          transform: translateY(0);
        }
      }

      span {
        font-weight: 800;
      }
    }
  }
}
