/* Media queries
   ----------------------------- */

@mixin phone {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 1279px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: 1441px) {
    @content;
  }
}