@charset "UTF-8";
/*!
 * Cape Morris
 * 2016
 */
/* Imports
   ----------------------------- */
@import 'https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700,800&subset=latin-ext';
/* Core
   ----------------------------- */
/*
  HTML5 Reset :: style.css
  ----------------------------------------------------------
  We have learned much from/been inspired by/taken code where offered from:
  Eric Meyer          :: http://meyerweb.com
  HTML5 Doctor        :: http://html5doctor.com
  and the HTML5 Boilerplate :: http://html5boilerplate.com
-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%; }

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
  In fact, it *will* cause problems with Google Maps' controls at small size.
  If this is the case for you, try uncommenting the following:
#map img {
    max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
  you'll have to restore the bullets within content,
  which is fine because they're probably customized anyway */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:after {
  content: " ";
  display: block;
  clear: both; }

/* Variables
   ----------------------------- */
/* Core styles
   ----------------------------- */
body {
  color: #fff;
  background: #1c1b1d;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

.container {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative; }

a {
  color: #35baf6;
  text-decoration: none; }

a:hover {
  color: #2d4454;
  text-decoration: none; }

h1 {
  font-size: 34px; }

h2 {
  font-size: 30px; }

h3 {
  font-size: 26px; }

h4 {
  font-size: 22px; }

h5 {
  font-size: 18px; }

/* Custom styles
   ----------------------------- */
img {
  vertical-align: bottom; }

.slick-initialized {
  visibility: visible; }

.no-scroll {
  overflow: hidden; }

.no-padding {
  padding: 0;
  margin: 0; }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

textarea:focus, input:focus {
  outline: 0; }

.form-control:focus, select:focus, textarea:focus, input:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control {
  border: solid 1px #35baf6;
  height: 85px;
  background: transparent;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 35px;
  color: #fff;
  font-size: 16px; }
  .form-control:focus {
    border: solid 3px #35baf6; }

.form-control::-moz-placeholder {
  color: #35baf6;
  font-size: 16px; }

.form-control:-ms-input-placeholder {
  color: #35baf6;
  font-size: 16px; }

.form-control::-webkit-input-placeholder {
  color: #35baf6;
  font-size: 16px; }

.form-control:focus::-webkit-input-placeholder {
  color: transparent; }

svg {
  overflow: visible; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.slick-dots {
  bottom: -50px; }

.slick-slide {
  outline: 0; }

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

iframe[name='google_conversion_frame'] {
  height: 0 !important;
  width: 0 !important;
  line-height: 0 !important;
  font-size: 0 !important;
  margin-top: -13px;
  float: left; }

.slick-dots li button:before {
  font-size: 10px !important;
  line-height: 20px !important; }

/* Media queries
   ----------------------------- */
/* Buttons
   ----------------------------- */
.btn-fill {
  background: #ff3600;
  padding: 32px 70px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border: 0;
  display: inline-block; }
  .btn-fill:hover {
    color: #fff;
    background: #ff3600;
    text-decoration: none; }
  .btn-fill:focus {
    color: #fff;
    text-decoration: none; }
    .btn-fill:focus:hover {
      color: #35baf6;
      text-decoration: none; }
  .btn-fill:active {
    color: #fff;
    text-decoration: none; }

.btn-border {
  background: none;
  padding: 17px 25%;
  border: solid 1px #626262;
  color: #626262;
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  transition: 0.6s ease; }
  .btn-border:hover {
    color: #fff;
    background: none;
    text-decoration: none;
    border: solid 1px #fff; }
  .btn-border:focus {
    color: #626262;
    text-decoration: none; }
    .btn-border:focus:hover {
      color: #fff;
      text-decoration: none; }
  .btn-border:active {
    color: #fff;
    text-decoration: none; }

.btn-text {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  display: block;
  margin: 10px 0 0 0;
  transition: 0.4s ease; }
  @media only screen and (max-width: 1279px) {
    .btn-text {
      margin: 10px 0 50px 0; } }
  .btn-text:hover i {
    margin-left: 20px; }
  .btn-text i {
    position: relative;
    top: 1px;
    margin-left: 10px;
    transition: 0.4s ease; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.4.0
========================================================================== */
[class~="grid"],
[class*="grid-"],
[class*="grid_"] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem; }

[class~="col"],
[class*="col-"],
[class*="col_"] {
  box-sizing: border-box;
  padding: 0 0.5rem 1rem;
  max-width: 100%; }

[class~="col"],
[class*="col_"] {
  flex: 1 1 0%; }

[class*="col-"] {
  flex: none; }

[class~="grid"][class~="col"],
[class~="grid"][class*="col-"],
[class~="grid"][class*="col_"],
[class*="grid-"][class~="col"],
[class*="grid-"][class*="col-"],
[class*="grid-"][class*="col_"],
[class*="grid_"][class~="col"],
[class*="grid_"][class*="col-"],
[class*="grid_"][class*="col_"] {
  margin: 0;
  padding: 0; }

/************************
    HELPERS SUFFIXES
*************************/
[class*="grid-"][class*="-noGutter"] {
  margin: 0; }
  [class*="grid-"][class*="-noGutter"] > [class~="col"],
  [class*="grid-"][class*="-noGutter"] > [class*="col-"] {
    padding: 0; }

[class*="grid-"][class*="-noWrap"] {
  flex-wrap: nowrap; }

[class*="grid-"][class*="-center"] {
  justify-content: center; }

[class*="grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto; }

[class*="grid-"][class*="-top"] {
  align-items: flex-start; }

[class*="grid-"][class*="-middle"] {
  align-items: center; }

[class*="grid-"][class*="-bottom"] {
  align-items: flex-end; }

[class*="grid-"][class*="-reverse"] {
  flex-direction: row-reverse; }

[class*="grid-"][class*="-column"] {
  flex-direction: column; }
  [class*="grid-"][class*="-column"] > [class*="col-"] {
    flex-basis: auto; }

[class*="grid-"][class*="-column-reverse"] {
  flex-direction: column-reverse; }

[class*="grid-"][class*="-spaceBetween"] {
  justify-content: space-between; }

[class*="grid-"][class*="-spaceAround"] {
  justify-content: space-around; }

[class*="grid-"][class*="-equalHeight"] > [class~="col"],
[class*="grid-"][class*="-equalHeight"] > [class*="col-"],
[class*="grid-"][class*="-equalHeight"] > [class*="col_"] {
  display: flex;
  flex-wrap: wrap; }
  [class*="grid-"][class*="-equalHeight"] > [class~="col"] > *,
  [class*="grid-"][class*="-equalHeight"] > [class*="col-"] > *,
  [class*="grid-"][class*="-equalHeight"] > [class*="col_"] > * {
    flex: 1 0 100%; }

[class*="grid-"][class*="-noBottom"] > [class~="col"],
[class*="grid-"][class*="-noBottom"] > [class*="col-"],
[class*="grid-"][class*="-noBottom"] > [class*="col_"] {
  padding-bottom: 0; }

[class*="col-"][class*="-top"] {
  align-self: flex-start; }

[class*="col-"][class*="-middle"] {
  align-self: center; }

[class*="col-"][class*="-bottom"] {
  align-self: flex-end; }

[class*="col-"][class*="-first"] {
  order: -1; }

[class*="col-"][class*="-last"] {
  order: 1; }

/************************
    GRID BY NUMBER
*************************/
[class*="grid-1"] > [class~="col"],
[class*="grid-1"] > [class*="col-"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="grid-2"] > [class~="col"],
[class*="grid-2"] > [class*="col-"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*="grid-3"] > [class~="col"],
[class*="grid-3"] > [class*="col-"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*="grid-4"] > [class~="col"],
[class*="grid-4"] > [class*="col-"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*="grid-5"] > [class~="col"],
[class*="grid-5"] > [class*="col-"] {
  flex-basis: 20%;
  max-width: 20%; }

[class*="grid-6"] > [class~="col"],
[class*="grid-6"] > [class*="col-"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*="grid-7"] > [class~="col"],
[class*="grid-7"] > [class*="col-"] {
  flex-basis: 14.28571%;
  max-width: 14.28571%; }

[class*="grid-8"] > [class~="col"],
[class*="grid-8"] > [class*="col-"] {
  flex-basis: 12.5%;
  max-width: 12.5%; }

[class*="grid-9"] > [class~="col"],
[class*="grid-9"] > [class*="col-"] {
  flex-basis: 11.11111%;
  max-width: 11.11111%; }

[class*="grid-10"] > [class~="col"],
[class*="grid-10"] > [class*="col-"] {
  flex-basis: 10%;
  max-width: 10%; }

[class*="grid-11"] > [class~="col"],
[class*="grid-11"] > [class*="col-"] {
  flex-basis: 9.09091%;
  max-width: 9.09091%; }

[class*="grid-12"] > [class~="col"],
[class*="grid-12"] > [class*="col-"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

@media screen and (max-width: 80em) {
  [class*="_lg-1"] > [class~="col"],
  [class*="_lg-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_lg-2"] > [class~="col"],
  [class*="_lg-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_lg-3"] > [class~="col"],
  [class*="_lg-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_lg-4"] > [class~="col"],
  [class*="_lg-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_lg-5"] > [class~="col"],
  [class*="_lg-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_lg-6"] > [class~="col"],
  [class*="_lg-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_lg-7"] > [class~="col"],
  [class*="_lg-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_lg-8"] > [class~="col"],
  [class*="_lg-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_lg-9"] > [class~="col"],
  [class*="_lg-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_lg-10"] > [class~="col"],
  [class*="_lg-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_lg-11"] > [class~="col"],
  [class*="_lg-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_lg-12"] > [class~="col"],
  [class*="_lg-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media screen and (max-width: 64em) {
  [class*="_md-1"] > [class~="col"],
  [class*="_md-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_md-2"] > [class~="col"],
  [class*="_md-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_md-3"] > [class~="col"],
  [class*="_md-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_md-4"] > [class~="col"],
  [class*="_md-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_md-5"] > [class~="col"],
  [class*="_md-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_md-6"] > [class~="col"],
  [class*="_md-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_md-7"] > [class~="col"],
  [class*="_md-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_md-8"] > [class~="col"],
  [class*="_md-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_md-9"] > [class~="col"],
  [class*="_md-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_md-10"] > [class~="col"],
  [class*="_md-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_md-11"] > [class~="col"],
  [class*="_md-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_md-12"] > [class~="col"],
  [class*="_md-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media screen and (max-width: 48em) {
  [class*="_sm-1"] > [class~="col"],
  [class*="_sm-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_sm-2"] > [class~="col"],
  [class*="_sm-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_sm-3"] > [class~="col"],
  [class*="_sm-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_sm-4"] > [class~="col"],
  [class*="_sm-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_sm-5"] > [class~="col"],
  [class*="_sm-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_sm-6"] > [class~="col"],
  [class*="_sm-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_sm-7"] > [class~="col"],
  [class*="_sm-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_sm-8"] > [class~="col"],
  [class*="_sm-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_sm-9"] > [class~="col"],
  [class*="_sm-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_sm-10"] > [class~="col"],
  [class*="_sm-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_sm-11"] > [class~="col"],
  [class*="_sm-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_sm-12"] > [class~="col"],
  [class*="_sm-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media screen and (max-width: 35.5em) {
  [class*="_xs-1"] > [class~="col"],
  [class*="_xs-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_xs-2"] > [class~="col"],
  [class*="_xs-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_xs-3"] > [class~="col"],
  [class*="_xs-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_xs-4"] > [class~="col"],
  [class*="_xs-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_xs-5"] > [class~="col"],
  [class*="_xs-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_xs-6"] > [class~="col"],
  [class*="_xs-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_xs-7"] > [class~="col"],
  [class*="_xs-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_xs-8"] > [class~="col"],
  [class*="_xs-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_xs-9"] > [class~="col"],
  [class*="_xs-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_xs-10"] > [class~="col"],
  [class*="_xs-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_xs-11"] > [class~="col"],
  [class*="_xs-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_xs-12"] > [class~="col"],
  [class*="_xs-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
[class*='grid'] > [class*="col-1"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

[class*='grid'] > [class*="col-2"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*='grid'] > [class*="col-3"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*='grid'] > [class*="col-4"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*='grid'] > [class*="col-5"] {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

[class*='grid'] > [class*="col-6"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*='grid'] > [class*="col-7"] {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

[class*='grid'] > [class*="col-8"] {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

[class*='grid'] > [class*="col-9"] {
  flex-basis: 75%;
  max-width: 75%; }

[class*='grid'] > [class*="col-10"] {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

[class*='grid'] > [class*="col-11"] {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

[class*='grid'] > [class*="col-12"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="grid"] > [data-push-left*="off-0"] {
  margin-left: 0; }

[class*="grid"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%; }

[class*="grid"] > [data-push-left*="off-2"] {
  margin-left: 16.66667%; }

[class*="grid"] > [data-push-left*="off-3"] {
  margin-left: 25%; }

[class*="grid"] > [data-push-left*="off-4"] {
  margin-left: 33.33333%; }

[class*="grid"] > [data-push-left*="off-5"] {
  margin-left: 41.66667%; }

[class*="grid"] > [data-push-left*="off-6"] {
  margin-left: 50%; }

[class*="grid"] > [data-push-left*="off-7"] {
  margin-left: 58.33333%; }

[class*="grid"] > [data-push-left*="off-8"] {
  margin-left: 66.66667%; }

[class*="grid"] > [data-push-left*="off-9"] {
  margin-left: 75%; }

[class*="grid"] > [data-push-left*="off-10"] {
  margin-left: 83.33333%; }

[class*="grid"] > [data-push-left*="off-11"] {
  margin-left: 91.66667%; }

[class*="grid"] > [data-push-right*="off-0"] {
  margin-right: 0; }

[class*="grid"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%; }

[class*="grid"] > [data-push-right*="off-2"] {
  margin-right: 16.66667%; }

[class*="grid"] > [data-push-right*="off-3"] {
  margin-right: 25%; }

[class*="grid"] > [data-push-right*="off-4"] {
  margin-right: 33.33333%; }

[class*="grid"] > [data-push-right*="off-5"] {
  margin-right: 41.66667%; }

[class*="grid"] > [data-push-right*="off-6"] {
  margin-right: 50%; }

[class*="grid"] > [data-push-right*="off-7"] {
  margin-right: 58.33333%; }

[class*="grid"] > [data-push-right*="off-8"] {
  margin-right: 66.66667%; }

[class*="grid"] > [data-push-right*="off-9"] {
  margin-right: 75%; }

[class*="grid"] > [data-push-right*="off-10"] {
  margin-right: 83.33333%; }

[class*="grid"] > [data-push-right*="off-11"] {
  margin-right: 91.66667%; }

@media screen and (max-width: 80em) {
  [class*='grid'] > [class*="_lg-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_lg-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_lg-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_lg-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_lg-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_lg-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_lg-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_lg-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_lg-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_lg-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_lg-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_lg-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_lg-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_lg-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_lg-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_lg-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_lg-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_lg-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_lg-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_lg-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_lg-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_lg-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_lg-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_lg-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_lg-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_lg-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_lg-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_lg-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_lg-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_lg-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_lg-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_lg-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_lg-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_lg-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_lg-first"] {
    order: -1; }
  [class*='grid'] [class*="_lg-last"] {
    order: 1; } }

@media screen and (max-width: 64em) {
  [class*='grid'] > [class*="_md-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_md-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_md-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_md-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_md-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_md-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_md-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_md-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_md-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_md-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_md-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_md-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_md-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_md-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_md-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_md-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_md-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_md-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_md-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_md-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_md-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_md-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_md-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_md-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_md-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_md-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_md-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_md-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_md-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_md-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_md-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_md-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_md-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_md-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_md-first"] {
    order: -1; }
  [class*='grid'] [class*="_md-last"] {
    order: 1; } }

@media screen and (max-width: 48em) {
  [class*='grid'] > [class*="_sm-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_sm-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_sm-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_sm-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_sm-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_sm-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_sm-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_sm-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_sm-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_sm-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_sm-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_sm-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_sm-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_sm-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_sm-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_sm-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_sm-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_sm-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_sm-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_sm-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_sm-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_sm-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_sm-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_sm-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_sm-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_sm-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_sm-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_sm-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_sm-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_sm-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_sm-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_sm-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_sm-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_sm-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_sm-first"] {
    order: -1; }
  [class*='grid'] [class*="_sm-last"] {
    order: 1; } }

@media screen and (max-width: 35.5em) {
  [class*='grid'] > [class*="_xs-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_xs-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_xs-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_xs-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_xs-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_xs-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_xs-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_xs-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_xs-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_xs-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_xs-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_xs-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_xs-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_xs-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_xs-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_xs-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_xs-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_xs-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_xs-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_xs-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_xs-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_xs-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_xs-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_xs-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_xs-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_xs-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_xs-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_xs-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_xs-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_xs-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_xs-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_xs-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_xs-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_xs-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_xs-first"] {
    order: -1; }
  [class*='grid'] [class*="_xs-last"] {
    order: 1; } }

/************************
    HIDING COLS
*************************/
[class*="col-"]:not([class*="grid"]):not([class*="col-0"]) {
  display: block; }

[class~="grid"][class*="col-"]:not([class*="col-0"]) {
  display: flex; }

[class*="col-"][class*="col-0"] {
  display: none; }

@media screen and (max-width: 80em) {
  [class*="grid"] > :not([class*="_lg-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_lg-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_lg-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_lg-0"] {
    display: none; } }

@media screen and (max-width: 64em) {
  [class*="grid"] > :not([class*="_md-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_md-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_md-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_md-0"] {
    display: none; } }

@media screen and (max-width: 48em) {
  [class*="grid"] > :not([class*="_sm-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_sm-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_sm-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_sm-0"] {
    display: none; } }

@media screen and (max-width: 35.5em) {
  [class*="grid"] > :not([class*="_xs-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_xs-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_xs-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_xs-0"] {
    display: none; } }

/* Menu
   ----------------------------- */
/* Menu
   ----------------------------- */
.menu-wrapper {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: none; }
  .menu-wrapper .menu-wrapper-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .menu-wrapper .menu-wrapper-content .loaded-content {
        display: none; } }
  .menu-wrapper .menu-wrapper-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 240px;
    height: 100%;
    z-index: 2; }
    @media only screen and (max-width: 1279px) {
      .menu-wrapper .menu-wrapper-nav {
        width: 200px; } }
    @media only screen and (max-width: 768px) {
      .menu-wrapper .menu-wrapper-nav {
        display: none; } }
    .menu-wrapper .menu-wrapper-nav #main-menu {
      position: absolute;
      bottom: 10%;
      right: 100px;
      text-align: right; }
      @media only screen and (max-width: 1279px) {
        .menu-wrapper .menu-wrapper-nav #main-menu {
          right: 75px;
          bottom: 75px; } }
      .menu-wrapper .menu-wrapper-nav #main-menu div {
        margin: 40px 0; }
        @media screen and (max-height: 620px) {
          .menu-wrapper .menu-wrapper-nav #main-menu div {
            margin: 20px 0; } }
        .menu-wrapper .menu-wrapper-nav #main-menu div:hover a {
          left: -10px; }
      .menu-wrapper .menu-wrapper-nav #main-menu a {
        color: #fff;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 1px;
        position: relative;
        left: 0;
        transition: 0.2s ease-out; }
  .menu-wrapper .nav-mobile {
    width: 100%;
    text-align: center;
    display: none;
    position: relative;
    z-index: 4; }
    @media only screen and (max-width: 768px) {
      .menu-wrapper .nav-mobile {
        display: block; } }
    .menu-wrapper .nav-mobile ul {
      width: 100%;
      list-style: none;
      margin-top: 50px; }
      .menu-wrapper .nav-mobile ul li {
        width: 100%;
        display: block;
        border-bottom: solid 1px #29282b; }
        .menu-wrapper .nav-mobile ul li:last-child {
          border: 0; }
        .menu-wrapper .nav-mobile ul li a {
          width: 100%;
          display: block;
          color: #fff;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 16px;
          letter-spacing: 1px;
          position: relative;
          padding: 20px 20px;
          right: 0;
          touch-action: manipulation;
          transition: 0.2s ease-out; }
          .menu-wrapper .nav-mobile ul li a:hover {
            background: #0c0c0c; }

.menu-svg {
  position: fixed;
  bottom: -1px;
  left: -1px;
  pointer-events: none;
  z-index: 11;
  overflow: hidden; }
  .menu-svg path {
    transform-origin: 0px 0px 0px;
    fill: #19181a; }

.contact-info {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 80px;
  display: grid;
  grid-template-rows: 1fr 50px 1fr; }
  @media only screen and (max-width: 768px) {
    .contact-info {
      display: flex;
      flex-direction: column;
      gap: 30px; } }
  .contact-info .contact-row {
    display: flex;
    width: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .contact-info .contact-row {
        display: flex;
        flex-direction: column;
        gap: 30px; } }
    .contact-info .contact-row .contact-column {
      display: flex;
      flex-direction: column;
      width: 50%; }
      @media only screen and (max-width: 768px) {
        .contact-info .contact-row .contact-column {
          width: 100%; } }
      .contact-info .contact-row .contact-column .contact-icon-wrapper {
        margin-bottom: 15px;
        height: 50px; }
      .contact-info .contact-row .contact-column .country {
        font-weight: bold;
        font-size: 16px; }
  @media only screen and (max-width: 768px) {
    .contact-info .contact-line {
      display: none; } }

.maps-wrapper {
  border: 1px solid red; }

.mCSB_dragger_bar {
  position: relative; }
  .mCSB_dragger_bar:after {
    content: url(../img/scroll_handler.png);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.6s ease-out; }
  .mCSB_dragger_bar.down:after {
    transform: translate(-50%, -50%) rotate(180deg); }

.loaded-content {
  width: 100%;
  height: 100%;
  position: relative; }
  .loaded-content .title {
    margin-left: 50px;
    font-size: 70px;
    font-weight: 300;
    line-height: 85px; }
    @media only screen and (max-width: 1440px) {
      .loaded-content .title {
        font-size: 45px;
        line-height: 65px; } }
    @media only screen and (max-width: 1279px) {
      .loaded-content .title {
        font-size: 36px;
        line-height: 40px; } }
    @media (max-width: 768px) {
      .loaded-content .title {
        margin-left: 0; } }

.ludzie-subpage {
  position: relative;
  width: 100%;
  height: 100%; }
  .ludzie-subpage .title-abs {
    font-size: 70px;
    font-weight: 300;
    line-height: 85px;
    position: relative;
    z-index: 2;
    top: 100px;
    left: 100px; }
    @media only screen and (max-width: 1279px) {
      .ludzie-subpage .title-abs {
        top: 75px;
        left: 75px; } }
  .ludzie-subpage .position {
    display: inline-block;
    padding: 0 0 0 30px;
    font-size: 12px;
    color: #7b7878;
    text-align: left; }
  .ludzie-subpage .video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    height: 100%;
    width: 100%; }
    .ludzie-subpage .video-wrapper #video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: auto;
      z-index: -1000;
      overflow: hidden;
      background-image: url("../img/loader.gif");
      background-repeat: no-repeat;
      background-size: 140px 140px;
      background-position: center;
      object-fit: cover; }
    .ludzie-subpage .video-wrapper .video-menu {
      position: absolute;
      top: 57%;
      transform: translateY(-50%);
      left: 100px;
      width: 300px;
      list-style: none; }
      @media only screen and (max-width: 1279px) {
        .ludzie-subpage .video-wrapper .video-menu {
          bottom: 75px;
          left: 75px; } }
      @media screen and (max-height: 530px) {
        .ludzie-subpage .video-wrapper .video-menu {
          display: none; } }
      .ludzie-subpage .video-wrapper .video-menu__wrapper {
        width: 100%; }
        .ludzie-subpage .video-wrapper .video-menu__wrapper .slick-list {
          width: 100%; }
        .ludzie-subpage .video-wrapper .video-menu__wrapper .slick-slide {
          display: flex;
          justify-content: flex-start;
          align-items: baseline; }
      .ludzie-subpage .video-wrapper .video-menu li {
        width: 100%;
        padding: 10px 0 5px 0; }
        @media screen and (max-height: 660px) {
          .ludzie-subpage .video-wrapper .video-menu li {
            padding: 10px 0 0 0; } }
        @media screen and (max-height: 600px) {
          .ludzie-subpage .video-wrapper .video-menu li {
            padding: 5px 0 0 0; } }
        .ludzie-subpage .video-wrapper .video-menu li .person-link {
          font-size: 20px;
          color: #fff;
          position: relative; }
          @media screen and (max-height: 750px) {
            .ludzie-subpage .video-wrapper .video-menu li .person-link {
              font-size: 14px; } }
          .ludzie-subpage .video-wrapper .video-menu li .person-link:focus {
            outline: 0 !important; }
          .ludzie-subpage .video-wrapper .video-menu li .person-link span {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            color: #7b7878;
            overflow: hidden; }
            .ludzie-subpage .video-wrapper .video-menu li .person-link span.active {
              animation: progressAnimation 10s linear; }

@keyframes progressAnimation {
  from {
    width: 0; }
  to {
    width: 100%; } }

.praca-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px; }
  .praca-subpage .title-sub {
    font-size: 24px;
    max-width: 60%;
    margin-left: 50px; }
    @media (max-width: 768px) {
      .praca-subpage .title-sub {
        margin-left: 0;
        max-width: 80%; } }
  .praca-subpage .desc {
    font-size: 18px;
    max-width: 60%;
    margin-left: 50px; }
    @media (max-width: 768px) {
      .praca-subpage .desc {
        margin-left: 0;
        max-width: 80%; } }
  .praca-subpage .btn-border {
    margin-left: 50px; }
    @media (max-width: 768px) {
      .praca-subpage .btn-border {
        margin-left: 0; } }
  @media only screen and (max-width: 1279px) {
    .praca-subpage {
      padding: 75px 200px 75px 75px; } }
  @media only screen and (max-width: 768px) {
    .praca-subpage {
      padding: 30px; } }
  .praca-subpage .small-text {
    font-size: 12px;
    line-height: 22px;
    color: #444444;
    max-width: 800px;
    margin: 30px 0 0 0; }
    .praca-subpage .small-texta {
      text-decoration: underline; }
    .praca-subpage .small-text span {
      font-style: italic; }
  .praca-subpage p {
    margin: 0 0 20px 0; }
  .praca-subpage #accordion-jobs {
    margin: 80px 0 0 0; }
    .praca-subpage #accordion-jobs a {
      color: #757575; }
    .praca-subpage #accordion-jobs ul {
      list-style: circle;
      margin: 20px 0; }
      .praca-subpage #accordion-jobs ul li {
        padding: 3px 0 3px 7px;
        margin: 0 0 0 20px; }
  .praca-subpage .ui-state-active {
    background: none;
    border: 0; }
  .praca-subpage .ui-state-default {
    color: #fff;
    background: none;
    border: 0; }
  .praca-subpage .ui-accordion .ui-accordion-content {
    padding: 20px 20px 20px 62px;
    background: none;
    border: 0;
    color: #fff; }
    @media only screen and (max-width: 768px) {
      .praca-subpage .ui-accordion .ui-accordion-content {
        padding: 20px; } }
  .praca-subpage .ui-accordion .ui-accordion-header {
    padding: 10px 10px 10px 20px;
    text-transform: uppercase;
    outline: 0;
    position: relative; }
  .praca-subpage .ui-state-active .ui-icon, .praca-subpage .ui-button:active .ui-icon {
    background-image: url("../img/accordion_arrow.png");
    padding: 0 25px; }
  .praca-subpage .ui-icon-triangle-1-s {
    background-position: 0 5px;
    position: absolute;
    top: 13px;
    left: 0; }
  .praca-subpage .ui-icon, .praca-subpage .ui-widget-content .ui-icon {
    background-image: url("../img/accordion_arrow_closed.png");
    padding: 0 25px; }
  .praca-subpage .ui-icon-triangle-1-e {
    background-position: 0 5px;
    position: absolute;
    top: 13px;
    left: 0; }
  .praca-subpage .jobs-footer {
    margin: 80px 0 0 0;
    max-width: 1000px; }
    .praca-subpage .jobs-footer .job-desc {
      font-size: 12px;
      line-height: 22px; }
      @media only screen and (max-width: 768px) {
        .praca-subpage .jobs-footer .job-desc {
          margin: 20px 0; } }
      .praca-subpage .jobs-footer .job-desc span {
        color: #717171; }

.clients-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px; }
  @media only screen and (max-width: 1279px) {
    .clients-subpage {
      padding: 75px 200px 75px 75px; } }
  @media only screen and (max-width: 768px) {
    .clients-subpage {
      padding: 30px; } }
  .clients-subpage .clients-logo {
    margin: 150px 0 0 0;
    max-width: 900px; }
    @media only screen and (max-width: 1440px) {
      .clients-subpage .clients-logo {
        margin: 50px 0 0 0; } }
    .clients-subpage .clients-logo .client-logo-wrapper {
      width: 100%;
      height: 150px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      justify-content: center; }
      @media only screen and (max-width: 768px) {
        .clients-subpage .clients-logo .client-logo-wrapper {
          height: 100px; } }
      @media only screen and (max-width: 768px) {
        .clients-subpage .clients-logo .client-logo-wrapper img {
          width: 80px; } }
      .clients-subpage .clients-logo .client-logo-wrapper .logo-brand {
        max-width: 120px; }
        .clients-subpage .clients-logo .client-logo-wrapper .logo-brand--vertical {
          max-height: 75px; }
    @media only screen and (max-width: 768px) {
      .clients-subpage .clients-logo .wmf-logo {
        width: 40px !important; } }
    @media only screen and (max-width: 768px) {
      .clients-subpage .clients-logo .ucb-logo {
        width: 50px !important; } }
    @media only screen and (max-width: 768px) {
      .clients-subpage .clients-logo .pfizer-logo {
        width: 60px !important; } }

.agency-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px; }
  @media only screen and (max-width: 1279px) {
    .agency-subpage {
      padding: 75px 200px 75px 75px; } }
  @media only screen and (max-width: 768px) {
    .agency-subpage {
      padding: 30px; } }
  .agency-subpage .cm-pin {
    width: 18px;
    margin: 40px 0 0 0; }
    .agency-subpage .cm-pin .pin-svg {
      fill: #fff; }
  .agency-subpage .about-us {
    font-size: 16px;
    color: #787878;
    line-height: 30px;
    font-weight: 300;
    max-width: 860px;
    margin: 60px 0 0 0; }
    .agency-subpage .about-us span {
      font-weight: 500; }
  .agency-subpage .about-us-more {
    font-size: 24px;
    color: #fff;
    line-height: 40px;
    font-weight: 300;
    margin: 80px 0 0 0; }
    .agency-subpage .about-us-more span {
      font-weight: 700; }

.portfolio-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0; }
  .portfolio-subpage .menu-case-client {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 300;
    color: #444147;
    text-transform: uppercase;
    text-align: center;
    padding: 150px 0 0 0;
    position: relative;
    z-index: 1; }
  .portfolio-subpage .menu-case-title {
    text-align: center;
    font-size: 30px;
    letter-spacing: 5px;
    color: #fff;
    font-weight: 200;
    padding: 60px 0 0 0;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 1;
    min-height: 165px;
    transition: 0.3s ease; }
    @media only screen and (max-width: 1279px) {
      .portfolio-subpage .menu-case-title {
        font-size: 26px; } }
  .portfolio-subpage .portfolio-slider {
    width: 100%;
    padding-right: 250px; }
    @media only screen and (max-width: 768px) {
      .portfolio-subpage .portfolio-slider {
        padding: 0; } }
    .portfolio-subpage .portfolio-slider .slick-dots {
      bottom: 50px; }
    .portfolio-subpage .portfolio-slider .slick-dots li.slick-active button:before {
      color: #fff;
      opacity: 1; }
    .portfolio-subpage .portfolio-slider .slick-dots li button:before {
      font-family: 'slick';
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: '•';
      text-align: center;
      opacity: .25;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .portfolio-subpage .portfolio-slider .slick-dots li button:before {
      font-size: 10px;
      line-height: 20px; }
    .portfolio-subpage .portfolio-slider .item {
      display: block;
      height: 100vh;
      padding: 0 20px;
      background: #19181a;
      border-right: solid 1px #2d2c2d; }
      .portfolio-subpage .portfolio-slider .item .menu-case-photo {
        width: initial;
        margin: 100px auto 0 auto;
        transition: 0.3s ease;
        max-width: 160px;
        max-height: 180px; }
      .portfolio-subpage .portfolio-slider .item:hover .menu-case-title {
        padding: 40px 0 0 0; }
      .portfolio-subpage .portfolio-slider .item:hover img {
        transform: scale(1.1); }

.contact-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px; }
  @media only screen and (max-width: 1279px) {
    .contact-subpage {
      padding: 75px 200px 75px 75px; } }
  @media only screen and (max-width: 768px) {
    .contact-subpage {
      padding: 30px; } }
  .contact-subpage .row {
    padding: 8% 0 0 0;
    max-width: 900px; }
  .contact-subpage .icon-wrapper {
    height: 70px; }
    @media only screen and (max-width: 768px) {
      .contact-subpage .icon-wrapper {
        height: 50px;
        margin-top: 50px; } }
    .contact-subpage .icon-wrapper .map-trigger {
      cursor: pointer;
      position: relative;
      top: 0;
      transition: 0.3s ease; }
  .contact-subpage .map-trigger-wrapper {
    display: inline-block;
    cursor: pointer; }
    .contact-subpage .map-trigger-wrapper:hover .map-trigger {
      top: -10px; }
  .contact-subpage a {
    color: #757575; }
    .contact-subpage a:hover {
      text-decoration: underline; }
  .contact-subpage address {
    font-style: normal;
    line-height: 28px; }
  .contact-subpage .info {
    line-height: 28px; }
  .contact-subpage .contact-footer {
    font-size: 12px;
    line-height: 20px;
    color: #444444;
    max-width: 800px;
    margin: 50px 0 0 0; }
  .contact-subpage .map-link {
    display: none; }
    @media only screen and (max-width: 768px) {
      .contact-subpage .map-link {
        display: block; } }
    .contact-subpage .map-link svg {
      width: 10px;
      position: relative;
      top: 2px;
      left: 6px; }
      .contact-subpage .map-link svg path {
        fill: none;
        stroke: #757575;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10; }

.map-popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #eee;
  z-index: 12;
  display: none; }
  .map-popup #googleMapPopup {
    width: 100%;
    height: 100%; }

.close-map-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  background: #19181a;
  z-index: 21;
  cursor: pointer; }
  .close-map-btn:hover .bars {
    transform: scale(1.3) translate(-50%, -50%); }
    .close-map-btn:hover .bars .bar {
      background: #fff; }
  .close-map-btn .bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    transform: translate(-50%, -50%);
    transition: 0.3s ease;
    /* Force Hardware Acceleration */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
    .close-map-btn .bars .bar {
      height: 1px;
      width: 24px;
      background: #fff;
      margin-bottom: 10px;
      position: relative;
      top: 0;
      transition: 0.4s ease;
      transform-origin: center center; }
      .close-map-btn .bars .bar:first-child {
        width: 21px; }
      .close-map-btn .bars .bar:last-child {
        width: 17px;
        margin-bottom: 0;
        top: 0;
        opacity: 1; }
  .close-map-btn.open .bar {
    transform: rotate(45deg); }
    .close-map-btn.open .bar:first-child {
      width: 24px;
      top: 11px;
      transform: rotate(-45deg); }
    .close-map-btn.open .bar:last-child {
      top: -10px;
      opacity: 0; }

.portfolio-content {
  width: 100%;
  position: relative; }
  .portfolio-content .portfolio-item {
    width: 50%;
    height: 187px;
    float: left;
    background: #eee;
    display: block; }
    .portfolio-content .portfolio-item .portfolio-name {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 300;
      color: #948076;
      text-transform: uppercase;
      text-align: center;
      margin: 30px 0 15px 0; }
    .portfolio-content .portfolio-item .portfolio-title {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 200;
      text-align: center;
      padding: 0 10px; }
    .portfolio-content .portfolio-item.zelazka {
      background: url("../img/realizacje_zelazka.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.zelazka .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.zelazka .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.tech-wizards {
      background: url("../img/tech-wizards/header_03.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.tech-wizards .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.tech-wizards .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.bepanthen {
      background: url("../img/bepanthen/header.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.bepanthen .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.bepanthen .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.velo {
      background: url("../img/velo/header.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.velo .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.velo .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.morliny {
      background: url("../img/morliny/header.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.morliny .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.morliny .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.zyrtec {
      background: url("../img/realizacje_zyrtec.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.zyrtec .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.zyrtec .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.unicef {
      background: url("../img/realizacje_unicef.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.unicef .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.unicef .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.kompan {
      background: url("../img/realizacje_kompan.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.kompan .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.kompan .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.sferis {
      background: url("../img/realizacje_sferis.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.sferis .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.sferis .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.bloniapark {
      background: url("../img/realizacje_blonia.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.bloniapark .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.bloniapark .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.patio {
      background: url("../img/realizacje_patio.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.patio .portfolio-name {
        color: #000; }
      .portfolio-content .portfolio-item.patio .portfolio-title {
        color: #000; }
    .portfolio-content .portfolio-item.chiesi {
      background: url("../img/realizacje_chiesi.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.chiesi .portfolio-name {
        color: #000; }
      .portfolio-content .portfolio-item.chiesi .portfolio-title {
        color: #000; }
    .portfolio-content .portfolio-item.lekam {
      background: url("../img/realizacje_lekam.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.lekam .portfolio-name {
        color: #000; }
      .portfolio-content .portfolio-item.lekam .portfolio-title {
        color: #000; }
    .portfolio-content .portfolio-item.genexo {
      background: url("../img/realizacje_zdrowisie.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.genexo .portfolio-name {
        color: #000; }
      .portfolio-content .portfolio-item.genexo .portfolio-title {
        color: #000; }
    .portfolio-content .portfolio-item.gostar {
      background: url("../img/realizacje_gostar.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.gostar .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.gostar .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.bracia-sadownicy {
      background: url("../img/realizacje_bracia_sadownicy.jpg") no-repeat center center/cover; }
      .portfolio-content .portfolio-item.bracia-sadownicy .portfolio-name {
        color: #fff; }
      .portfolio-content .portfolio-item.bracia-sadownicy .portfolio-title {
        color: #fff; }
    .portfolio-content .portfolio-item.go-top {
      background: #1c1b1d;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center; }
      .portfolio-content .portfolio-item.go-top .pin-trigger {
        width: 25px;
        overflow: visible; }
        .portfolio-content .portfolio-item.go-top .pin-trigger .pin-svg {
          fill: #fff; }

.team-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: wrap; }
  .team-content .team-item {
    position: relative; }
    .team-content .team-item__mask {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 2; }
    .team-content .team-item__content {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      color: #fff; }
    .team-content .team-item__name {
      font-size: 24px; }
    .team-content .team-item__position {
      font-size: 15px; }
  .team-content .team-item {
    width: 50%;
    float: left;
    background: #2d2d2d;
    display: block; }
    .team-content .team-item img {
      display: block;
      width: 100%;
      z-index: 1; }
    .team-content .team-item .team-name {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 300;
      color: #948076;
      text-transform: uppercase;
      text-align: center;
      margin: 30px 0 15px 0; }
    .team-content .team-item .team-title {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 200;
      text-align: center;
      padding: 0 10px; }

.video-slick-arrows {
  position: absolute;
  left: 0;
  transition: .3s ease;
  height: 100%;
  z-index: 80;
  cursor: pointer; }
  .video-slick-arrows__top {
    position: absolute;
    transition: .3s ease;
    top: -80px; }
    .video-slick-arrows__top:hover {
      transform: translateY(-4px); }
  .video-slick-arrows__bottom {
    transition: .3s ease;
    position: absolute;
    bottom: -100px; }
    .video-slick-arrows__bottom:hover {
      transform: translateY(4px); }
  .video-slick-arrows svg {
    width: 18px; }

#video-viewport img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
  height: 100%; }

/* Homepage
   ----------------------------- */
.logo-wrapper {
  width: 135px;
  position: fixed;
  top: 100px;
  left: 100px;
  cursor: pointer;
  z-index: 10;
  display: block;
  overflow: visible !important; }
  .logo-wrapper svg {
    position: relative;
    z-index: 2; }
  .logo-wrapper .logo-svg {
    fill: #fff; }

.subpage .lang {
  right: 235px; }

.lang {
  z-index: 10;
  position: fixed;
  top: 103px;
  right: 170px; }
  .lang__button {
    cursor: pointer;
    margin-top: 10px;
    transition: .3s ease; }
    .lang__button:nth-child(1) {
      margin-right: 8px; }
    .lang__button:nth-child(2) {
      margin-right: 8px; }
    .lang__button:hover {
      transform: translateY(-3px); }
    .lang__button.is-active span {
      font-weight: 800; }
  .lang__list {
    position: absolute;
    top: -10px;
    display: flex;
    left: -56px; }
  .lang__active span {
    cursor: pointer; }
  .lang--case {
    right: 240px; }
  .lang a {
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    display: inline-block; }
    .lang a span {
      position: relative;
      bottom: 0;
      transition: 0.3s; }

.btn-menu {
  display: block;
  position: fixed;
  top: 135px;
  right: 100px;
  cursor: pointer;
  margin-top: 5px;
  padding: 15px;
  z-index: 11; }
  .btn-menu svg {
    width: 23px; }
  .btn-menu .circle, .btn-menu .linesX {
    visibility: hidden; }

.case-arrow-back {
  position: fixed;
  right: 170px;
  top: 92px;
  width: 40px;
  height: 40px;
  display: block;
  z-index: 10;
  cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .case-arrow-back {
      display: none; } }
  .case-arrow-back svg {
    display: block; }
  .case-arrow-back .arrow-line {
    fill: none;
    stroke: #000;
    stroke-width: 1.3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }
  .case-arrow-back.light .arrow-line {
    stroke: #fff; }

.menu-button {
  display: block;
  position: fixed;
  top: 85px;
  right: 100px;
  cursor: pointer;
  margin-top: 5px;
  padding: 15px;
  z-index: 110;
  touch-action: manipulation; }
  .menu-button .bar {
    display: block;
    width: 23px;
    height: 2px;
    background: #fff;
    position: relative;
    left: 0;
    top: 0;
    transition: 0.3s ease; }
    .menu-button .bar:first-child {
      margin-bottom: 8px; }
  .menu-button.open .bar:first-child {
    transform: rotate(45deg);
    top: 5px; }
  .menu-button.open .bar:last-child {
    transform: rotate(-45deg);
    top: -5px; }
  .menu-button.open:hover .bar {
    left: 0;
    background: #a7a7a7 !important; }
    .menu-button.open:hover .bar:first-child {
      left: 0; }
  .menu-button:hover .bar {
    left: 4px; }
    .menu-button:hover .bar:first-child {
      left: -4px; }

body.dark-ui .logo-wrapper .logo-svg {
  fill: #000; }

body.dark-ui .menu-button .bar {
  background: #000; }

body.dark-ui .lang a span {
  color: #000; }

body.dark-ui .slick-dots li.slick-active button:before {
  color: #000;
  opacity: 1; }

body.dark-ui .slick-dots li button:before {
  font-family: 'slick';
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body.dark-ui .slick-dots li button:before {
  font-size: 10px;
  line-height: 20px; }

#page-wrapper {
  width: 100%;
  height: 100.1vh;
  background-color: #1c1b1d;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition: background-color 0.5s ease; }
  #page-wrapper.subpage {
    display: block;
    height: auto; }
  #page-wrapper.menu-page {
    padding-top: 75px; }
    #page-wrapper.menu-page:after {
      content: "";
      display: none;
      background: #1c1b1d;
      width: 100%;
      height: 75px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9; }
      @media only screen and (max-width: 768px) {
        #page-wrapper.menu-page:after {
          display: block; } }

#page-wrapper.home-homepage .social-icon {
  fill: #575757; }

#page-wrapper.home-homepage .case-arrow {
  display: none; }

#page-wrapper.case1-homepage {
  height: 100vh;
  background-color: #1A191C; }
  #page-wrapper.case1-homepage .copyrights, #page-wrapper.case1-homepage .cookies-button, #page-wrapper.case1-homepage .case-title, #page-wrapper.case1-homepage .slogan {
    color: #fff; }
  #page-wrapper.case1-homepage .duck-svg, #page-wrapper.case1-homepage .sound-svg, #page-wrapper.case1-homepage .logo-svg {
    fill: #fff; }
  #page-wrapper.case1-homepage .case-subtitle {
    color: #72675b; }

#page-wrapper.case2-homepage {
  height: 100vh;
  background-color: #edf3f9; }
  #page-wrapper.case2-homepage .copyrights, #page-wrapper.case2-homepage .cookies-button, #page-wrapper.case2-homepage .case-title, #page-wrapper.case2-homepage .slogan, #page-wrapper.case2-homepage .case-title-subpage {
    color: #252730; }
  #page-wrapper.case2-homepage .social-icon, #page-wrapper.case2-homepage .logo-svg, #page-wrapper.case2-homepage .pin-svg {
    fill: #252730 !important; }
  #page-wrapper.case2-homepage .dot-svg, #page-wrapper.case2-homepage .arrow-line {
    stroke: #252730 !important; }
  #page-wrapper.case2-homepage .bar {
    background: #252730; }
  #page-wrapper.case2-homepage .pulse {
    border: solid 1px #252730; }
  #page-wrapper.case2-homepage .case-subtitle {
    color: #8a8383; }
  #page-wrapper.case2-homepage .lang a {
    color: #000; }
  #page-wrapper.case2-homepage .lang span {
    color: #252730; }

#page-wrapper.case3-homepage {
  height: 100vh;
  background-color: #000; }
  #page-wrapper.case3-homepage .case-subtitle {
    color: #545b72; }
  #page-wrapper.case3-homepage .case-title, #page-wrapper.case3-homepage .copyrights, #page-wrapper.case3-homepage .cookies-button, #page-wrapper.case3-homepage .slogan {
    color: #fff; }

#page-wrapper.case4-homepage {
  height: 100vh;
  background-color: #f2f2f2; }
  #page-wrapper.case4-homepage .copyrights, #page-wrapper.case4-homepage .cookies-button .case-title, #page-wrapper.case4-homepage .slogan, #page-wrapper.case4-homepage .case-title-subpage {
    color: #252730; }
  #page-wrapper.case4-homepage .social-icon, #page-wrapper.case4-homepage .logo-svg, #page-wrapper.case4-homepage .pin-svg {
    fill: #252730 !important; }
  #page-wrapper.case4-homepage .dot-svg, #page-wrapper.case4-homepage .arrow-line {
    stroke: #252730 !important; }
  #page-wrapper.case4-homepage .bar {
    background: #252730; }
  #page-wrapper.case4-homepage .pulse {
    border: solid 1px #252730; }
  #page-wrapper.case4-homepage .case-subtitle {
    color: #8a8383; }
  #page-wrapper.case4-homepage .lang a {
    color: #000; }
  #page-wrapper.case4-homepage .lang span {
    color: #252730; }

#page-wrapper.case5-homepage {
  height: 100vh;
  background-color: #000r; }
  #page-wrapper.case5-homepage .copyrights, #page-wrapper.case5-homepage .cookies-button, #page-wrapper.case5-homepage .case-title, #page-wrapper.case5-homepage .slogan {
    color: #fff; }
  #page-wrapper.case5-homepage .duck-svg, #page-wrapper.case5-homepage .sound-svg, #page-wrapper.case5-homepage .logo-svg {
    fill: #fff; }
  #page-wrapper.case5-homepage .case-subtitle {
    color: #72675b; }

.case-title {
  position: absolute;
  left: 50%;
  top: 29%;
  color: #1c1b1d;
  transform: translateX(-50%);
  font-size: 60px;
  letter-spacing: 5px;
  font-weight: 200;
  text-align: center;
  opacity: 0; }
  @media only screen and (max-width: 1440px) {
    .case-title {
      font-size: 50px; } }

.case-subtitle {
  position: absolute;
  left: 50%;
  top: 22%;
  transform: translateX(-50%);
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #948076;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px 0;
  visibility: hidden;
  opacity: 0; }
  @media only screen and (max-width: 1279px) {
    .case-subtitle {
      display: none; } }

.case-arrow {
  position: absolute;
  left: 50%;
  top: 70%;
  width: 55px;
  height: 55px;
  padding: 15px;
  display: block;
  opacity: 0;
  transform: translateX(-50%); }
  @media only screen and (max-width: 1279px) {
    .case-arrow {
      display: none; } }
  .case-arrow svg {
    display: block; }
  .case-arrow .arrow-line {
    fill: none;
    stroke: #fff;
    stroke-width: 1.1;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10; }

.case-title-subpage {
  text-align: center;
  font-size: 60px;
  letter-spacing: 5px;
  font-weight: 200;
  padding: 20px 0 0 0;
  margin: 0 0 100px 0;
  width: 100%;
  position: relative;
  z-index: 1; }

.case-subtitle-subpage {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #948076;
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 1279px) {
    .case-subtitle-subpage {
      padding: 0; } }

/*
 * Pulse animation
*/
@keyframes pulsate {
  0% {
    transform: scale(0.2, 0.2);
    opacity: 0.0; }
  50% {
    opacity: 8.0; }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0; } }

.time {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%); }

.canvas {
  width: 1170px;
  margin: 0 auto;
  position: relative;
  overflow: visible; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .canvas {
      transform: scale(0.8); } }
  .canvas .btn-case {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: none;
    position: absolute;
    left: -25px;
    top: -23px;
    z-index: 5;
    cursor: pointer; }
    .canvas .btn-case:hover .pulse {
      display: block; }
    .canvas .btn-case .pulse {
      border: 1px solid #fff;
      border-radius: 50%;
      height: 38px;
      width: 38px;
      animation: pulsate 1.5s ease-out;
      animation-iteration-count: infinite;
      opacity: 0.0;
      position: absolute;
      top: 15%;
      left: 12%;
      z-index: 3;
      display: none; }
  .canvas .btn-case1 {
    left: 206px; }
  .canvas .btn-case2 {
    left: 444px; }
  .canvas .btn-case3 {
    left: 675px; }
  .canvas .btn-case4 {
    left: 913px; }
  .canvas .btn-case5 {
    left: 1145px; }
  .canvas #case-line {
    position: relative;
    z-index: 1; }
  .canvas .dot-svg {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    stroke-width: 1px;
    stroke-miterlimit: 10;
    opacity: 0.5; }
  .canvas .case-svg {
    stroke-width: 2px;
    stroke-miterlimit: 10;
    opacity: 0.7; }
  .canvas .pin-wrapper {
    width: 1195px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%); }
    .canvas .pin-wrapper #controls {
      width: 100%; }
      .canvas .pin-wrapper #controls .ui-state-default, .canvas .pin-wrapper #controls .ui-widget-content .ui-state-default, .canvas .pin-wrapper #controls .ui-widget-header .ui-state-default {
        background: #eee;
        border: 0; }
      .canvas .pin-wrapper #controls .ui-widget-content {
        border: 0;
        background: rgba(255, 255, 255, 0);
        color: #333;
        height: 1px; }
      .canvas .pin-wrapper #controls .ui-slider-handle {
        border: 0;
        outline: 0;
        background: none;
        cursor: pointer; }
  .canvas .pin-trigger {
    width: 25px;
    overflow: visible; }
    .canvas .pin-trigger .pin-svg {
      fill: #fff; }

.main-content {
  width: 100%; }

.non-ios {
  display: none; }
  .non-ios--visible {
    display: block; }

.ios {
  display: none; }
  .ios .tilt-back {
    animation: animate 1.5s infinite; }

@keyframes animate {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-0.3rem); }
  100% {
    transform: translateX(0); } }
  .ios--visible {
    display: block; }

.footer {
  width: 100%;
  color: #575757;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 100px 100px 100px;
  font-size: 10px;
  text-transform: uppercase;
  overflow: auto; }
  .footer .copyrights {
    float: left; }
    .footer .copyrights .cookies-button {
      border: none;
      background: transparent;
      color: #575757;
      font-size: 10px;
      padding: 0 20px; }
  .footer .slogan {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .footer .features {
    float: right; }
    .footer .features .footer-icon {
      width: 22px;
      display: inline-block;
      margin-left: 10px;
      padding: 5px; }
      .footer .features .footer-icon .social-icon {
        fill: #fff; }
      .footer .features .footer-icon svg {
        position: relative;
        bottom: 0;
        transition: 0.3s ease; }
      .footer .features .footer-icon:hover svg {
        bottom: 5px; }
      .footer .features .footer-icon.facebook {
        width: 17px; }

.landscape-lock {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background: #1c1b1d;
  display: none;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center; }
  @media (max-device-width: 480px) and (orientation: landscape) {
    .landscape-lock {
      display: -webkit-flex;
      display: flex; } }
  .landscape-lock .tilt-wrapper .tilt-icon {
    position: relative;
    width: 48px;
    margin: 0 auto; }
    .landscape-lock .tilt-wrapper .tilt-icon .rotate-front {
      position: absolute;
      bottom: 4px;
      left: 2px;
      width: 46px; }
      @media only screen and (max-width: 1279px) {
        .landscape-lock .tilt-wrapper .tilt-icon .rotate-front {
          display: block; } }
  .landscape-lock .tilt-wrapper .tilt-desc {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin: 20px;
    letter-spacing: 1px; }

/* Cases
   ----------------------------- */
.section {
  -webkit-transform: translate3d(0, 0, 0); }

.intro {
  width: 100%;
  height: 100vh;
  padding: 75px 0 0 0;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center; }
  .intro:after {
    content: "";
    display: none;
    background: #fff;
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9; }
    @media only screen and (max-width: 768px) {
      .intro:after {
        display: block; } }

#fullpage-menu {
  transform: translateY(-50%);
  position: fixed;
  z-index: 10;
  right: 17px;
  top: 50%;
  opacity: 1;
  display: block; }
  #fullpage-menu ul {
    margin: 0;
    padding: 0;
    margin-right: 30px; }
    #fullpage-menu ul li {
      width: 5px;
      height: 5px;
      margin-bottom: 40px;
      display: block;
      position: relative; }
      #fullpage-menu ul li:last-child {
        margin-bottom: 0; }
      #fullpage-menu ul li:first-child a::after {
        display: none; }
      #fullpage-menu ul li:hover .fp-tooltip {
        transition: opacity 0.2s ease-in;
        width: auto;
        opacity: 1; }
      #fullpage-menu ul li a {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none; }
        #fullpage-menu ul li a:after {
          content: "....................................";
          width: 30px;
          height: 11px;
          overflow: hidden;
          color: #cdd1e1;
          font-size: 11px;
          letter-spacing: 4px;
          position: absolute;
          left: 13px;
          top: -7px;
          transform-origin: left top;
          transform: rotate(90deg) translateX(-100%);
          pointer-events: none; }
        #fullpage-menu ul li a:hover span {
          width: 8px;
          height: 8px;
          margin: -5px 0 0 -4px;
          background: #5799fc; }
        #fullpage-menu ul li a.active + .fp-tooltip {
          transition: opacity 0.2s ease-in;
          width: auto;
          opacity: 1; }
        #fullpage-menu ul li a span {
          top: 0;
          background: #cdd1e1;
          z-index: 1;
          width: 5px;
          height: 5px;
          transition: all 0.1s ease-in-out;
          margin: -2px 0 0 -2px;
          left: 50%;
          border: 0;
          border-radius: 50%;
          position: absolute; }
      #fullpage-menu ul li .fp-tooltip {
        position: absolute;
        white-space: nowrap;
        max-width: 240px;
        overflow: hidden;
        display: block;
        width: 0;
        cursor: pointer;
        font-family: 'Work Sans', sans-serif;
        color: #000;
        text-transform: uppercase !important;
        font-size: 12px;
        padding-right: 15px;
        top: -8px;
        right: 8px !important;
        mix-blend-mode: darken;
        opacity: 0; }
        #fullpage-menu ul li .fp-tooltip--bdh, #fullpage-menu ul li .fp-tooltip--bpn, #fullpage-menu ul li .fp-tooltip--velo, #fullpage-menu ul li .fp-tooltip--morliny {
          color: white; }
      #fullpage-menu ul li.visited a span {
        width: 5px;
        height: 5px;
        margin: -2px 0 0 -2px;
        background: #5799fc; }
        #fullpage-menu ul li.visited a span:hover {
          width: 10px;
          height: 10px;
          margin: -5px 0 0 -5px; }
      #fullpage-menu ul li.visited a::after {
        content: "....................................";
        width: 30px;
        height: 11px;
        overflow: hidden;
        color: #cdd1e1;
        font-size: 11px;
        letter-spacing: 4px;
        position: absolute;
        left: 13px;
        top: -7px;
        transform-origin: left top;
        transform: rotate(90deg) translateX(-100%);
        pointer-events: none; }
    #fullpage-menu ul li a.active span,
    #fullpage-menu ul li:hover a.active span {
      width: 10px;
      height: 10px;
      margin: -5px 0 0 -5px;
      background: #5799fc; }

.footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 0 0 0 0; }
  .footer-wrapper a {
    color: #a2bb3f;
    margin-left: 0;
    transition: 0.3s; }
    .footer-wrapper a:hover {
      margin-left: 5px; }
  .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    @media only screen and (max-width: 1279px) {
      .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .footer-wrapper .case-footer .col1 {
      position: relative; }
      .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      @media only screen and (max-width: 768px) {
        .footer-wrapper .case-footer .contact {
          font-size: 16px; } }
      .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

.fp-tooltip {
  opacity: 1;
  visibility: visible; }
  .fp-tooltip.hide {
    opacity: 0;
    visibility: hidden; }

.case1.subpage .logo-wrapper, .case1.subpage .case-arrow-back, .case1.subpage .menu-button {
  background: black;
  mix-blend-mode: exclusion; }

.case1.subpage .copyrights, .case1.subpage .case-title, .case1.subpage .slogan {
  color: #fff; }

.case1.subpage .logo-svg {
  fill: #fff; }

.case1.subpage .arrow-line {
  stroke: #fff; }

.case1.subpage .lang {
  mix-blend-mode: exclusion; }

.case1.subpage .case-title-subpage {
  font-size: 50px;
  font-weight: 500; }
  @media only screen and (max-width: 1279px) {
    .case1.subpage .case-title-subpage {
      font-size: 30px;
      padding: 7px 30px;
      margin-top: 100px; } }
  @media only screen and (max-width: 768px) {
    .case1.subpage .case-title-subpage {
      font-size: 20px;
      padding: 7px 30px;
      margin-top: 30px; } }
  .case1.subpage .case-title-subpage span {
    position: relative;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 10px 45px;
    margin: 0 0 14px 0; }
    .case1.subpage .case-title-subpage span:after {
      content: "";
      background: #a2bb3f;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      -webkit-border-top-left-radius: 25px;
      -webkit-border-bottom-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-bottomright: 25px;
      border-top-left-radius: 25px;
      border-bottom-right-radius: 25px; }
    @media only screen and (max-width: 768px) {
      .case1.subpage .case-title-subpage span {
        padding: 7px 20px; } }

.case1.subpage .case-subtitle-subpage {
  color: #fff; }

.case1.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  display: block; }

.case1.subpage .section-title {
  font-size: 43px;
  color: #a2bb3f;
  font-weight: 300;
  letter-spacing: 1px; }
  @media only screen and (max-width: 1279px) {
    .case1.subpage .section-title {
      font-size: 28px; } }

.case1.subpage .section-title-small {
  font-size: 24px;
  color: #262832;
  font-weight: 400;
  letter-spacing: 1px; }

.case1.subpage .heading {
  font-size: 30px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 40px; }
  .case1.subpage .heading img {
    vertical-align: inherit; }

.case1.subpage .default-text {
  font-size: 18px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px; }

.case1.subpage .section {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative; }
  @media only screen and (max-width: 1279px) {
    .case1.subpage .section {
      padding: 0 30px; } }

.case1.subpage .main-content {
  background: #fff; }

.case1.subpage .intro {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: visible;
  background: #e4e4e4; }
  .case1.subpage .intro:after {
    background: #a2bb3f; }
  .case1.subpage .intro .intro-bg {
    background-image: url("../img/sferis/sferis_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .case1.subpage .intro .samsung1 {
    position: absolute;
    bottom: -140px;
    left: 52%;
    transform: translateX(-50%);
    z-index: 2; }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .intro .samsung1 {
        width: 110px; } }
    @media only screen and (max-width: 768px) {
      .case1.subpage .intro .samsung1 {
        width: 80px;
        bottom: -40px; } }
  .case1.subpage .intro .samsung2 {
    position: absolute;
    bottom: -140px;
    left: 46%;
    transform: translateX(-50%);
    z-index: 1; }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .intro .samsung2 {
        width: 100px; } }
    @media only screen and (max-width: 768px) {
      .case1.subpage .intro .samsung2 {
        width: 70px;
        bottom: -40px; } }

.case1.subpage .brief {
  padding: 230px 0 100px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case1.subpage .brief {
      padding: 100px 0 50px 0; } }
  .case1.subpage .brief .frame1 {
    position: absolute;
    top: 200px;
    right: 0; }
    @media only screen and (max-width: 1440px) {
      .case1.subpage .brief .frame1 {
        right: -250px; } }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .brief .frame1 {
        display: none; } }
  .case1.subpage .brief .heading {
    padding: 40px 0 80px 0; }
  .case1.subpage .brief .slogan {
    padding: 120px 0;
    font-size: 47px; }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .brief .slogan {
        font-size: 26px;
        padding: 60px 0; } }
    @media only screen and (max-width: 768px) {
      .case1.subpage .brief .slogan {
        font-size: 20px; } }
    .case1.subpage .brief .slogan .zawsze1 {
      color: #000;
      font-weight: 500;
      padding: 18px 45px; }
      @media only screen and (max-width: 768px) {
        .case1.subpage .brief .slogan .zawsze1 {
          padding: 10px 25px; } }
    .case1.subpage .brief .slogan .zawsze2, .case1.subpage .brief .slogan .zawsze3 {
      font-weight: 700;
      color: #fff;
      position: relative;
      display: inline-block;
      letter-spacing: 1px;
      padding: 10px 45px;
      margin: 0 0 14px 0;
      z-index: 1; }
      @media only screen and (max-width: 768px) {
        .case1.subpage .brief .slogan .zawsze2, .case1.subpage .brief .slogan .zawsze3 {
          padding: 10px 25px; } }
      .case1.subpage .brief .slogan .zawsze2:after, .case1.subpage .brief .slogan .zawsze3:after {
        content: "";
        background: #a2bb3f;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        -webkit-border-top-left-radius: 25px;
        -webkit-border-bottom-right-radius: 25px;
        -moz-border-radius-topleft: 25px;
        -moz-border-radius-bottomright: 25px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px; }
      .case1.subpage .brief .slogan .zawsze2 span, .case1.subpage .brief .slogan .zawsze3 span {
        font-weight: 700; }
  .case1.subpage .brief .padding-top {
    padding: 100px 0 100px 0; }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .brief .padding-top {
        padding: 50px 0; } }
    .case1.subpage .brief .padding-top .default-text {
      padding: 0 40px 0 0; }
  .case1.subpage .brief .section-title-small {
    margin: 120px 0 50px 0; }

.case1.subpage .typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.case1.subpage .slider {
  width: 100%;
  overflow: visible;
  position: relative; }
  @media only screen and (max-width: 1279px) {
    .case1.subpage .slider {
      display: none; } }
  .case1.subpage .slider .background {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 563px;
    background: #f4f4f4; }
  .case1.subpage .slider .item {
    width: 100%;
    height: 663px;
    position: relative;
    overflow: visible;
    z-index: 2;
    outline: 0; }
  .case1.subpage .slider .section {
    max-width: 1170px;
    z-index: 3; }
  .case1.subpage .slider .sferis-slider {
    position: relative;
    z-index: 2; }
    .case1.subpage .slider .sferis-slider.lang-pl {
      background: url("../img/sferis/slider_bg.jpg") no-repeat bottom center; }
    .case1.subpage .slider .sferis-slider.lang-en {
      background: url("../img/sferis/slider_bg_en.jpg") no-repeat bottom center; }
  .case1.subpage .slider .slide-samsung .slogan {
    font-size: 27px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 160px;
    top: 284px;
    display: inline-block;
    letter-spacing: 1px;
    padding: 9px 35px;
    margin: 0 0 14px 0;
    z-index: 1; }
    .case1.subpage .slider .slide-samsung .slogan:after {
      content: "";
      background: #a2bb3f;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      -webkit-border-top-left-radius: 25px;
      -webkit-border-bottom-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-bottomright: 25px;
      border-top-left-radius: 25px;
      border-bottom-right-radius: 25px; }
  .case1.subpage .slider .slide-samsung .samsung-logo {
    position: absolute;
    left: 160px;
    bottom: 80px;
    width: initial; }
  .case1.subpage .slider .slide-samsung .phone {
    position: absolute;
    right: -100px;
    top: 50px;
    width: initial; }
  .case1.subpage .slider .slide-lenovo .slogan {
    font-size: 27px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 160px;
    top: 284px;
    display: inline-block;
    letter-spacing: 1px;
    padding: 9px 35px;
    margin: 0 0 14px 0;
    z-index: 1; }
    .case1.subpage .slider .slide-lenovo .slogan:after {
      content: "";
      background: #a2bb3f;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      -webkit-border-top-left-radius: 25px;
      -webkit-border-bottom-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-bottomright: 25px;
      border-top-left-radius: 25px;
      border-bottom-right-radius: 25px; }
  .case1.subpage .slider .slide-lenovo .samsung-logo {
    position: absolute;
    left: 160px;
    bottom: 80px;
    width: initial; }
  .case1.subpage .slider .slide-lenovo .phone {
    position: absolute;
    right: 0;
    top: 50px;
    width: initial; }
  .case1.subpage .slider .slide-huawei .slogan {
    font-size: 27px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 160px;
    top: 284px;
    display: inline-block;
    letter-spacing: 1px;
    padding: 9px 35px;
    margin: 0 0 14px 0;
    z-index: 1; }
    .case1.subpage .slider .slide-huawei .slogan:after {
      content: "";
      background: #a2bb3f;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      -webkit-border-top-left-radius: 25px;
      -webkit-border-bottom-right-radius: 25px;
      -moz-border-radius-topleft: 25px;
      -moz-border-radius-bottomright: 25px;
      border-top-left-radius: 25px;
      border-bottom-right-radius: 25px; }
  .case1.subpage .slider .slide-huawei .samsung-logo {
    position: absolute;
    left: 160px;
    bottom: 80px;
    width: initial; }
  .case1.subpage .slider .slide-huawei .phone {
    position: absolute;
    right: 0;
    top: 150px;
    width: initial; }

.case1.subpage .owl-theme .owl-controls {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-tap-highlight-color: transparent; }

.case1.subpage .owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.case1.subpage .owl-carousel .owl-controls .owl-dot, .case1.subpage .owl-carousel .owl-controls .owl-nav .owl-next, .case1.subpage .owl-carousel .owl-controls .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.case1.subpage .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: none;
  border: solid 2px #a2bb3f;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

.case1.subpage .owl-theme .owl-dots .owl-dot.active span, .case1.subpage .owl-theme .owl-dots .owl-dot:hover span {
  background: #a2bb3f; }

.case1.subpage .kampania-mediowa {
  width: 100%;
  background: url("../img/sferis/kampania_bg.jpg") no-repeat top 730px center;
  padding: 100px 0 0 0; }
  @media only screen and (max-width: 1279px) {
    .case1.subpage .kampania-mediowa {
      background: url("../img/sferis/kampania_bg.jpg") no-repeat top 230px center;
      padding: 50px 0 0 0; } }
  .case1.subpage .kampania-mediowa .frame2 {
    position: relative;
    margin: 0 0 0 0; }
  .case1.subpage .kampania-mediowa .logo-samsung {
    position: relative;
    margin: 0 auto 80px auto; }
  .case1.subpage .kampania-mediowa .title {
    font-size: 67px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    margin: 80px 0 160px 0; }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .kampania-mediowa .title {
        font-size: 40px;
        margin: 40px 0 80px 0; } }
    @media only screen and (max-width: 768px) {
      .case1.subpage .kampania-mediowa .title {
        font-size: 30px;
        margin: 30px 0 70px 0; } }

.case1.subpage .sferis-stats {
  width: 100%; }
  .case1.subpage .sferis-stats .title {
    font-size: 50px;
    color: #2f2f2f;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    margin: 80px 0 80px 0; }
  .case1.subpage .sferis-stats .icon-wrapper {
    min-height: 80px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center; }
  .case1.subpage .sferis-stats .stats-number {
    font-size: 52px;
    color: #a2bb3f;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    margin: 0 0 20px 0; }
  .case1.subpage .sferis-stats .stats-text {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: center;
    margin: 0; }

.case1.subpage .footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 100px 0 0 0; }
  .case1.subpage .footer-wrapper a {
    color: #a2bb3f; }
  .case1.subpage .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px; }
    @media only screen and (max-width: 1279px) {
      .case1.subpage .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .case1.subpage .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .case1.subpage .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .case1.subpage .footer-wrapper .case-footer .col1 {
      position: relative; }
      .case1.subpage .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .case1.subpage .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .case1.subpage .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case1.subpage .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      .case1.subpage .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

.case2.subpage {
  height: auto;
  padding: 0;
  overflow: hidden; }
  .case2.subpage a {
    color: #ff3d00; }
  .case2.subpage .arrow-line {
    stroke: #fff; }
  .case2.subpage .main-content {
    background-color: #1c1713; }
  .case2.subpage .main-photo {
    text-align: center;
    width: 1190px;
    max-width: 100%;
    position: absolute;
    left: 50%;
    bottom: -150px;
    transform: translateX(-50%); }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .main-photo {
        padding: 0 20px;
        bottom: -100px; } }
    @media only screen and (max-width: 768px) {
      .case2.subpage .main-photo {
        bottom: 0; } }
  .case2.subpage .special {
    color: #ff4f00;
    font-weight: inherit; }
  .case2.subpage .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #a28e86;
    font-weight: 300;
    letter-spacing: 1px;
    display: block; }
  .case2.subpage .lead {
    font-size: 45px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 62px; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .lead {
        font-size: 28px;
        color: #fff;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 39px; } }
  .case2.subpage .default-text {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px; }
  .case2.subpage .section {
    max-width: 850px;
    margin: 0 auto;
    position: relative; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .section {
        padding: 0 30px; } }
  .case2.subpage .intro {
    height: 100vh; }
    .case2.subpage .intro:after {
      background: #1c1713; }
  .case2.subpage ul.blonia-list {
    margin: 50px 0 0 0; }
    .case2.subpage ul.blonia-list li {
      background: url("../img/blonia_dot.png");
      background-repeat: no-repeat;
      background-position: left;
      padding: 8px 0 8px 45px;
      font-size: 18px;
      font-weight: 300;
      color: #a28e86; }
  .case2.subpage .brief {
    padding: 80px 0 0 0; }
    .case2.subpage .brief .lead {
      margin: 50px 0 60px 0; }
  .case2.subpage .blonia-photo {
    position: relative;
    margin-top: -150px; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-photo {
        margin-top: -100px; } }
    @media only screen and (max-width: 768px) {
      .case2.subpage .blonia-photo {
        margin-top: -20px; } }
    .case2.subpage .blonia-photo img {
      width: 100%; }
    .case2.subpage .blonia-photo svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .case2.subpage .blonia-photo .blonia-lines {
      fill: none;
      stroke: #ff3d00;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      fill-rule: evenodd; }
  .case2.subpage .blonia-analiza-rynku {
    padding: 250px 0 250px 0; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-analiza-rynku {
        padding: 150px 0 100px 0;
        text-align: center; }
        .case2.subpage .blonia-analiza-rynku .blonia-analiza-img {
          width: 60%; } }
    .case2.subpage .blonia-analiza-rynku .small-title {
      margin: 0 0 20px 0; }
    .case2.subpage .blonia-analiza-rynku .analiza-row2 {
      margin: 100px 0 0 0; }
      @media only screen and (max-width: 768px) {
        .case2.subpage .blonia-analiza-rynku .analiza-row2 .no-padding {
          padding-bottom: 30px; } }
    .case2.subpage .blonia-analiza-rynku .circle {
      width: 112px;
      height: 112px;
      border: solid 2px #ff3d00;
      border-radius: 50%;
      display: table;
      text-align: center; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-analiza-rynku .circle {
          margin: 0 auto; } }
      .case2.subpage .blonia-analiza-rynku .circle .lokale {
        display: table-cell;
        vertical-align: middle;
        color: #ff3d00;
        font-size: 28px; }
    .case2.subpage .blonia-analiza-rynku .analiza-title {
      font-size: 24px;
      font-weight: 300;
      margin: 10px 0 0 0; }
    .case2.subpage .blonia-analiza-rynku .analiza-desc {
      font-size: 18px;
      font-weight: 300;
      color: #a28e86;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-analiza-rynku .analiza-desc {
          margin: 0 0 20px 0; } }
  .case2.subpage .blonia-analiza-inwestycji {
    padding: 0 20px; }
    .case2.subpage .blonia-analiza-inwestycji .blonia-lines2 {
      fill: none;
      stroke: #EF4423;
      stroke-miterlimit: 10; }
    .case2.subpage .blonia-analiza-inwestycji .small-title {
      margin-top: 40px; }
    .case2.subpage .blonia-analiza-inwestycji .lead {
      margin: 40px 0 0 0; }
  .case2.subpage .blonia-strategia-marketingowa {
    padding: 200px 0; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-strategia-marketingowa {
        padding: 100px 0; } }
    .case2.subpage .blonia-strategia-marketingowa .lead {
      margin: 40px 0 0 0; }
    .case2.subpage .blonia-strategia-marketingowa img {
      max-width: none;
      margin: 120px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-strategia-marketingowa img {
          width: 43%;
          margin: 40px auto 0 auto;
          display: block; } }
  .case2.subpage .blonia-linia-kreatywna-container {
    width: 100%;
    padding: 200px 0;
    background: #241c19; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-linia-kreatywna-container {
        padding: 80px 0; } }
  .case2.subpage .blonia-linia-kreatywna .lead {
    margin: 40px 0 0 0; }
  .case2.subpage .blonia-linia-kreatywna .default-text {
    color: #a28e86;
    margin: 50px 0 0 0; }
  .case2.subpage .blonia-linia-kreatywna .logo-steps {
    margin: 160px 0 0 0; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-linia-kreatywna .logo-steps {
        margin: 50px 0 0 0; } }
    .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step {
      height: 200px;
      position: relative;
      display: table; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step {
          height: 150px;
          text-align: center;
          margin: 0 auto; } }
      .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step.center-step {
        text-align: center; }
        .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step.center-step:before {
          content: url("../img/blonia_steps_dot.png");
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%); }
        .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step.center-step:after {
          content: url("../img/blonia_steps_dot.png");
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }
      .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step.last-step {
        text-align: right; }
      .case2.subpage .blonia-linia-kreatywna .logo-steps .logo-wrapper-step .helper {
        display: table-cell;
        vertical-align: middle; }
  .case2.subpage .blonia-linia-kreatywna .slogan {
    margin: 160px 0 0 0; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-linia-kreatywna .slogan {
        margin: 60px 0 0 0; } }
    .case2.subpage .blonia-linia-kreatywna .slogan .haslo {
      font-size: 39px;
      font-weight: 300;
      padding: 36px 0 0 0;
      display: block; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-linia-kreatywna .slogan .haslo {
          padding: 0 0 20px 0; } }
    .case2.subpage .blonia-linia-kreatywna .slogan .slogan-wrapper {
      border-left: solid 1px #836a44;
      padding-left: 80px; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-linia-kreatywna .slogan .slogan-wrapper {
          padding-top: 20px; } }
      @media only screen and (max-width: 768px) {
        .case2.subpage .blonia-linia-kreatywna .slogan .slogan-wrapper {
          border: 0;
          border-top: solid 1px #836a44;
          padding-left: 0;
          padding-top: 20px; } }
  .case2.subpage .blonia-akcja-promocyjna {
    padding: 180px 0; }
    @media only screen and (max-width: 1279px) {
      .case2.subpage .blonia-akcja-promocyjna {
        padding: 90px 0; } }
    .case2.subpage .blonia-akcja-promocyjna .teaser-title {
      font-size: 30px;
      font-weight: 300;
      margin: 150px 0 55px 0; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-akcja-promocyjna .teaser-title {
          margin: 75px 0 30px 0; } }
    .case2.subpage .blonia-akcja-promocyjna .teaser-desc {
      font-size: 18px;
      color: #a28e86;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 1px;
      margin-bottom: 100px; }
    .case2.subpage .blonia-akcja-promocyjna .title {
      text-align: center;
      font-weight: 300;
      margin: 270px 0 200px 0; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-akcja-promocyjna .title {
          margin: 100px 0 100px 0;
          font-size: 36px; } }
  .case2.subpage .blonia-serwis-internetowy {
    background: #241c19;
    text-align: center;
    position: relative;
    height: 290px; }
    @media only screen and (max-width: 768px) {
      .case2.subpage .blonia-serwis-internetowy {
        height: unset; } }
    .case2.subpage .blonia-serwis-internetowy .serwis {
      position: absolute;
      top: -250px;
      left: 50%;
      transform: translateX(-50%); }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-serwis-internetowy .serwis {
          top: -150px; } }
      @media only screen and (max-width: 768px) {
        .case2.subpage .blonia-serwis-internetowy .serwis {
          height: unset;
          padding: 0 20px; } }
    .case2.subpage .blonia-serwis-internetowy .btn-fill {
      margin: 400px auto 220px auto;
      color: #241c19; }
      .case2.subpage .blonia-serwis-internetowy .btn-fill:hover {
        background: rgba(255, 77, 29, 0.8); }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .blonia-serwis-internetowy .btn-fill {
          margin: 100px auto 0 auto; } }
  .case2.subpage .footer-wrapper {
    width: 100%;
    background: #1c1713;
    position: relative;
    margin: 100px 0 0 0; }
    .case2.subpage .footer-wrapper .case-footer {
      padding: 160px 20px 200px 20px;
      max-width: 600px; }
      @media only screen and (max-width: 1279px) {
        .case2.subpage .footer-wrapper .case-footer {
          text-align: center;
          padding: 80px 20px 100px 20px; } }
      .case2.subpage .footer-wrapper .case-footer .footer-title {
        font-size: 30px;
        color: #fff;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 0 90px 0;
        line-height: 48px; }
        @media only screen and (max-width: 768px) {
          .case2.subpage .footer-wrapper .case-footer .footer-title {
            font-size: 22px;
            line-height: 30px; } }
      .case2.subpage .footer-wrapper .case-footer .col1 {
        position: relative; }
        .case2.subpage .footer-wrapper .case-footer .col1:after {
          content: " ";
          display: block;
          height: 100px;
          width: 1px;
          background: #2f2b28;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -50px; }
        @media only screen and (max-width: 1279px) {
          .case2.subpage .footer-wrapper .case-footer .col1:after {
            display: none; } }
      .case2.subpage .footer-wrapper .case-footer .contact {
        color: #fff;
        font-size: 21px;
        margin: 20px 0 0 0; }
        @media only screen and (max-width: 1279px) {
          .case2.subpage .footer-wrapper .case-footer .contact {
            font-size: 18px; } }
        .case2.subpage .footer-wrapper .case-footer .contact .position {
          font-size: 12px; }

.case3.subpage .copyrights, .case3.subpage .case-title, .case3.subpage .slogan, .case3.subpage .case-title-subpage, .case3.subpage .case-subtitle-subpage {
  color: #252730; }

.case3.subpage .bar {
  background: #252730; }

.case3.subpage .logo-svg {
  fill: #252730; }

.case3.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  display: block; }

.case3.subpage .lang span {
  color: #262933; }

.case3.subpage .lead {
  font-size: 30px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 40px; }
  @media only screen and (max-width: 768px) {
    .case3.subpage .lead {
      font-size: 24px; } }

.case3.subpage .default-text {
  font-size: 18px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px; }

.case3.subpage .title {
  font-size: 56px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px; }
  @media only screen and (max-width: 1279px) {
    .case3.subpage .title {
      font-size: 36px; } }

@media only screen and (max-width: 768px) {
  .case3.subpage .case-title-subpage {
    font-size: 26px; } }

.case3.subpage .section {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative; }
  @media only screen and (max-width: 1279px) {
    .case3.subpage .section {
      padding: 0 30px; } }

.case3.subpage .main-content {
  background: #fff; }

.case3.subpage .intro {
  overflow-y: visible;
  background: #dcbd2e;
  text-align: center;
  padding: 75px 20px 20px 20px; }
  .case3.subpage .intro .intro-car {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 25px; }
  .case3.subpage .intro .intro-confetti {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
  .case3.subpage .intro:after {
    background: #dcbd2e; }

.case3.subpage .brief {
  padding: 140px 0 100px 0;
  position: relative; }
  .case3.subpage .brief .photo1 {
    position: absolute;
    right: 0;
    bottom: -100px;
    z-index: 1; }
  .case3.subpage .brief .lead {
    padding: 40px 0 60px 0; }

.case3.subpage .icons {
  width: 100%; }
  .case3.subpage .icons .tab-link {
    margin-bottom: 50px;
    display: block; }
    .case3.subpage .icons .tab-link.active svg path {
      fill: #dcbd2c; }
    .case3.subpage .icons .tab-link.active svg circle {
      fill: #dcbd2c; }
    .case3.subpage .icons .tab-link.active svg path {
      fill: #dcbd2c; }
    .case3.subpage .icons .tab-link .icon-title:after {
      display: block; }
  .case3.subpage .icons .icon-wrapper {
    height: 125px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .icons .icon-wrapper {
        height: 50px; } }
    .case3.subpage .icons .icon-wrapper .outdoor {
      width: 66px; }
      @media only screen and (max-width: 768px) {
        .case3.subpage .icons .icon-wrapper .outdoor {
          width: 30px; } }
  .case3.subpage .icons .icon-title {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    text-align: center;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .icons .icon-title {
        font-size: 12px;
        font-weight: 400; } }
    .case3.subpage .icons .icon-title:after {
      content: "";
      display: none;
      width: 50px;
      height: 2px;
      background: #dcbd2c;
      position: absolute;
      left: 50%;
      bottom: -15px;
      transform: translateX(-50%); }
  .case3.subpage .icons .tab-content {
    color: #000; }
    .case3.subpage .icons .tab-content .tab-pane {
      display: none; }
      .case3.subpage .icons .tab-content .tab-pane.active {
        display: block; }
      .case3.subpage .icons .tab-content .tab-pane img {
        margin: 50px auto 0 auto;
        display: block; }

.case3.subpage .lottery {
  padding: 100px 0 0 0;
  position: relative; }
  .case3.subpage .lottery .kupon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 44%;
    max-width: 855px; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .lottery .kupon {
        right: -50px;
        top: 100px; } }
  .case3.subpage .lottery .pen {
    position: absolute;
    top: 550px;
    right: 0;
    z-index: 1;
    width: 47%;
    max-width: 895px; }
    @media only screen and (max-width: 1440px) {
      .case3.subpage .lottery .pen {
        top: 350px;
        right: -50px; } }
    @media only screen and (max-width: 768px) {
      .case3.subpage .lottery .pen {
        top: 200px; } }
  .case3.subpage .lottery .title {
    padding: 0 0 80px 0; }

.case3.subpage .safe-lottery {
  width: 100%;
  padding: 200px 0;
  background: url("../img/patio/kupon_bg.jpg");
  background-size: cover;
  margin: 240px 0 0 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case3.subpage .safe-lottery {
      margin: 120px 0 0 0;
      padding: 100px 0; } }
  @media only screen and (max-width: 768px) {
    .case3.subpage .safe-lottery .photo1 {
      margin: 0 0 30px 0; } }
  @media only screen and (max-width: 768px) {
    .case3.subpage .safe-lottery .title {
      margin-bottom: 30px; } }
  .case3.subpage .safe-lottery .confetti2 {
    position: absolute;
    left: 0;
    top: -350px; }

.case3.subpage .awards {
  width: 100%;
  padding: 250px 0;
  margin: 0 0 0 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case3.subpage .awards {
      padding: 70px 0; } }
  .case3.subpage .awards .title {
    margin-bottom: 80px; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .awards .title {
        margin-bottom: 40px; } }
  .case3.subpage .awards .awards-car {
    position: absolute;
    left: 0;
    top: 300px; }
    @media only screen and (max-width: 1440px) {
      .case3.subpage .awards .awards-car {
        left: -200px; } }
    @media only screen and (max-width: 768px) {
      .case3.subpage .awards .awards-car {
        display: none; } }
  .case3.subpage .awards .awards-tv {
    position: absolute;
    right: 300px;
    top: 350px;
    z-index: 1; }
    @media only screen and (max-width: 1440px) {
      .case3.subpage .awards .awards-tv {
        right: 100px; } }
    @media only screen and (max-width: 768px) {
      .case3.subpage .awards .awards-tv {
        display: none; } }
  .case3.subpage .awards .awards-scooter {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 2; }
    @media only screen and (max-width: 1440px) {
      .case3.subpage .awards .awards-scooter {
        right: -100px; } }
    @media only screen and (max-width: 768px) {
      .case3.subpage .awards .awards-scooter {
        display: none; } }

.case3.subpage .big-opening {
  width: 100%;
  padding: 100px 0;
  margin: 0 0 0 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case3.subpage .big-opening {
      padding: 70px 0; } }
  .case3.subpage .big-opening .title {
    margin-bottom: 80px; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .big-opening .title {
        margin-bottom: 40px; } }

.case3.subpage .slider {
  position: relative;
  margin-bottom: 550px; }
  @media only screen and (max-width: 768px) {
    .case3.subpage .slider {
      margin-bottom: 200px; } }
  .case3.subpage .slider .patio-slider {
    width: 100%;
    padding: 100px 0 0 0;
    margin: 0 0 0 0;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .slider .patio-slider {
        padding: 50px 0 0 0; } }
    .case3.subpage .slider .patio-slider .photo {
      width: 100%; }

.case3.subpage .owl-theme .owl-controls {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-tap-highlight-color: transparent; }

.case3.subpage .owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.case3.subpage .owl-carousel .owl-controls .owl-dot, .case3.subpage .owl-carousel .owl-controls .owl-nav .owl-next, .case3.subpage .owl-carousel .owl-controls .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.case3.subpage .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: none;
  border: solid 2px #5a5a5a;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

.case3.subpage .owl-theme .owl-dots .owl-dot.active span, .case3.subpage .owl-theme .owl-dots .owl-dot:hover span {
  background: #5a5a5a; }

.case3.subpage .audiojs {
  width: 100%;
  margin: 150px 0 100px 0;
  height: 36px;
  position: relative;
  background: transparent;
  overflow: visible;
  font-family: monospace;
  font-size: 12px;
  background-image: none;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  /* .progress:after {
        content: " ";
        position: absolute;
        background: red;
        width: 5px;
        height: 20px;
        top: -10px;
        right: 0;
        z-index: 9999;
        display: block;
    }*/ }
  @media only screen and (max-width: 768px) {
    .case3.subpage .audiojs {
      margin: 50px 0 0 0; } }
  .case3.subpage .audiojs .scrubber {
    width: 80%;
    position: absolute;
    top: 4px;
    height: 7px;
    width: 80%;
    border: none;
    right: 0; }
  .case3.subpage .audiojs .play-pause {
    width: 25px;
    position: absolute;
    left: 120px;
    height: 40px;
    padding: 4px 6px;
    margin: 0px;
    float: left;
    overflow: hidden;
    border-right: none; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .audiojs .play-pause {
        left: 0; } }
    .case3.subpage .audiojs .play-pause .play {
      background: url("../img/play.jpg") 6px 5px no-repeat; }
    .case3.subpage .audiojs .play-pause .pause {
      background: url("../img/pause.jpg") 6px 6px no-repeat; }
  .case3.subpage .audiojs .progress {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 7px;
    width: 0px;
    background: #dcbd2c;
    z-index: 1;
    background-image: none;
    background-image: -moz-linear-gradient(center top, #ccc 0%, #ddd 50%, #ccc 51%, #ccc 100%); }
  .case3.subpage .audiojs .time {
    float: none;
    height: 36px;
    line-height: 36px;
    margin: 0px 0px 0px 6px;
    padding: 0px 6px 0px 12px;
    border-left: none;
    color: #000;
    font-family: 'Work Sans', sans-serif;
    text-shadow: none;
    left: 50px;
    top: 17px; }
    @media only screen and (max-width: 768px) {
      .case3.subpage .audiojs .time {
        left: 40px;
        top: -15px; } }
    .case3.subpage .audiojs .time em {
      color: #000; }

.case3.subpage .footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 100px 0 0 0; }
  .case3.subpage .footer-wrapper .footer-coupon {
    position: absolute;
    left: 50%;
    top: -440px;
    z-index: 2;
    transform: translateX(-50%); }
    @media only screen and (max-width: 768px) {
      .case3.subpage .footer-wrapper .footer-coupon {
        top: -120px;
        left: 53%; } }
  .case3.subpage .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px; }
    @media only screen and (max-width: 1279px) {
      .case3.subpage .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .case3.subpage .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .case3.subpage .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .case3.subpage .footer-wrapper .case-footer .col1 {
      position: relative; }
      .case3.subpage .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d2d2d2;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .case3.subpage .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .case3.subpage .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case3.subpage .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      .case3.subpage .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

.case4.subpage {
  height: auto;
  padding: 0;
  overflow: hidden; }
  .case4.subpage .copyrights, .case4.subpage .case-title, .case4.subpage .slogan, .case4.subpage .case-title-subpage {
    color: #fff; }
  .case4.subpage .arrow-line {
    stroke: #fff; }
  .case4.subpage .logo-svg {
    fill: #fff; }
  .case4.subpage .case-subtitle-subpage {
    color: #6a6d75; }
  .case4.subpage .bar {
    background: #fff; }
  .case4.subpage .main-content {
    background-color: #272a34; }
  .case4.subpage .main-photo {
    max-width: 1160px;
    position: absolute;
    left: 50%;
    bottom: -350px;
    transform: translateX(-50%); }
    @media only screen and (max-width: 1279px) {
      .case4.subpage .main-photo {
        bottom: -330px;
        width: 850px; } }
    @media only screen and (max-width: 768px) {
      .case4.subpage .main-photo {
        bottom: -50px;
        width: 250px; } }
  .case4.subpage .section {
    max-width: 850px;
    margin: 0 auto;
    position: relative; }
    @media only screen and (max-width: 1279px) {
      .case4.subpage .section {
        padding: 0 30px; } }
  .case4.subpage .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #787d90;
    font-weight: 300;
    letter-spacing: 1px;
    display: block; }
  .case4.subpage .lead {
    font-size: 30px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 40px; }
    @media only screen and (max-width: 768px) {
      .case4.subpage .lead {
        font-size: 24px;
        line-height: 36px; } }
  .case4.subpage .default-text {
    font-size: 18px;
    color: #787d90;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px; }
  .case4.subpage .title {
    font-size: 56px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px; }
  .case4.subpage .intro:after {
    background: #272a34; }
  .case4.subpage .brief {
    padding: 480px 0 200px 0; }
    @media only screen and (max-width: 768px) {
      .case4.subpage .brief {
        padding: 80px 0 100px 0; } }
    .case4.subpage .brief .lead {
      margin: 50px 0 60px 0; }
  .case4.subpage .ipad {
    position: relative; }
    .case4.subpage .ipad .title {
      max-width: 550px;
      padding: 100px 0 80px 0; }
      @media only screen and (max-width: 768px) {
        .case4.subpage .ipad .title {
          font-size: 34px;
          padding: 0 0 80px 0; } }
    .case4.subpage .ipad .photo1 {
      position: absolute;
      right: 0;
      top: 0; }
      @media only screen and (max-width: 1440px) {
        .case4.subpage .ipad .photo1 {
          right: -100px; } }
      @media only screen and (max-width: 1279px) {
        .case4.subpage .ipad .photo1 {
          right: -250px; } }
      @media only screen and (max-width: 768px) {
        .case4.subpage .ipad .photo1 {
          position: initial;
          margin: 80px 0 0 30px; } }
  .case4.subpage .menu-bar .section {
    padding: 400px 0 0 300px; }
    @media only screen and (max-width: 768px) {
      .case4.subpage .menu-bar .section {
        padding: 100px 20px 0 20px; } }
    .case4.subpage .menu-bar .section .title {
      padding: 0 0 50px 0; }
      @media only screen and (max-width: 768px) {
        .case4.subpage .menu-bar .section .title {
          font-size: 34px; } }
    .case4.subpage .menu-bar .section .photo2 {
      position: absolute;
      left: -350px;
      top: 450px; }
      @media only screen and (max-width: 768px) {
        .case4.subpage .menu-bar .section .photo2 {
          position: initial;
          margin: 40px 0 0 0; } }
  .case4.subpage .remote {
    margin: 300px 0 0 0;
    padding: 0 200px 0 0;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .case4.subpage .remote {
        margin: 0 0 0 0;
        padding: 0 0 0 0; } }
    .case4.subpage .remote .title {
      max-width: 550px;
      padding: 100px 0 80px 0; }
      @media only screen and (max-width: 768px) {
        .case4.subpage .remote .title {
          font-size: 34px; } }
    .case4.subpage .remote .photo3 {
      position: absolute;
      right: 0;
      top: 0; }
      @media only screen and (max-width: 1440px) {
        .case4.subpage .remote .photo3 {
          right: -200px; } }
      @media only screen and (max-width: 1279px) {
        .case4.subpage .remote .photo3 {
          right: -250px;
          top: -100px; } }
      @media only screen and (max-width: 768px) {
        .case4.subpage .remote .photo3 {
          position: initial;
          margin: 70px 0 0 0; } }
    .case4.subpage .remote .icons {
      margin: 50px 0 0 0; }
  .case4.subpage .support {
    padding: 300px 0 150px 0;
    border-bottom: solid 1px #1f2129;
    overflow: auto; }
    @media only screen and (max-width: 768px) {
      .case4.subpage .support {
        padding: 50px 0 50px 0; } }
    @media only screen and (max-width: 768px) {
      .case4.subpage .support .support-icon {
        margin: 0 auto 40px auto;
        width: 110px;
        display: block; } }
    .case4.subpage .support .title {
      margin: 0 0 50px 0; }
      @media only screen and (max-width: 768px) {
        .case4.subpage .support .title {
          font-size: 34px; } }
  .case4.subpage .screenshots {
    padding: 200px 0 80px 0; }
    @media only screen and (max-width: 768px) {
      .case4.subpage .screenshots {
        padding: 50px 0 50px 0; } }
    .case4.subpage .screenshots .title {
      text-align: center;
      padding: 0 0 100px 0; }
      @media only screen and (max-width: 768px) {
        .case4.subpage .screenshots .title {
          font-size: 34px; } }
    .case4.subpage .screenshots .screenshot-img {
      margin: 0 auto;
      display: block;
      padding: 0 0 60px 0; }
  .case4.subpage .footer-wrapper {
    width: 100%;
    position: relative;
    background: #1f2129; }
    .case4.subpage .footer-wrapper a {
      color: #35baf6; }
    .case4.subpage .footer-wrapper .case-footer {
      padding: 160px 20px 200px 20px;
      max-width: 600px; }
      @media only screen and (max-width: 1279px) {
        .case4.subpage .footer-wrapper .case-footer {
          text-align: center;
          padding: 80px 20px 100px 20px; } }
      .case4.subpage .footer-wrapper .case-footer .footer-title {
        font-size: 30px;
        color: #fff;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 0 90px 0;
        line-height: 48px; }
        @media only screen and (max-width: 768px) {
          .case4.subpage .footer-wrapper .case-footer .footer-title {
            font-size: 22px;
            line-height: 30px; } }
      .case4.subpage .footer-wrapper .case-footer .col1 {
        position: relative; }
        .case4.subpage .footer-wrapper .case-footer .col1:after {
          content: " ";
          display: block;
          height: 100px;
          width: 1px;
          background: #333d4c;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -50px; }
        @media only screen and (max-width: 1279px) {
          .case4.subpage .footer-wrapper .case-footer .col1:after {
            display: none; } }
      .case4.subpage .footer-wrapper .case-footer .contact {
        color: #fff;
        font-size: 21px;
        margin: 20px 0 0 0; }
        @media only screen and (max-width: 1279px) {
          .case4.subpage .footer-wrapper .case-footer .contact {
            font-size: 18px; } }
        .case4.subpage .footer-wrapper .case-footer .contact .position {
          font-size: 12px; }

.case5.subpage .copyrights, .case5.subpage .case-title, .case5.subpage .slogan, .case5.subpage .case-title-subpage,
.case5.subpage .lang__list {
  color: #252730; }

.case5.subpage .bar {
  background: #252730; }

.case5.subpage .logo-svg {
  fill: #252730; }

.case5.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #252730;
  font-weight: 300;
  letter-spacing: 1px;
  display: block; }

.case5.subpage .section-title {
  font-size: 44px;
  color: #2c313d;
  font-weight: 700;
  letter-spacing: 1px; }

.case5.subpage .section-text {
  font-size: 44px;
  color: #2c313d;
  font-weight: 300;
  letter-spacing: 1px; }
  @media only screen and (max-width: 1440px) {
    .case5.subpage .section-text {
      font-size: 36px; } }
  @media only screen and (max-width: 768px) {
    .case5.subpage .section-text {
      font-size: 25px; } }

.case5.subpage .heading {
  font-size: 30px;
  color: #252730;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 40px; }

.case5.subpage .default-text {
  font-size: 18px;
  color: #252730;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px; }

.case5.subpage .section {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative; }
  @media only screen and (max-width: 1279px) {
    .case5.subpage .section {
      padding: 0 30px; } }

.case5.subpage .intro {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: visible;
  background: #fff; }
  .case5.subpage .intro .woman {
    position: absolute;
    bottom: 20px;
    left: 70%;
    transform: translateX(-50%);
    z-index: 1; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .intro .woman {
        bottom: 20px;
        left: initial;
        width: 145px;
        right: 0; } }
  .case5.subpage .intro .grass {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
  .case5.subpage .intro .lifebuoy {
    position: absolute;
    bottom: -50px;
    left: 80%;
    transform: translateX(-50%);
    z-index: 3; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .intro .lifebuoy {
        bottom: -15px;
        width: 70px;
        left: initial;
        right: 0; } }
  .case5.subpage .intro .plane {
    position: absolute;
    bottom: 321px;
    left: 20%;
    transform: translateX(-50%);
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .intro .plane {
        bottom: 400px;
        left: 20%;
        width: 70px; } }

.case5.subpage .main-content {
  background: #fff; }

.case5.subpage .brief {
  padding: 200px 0 100px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .brief {
      padding: 100px 0 50px 0; } }
  .case5.subpage .brief .photo1 {
    position: absolute;
    right: 0;
    bottom: -100px;
    z-index: 1; }
  .case5.subpage .brief .heading {
    padding: 40px 0 60px 0; }
  .case5.subpage .brief .padding-top {
    padding-top: 120px; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .brief img {
      margin: 0 auto;
      display: block;
      padding: 0 0 40px 0; } }

.case5.subpage .key-visual {
  margin: 150px 0;
  padding: 80px 0;
  background: #f5f5f5; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .key-visual {
      margin: 0 0;
      padding: 0 30px; } }
  .case5.subpage .key-visual .section-title {
    margin: 100px 0 70px 0; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .key-visual .section-title {
        margin: 50px 0 70px 0; } }
  .case5.subpage .key-visual .slider-wrapper {
    max-width: 474px;
    height: auto;
    background: url("../img/lekam/frame1.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 24px 25px; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .key-visual .slider-wrapper {
        margin: 50px 0 0 0; } }

.case5.subpage .www {
  margin: 300px 0; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .www {
      margin: 100px 0;
      padding: 0 30px; } }
  .case5.subpage .www .section-title {
    margin: 100px 0 70px 0; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .www .section-title {
        margin: 50px 0 70px 0; } }
  .case5.subpage .www .slider-wrapper {
    width: 690px;
    height: auto;
    padding: 0;
    position: relative;
    background: url("../img/lekam/www.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 0 7px; }
    @media only screen and (max-width: 1440px) {
      .case5.subpage .www .slider-wrapper {
        width: 550px; } }
    @media only screen and (max-width: 768px) {
      .case5.subpage .www .slider-wrapper {
        margin-top: 40px;
        width: 100%; } }
    .case5.subpage .www .slider-wrapper .key-visual-slider .item {
      border-radius: 30px; }

.case5.subpage .btl {
  margin: 150px 0;
  padding: 80px 0;
  background: #f5f5f5; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .btl {
      margin: 50px 0;
      padding: 0 30px; } }
  .case5.subpage .btl .section-title {
    margin: 100px 0 70px 0; }
    @media only screen and (max-width: 768px) {
      .case5.subpage .btl .section-title {
        margin: 50px 0 70px 0; } }

.case5.subpage .alfabet-zdrowia {
  width: 100%;
  padding: 250px 200px; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .alfabet-zdrowia {
      padding: 50px 20px; } }
  @media only screen and (max-width: 1440px) {
    .case5.subpage .alfabet-zdrowia .section-text {
      padding-left: 50px; } }
  @media only screen and (max-width: 768px) {
    .case5.subpage .alfabet-zdrowia .section-text {
      padding: 0; } }
  @media only screen and (max-width: 768px) {
    .case5.subpage .alfabet-zdrowia img {
      margin: 0 auto 30px auto;
      display: block;
      width: 170px; } }

.case5.subpage .owl-theme .owl-controls {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-tap-highlight-color: transparent; }

.case5.subpage .owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.case5.subpage .owl-carousel .owl-controls .owl-dot, .case5.subpage .owl-carousel .owl-controls .owl-nav .owl-next, .case5.subpage .owl-carousel .owl-controls .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.case5.subpage .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: none;
  border: solid 2px #5a5a5a;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

.case5.subpage .owl-theme .owl-dots .owl-dot.active span, .case5.subpage .owl-theme .owl-dots .owl-dot:hover span {
  background: #5a5a5a; }

.case5.subpage .fb-posts {
  margin: 50px 20px;
  text-align: center; }
  .case5.subpage .fb-posts .fb-post {
    margin-right: 44px; }
    @media only screen and (max-width: 1440px) {
      .case5.subpage .fb-posts .fb-post {
        display: block;
        margin: 0 auto 30px auto; } }

.case5.subpage .fb-adress {
  text-align: center;
  font-size: 33px;
  padding: 200px 20px; }
  @media only screen and (max-width: 768px) {
    .case5.subpage .fb-adress {
      padding: 50px 20px;
      font-size: 16px; } }
  .case5.subpage .fb-adress a {
    color: #2c313d; }
    .case5.subpage .fb-adress a:hover {
      text-decoration: underline; }
  .case5.subpage .fb-adress span {
    font-weight: 700; }

.case5.subpage .footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 100px 0 0 0; }
  .case5.subpage .footer-wrapper a {
    color: #ca2b25; }
  .case5.subpage .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px; }
    @media only screen and (max-width: 1279px) {
      .case5.subpage .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .case5.subpage .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .case5.subpage .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .case5.subpage .footer-wrapper .case-footer .col1 {
      position: relative; }
      .case5.subpage .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .case5.subpage .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .case5.subpage .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case5.subpage .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      .case5.subpage .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

.case6.subpage .copyrights, .case6.subpage .case-title, .case6.subpage .slogan, .case6.subpage .case-title-subpage {
  color: #252730; }

.case6.subpage .bar {
  background: #252730; }

.case6.subpage .logo-svg {
  fill: #252730; }

.case6.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  display: block; }

.case6.subpage .lang span {
  color: #262933; }

.case6.subpage .lead {
  font-size: 30px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 40px; }

.case6.subpage .default-text {
  font-size: 18px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px; }

.case6.subpage .title {
  font-size: 56px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px; }

.case6.subpage .section {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative; }
  @media only screen and (max-width: 1279px) {
    .case6.subpage .section {
      padding: 0 30px; } }

.case6.subpage .main-content {
  background: #fff; }

.case6.subpage .intro {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: visible;
  background: #b4d9cc; }
  .case6.subpage .intro:after {
    background: #b4d9cc; }
  .case6.subpage .intro .trawa {
    width: 100%;
    position: absolute;
    bottom: 0; }
  .case6.subpage .intro .zdrowis {
    position: absolute;
    bottom: -120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .case6.subpage .intro .zdrowis {
        bottom: -20px;
        width: 100px; } }
  .case6.subpage .intro .chmura1 {
    width: 120px;
    position: absolute;
    bottom: 200px;
    right: calc(100% - 120px); }
  .case6.subpage .intro .chmura2 {
    position: absolute;
    bottom: 300px;
    left: calc(100% - 120px); }

.case6.subpage .brief {
  padding: 140px 0 100px 0;
  position: relative; }
  .case6.subpage .brief .photo1 {
    position: absolute;
    right: 0;
    bottom: -100px;
    z-index: 1; }
    @media only screen and (max-width: 1440px) {
      .case6.subpage .brief .photo1 {
        width: 30%;
        bottom: 200px; } }
    @media only screen and (max-width: 768px) {
      .case6.subpage .brief .photo1 {
        display: none; } }
  .case6.subpage .brief .lead {
    padding: 40px 0 60px 0; }

.case6.subpage .technologia-html5 {
  width: 100%;
  height: 247px;
  background-image: url("../img/zdrowisie/technologia_bg.jpg");
  background-size: cover;
  background-position: center center;
  position: relative; }
  .case6.subpage .technologia-html5 .tech-ipad {
    position: absolute;
    top: 60px;
    left: -250px;
    max-width: initial; }
    @media only screen and (max-width: 768px) {
      .case6.subpage .technologia-html5 .tech-ipad {
        position: relative;
        top: 0;
        left: 0;
        display: none; } }
  .case6.subpage .technologia-html5 .technologia-title {
    font-size: 22px;
    color: #3f6b61;
    padding: 73px 0; }
    .case6.subpage .technologia-html5 .technologia-title span {
      font-size: 64px; }

.case6.subpage .opis {
  width: 100%;
  padding: 0 0 100px 0;
  position: relative; }
  .case6.subpage .opis .lead {
    font-size: 48px;
    margin: 100px 0 0 0;
    line-height: 55px; }
    @media only screen and (max-width: 768px) {
      .case6.subpage .opis .lead {
        font-size: 30px;
        margin: 100px 0 0 0;
        line-height: 36px; } }
  .case6.subpage .opis .default-text {
    margin: 70px 0 0 0; }

.case6.subpage .przedstaw-sie {
  width: 100%;
  background: #f8f8f8;
  position: relative;
  overflow: hidden; }
  .case6.subpage .przedstaw-sie .ipad {
    position: absolute;
    right: -100px;
    bottom: 0;
    max-width: initial; }
    @media only screen and (max-width: 768px) {
      .case6.subpage .przedstaw-sie .ipad {
        position: relative;
        max-width: 100%;
        right: 0; } }
  .case6.subpage .przedstaw-sie .lead {
    font-size: 48px;
    margin: 100px 0 80px 0;
    line-height: 55px; }
    @media only screen and (max-width: 768px) {
      .case6.subpage .przedstaw-sie .lead {
        font-size: 30px;
        margin: 100px 0 80px 0;
        line-height: 36px; } }
  .case6.subpage .przedstaw-sie .default-text {
    margin: 0 0 100px 0;
    width: 100%;
    max-width: 280px; }

.case6.subpage .nie-znosza {
  width: 100%;
  padding: 150px 0;
  text-align: center;
  position: relative; }
  .case6.subpage .nie-znosza .zdrowis2 {
    position: absolute;
    right: -220px;
    top: -400px;
    max-width: initial; }
  .case6.subpage .nie-znosza .lead {
    font-size: 36px; }
  .case6.subpage .nie-znosza img {
    padding: 60px 30px 0 0; }
    .case6.subpage .nie-znosza img:last-child {
      padding: 60px 0 0 0; }

.case6.subpage .dobra-zabawa {
  width: 100%;
  position: relative; }
  .case6.subpage .dobra-zabawa .chmury {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);
    max-width: initial;
    z-index: 2; }
  .case6.subpage .dobra-zabawa .zabawa-w-dzien {
    width: 100%;
    background: #f2f2f2;
    text-align: center; }
    .case6.subpage .dobra-zabawa .zabawa-w-dzien .heading {
      font-size: 30px;
      color: #232435;
      font-weight: 500;
      margin: 0 0 110px 0;
      position: relative;
      top: 100px; }
    .case6.subpage .dobra-zabawa .zabawa-w-dzien img {
      position: relative;
      top: 100px;
      z-index: 1; }
      @media only screen and (max-width: 768px) {
        .case6.subpage .dobra-zabawa .zabawa-w-dzien img {
          top: 0;
          margin: 40px 0 0 0;
          padding: 0 30px; } }
  .case6.subpage .dobra-zabawa .zabawa-w-nocy {
    width: 100%;
    background: #303a4d;
    text-align: center;
    height: 808px; }
    .case6.subpage .dobra-zabawa .zabawa-w-nocy .heading {
      font-size: 30px;
      color: #fff;
      font-weight: 500;
      margin: 0 0 110px 0;
      position: relative;
      top: 100px; }
    .case6.subpage .dobra-zabawa .zabawa-w-nocy img {
      position: relative;
      top: 100px; }
      @media only screen and (max-width: 768px) {
        .case6.subpage .dobra-zabawa .zabawa-w-nocy img {
          padding: 0 30px; } }

.case6.subpage .footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 350px 0 0 0; }
  .case6.subpage .footer-wrapper a {
    color: #b4d9cc; }
  .case6.subpage .footer-wrapper .lizak {
    position: absolute;
    left: 50%;
    top: -100px;
    transform: translateX(-50%); }
    @media only screen and (max-width: 1279px) {
      .case6.subpage .footer-wrapper .lizak {
        width: 50px;
        top: -60px; } }
  .case6.subpage .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px; }
    @media only screen and (max-width: 1279px) {
      .case6.subpage .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .case6.subpage .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .case6.subpage .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .case6.subpage .footer-wrapper .case-footer .col1 {
      position: relative; }
      .case6.subpage .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d2d3d4;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .case6.subpage .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .case6.subpage .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case6.subpage .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      .case6.subpage .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

.case7.subpage .logo-wrapper, .case7.subpage .case-arrow-back, .case7.subpage .menu-button {
  background: black;
  mix-blend-mode: exclusion; }

.case7.subpage .case-subtitle-subpage {
  color: #fff; }

.case7.subpage .arrow-line {
  stroke: #fff; }

.case7.subpage .lang {
  mix-blend-mode: exclusion; }
  .case7.subpage .lang span {
    color: #fff; }

.case7.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  display: block; }

.case7.subpage .section-title {
  font-size: 40px;
  color: #232435;
  font-weight: 300;
  letter-spacing: 1px; }
  @media only screen and (max-width: 768px) {
    .case7.subpage .section-title {
      font-size: 28px; } }

.case7.subpage .heading {
  font-size: 30px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 40px; }

.case7.subpage .default-text {
  font-size: 18px;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px; }

.case7.subpage .section {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative; }
  @media only screen and (max-width: 1279px) {
    .case7.subpage .section {
      padding: 0 30px; } }

.case7.subpage .intro {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: visible;
  background: #24273a; }
  .case7.subpage .intro:after {
    background: #24273a; }
  .case7.subpage .intro .intro-bg {
    background-image: url("../img/gostar/intro_bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .case7.subpage .intro .kwiatek {
    position: absolute;
    bottom: -75px;
    left: 49%;
    transform: translateX(-50%);
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .case7.subpage .intro .kwiatek {
        bottom: -35px;
        left: 64%; } }
  .case7.subpage .intro .pudelko {
    position: absolute;
    bottom: -121px;
    left: 66%;
    transform: translateX(-50%);
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .case7.subpage .intro .pudelko {
        bottom: -41px;
        left: 79%; } }

.case7.subpage .main-content {
  background: #fff; }

.case7.subpage .brief {
  padding: 200px 0 100px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case7.subpage .brief {
      padding: 100px 0 50px 0; } }
  .case7.subpage .brief .photo1 {
    position: absolute;
    right: 0;
    bottom: -100px;
    z-index: 1; }
  .case7.subpage .brief .heading {
    padding: 40px 0 60px 0; }

.case7.subpage .kreacja {
  width: 100%;
  padding: 60px 0 0 0; }
  .case7.subpage .kreacja .small-title {
    margin: 0 0 50px 0; }
  .case7.subpage .kreacja .gold {
    color: #e9c679;
    font-size: 50px;
    line-height: 62px;
    font-weight: 300; }
    @media only screen and (max-width: 768px) {
      .case7.subpage .kreacja .gold {
        font-size: 36px;
        line-height: 45px; } }
  @media only screen and (max-width: 768px) {
    .case7.subpage .kreacja img {
      margin: 40px auto 0 auto;
      display: block; } }

.case7.subpage .ksiega-znaku {
  width: 100%;
  background: url("../img/gostar/ksiega_znaku_bg.jpg");
  background-size: 100%;
  background-position: center 100px;
  background-repeat: no-repeat;
  margin: 200px 0 100px 0; }
  @media only screen and (max-width: 768px) {
    .case7.subpage .ksiega-znaku {
      margin: 100px 0 50px 0; } }
  .case7.subpage .ksiega-znaku .logo-boxes-wrapper {
    margin: 0 0 80px 0; }
    .case7.subpage .ksiega-znaku .logo-boxes-wrapper .logo-box {
      width: 100%;
      height: 195px;
      background: #232435;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center; }
      .case7.subpage .ksiega-znaku .logo-boxes-wrapper .logo-box.center {
        background: #f4f0e8; }
      .case7.subpage .ksiega-znaku .logo-boxes-wrapper .logo-box.right {
        background: #e9c679; }
  .case7.subpage .ksiega-znaku .default-text {
    margin: 80px 0; }
  .case7.subpage .ksiega-znaku .folder-wrapper {
    position: relative; }
    .case7.subpage .ksiega-znaku .folder-wrapper .okulary {
      position: absolute;
      top: -70px;
      right: -250px;
      z-index: 1; }
      @media only screen and (max-width: 768px) {
        .case7.subpage .ksiega-znaku .folder-wrapper .okulary {
          display: none; } }
    .case7.subpage .ksiega-znaku .folder-wrapper .folder {
      margin: 0 auto;
      max-width: initial;
      position: relative;
      top: 60px;
      left: -100px; }
      @media only screen and (max-width: 768px) {
        .case7.subpage .ksiega-znaku .folder-wrapper .folder {
          top: 0;
          left: 0;
          max-width: 100%; } }

.case7.subpage .nowoczesna-strona {
  width: 100%;
  position: relative; }
  .case7.subpage .nowoczesna-strona .nowoczesna {
    position: absolute;
    top: 0;
    right: 0; }
    @media only screen and (max-width: 1440px) {
      .case7.subpage .nowoczesna-strona .nowoczesna {
        right: -150px; } }
    @media only screen and (max-width: 1440px) {
      .case7.subpage .nowoczesna-strona .nowoczesna {
        right: -200px; } }
    @media only screen and (max-width: 768px) {
      .case7.subpage .nowoczesna-strona .nowoczesna {
        position: relative;
        right: 0;
        padding: 40px 0 0 40px; } }
  .case7.subpage .nowoczesna-strona .section-title {
    margin: 60px 0 0 0; }
  .case7.subpage .nowoczesna-strona .default-text {
    margin: 50px 0 0 0; }
    .case7.subpage .nowoczesna-strona .default-text a {
      color: #e9c679;
      font-weight: 500; }
      .case7.subpage .nowoczesna-strona .default-text a:hover {
        color: #35baf6; }

.case7.subpage .druk-folderow {
  width: 100%;
  background: url("../img/gostar/ksiega_znaku_bg.jpg");
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 100px 0 100px 0;
  margin: 500px 0 0 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .case7.subpage .druk-folderow {
      background-size: cover;
      margin: 50px 0 0 0; } }
  .case7.subpage .druk-folderow .folder2 {
    position: absolute;
    left: 0;
    top: -100px; }
    @media only screen and (max-width: 1440px) {
      .case7.subpage .druk-folderow .folder2 {
        left: -100px; } }
    @media only screen and (max-width: 1279px) {
      .case7.subpage .druk-folderow .folder2 {
        left: -180px; } }
    @media only screen and (max-width: 768px) {
      .case7.subpage .druk-folderow .folder2 {
        position: relative;
        top: 0;
        margin: 40px 0 0 0; } }
  .case7.subpage .druk-folderow .podkowa {
    position: absolute;
    right: -70px;
    top: -100px; }
    @media only screen and (max-width: 1279px) {
      .case7.subpage .druk-folderow .podkowa {
        top: -150px; } }
    @media only screen and (max-width: 768px) {
      .case7.subpage .druk-folderow .podkowa {
        display: none; } }
  .case7.subpage .druk-folderow .default-text {
    margin: 80px 0 0 0; }

.case7.subpage .wizualizacja {
  width: 100%;
  padding: 280px 0 0 0; }
  @media only screen and (max-width: 768px) {
    .case7.subpage .wizualizacja {
      padding: 70px 0 0 0; } }
  .case7.subpage .wizualizacja .section-title {
    text-align: center;
    max-width: 740px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 100px 0; }
  .case7.subpage .wizualizacja .wizualizacja-photo {
    width: 100%;
    margin: 100px 0 0 0;
    vertical-align: bottom; }
    @media only screen and (max-width: 768px) {
      .case7.subpage .wizualizacja .wizualizacja-photo {
        margin: 0; } }

.case7.subpage .gostarVideo__title {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 150px; }

.case7.subpage .video-wrapper {
  width: 100%;
  max-width: 1000px;
  margin: 150px auto 0 auto; }
  .case7.subpage .video-wrapper .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    height: auto; }
  .case7.subpage .video-wrapper .embed-container iframe, .case7.subpage .video-wrapper .embed-container object, .case7.subpage .video-wrapper .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.case7.subpage .gostarVideo {
  background: url(../img/frame_gostar.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding: 65px 6px 11px 6px;
  margin-bottom: 150px; }
  @media only screen and (max-width: 768px) {
    .case7.subpage .gostarVideo {
      padding: 43px 6px 11px 6px; } }

.case7.subpage .footer-wrapper {
  width: 100%;
  background: #eff2f7;
  position: relative;
  margin: 0 0 0 0; }
  .case7.subpage .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px; }
    @media only screen and (max-width: 1279px) {
      .case7.subpage .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .case7.subpage .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .case7.subpage .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .case7.subpage .footer-wrapper .case-footer .col1 {
      position: relative; }
      .case7.subpage .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #bfc8d6;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .case7.subpage .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .case7.subpage .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case7.subpage .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      .case7.subpage .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

/* max-width: 1600px
   ----------------------------- */
/* max-width: 1440px
   ----------------------------- */
/* max-width: 1366px
   ----------------------------- */
/* max-width: 1280px
   ----------------------------- */
/* max-width: 1024px
   ----------------------------- */
/* max-width: 768px
   ----------------------------- */
/* max-width: 576px
   ----------------------------- */
.fp-tableCell {
  vertical-align: middle; }

.divclear {
  clear: both; }

.case8.subpage .logo-wrapper, .case8.subpage .case-arrow-back, .case8.subpage .menu-button {
  background: #000;
  mix-blend-mode: exclusion; }

.case8.subpage .arrow-line {
  stroke: #fff; }

.case8.subpage .lang {
  mix-blend-mode: exclusion; }

.case8.subpage .wrap {
  max-width: 1022px;
  width: 100%;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  position: relative; }

.case8.subpage .small-title,
.case8.subpage .heading,
.case8.subpage .default-text,
.case8.subpage .title {
  font-weight: 300;
  letter-spacing: 1px; }

.case8.subpage .title {
  font-size: 52px;
  color: #2c313d;
  padding-bottom: 30px; }

.case8.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #262933;
  display: block; }

.case8.subpage .heading {
  padding: 40px 0 60px;
  color: #262832;
  line-height: 40px; }

.case8.subpage .default-text {
  font-size: 18px;
  color: #252730;
  line-height: 30px; }

.case8.subpage .text-bold {
  font-weight: 500; }

.case8.subpage .intro-unicef .wrap {
  background: #000000;
  height: 100vh;
  width: 100vw;
  position: relative; }

.case8.subpage .intro-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; }

.case8.subpage .unicef-logo {
  display: block;
  margin: 0 auto; }

.case8.subpage .case-title-subpage {
  color: #cdd1e1;
  letter-spacing: 0;
  font-size: 65px;
  letter-spacing: 3px; }

.case8.subpage .intro-img {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0; }
  @media only screen and (max-width: 1440px) {
    .case8.subpage .intro-img {
      width: 550px; } }

.case8.subpage .brief .wrap {
  background: #ffffff; }

.case8.subpage .brief .heading {
  font-size: 30px; }

.case8.subpage .brief .claim-wrap {
  height: auto;
  overflow: hidden;
  margin: 95px 65px;
  padding-bottom: 30px;
  position: relative; }

.case8.subpage .brief .claim-wrap::after {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 87%;
  height: 1px;
  background: #f3f3f3; }

.case8.subpage .brief .claim {
  float: left;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 50px;
  color: #5799fc;
  line-height: 1.1;
  position: relative;
  top: 28px;
  overflow: hidden;
  margin: 0 auto;
  letter-spacing: 1px; }

.case8.subpage .brief .typed-cursor {
  font-size: 56px;
  opacity: 1;
  line-height: 100px;
  font-weight: 200;
  color: #cbcbcb;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite; }

.case8.subpage .brief .zoom {
  width: 100px;
  float: right; }

.case8.subpage .brief .claim-small {
  font-size: 16px;
  color: #878787;
  text-align: center; }

.case8.subpage .users .wrap {
  background: #000000;
  max-width: 1200px; }

.case8.subpage .users .icons {
  height: 220px;
  overflow: auto;
  width: 100%; }

.case8.subpage .users .logos {
  text-align: center;
  margin-bottom: 80px;
  position: relative; }

.case8.subpage .users .logo:first-of-type {
  margin-right: 100px; }

.case8.subpage .users .plus {
  line-height: 37px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  position: absolute;
  left: 52%;
  top: 0; }

.case8.subpage .users .default-text {
  text-align: center;
  color: #999999;
  margin: 0 170px;
  line-height: 32px;
  font-size: 26px;
  letter-spacing: 0; }

.case8.subpage .users .highlight {
  font-weight: 600;
  font-size: 52px;
  color: #ffffff;
  text-align: center;
  margin: 68px 0; }

.case8.subpage .users .icons-block,
.case8.subpage .users .icons-block-2 {
  width: 10%;
  float: left;
  padding: 0 6px; }
  .case8.subpage .users .icons-block img,
  .case8.subpage .users .icons-block-2 img {
    float: left;
    margin: 4px 2px;
    display: none; }

.case8.subpage .users .icons-block:first-of-type {
  margin-left: 0; }

.case8.subpage .users .icons-block-2 {
  margin-right: 0; }

.case8.subpage .users .users-total {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  margin: 0 auto 30px 0;
  right: 0; }
  .case8.subpage .users .users-total img {
    margin-right: 5px; }
  .case8.subpage .users .users-total img, .case8.subpage .users .users-total p {
    display: inline-block;
    vertical-align: middle; }
  .case8.subpage .users .users-total p {
    width: auto;
    color: #999999;
    font-size: 20px; }

.case8.subpage .support .wrap {
  padding-left: 350px; }

.case8.subpage .support .heading {
  font-size: 55px; }

.case8.subpage .support .scene-wrap {
  position: absolute;
  left: 20%;
  width: 640px;
  top: 10%;
  height: 100%; }

.case8.subpage .support .scene-support {
  position: relative;
  top: 10%;
  /* width: 150%;
      height: 100%;
      top: 20%;
      li {
          float: left;
          width: 33.3333%;
      }
      li:nth-of-type(2) {
          left: -18%;
          position: relative;
          top: -10px;
      }
      li:nth-of-type(3) {
          left: -36%;
          position: relative;
          top: -20px;
      } */ }

.case8.subpage .mechanism .screen {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.case8.subpage .mechanism .title {
  font-size: 56px;
  margin-bottom: 75px;
  padding-bottom: 0; }

.case8.subpage .mechanism .default-text {
  line-height: 35px;
  padding-right: 50px; }

.case8.subpage .mechanism .step-wrap {
  margin: 40px 0;
  position: relative;
  width: 31%;
  float: left; }

.case8.subpage .mechanism .step {
  position: relative;
  width: 100%;
  height: 120px; }
  .case8.subpage .mechanism .step img {
    display: none; }
    @media only screen and (max-width: 768px) {
      .case8.subpage .mechanism .step img {
        display: block; } }
  .case8.subpage .mechanism .step img.numb {
    position: relative;
    vertical-align: top;
    top: -7px; }
  .case8.subpage .mechanism .step img.base {
    position: relative; }
  .case8.subpage .mechanism .step img.shape {
    position: absolute; }

.case8.subpage .mechanism .step-wrap:first-of-type img.shape {
  top: 30%;
  right: -10%; }

.case8.subpage .mechanism .step-wrap:nth-of-type(2) {
  margin-left: 3.5%;
  margin-right: 3.5%; }
  .case8.subpage .mechanism .step-wrap:nth-of-type(2) img.base {
    position: relative;
    left: -3%; }
  .case8.subpage .mechanism .step-wrap:nth-of-type(2) img.shape {
    top: 45%;
    right: 8%; }

.case8.subpage .mechanism .step-wrap:last-of-type img.base {
  float: right;
  right: 40%; }

.case8.subpage .mechanism .step-wrap:last-of-type img.numb {
  right: 20%; }

.case8.subpage .mechanism .action {
  margin-top: 30px;
  margin-bottom: 10px; }

.case8.subpage .mechanism .name {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
  height: 45px;
  padding-right: 0;
  letter-spacing: 0; }

.case8.subpage .mechanism .cta,
.case8.subpage .mechanism .act {
  font-size: 12.5px;
  font-weight: 400;
  line-height: 2;
  padding-right: 0;
  letter-spacing: 0.5px; }

.case8.subpage .mechanism .cta {
  color: #878787; }

.case8.subpage .mechanism .act {
  color: #7ab62e; }

.case8.subpage .help {
  background-image: url("../img/unicef/children.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* .text-block {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    } */ }
  .case8.subpage .help .title {
    text-align: center;
    color: #ffffff;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 73px; }
  .case8.subpage .help .default-text {
    color: #ffffff;
    font-size: 45px;
    text-align: center;
    font-weight: 400; }

.case8.subpage .footer-wrapper {
  width: 100%;
  background: #f7f7f7;
  position: relative;
  margin: 0 0 0 0; }
  .case8.subpage .footer-wrapper a {
    color: #a2bb3f; }
  .case8.subpage .footer-wrapper .case-footer {
    padding: 160px 20px 200px 20px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    @media only screen and (max-width: 1279px) {
      .case8.subpage .footer-wrapper .case-footer {
        text-align: center;
        padding: 80px 20px 100px 20px; } }
    .case8.subpage .footer-wrapper .case-footer .footer-title {
      font-size: 30px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 90px 0;
      line-height: 48px; }
      @media only screen and (max-width: 768px) {
        .case8.subpage .footer-wrapper .case-footer .footer-title {
          font-size: 22px;
          line-height: 30px; } }
    .case8.subpage .footer-wrapper .case-footer .col1 {
      position: relative; }
      .case8.subpage .footer-wrapper .case-footer .col1:after {
        content: " ";
        display: block;
        height: 100px;
        width: 1px;
        background: #d8d8d8;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -50px; }
      @media only screen and (max-width: 1279px) {
        .case8.subpage .footer-wrapper .case-footer .col1:after {
          display: none; } }
    .case8.subpage .footer-wrapper .case-footer .contact {
      color: #2f2f2f;
      font-size: 21px;
      margin: 20px 0 0 0; }
      @media only screen and (max-width: 1279px) {
        .case8.subpage .footer-wrapper .case-footer .contact {
          font-size: 18px; } }
      .case8.subpage .footer-wrapper .case-footer .contact .position {
        font-size: 12px; }

@media screen and (max-width: 1600px) {
  .case8.subpage .intro-text {
    top: 23%; }
  .case8.subpage .support .scene-wrap {
    left: 5%; } }

@media screen and (max-width: 1440px) {
  .case8.subpage .users .logos {
    margin-bottom: 55px; }
  .case8.subpage .users .default-text {
    margin: 0 170px;
    line-height: 1.5;
    font-size: 20px; }
  .case8.subpage .users .highlight {
    font-size: 44px;
    margin: 32px 0; }
  .case8.subpage .mechanism .title {
    font-size: 42px;
    margin-bottom: 45px; }
  .case8.subpage .mechanism .default-text {
    line-height: 1.5;
    padding-right: 100px;
    font-size: 16px; }
  .case8.subpage .mechanism .screen {
    max-height: 400px; }
  .case8.subpage .brief .heading {
    font-size: 20px;
    padding: 20px 0 30px; }
  .case8.subpage .default-text {
    font-size: 16px;
    line-height: 1.5; }
  .case8.subpage .brief .claim-wrap {
    margin: 35px 65px;
    padding-bottom: 20px; }
  .case8.subpage .support .scene-wrap {
    width: 250px; } }

@media screen and (max-width: 1280px) {
  .case8.subpage .wrap {
    padding-right: 70px;
    padding-left: 30px; }
  .case8.subpage .users .icons-block img, .case8.subpage .users .icons-block-2 img {
    width: 14%; }
  .case8.subpage .users .logos {
    margin-bottom: 50px; }
  .case8.subpage .users .highlight {
    margin: 50px 0; }
  .case8.subpage .support .wrap {
    padding-left: 25%;
    padding-right: 220px; } }

@media screen and (max-width: 1024px) {
  .case8.subpage .intro-text {
    top: 30%; }
  .case8.subpage .intro-img {
    width: 400px; }
  .case8.subpage .mechanism .screen {
    display: none; }
  .case8.subpage .mechanism .title {
    margin-bottom: 30px;
    padding-top: 120px; }
  .case8.subpage .brief .wrap {
    padding-right: 180px;
    padding-top: 80px; }
  .case8.subpage .brief .claim-wrap {
    margin: 35px 65px; }
  .case8.subpage .users .default-text {
    margin: 0 100px; }
  .case8.subpage .users .highlight {
    margin: 30px 0; }
  .case8.subpage .users .icons-block, .case8.subpage .users .icons-block-2 {
    padding: 0; }
    .case8.subpage .users .icons-block img, .case8.subpage .users .icons-block-2 img {
      width: 14%; }
  .case8.subpage .support .wrap {
    max-width: 800px; }
  .case8.subpage .support .heading {
    line-height: 60px; }
  .case8.subpage .support .scene-wrap {
    width: 270px; }
  .case8.subpage .help .default-text {
    font-size: 35px; } }

@media screen and (max-width: 768px) {
  .case8.subpage .fp-section,
  .case8.subpage .fp-slide,
  .case8.subpage .fp-tableCell {
    height: auto !important; }
  .case8.subpage .heading {
    font-size: 24px; }
  .case8.subpage .title {
    font-size: 36px; }
  .case8.subpage .intro-unicef .wrap {
    height: 650px;
    width: 100%; }
  .case8.subpage .intro-unicef .intro-img {
    width: 75%; }
  .case8.subpage .intro-unicef .case-title-subpage {
    font-size: 35px; }
  .case8.subpage .brief .wrap {
    padding: 140px 30px 100px 30px; }
  .case8.subpage .brief .claim-wrap {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px; }
  .case8.subpage .brief .claim-wrap::after {
    bottom: 0;
    width: 100%; }
  .case8.subpage .brief .claim {
    width: auto;
    font-size: 30px; }
  .case8.subpage .brief .claim-small {
    margin-top: 20px; }
  .case8.subpage .brief .typed-cursor {
    line-height: 70px;
    font-size: 40px; }
  .case8.subpage .brief .zoom {
    width: 70px; }
  .case8.subpage .users .wrap {
    padding: 120px 30px 30px 30px; }
  .case8.subpage .users .logo:first-of-type {
    margin: 0 auto 10px auto; }
  .case8.subpage .users .logo {
    margin: 0 auto; }
  .case8.subpage .users .default-text {
    margin: 0; }
  .case8.subpage .users .icons {
    height: auto; }
  .case8.subpage .users .plus {
    position: static; }
  .case8.subpage .users .highlight {
    font-size: 40px; }
  .case8.subpage .users .icons-block,
  .case8.subpage .users .icons-block-2 {
    width: 16%;
    margin: 0 1px;
    padding: 0; }
    .case8.subpage .users .icons-block:first-of-type,
    .case8.subpage .users .icons-block-2:first-of-type {
      margin: 0 1px; }
  .case8.subpage .users .users-total {
    position: relative;
    margin-top: 30px; }
    .case8.subpage .users .users-total img {
      display: inline-block !important; }
  .case8.subpage .mechanism .wrap {
    padding: 0 30px; }
  .case8.subpage .mechanism .default-text {
    padding-right: 0; }
  .case8.subpage .mechanism .step-wrap {
    width: 100%;
    float: none;
    height: 120px;
    margin: 70px 0; }
  .case8.subpage .mechanism .title {
    font-size: 36px; }
  .case8.subpage .mechanism .step {
    width: 31%;
    float: left; }
  .case8.subpage .mechanism .step-wrap:first-of-type img.shape,
  .case8.subpage .mechanism .step-wrap:nth-of-type(2) img.shape {
    display: none !important; }
  .case8.subpage .mechanism .step-wrap:nth-of-type(2) img.numb,
  .case8.subpage .mechanism .step-wrap:nth-of-type(2) img.base,
  .case8.subpage .mechanism .step-wrap:last-of-type img.numb,
  .case8.subpage .mechanism .step-wrap:last-of-type img.base {
    left: 0;
    right: 0;
    float: none; }
  .case8.subpage .mechanism .step-wrap:nth-of-type(2) {
    margin: 0; }
  .case8.subpage .mechanism .action {
    width: 63%;
    float: left;
    margin: 0;
    margin-left: 5%;
    margin-top: 10px; }
  .case8.subpage .mechanism .name {
    height: auto; }
  .case8.subpage .mechanism .divclear {
    display: none; }
  .case8.subpage .help .wrap {
    padding: 150px 30px; }
  .case8.subpage .help .title {
    font-size: 30px;
    padding-bottom: 0; }
  .case8.subpage .help .default-text {
    font-size: 18px; }
  .case8.subpage .support .heading {
    font-size: 36px;
    padding: 60px 0 30px 0; }
  .case8.subpage .support .scene-wrap {
    width: 250px;
    top: 50px;
    padding: 30px;
    left: 50%;
    transform: translateX(-50%);
    height: auto; }
  .case8.subpage .support .scene-support {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    transform: translateX(-20%); }
  .case8.subpage .support .wrap {
    position: static;
    width: 100%;
    max-width: 100%;
    padding: 30px;
    padding-bottom: 80px;
    padding-top: 350px; } }

@media screen and (max-width: 576px) {
  .case8.subpage .intro-unicef .wrap {
    height: 570px; }
  .case8.subpage .users .icons-block, .case8.subpage .users .icons-block-2 {
    padding: 0;
    margin: 0; }
    .case8.subpage .users .icons-block img, .case8.subpage .users .icons-block-2 img {
      width: 10%; }
  .case8.subpage .brief .typed-cursor {
    line-height: 65px;
    font-size: 30px; }
  .case8.subpage .brief .claim {
    font-size: 18px; }
  .case8.subpage .brief .zoom {
    width: 60px; }
  .case8.subpage .brief .claim-wrap {
    margin: 35px 0; } }

.case9.subpage #fp-nav ul li.visited a span, .case9.subpage #fp-nav ul li a:hover span, .case9.subpage #fp-nav ul li a.active span {
  background: #ec6816; }

.case9.subpage .logo-wrapper, .case9.subpage .case-arrow-back, .case9.subpage .menu-button {
  mix-blend-mode: exclusion; }

.case9.subpage .lang {
  mix-blend-mode: exclusion; }

.case9.subpage .case-title-subpage {
  color: #ec6816; }

.case9.subpage .case-subtitle-subpage {
  color: #fff; }

.case9.subpage .arrow-line {
  stroke: #fff; }

.case9.subpage .small-title {
  font-size: 13px;
  text-transform: uppercase;
  color: #262933;
  font-weight: 300;
  letter-spacing: 1px;
  display: block; }

.case9.subpage .section-title {
  font-size: 40px;
  color: #ec6816;
  font-weight: 300;
  letter-spacing: 1px; }
  @media only screen and (max-width: 768px) {
    .case9.subpage .section-title {
      font-size: 28px; } }

.case9.subpage .heading {
  font-size: 36px;
  color: #ec6816;
  font-weight: 300;
  letter-spacing: 1px; }
  @media only screen and (max-width: 1279px) {
    .case9.subpage .heading {
      font-size: 32px; } }
  @media only screen and (max-width: 768px) {
    .case9.subpage .heading {
      font-size: 26px; } }

.case9.subpage .default-text {
  font-size: 18px;
  color: #333333;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px; }
  @media only screen and (max-width: 1279px) {
    .case9.subpage .default-text {
      font-size: 16px;
      line-height: 26px; } }

.case9.subpage .section {
  position: relative; }

.case9.subpage .intro {
  padding: 0;
  overflow: hidden; }
  .case9.subpage .intro:after {
    background: #1c1b1d; }
  @media only screen and (max-width: 768px) {
    .case9.subpage .intro {
      height: 600px !important; } }

.case9.subpage #steam-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%; }

.case9.subpage #steam-canvas2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; }

.case9.subpage .home-iron {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

.case9.subpage .brief {
  background-color: #fff; }
  .case9.subpage .brief > .fp-tableCell {
    position: relative;
    overflow: hidden; }
  .case9.subpage .brief .wrap {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    z-index: 100; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .brief .wrap {
        max-width: 600px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .brief .wrap {
        max-width: 320px;
        margin: 0  auto;
        padding: 50px 25px 50px;
        text-align: center; } }
  .case9.subpage .brief .heading {
    margin: 60px 0; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .brief .heading {
        margin: 40px 0; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .brief .heading {
        margin: 25px 0; } }
  .case9.subpage .brief .iron {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
    @media only screen and (max-width: 768px) {
      .case9.subpage .brief .iron {
        max-width: 120px; } }
  .case9.subpage .brief .slogan {
    font-size: 50px;
    color: #323232;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 65px; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .brief .slogan {
        margin-top: 45px;
        font-size: 42px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .brief .slogan {
        margin-top: 25px;
        font-size: 36px; } }
    .case9.subpage .brief .slogan span {
      color: #e82c2e;
      font-weight: 600; }

.case9.subpage .calc-collector {
  background-image: url("../img/zelazka_tefal/boomerang.gif");
  background-size: cover; }
  @media only screen and (max-width: 1279px) {
    .case9.subpage .calc-collector {
      padding: 100px 0; } }
  @media only screen and (max-width: 768px) {
    .case9.subpage .calc-collector {
      padding: 50px 0; } }
  .case9.subpage .calc-collector__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.44); }
  .case9.subpage .calc-collector .wrap {
    text-align: center;
    position: relative;
    z-index: 2; }
  .case9.subpage .calc-collector .heading {
    font-size: 80px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .calc-collector .heading {
        font-size: 66px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .calc-collector .heading {
        font-size: 34px; } }
  .case9.subpage .calc-collector .default-text {
    font-size: 33px;
    color: #ec6816;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.5; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .calc-collector .default-text {
        font-size: 27px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .calc-collector .default-text {
        font-size: 23px; } }
  .case9.subpage .calc-collector .slogan {
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.5; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .calc-collector .slogan {
        font-size: 24px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .calc-collector .slogan {
        font-size: 20px; } }

.case9.subpage .landing {
  background-color: #191919; }
  .case9.subpage .landing .fp-tableCell {
    display: block; }
  .case9.subpage .landing .wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 200px; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .landing .wrap {
        padding: 75px 25px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .landing .wrap {
        padding: 45px 25px; } }
    .case9.subpage .landing .wrap .heading {
      font-size: 46px;
      color: #ec6816;
      font-weight: 300;
      letter-spacing: 1px; }
      @media only screen and (max-width: 1279px) {
        .case9.subpage .landing .wrap .heading {
          font-size: 40px;
          padding-bottom: 25px; } }
      @media only screen and (max-width: 768px) {
        .case9.subpage .landing .wrap .heading {
          font-size: 32px; } }
    .case9.subpage .landing .wrap .default-text {
      font-size: 18px;
      color: #666666;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 30px;
      max-width: 900px;
      text-align: center; }
      @media only screen and (max-width: 1279px) {
        .case9.subpage .landing .wrap .default-text {
          font-size: 16px;
          line-height: 26px;
          padding-bottom: 25px; } }

.case9.subpage .quiz {
  background-color: #fff; }
  @media only screen and (max-width: 1279px) {
    .case9.subpage .quiz {
      padding: 0 45px; } }
  @media only screen and (max-width: 768px) {
    .case9.subpage .quiz {
      padding: 0 25px; } }
  .case9.subpage .quiz .quiz-slider {
    background-image: url(../img/zelazka_tefal/frame.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    padding: 75px 8px 17px 8px;
    width: 100%;
    max-width: 829px;
    margin-left: -70px; }
    @media only screen and (max-width: 768px) {
      .case9.subpage .quiz .quiz-slider {
        margin-left: auto; } }
  @media only screen and (max-width: 768px) {
    .case9.subpage .quiz .quiz-desc {
      text-align: center;
      padding-top: 75px;
      padding-bottom: 45px; } }
  .case9.subpage .quiz .heading {
    font-size: 56px;
    color: #ec6816;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 40px; }
  .case9.subpage .quiz .default-text {
    font-size: 18px;
    color: #191919;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5;
    max-width: 540px; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .quiz .default-text {
        max-width: 400px; } }
    @media only screen and (max-width: 768px) {
      .case9.subpage .quiz .default-text {
        margin: 0 auto; } }
  .case9.subpage .quiz .logos-wrapper {
    margin-top: 40px; }
    .case9.subpage .quiz .logos-wrapper .quiz-logo {
      display: inline-block; }
      .case9.subpage .quiz .logos-wrapper .quiz-logo:nth-child(1), .case9.subpage .quiz .logos-wrapper .quiz-logo:nth-child(2) {
        padding-right: 40px; }

.case9.subpage .facebook {
  background-color: #191919;
  overflow: hidden; }
  .case9.subpage .facebook .wrap {
    max-width: 1440px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start; }
    @media only screen and (max-width: 1440px) {
      .case9.subpage .facebook .wrap {
        max-width: 900px; } }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .facebook .wrap {
        padding: 45px 20px; } }
    .case9.subpage .facebook .wrap .inner-wrapper {
      display: flex;
      margin: 0 auto -105px; }
      @media only screen and (max-width: 1279px) {
        .case9.subpage .facebook .wrap .inner-wrapper {
          flex-direction: column;
          margin: 0 auto -245px; } }
  .case9.subpage .facebook .text-wrapper {
    margin: -80px auto 0;
    padding-left: 150px;
    display: inline-block; }
    @media only screen and (max-width: 1440px) {
      .case9.subpage .facebook .text-wrapper {
        margin: -122px auto 0; } }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .facebook .text-wrapper {
        order: 1;
        text-align: center;
        padding: 0 0 45px;
        margin: 0 auto; } }
  .case9.subpage .facebook .heading {
    font-size: 56px;
    color: #ec6816;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 40px; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .facebook .heading {
        font-size: 40px;
        font-size: 48px; } }
  .case9.subpage .facebook .default-text {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5;
    max-width: 630px; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .facebook .default-text {
        font-size: 16px; } }
  .case9.subpage .facebook .phone-wrapper {
    position: relative;
    display: inline-block; }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .facebook .phone-wrapper {
        margin: 0 auto;
        max-width: 280px;
        order: 2; } }
  .case9.subpage .facebook .ads-wrapper {
    position: absolute;
    left: 45px;
    top: 228px;
    width: 1150px; }
    @media only screen and (max-width: 1440px) {
      .case9.subpage .facebook .ads-wrapper {
        top: 180px;
        width: 835px;
        left: 33px; } }
    @media only screen and (max-width: 1279px) {
      .case9.subpage .facebook .ads-wrapper {
        top: 180px;
        left: 25px;
        width: 229px; } }
    .case9.subpage .facebook .ads-wrapper .ads-wrapper__item {
      transform: translateY(500%); }
      @media only screen and (max-width: 1440px) {
        .case9.subpage .facebook .ads-wrapper .ads-wrapper__item {
          height: 205px; } }
      @media only screen and (max-width: 1279px) {
        .case9.subpage .facebook .ads-wrapper .ads-wrapper__item {
          height: initial; } }

.case9.subpage .footer-wrapper {
  background: #fff;
  color: #2f2f2f; }
  .case9.subpage .footer-wrapper a {
    color: #ec6816; }

.case10.subpage {
  color: #262832;
  /* Variables
   ----------------------------- */
  letter-spacing: 1px; }
  .case10.subpage p {
    margin: 0 0 10px; }
  .case10.subpage .logo-wrapper, .case10.subpage .case-arrow-back, .case10.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case10.subpage .arrow-line {
    stroke: #fff; }
  .case10.subpage .lang {
    mix-blend-mode: exclusion; }
    .case10.subpage .lang span {
      color: #fff; }
  .case10.subpage .ucb-btn {
    font-weight: 800;
    text-align: center;
    color: #fff;
    padding: 14px 50px;
    background-color: #105d98;
    border-radius: 25px;
    transition: .3s ease;
    text-decoration: none; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-btn {
        padding: 11px 27px; } }
    .case10.subpage .ucb-btn:hover, .case10.subpage .ucb-btn:focus {
      background-color: #fff;
      color: #105d98;
      text-decoration: none; }
  .case10.subpage .ucb-small-description {
    font-size: 13px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-small-description {
        font-size: 11px; } }
  .case10.subpage .ucb-description {
    font-size: 18px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-description {
        font-size: 16px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-description {
        font-size: 14px; } }
  .case10.subpage .ucb-large-description {
    font-size: 20px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-large-description {
        font-size: 18px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-large-description {
        font-size: 16px; } }
  .case10.subpage .ucb-small-header {
    font-size: 36px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-small-header {
        font-size: 32px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-small-header {
        font-size: 28px; } }
  .case10.subpage .ucb-header {
    font-size: 48px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-header {
        font-size: 40px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-header {
        font-size: 32px; } }
  .case10.subpage span,
  .case10.subpage p,
  .case10.subpage h1 {
    /* Colors */
    /* Weight */
    /* Text transform */ }
    .case10.subpage span.color-white,
    .case10.subpage p.color-white,
    .case10.subpage h1.color-white {
      color: #fff; }
    .case10.subpage span.color-dark,
    .case10.subpage p.color-dark,
    .case10.subpage h1.color-dark {
      color: #000; }
    .case10.subpage span.font-extra-light,
    .case10.subpage p.font-extra-light,
    .case10.subpage h1.font-extra-light {
      font-weight: 100; }
    .case10.subpage span.font-light,
    .case10.subpage p.font-light,
    .case10.subpage h1.font-light {
      font-weight: 300; }
    .case10.subpage span.font-reqular,
    .case10.subpage p.font-reqular,
    .case10.subpage h1.font-reqular {
      font-weight: 400; }
    .case10.subpage span.font-medium,
    .case10.subpage p.font-medium,
    .case10.subpage h1.font-medium {
      font-weight: 500; }
    .case10.subpage span.font-semi-bold,
    .case10.subpage p.font-semi-bold,
    .case10.subpage h1.font-semi-bold {
      font-weight: 600; }
    .case10.subpage span.font-bold,
    .case10.subpage p.font-bold,
    .case10.subpage h1.font-bold {
      font-weight: 700; }
    .case10.subpage span.font-extra-bold,
    .case10.subpage p.font-extra-bold,
    .case10.subpage h1.font-extra-bold {
      font-weight: 800; }
    .case10.subpage span.uppercase,
    .case10.subpage p.uppercase,
    .case10.subpage h1.uppercase {
      text-transform: uppercase; }
  .case10.subpage .relative {
    position: relative; }
  @media only screen and (max-width: 1279px) {
    .case10.subpage .no-height-mobile {
      height: auto !important; } }
  .case10.subpage body {
    overflow-x: hidden !important; }
  .case10.subpage .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .case10.subpage .top-video {
    z-index: -1; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .top-video {
        display: none; } }
  .case10.subpage .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .case10.subpage .hello-ucb-bg:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0b1720;
    opacity: .85; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .hello-ucb-bg:before {
        content: none; } }
  @media only screen and (max-width: 1279px) {
    .case10.subpage .hello-ucb-bg {
      background-image: url("../img/zyrtec/zyrtec_ucb.png") !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; } }
  .case10.subpage .hello-ucb {
    text-align: center;
    z-index: 100; }
    .case10.subpage .hello-ucb > .upc-small-description,
    .case10.subpage .hello-ucb h1 {
      position: relative;
      letter-spacing: 2px; }
    .case10.subpage .hello-ucb h1 {
      font-size: 65px;
      letter-spacing: 8px;
      margin-bottom: 10%; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .hello-ucb h1 {
          font-size: 36px; } }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .hello-ucb h1 {
          font-size: 48px;
          margin-bottom: 20%;
          letter-spacing: 5px; } }
    .case10.subpage .hello-ucb .know-more {
      font-size: 16px;
      margin-bottom: 45px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .hello-ucb .know-more {
          margin-bottom: 35px;
          font-size: 14px; } }
    .case10.subpage .hello-ucb .ucb-btn-box {
      position: absolute;
      bottom: 15%;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .hello-ucb .ucb-btn-box {
          position: relative; } }
  .case10.subpage .ucb-brief {
    width: 100%;
    max-width: 1066px;
    margin: 0 auto;
    padding-top: 145px;
    padding-bottom: 175px;
    text-align: left;
    padding-left: auto;
    padding-right: auto; }
    @media only screen and (max-width: 1440px) {
      .case10.subpage .ucb-brief {
        text-align: center;
        padding-top: 25px;
        padding-bottom: 25px; } }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-brief {
        text-align: center;
        padding-top: 105px;
        padding-bottom: 125px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-brief {
        padding-top: 85px;
        padding-bottom: 85px;
        padding-left: 15px;
        padding-right: 15px; } }
    .case10.subpage .ucb-brief .ucb-small-description {
      margin-bottom: 55px; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-brief .ucb-small-description {
          margin-bottom: 15px; } }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-brief .ucb-small-description {
          margin-bottom: 35px; } }
    .case10.subpage .ucb-brief .ucb-small-headers {
      margin-bottom: 50px;
      font-size: 30px;
      line-height: 1.3; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-brief .ucb-small-headers {
          margin-bottom: 20px;
          font-size: 24px; } }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-brief .ucb-small-headers {
          font-size: 17px;
          margin-bottom: 50px; } }
    .case10.subpage .ucb-brief .ucb-description {
      margin-bottom: 95px;
      color: #1f242f;
      line-height: 2; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-brief .ucb-description {
          margin-bottom: 45px; } }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-brief .ucb-description {
          margin-bottom: 65px; } }
  .case10.subpage .ucb-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-flex {
        flex-direction: column; } }
    .case10.subpage .ucb-flex .ucb-col {
      margin: 0;
      text-align: center; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-flex .ucb-col {
          margin-bottom: 15px; } }
      .case10.subpage .ucb-flex .ucb-col:nth-child(1) {
        flex: 1 1 40%;
        width: 40%;
        margin-top: 35px; }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ucb-flex .ucb-col:nth-child(1) {
            flex: 1 1 100%;
            width: 100%; } }
      .case10.subpage .ucb-flex .ucb-col:nth-child(2), .case10.subpage .ucb-flex .ucb-col:nth-child(3), .case10.subpage .ucb-flex .ucb-col:nth-child(4) {
        flex: 1 1 20%;
        width: 20%; }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ucb-flex .ucb-col:nth-child(2), .case10.subpage .ucb-flex .ucb-col:nth-child(3), .case10.subpage .ucb-flex .ucb-col:nth-child(4) {
            flex: 1 1 100%;
            width: 100%; } }
      .case10.subpage .ucb-flex .ucb-col > img {
        display: block;
        margin: 0 auto 25px 0; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-flex .ucb-col > img {
            margin: 0 auto 10px; } }
      .case10.subpage .ucb-flex .ucb-col > img + .zyrtec-desc {
        text-align: left;
        margin: 0;
        max-width: 250px; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-flex .ucb-col > img + .zyrtec-desc {
            text-align: center; } }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ucb-flex .ucb-col > img + .zyrtec-desc {
            margin: 0 auto; } }
  .case10.subpage .zyrtec-desc {
    font-size: 14px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .zyrtec-desc {
        font-size: 12px; } }
  .case10.subpage .ucb-number {
    color: #005aaa;
    font-size: 52px; }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-number {
        font-size: 32px; } }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-number {
        font-size: 42px; } }
  .case10.subpage .ucb-communication {
    color: #202430;
    text-align: left;
    max-width: 90%;
    margin: auto; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-communication {
        max-width: 100%;
        text-align: center; } }
    .case10.subpage .ucb-communication .ucb-header {
      margin: 0 auto 25px 0; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-communication .ucb-header {
          margin-bottom: 15px; } }
    .case10.subpage .ucb-communication .ubc-large-description,
    .case10.subpage .ucb-communication .ucb-header {
      max-width: 380px;
      line-height: 1.8; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-communication .ubc-large-description,
        .case10.subpage .ucb-communication .ucb-header {
          margin: 0 auto;
          max-width: 300px; } }
  .case10.subpage .ubc-svg-box {
    height: 300px;
    width: 400px;
    margin: 0 auto; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-svg-box {
        width: 300px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ubc-svg-box {
        margin-top: 45px; } }
    .case10.subpage .ubc-svg-box svg {
      width: 100%;
      margin: 0 auto; }
  .case10.subpage .ubc-svg-green,
  .case10.subpage .ubc-svg-blue {
    position: absolute; }
    .case10.subpage .ubc-svg-green svg,
    .case10.subpage .ubc-svg-blue svg {
      width: 100%;
      opacity: 0; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-svg-green svg,
        .case10.subpage .ubc-svg-blue svg {
          opacity: 1; } }
    .case10.subpage .ubc-svg-green p,
    .case10.subpage .ubc-svg-blue p {
      position: absolute;
      margin: 0 auto;
      text-align: left;
      line-height: 1.2;
      opacity: 0; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-svg-green p,
        .case10.subpage .ubc-svg-blue p {
          opacity: 1; } }
  .case10.subpage .ubc-svg-green {
    top: -45px;
    left: 0;
    right: 60px; }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ubc-svg-green {
        left: 0;
        right: 60px; } }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-svg-green {
        top: 0;
        left: 0;
        right: 60px; } }
    .case10.subpage .ubc-svg-green svg {
      max-width: 320px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-svg-green svg {
          max-width: 250px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-svg-green svg {
          max-width: 180px; } }
    .case10.subpage .ubc-svg-green p {
      top: 105px;
      right: auto;
      left: 70px;
      color: #caeaee;
      font-size: 42px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-svg-green p {
          left: 54px;
          top: 85px;
          font-size: 32px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-svg-green p {
          left: 64px;
          top: 58px;
          font-size: 24px; } }
  .case10.subpage .ubc-svg-blue {
    top: 116px;
    left: 208px;
    right: 0; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-svg-blue {
        top: 114px;
        left: 148px;
        right: 0; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ubc-svg-blue {
        top: 78px;
        left: 105px;
        right: 0; } }
    .case10.subpage .ubc-svg-blue svg {
      transform: rotate(-180deg);
      max-width: 210px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-svg-blue svg {
          max-width: 150px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-svg-blue svg {
          max-width: 120px;
          transform: rotate(-5deg); } }
    .case10.subpage .ubc-svg-blue p {
      color: #96d5d1;
      font-size: 24px;
      top: 87px;
      left: 34px;
      right: auto; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-svg-blue p {
          top: 68px;
          right: 34px;
          font-size: 20px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-svg-blue p {
          top: 45px;
          right: auto;
          left: 64px;
          font-size: 16px; } }
  .case10.subpage .ubc-fb {
    text-align: center; }
    .case10.subpage .ubc-fb .ubc-fb-title {
      width: 100%;
      max-width: 740px;
      margin-bottom: 100px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-fb .ubc-fb-title {
          margin-bottom: 50px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-fb .ubc-fb-title {
          margin-bottom: 25px; } }
  .case10.subpage .ubc-second-box,
  .case10.subpage .ubc-fb-title {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case10.subpage .ubc-second-box {
    width: 100%;
    max-width: 1250px;
    height: 480px;
    margin: 0 auto;
    bottom: 0; }
    @media only screen and (max-width: 1440px) {
      .case10.subpage .ubc-second-box {
        max-width: 1050px;
        height: 350px; } }
  .case10.subpage .ucb-fb-title {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 300px; }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-fb-title {
        top: 15%; } }
    .case10.subpage .ucb-fb-title .ucb-large-description {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      padding-left: 25px;
      padding-right: 25px; }
  .case10.subpage .ubc-phone-container {
    width: 100%;
    max-width: 1250px; }
  .case10.subpage .ubc-slider-phone {
    text-align: center;
    text-transform: uppercase; }
  .case10.subpage .ubc-pagination {
    position: absolute;
    top: 65%;
    width: 100%; }
    @media only screen and (min-width: 1441px) {
      .case10.subpage .ubc-pagination {
        top: 50%; } }
    .case10.subpage .ubc-pagination > .pagination-arrow {
      position: absolute;
      cursor: pointer;
      transition: .3s ease; }
      .case10.subpage .ubc-pagination > .pagination-arrow.left {
        left: -35px; }
        @media only screen and (min-width: 1441px) {
          .case10.subpage .ubc-pagination > .pagination-arrow.left {
            left: -15px; } }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ubc-pagination > .pagination-arrow.left {
            left: 0; } }
        .case10.subpage .ubc-pagination > .pagination-arrow.left:hover {
          transform: translateX(-5px); }
      .case10.subpage .ubc-pagination > .pagination-arrow.right {
        right: -35px; }
        @media only screen and (min-width: 1441px) {
          .case10.subpage .ubc-pagination > .pagination-arrow.right {
            right: -15px; } }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ubc-pagination > .pagination-arrow.right {
            right: 0; } }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ubc-pagination > .pagination-arrow.right {
            right: -15px; } }
        .case10.subpage .ubc-pagination > .pagination-arrow.right:hover {
          transform: translateX(5px); }
      .case10.subpage .ubc-pagination > .pagination-arrow svg {
        width: 55px;
        height: 15px; }
        @media only screen and (min-width: 1441px) {
          .case10.subpage .ubc-pagination > .pagination-arrow svg {
            width: 35px;
            height: 10px; } }
  .case10.subpage .ubc-iphone-box {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto; }
    @media only screen and (max-width: 1440px) {
      .case10.subpage .ubc-iphone-box {
        max-width: 220px; } }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-iphone-box {
        margin-bottom: 0; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ubc-iphone-box {
        margin-bottom: -25px; } }
  .case10.subpage .ubc-slide-phone p {
    margin-bottom: 40px; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-slide-phone p {
        margin-bottom: 30px; } }
  .case10.subpage .ubc-slide-phone svg {
    margin-bottom: -165px; }
    @media only screen and (min-width: 1441px) {
      .case10.subpage .ubc-slide-phone svg {
        margin-bottom: -125px; } }
    @media only screen and (max-width: 1440px) {
      .case10.subpage .ubc-slide-phone svg {
        margin-bottom: -121px; } }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-slide-phone svg {
        margin-bottom: -121px; } }
  .case10.subpage .ubc-slide-phone img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 262px; }
    @media only screen and (max-width: 1440px) {
      .case10.subpage .ubc-slide-phone img {
        max-width: 190px; } }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ubc-slide-phone img {
        max-width: 193px; } }
  .case10.subpage .ubc-page {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ubc-page {
        max-width: 950px; } }
    .case10.subpage .ubc-page .ubc-page-img,
    .case10.subpage .ubc-page .ubc-page-description {
      width: 100%;
      margin: 0 auto;
      letter-spacing: 0.5px; }
    .case10.subpage .ubc-page .ubc-page-img {
      max-width: 667px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-page .ubc-page-img {
          max-width: 460px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-page .ubc-page-img {
          max-width: 320px;
          margin-bottom: 50px; } }
    .case10.subpage .ubc-page .ubc-page-description {
      max-width: 400px;
      text-align: left; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ubc-page .ubc-page-description {
          max-width: 360px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ubc-page .ubc-page-description {
          max-width: 320px;
          text-align: center; } }
      .case10.subpage .ubc-page .ubc-page-description .ucb-small-header {
        margin-bottom: 30px;
        color: #202430; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ubc-page .ubc-page-description .ucb-small-header {
            margin-bottom: 15px; } }
      .case10.subpage .ubc-page .ubc-page-description .ucb-description {
        margin-bottom: 50px;
        color: #1f242f;
        line-height: 1.7; }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ubc-page .ubc-page-description .ucb-description {
            margin-bottom: 35px; } }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ubc-page .ubc-page-description .ucb-description {
            margin-bottom: 20px; } }
      .case10.subpage .ubc-page .ubc-page-description a {
        color: #005496;
        text-decoration: none;
        transition: .3s ease; }
        .case10.subpage .ubc-page .ubc-page-description a:hover {
          color: #105d98; }
  .case10.subpage .ucb-post {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-post {
        max-width: 950px; } }
    .case10.subpage .ucb-post .ucb-post-description {
      max-width: 545px;
      text-align: left;
      color: #000;
      line-height: 2;
      margin: 0 auto;
      padding-left: 25px;
      padding-right: 25px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-post .ucb-post-description {
          max-width: 360px;
          order: 1; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-post .ucb-post-description {
          max-width: 320px;
          margin-bottom: 50px;
          text-align: center;
          order: 2; } }
      .case10.subpage .ucb-post .ucb-post-description .ucb-header {
        margin-bottom: 25px; }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ucb-post .ucb-post-description .ucb-header {
            margin-bottom: 10px; } }
    .case10.subpage .ucb-post .ucb-post-img-box {
      height: 100vh;
      order: 2; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-post .ucb-post-img-box {
          height: 100%;
          order: 1; } }
    .case10.subpage .ucb-post .ucb-post-img {
      margin-left: auto;
      margin-right: -30px;
      max-width: 500px; }
      @media only screen and (max-width: 1440px) {
        .case10.subpage .ucb-post .ucb-post-img {
          width: 400px;
          margin: 0 auto; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-post .ucb-post-img {
          position: relative;
          max-width: 320px;
          margin-bottom: 30px; } }
  .case10.subpage .ucb-csr {
    background-image: url("../img/zyrtec/ucb-kid-2.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 100%; }
    @media only screen and (max-width: 768px) {
      .case10.subpage .ucb-csr {
        background-image: url(""); } }
    .case10.subpage .ucb-csr .ucb-csr-img {
      height: 100vh;
      width: 100%; }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-csr .ucb-csr-img {
          background-image: url("../img/zyrtec/ucb-kid.png");
          background-position: left;
          background-repeat: no-repeat;
          background-size: cover;
          height: 600px;
          margin-bottom: 50px; } }
      .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-green,
      .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-blue {
        top: auto;
        left: auto;
        visibility: hidden; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-green,
          .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-blue {
            visibility: visible; } }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-green {
          right: 10px;
          bottom: 45px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-green {
          right: 60px;
          bottom: 0; } }
      .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-blue {
        right: 0;
        bottom: -9px; }
        .case10.subpage .ucb-csr .ucb-csr-img .ubc-svg-blue .font-medium {
          text-align: center;
          top: 50px;
          right: 25px; }
      .case10.subpage .ucb-csr .ucb-csr-img .like-share-box {
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        position: absolute;
        right: 0;
        left: 30px;
        max-width: 120px;
        text-align: center; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-csr .ucb-csr-img .like-share-box {
            font-size: 20px; } }
        .case10.subpage .ucb-csr .ucb-csr-img .like-share-box.like-part {
          top: 55px; }
        .case10.subpage .ucb-csr .ucb-csr-img .like-share-box.share-part {
          top: 88px; }
        .case10.subpage .ucb-csr .ucb-csr-img .like-share-box svg {
          width: 17px; }
    .case10.subpage .ucb-csr .ucb-csr-description {
      width: 100%;
      max-width: 400px;
      height: 340px;
      margin: auto;
      text-align: left;
      padding-left: 25px;
      padding-right: 25px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-csr .ucb-csr-description {
          max-width: 360px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-csr .ucb-csr-description {
          max-width: 320px;
          height: auto;
          margin-bottom: 50px;
          text-align: center; } }
      .case10.subpage .ucb-csr .ucb-csr-description .ucb-small-header {
        margin-bottom: 30px;
        color: #202430; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-csr .ucb-csr-description .ucb-small-header {
            margin-bottom: 15px; } }
      .case10.subpage .ucb-csr .ucb-csr-description .ucb-description {
        margin-bottom: 50px;
        color: #1f242f;
        line-height: 2; }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ucb-csr .ucb-csr-description .ucb-description {
            margin-bottom: 35px; } }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-csr .ucb-csr-description .ucb-description {
            margin-bottom: 20px; } }
  .case10.subpage .lookbook-video {
    overflow: hidden; }
  .case10.subpage .ucb-yt {
    width: 100%;
    height: 100vh; }
    .case10.subpage .ucb-yt:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #0b1720;
      opacity: .65;
      z-index: 100; }
    .case10.subpage .ucb-yt .embed-responsive-item {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; }
    .case10.subpage .ucb-yt .mbl-video-svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 100;
      text-align: center;
      transition: .3s ease; }
      .case10.subpage .ucb-yt .mbl-video-svg svg {
        width: 100px;
        margin-bottom: 30px;
        transition: .3s ease;
        cursor: pointer; }
        @media only screen and (max-width: 1279px) {
          .case10.subpage .ucb-yt .mbl-video-svg svg {
            width: 75px;
            margin-bottom: 10px; } }
        .case10.subpage .ucb-yt .mbl-video-svg svg:hover {
          transform: scale(1.1); }
      .case10.subpage .ucb-yt .mbl-video-svg .see-yt {
        font-size: 12px;
        margin-bottom: 40px; }
        @media only screen and (max-width: 768px) {
          .case10.subpage .ucb-yt .mbl-video-svg .see-yt {
            margin-bottom: 25px; } }
      .case10.subpage .ucb-yt .mbl-video-svg p {
        line-height: 1;
        white-space: nowrap; }
  .case10.subpage .ucb-mag-box {
    padding-top: 45px; }
  .case10.subpage .ucb-mag {
    width: 100%;
    max-width: 1065px;
    margin: -100px auto 0;
    padding: 0 25px;
    text-align: center; }
    @media only screen and (max-width: 1279px) {
      .case10.subpage .ucb-mag {
        margin: -50px auto 0; } }
    .case10.subpage .ucb-mag .ucb-header {
      margin-bottom: 30px; }
      @media only screen and (max-width: 1279px) {
        .case10.subpage .ucb-mag .ucb-header {
          margin-bottom: 15px; } }
    .case10.subpage .ucb-mag .ucb-description {
      margin-bottom: 60px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.5; }
      @media only screen and (max-width: 1440px) {
        .case10.subpage .ucb-mag .ucb-description {
          margin-bottom: 40px; } }
    @media only screen and (max-width: 1440px) {
      .case10.subpage .ucb-mag .ucb-mag-logo {
        width: 100%;
        max-width: 120px;
        margin: 0 auto; } }
    .case10.subpage .ucb-mag .ucb-mag-image {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto auto 0;
      max-width: 650px; }
      @media only screen and (max-width: 1440px) {
        .case10.subpage .ucb-mag .ucb-mag-image {
          max-width: 450px; } }
      @media only screen and (max-width: 768px) {
        .case10.subpage .ucb-mag .ucb-mag-image {
          max-width: 250px; } }
  .case10.subpage .footer-wrapper {
    background: #f2f9fc;
    color: #2f2f2f; }
    .case10.subpage .footer-wrapper a {
      color: #005aaa; }
  @media only screen and (max-width: 768px) {
    .case10.subpage .macbook-section {
      padding: 25px 15px; } }

.case11.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important; }
  .case11.subpage #fp-nav ul li.visited a span, .case11.subpage #fp-nav ul li a:hover span, .case11.subpage #fp-nav ul li a.active span {
    background: #ed2327; }
  .case11.subpage #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion; }
  .case11.subpage .lang {
    mix-blend-mode: exclusion; }
    .case11.subpage .lang span {
      color: #fff; }
  .case11.subpage p {
    margin: 0 0 10px; }
  .case11.subpage .arrow-line {
    stroke: #fff; }
  .case11.subpage .logo-wrapper, .case11.subpage .case-arrow-back, .case11.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case11.subpage .ks-small-description {
    font-size: 13px; }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .ks-small-description {
        font-size: 11px; } }
  .case11.subpage .ks-description {
    font-size: 18px; }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .ks-description {
        font-size: 16px; } }
    @media only screen and (max-width: 768px) {
      .case11.subpage .ks-description {
        font-size: 14px; } }
  .case11.subpage .ks-large-description {
    font-size: 32px; }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .ks-large-description {
        font-size: 26px; } }
    @media only screen and (max-width: 768px) {
      .case11.subpage .ks-large-description {
        font-size: 20px; } }
  .case11.subpage .ks-small-heading {
    font-size: 38px; }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .ks-small-heading {
        font-size: 32px; } }
    @media only screen and (max-width: 768px) {
      .case11.subpage .ks-small-heading {
        font-size: 28px; } }
  .case11.subpage .ks-heading {
    font-size: 48px; }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .ks-heading {
        font-size: 40px; } }
    @media only screen and (max-width: 768px) {
      .case11.subpage .ks-heading {
        font-size: 32px; } }
  .case11.subpage span,
  .case11.subpage p,
  .case11.subpage h1 {
    /* Colors */
    /* Weight */
    /* Text transform */ }
    .case11.subpage span.color-white,
    .case11.subpage p.color-white,
    .case11.subpage h1.color-white {
      color: #fff; }
    .case11.subpage span.color-black,
    .case11.subpage p.color-black,
    .case11.subpage h1.color-black {
      color: #000; }
    .case11.subpage span.color-red,
    .case11.subpage p.color-red,
    .case11.subpage h1.color-red {
      color: #ed2327; }
    .case11.subpage span.font-extra-light,
    .case11.subpage p.font-extra-light,
    .case11.subpage h1.font-extra-light {
      font-weight: 100; }
    .case11.subpage span.font-light,
    .case11.subpage p.font-light,
    .case11.subpage h1.font-light {
      font-weight: 300; }
    .case11.subpage span.font-reqular,
    .case11.subpage p.font-reqular,
    .case11.subpage h1.font-reqular {
      font-weight: 400; }
    .case11.subpage span.font-medium,
    .case11.subpage p.font-medium,
    .case11.subpage h1.font-medium {
      font-weight: 500; }
    .case11.subpage span.font-semi-bold,
    .case11.subpage p.font-semi-bold,
    .case11.subpage h1.font-semi-bold {
      font-weight: 600; }
    .case11.subpage span.uppercase,
    .case11.subpage p.uppercase,
    .case11.subpage h1.uppercase {
      text-transform: uppercase; }
    .case11.subpage span.palanquin,
    .case11.subpage p.palanquin,
    .case11.subpage h1.palanquin {
      font-family: "Palanquin", sans-serif; }
  .case11.subpage .relative {
    position: relative; }
  @media only screen and (max-width: 1279px) {
    .case11.subpage .no-height-mobile {
      height: auto !important; } }
  .case11.subpage .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case11.subpage body {
    overflow-x: hidden !important; }
  .case11.subpage .hello-ks {
    position: relative;
    text-align: center;
    overflow: hidden; }
    .case11.subpage .hello-ks .mask {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #333;
      opacity: .85;
      z-index: 0; }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .hello-ks {
        background-image: url("../img/kompan-smaku/mobile-ks.png") !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; } }
    .case11.subpage .hello-ks .top-video {
      z-index: -1; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .hello-ks .top-video {
          display: none; } }
    .case11.subpage .hello-ks h1,
    .case11.subpage .hello-ks p {
      position: relative;
      z-index: 100; }
    .case11.subpage .hello-ks h1 {
      font-size: 65px;
      letter-spacing: 1px;
      margin-bottom: 250px; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .hello-ks h1 {
          font-size: 56px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .hello-ks h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .hello-ks h1 {
          font-size: 36px; } }
    .case11.subpage .hello-ks img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      width: 100%;
      max-width: 680px;
      margin: 0 auto; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .hello-ks img {
          max-width: 540px; } }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .hello-ks img {
          max-width: 420px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .hello-ks img {
          max-width: 300px; } }
  @media only screen and (max-width: 768px) {
    .case11.subpage .brief-ks {
      padding: 45px 25px; } }
  .case11.subpage .brief-ks-box {
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .case11.subpage .brief-ks-box {
        text-align: center; } }
    .case11.subpage .brief-ks-box > div:nth-child(1) {
      order: 1; }
      .case11.subpage .brief-ks-box > div:nth-child(1) img {
        max-width: 270px; }
        @media only screen and (max-width: 1440px) {
          .case11.subpage .brief-ks-box > div:nth-child(1) img {
            max-width: 200px; } }
    .case11.subpage .brief-ks-box > div:nth-child(2) {
      width: 100%;
      max-width: 650px;
      margin: 0 auto 0 0;
      order: 2; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .brief-ks-box > div:nth-child(2) {
          margin: 0 auto;
          order: 3; } }
    .case11.subpage .brief-ks-box > div:nth-child(3) {
      order: 3;
      margin-top: -15px; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .brief-ks-box > div:nth-child(3) {
          margin-top: auto;
          order: 2; } }
      .case11.subpage .brief-ks-box > div:nth-child(3) img {
        max-width: 160px; }
        @media only screen and (max-width: 1440px) {
          .case11.subpage .brief-ks-box > div:nth-child(3) img {
            max-width: 120px; } }
        @media only screen and (max-width: 1279px) {
          .case11.subpage .brief-ks-box > div:nth-child(3) img {
            margin-bottom: 25px; } }
    .case11.subpage .brief-ks-box > div:nth-child(4) {
      order: 4;
      width: 100%;
      max-width: 650px;
      margin: 0 auto 0 0; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .brief-ks-box > div:nth-child(4) {
          margin: 0 auto; } }
    .case11.subpage .brief-ks-box img {
      display: block;
      width: 100%;
      margin: 0 auto; }
    .case11.subpage .brief-ks-box .ks-small-description {
      margin-bottom: 25px; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .brief-ks-box .ks-small-description {
          margin-bottom: 15px; } }
    .case11.subpage .brief-ks-box .ks-large-description {
      margin-bottom: 25px; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .brief-ks-box .ks-large-description {
          margin-bottom: 15px; } }
    .case11.subpage .brief-ks-box .ks-description {
      margin-bottom: 70px; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .brief-ks-box .ks-description {
          margin-bottom: 45px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .brief-ks-box .ks-description {
          margin-bottom: 15px; } }
    .case11.subpage .brief-ks-box .more-about-ks {
      text-align: left; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .brief-ks-box .more-about-ks {
          text-align: center; } }
      .case11.subpage .brief-ks-box .more-about-ks .ks-description {
        margin-bottom: 0; }
      .case11.subpage .brief-ks-box .more-about-ks .brief-numbers {
        font-size: 56px; }
        @media only screen and (max-width: 1440px) {
          .case11.subpage .brief-ks-box .more-about-ks .brief-numbers {
            font-size: 48px; } }
        @media only screen and (max-width: 1279px) {
          .case11.subpage .brief-ks-box .more-about-ks .brief-numbers {
            font-size: 36px; } }
  .case11.subpage .recipes-for-dishes,
  .case11.subpage .searching {
    background-color: #f2f2f2;
    text-align: center; }
    .case11.subpage .recipes-for-dishes .ks-description,
    .case11.subpage .searching .ks-description {
      width: 100%;
      max-width: 780px;
      margin: 0 auto 70px; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .recipes-for-dishes .ks-description,
        .case11.subpage .searching .ks-description {
          margin: 0 auto 30px; } }
    .case11.subpage .recipes-for-dishes .ks-slider .ks-slide img,
    .case11.subpage .searching .ks-slider .ks-slide img {
      width: 100%;
      max-width: 920px;
      margin: 0 auto; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .recipes-for-dishes .ks-slider .ks-slide img,
        .case11.subpage .searching .ks-slider .ks-slide img {
          max-width: 80%; } }
  .case11.subpage .ks-pagination {
    position: absolute;
    top: 50%;
    width: 100%; }
    .case11.subpage .ks-pagination .pagination-arrow {
      position: absolute;
      cursor: pointer;
      transition: .3s ease; }
      .case11.subpage .ks-pagination .pagination-arrow.left {
        left: -35px; }
        @media only screen and (max-width: 1440px) {
          .case11.subpage .ks-pagination .pagination-arrow.left {
            left: -10px; } }
        .case11.subpage .ks-pagination .pagination-arrow.left:hover {
          transform: translateX(-5px); }
      .case11.subpage .ks-pagination .pagination-arrow.right {
        right: -35px; }
        .case11.subpage .ks-pagination .pagination-arrow.right:hover {
          transform: translateX(5px); }
      .case11.subpage .ks-pagination .pagination-arrow svg {
        width: 55px;
        height: 15px; }
        @media only screen and (min-width: 1441px) {
          .case11.subpage .ks-pagination .pagination-arrow svg {
            width: 35px;
            height: 10px; } }
  .case11.subpage .options-section {
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .case11.subpage .options-section {
        text-align: center; } }
    .case11.subpage .options-section .ks-description {
      width: 100%;
      max-width: 385px;
      margin: 0 auto 85px 0; }
      @media only screen and (max-width: 1440px) {
        .case11.subpage .options-section .ks-description {
          margin: 0 auto 65px 0; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .options-section .ks-description {
          margin: 0 auto 45px; } }
    .case11.subpage .options-section .claim-wrap {
      color: #7b7b7b;
      text-align: left; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .options-section .claim-wrap {
          text-align: center; } }
      .case11.subpage .options-section .claim-wrap .typed-cursor {
        display: none; }
      .case11.subpage .options-section .claim-wrap .claim-title {
        text-align: left; }
        @media only screen and (max-width: 768px) {
          .case11.subpage .options-section .claim-wrap .claim-title {
            text-align: center;
            margin-bottom: 10px; } }
      .case11.subpage .options-section .claim-wrap .claim {
        position: relative;
        font-size: 24px;
        text-align: left;
        min-height: 28px; }
        @media only screen and (max-width: 1440px) {
          .case11.subpage .options-section .claim-wrap .claim {
            font-size: 20px; } }
        @media only screen and (max-width: 1279px) {
          .case11.subpage .options-section .claim-wrap .claim {
            font-size: 18px; } }
        @media only screen and (max-width: 768px) {
          .case11.subpage .options-section .claim-wrap .claim {
            margin-bottom: 20px;
            text-align: center; } }
        .case11.subpage .options-section .claim-wrap .claim:before {
          content: '';
          position: absolute;
          width: 210px;
          height: 1px;
          background-color: #000;
          left: 0;
          right: auto;
          margin: 0 auto;
          bottom: -10px; }
          @media only screen and (max-width: 768px) {
            .case11.subpage .options-section .claim-wrap .claim:before {
              right: 0; } }
      .case11.subpage .options-section .claim-wrap .claim-button {
        display: inline-block;
        font-size: 15px;
        padding: 15px 22px 17px;
        background-color: #ed2327;
        border-radius: 2px;
        color: #fff;
        border: 1px solid #ed2327;
        transition: 0.3s ease; }
        .case11.subpage .options-section .claim-wrap .claim-button p {
          white-space: nowrap;
          margin: 0; }
      .case11.subpage .options-section .claim-wrap .ripple {
        width: 0;
        height: 0;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        transform: scale(0);
        position: absolute;
        opacity: 1; }
      .case11.subpage .options-section .claim-wrap .rippleEffect {
        animation: rippleDrop .6s linear; }

@keyframes rippleDrop {
  100% {
    transform: scale(2);
    opacity: 0; } }
    .case11.subpage .options-section .cursor-container svg {
      position: absolute;
      width: 15px;
      height: 15px; }
    .case11.subpage .options-section .cursor-container .normal-cursor {
      left: -125px;
      bottom: -40px; }
    .case11.subpage .options-section .cursor-container .pointer-cursor {
      opacity: 0;
      bottom: -5px;
      left: -7px; }
    @media only screen and (max-width: 768px) {
      .case11.subpage .options-section .iphone-container {
        height: 300px; } }
    .case11.subpage .options-section .iphone-box {
      position: absolute;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      bottom: 0;
      left: auto;
      right: 0;
      height: 100vh; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .options-section .iphone-box {
          max-width: 300px;
          height: auto;
          left: 0; } }
      .case11.subpage .options-section .iphone-box .iphone-box-img2 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        margin: 0 auto; }
        @media only screen and (max-width: 1279px) {
          .case11.subpage .options-section .iphone-box .iphone-box-img2 {
            max-width: 220px; } }
      .case11.subpage .options-section .iphone-box .iphone-box-img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        transform: translateY(100%); }
        @media only screen and (max-width: 1279px) {
          .case11.subpage .options-section .iphone-box .iphone-box-img {
            max-width: 220px; } }
      .case11.subpage .options-section .iphone-box .iphone-box-message {
        position: absolute;
        left: 51px;
        bottom: 270px;
        width: 0;
        max-width: 200px; }
        @media only screen and (max-width: 1279px) {
          .case11.subpage .options-section .iphone-box .iphone-box-message {
            left: 155px;
            bottom: 115px;
            max-width: 100px; } }
        @media only screen and (max-width: 768px) {
          .case11.subpage .options-section .iphone-box .iphone-box-message {
            left: 65px; } }
  .case11.subpage .add-recipe-section {
    overflow: hidden; }
  @media only screen and (max-width: 1440px) {
    .case11.subpage .add-recipe .add-recipe-img {
      height: 380px; } }
  @media only screen and (max-width: 768px) {
    .case11.subpage .add-recipe .add-recipe-img {
      height: 280px; } }
  .case11.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: 680px; }
    @media only screen and (max-width: 1440px) {
      .case11.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
        transform: translateY(-50%) scale(0.7);
        transform-origin: left; } }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
        right: 0;
        top: 85px;
        bottom: auto;
        margin: auto;
        max-width: 380px; } }
    @media only screen and (max-width: 768px) {
      .case11.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
        top: 172px;
        max-width: 220px; } }
    .case11.subpage .add-recipe .add-recipe-img .add-recipe-wrapper .add-recipe-zoom {
      position: absolute;
      top: 50%;
      right: -70px;
      transform: translateY(-50%);
      margin-left: 50px;
      height: 488px;
      transform-origin: 50% 50%; }
      @media only screen and (max-width: 768px) {
        .case11.subpage .add-recipe .add-recipe-img .add-recipe-wrapper .add-recipe-zoom {
          height: 386px; } }
  .case11.subpage .add-recipe .add-recipe-title {
    width: 100%;
    max-width: 435px;
    margin-left: 120px; }
    @media only screen and (max-width: 1440px) {
      .case11.subpage .add-recipe .add-recipe-title {
        margin-left: 0; } }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .add-recipe .add-recipe-title {
        text-align: center;
        margin: 0 auto;
        padding: 0 25px; } }
  .case11.subpage .searching {
    background-image: url("../img/kompan-smaku/searching-bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 100%; }
    @media only screen and (max-width: 1440px) {
      .case11.subpage .searching {
        background-size: 700px; } }
    @media only screen and (max-width: 1279px) {
      .case11.subpage .searching {
        background-image: none; } }
    .case11.subpage .searching .searching-bg {
      position: absolute;
      bottom: 0;
      left: 0; }
    .case11.subpage .searching .searching-img {
      display: none; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .searching .searching-img {
          display: block;
          width: 100%;
          max-width: 420px;
          margin: 0 15px 45px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .searching .searching-img {
          max-width: 250px;
          margin: 0 5% 0 auto; } }
    .case11.subpage .searching .searching-title {
      font-size: 56px; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .searching .searching-title {
          font-size: 46px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .searching .searching-title {
          font-size: 36px; } }
    .case11.subpage .searching .searching-title-box {
      width: 100%;
      max-width: 395px;
      margin: 0 auto;
      text-align: left; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .searching .searching-title-box {
          text-align: center; } }
  .case11.subpage .calendar {
    text-align: center; }
    .case11.subpage .calendar .calendar-desc {
      margin-top: -280px; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .calendar .calendar-desc {
          margin-top: -180px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .calendar .calendar-desc {
          margin-top: -50px; } }
    .case11.subpage .calendar .calendar-image {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 930px;
      height: 460px;
      margin: auto auto 0 auto; }
      @media only screen and (max-width: 1279px) {
        .case11.subpage .calendar .calendar-image {
          max-width: 500px;
          height: 248px; } }
      @media only screen and (max-width: 768px) {
        .case11.subpage .calendar .calendar-image {
          max-width: 280px;
          height: 138px; } }
      .case11.subpage .calendar .calendar-image img {
        width: 100%;
        margin: 0 auto; }
  .case11.subpage .footer-wrapper {
    background: #fff;
    color: #2f2f2f; }
    .case11.subpage .footer-wrapper a {
      color: #000; }
  @media only screen and (max-width: 768px) {
    .case11.subpage .data-option-section {
      padding: 45px 0; } }
  @media only screen and (max-width: 768px) {
    .case11.subpage .add-recipe-section {
      margin: 45px 0; } }

/* Hello */
.hello-bs {
  background: url("../img/bracia-sadownicy/header-bg.jpg") 100%/cover no-repeat;
  position: relative; }
  .hello-bs::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }

.case12.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important; }
  .case12.subpage #fp-nav ul li.visited a span, .case12.subpage #fp-nav ul li a:hover span, .case12.subpage #fp-nav ul li a.active span {
    background: #ed2327; }
  .case12.subpage #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion; }
  .case12.subpage .lang {
    mix-blend-mode: exclusion; }
    .case12.subpage .lang span {
      color: #fff; }
  .case12.subpage .arrow-line {
    stroke: #fff; }
  .case12.subpage .logo-wrapper, .case12.subpage .case-arrow-back, .case12.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case12.subpage .ks-small-description {
    font-size: 13px; }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .ks-small-description {
        font-size: 11px; } }
  .case12.subpage .ks-description {
    font-size: 18px; }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .ks-description {
        font-size: 16px; } }
    @media only screen and (max-width: 768px) {
      .case12.subpage .ks-description {
        font-size: 14px; } }
  .case12.subpage .ks-large-description {
    font-size: 32px; }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .ks-large-description {
        font-size: 26px; } }
    @media only screen and (max-width: 768px) {
      .case12.subpage .ks-large-description {
        font-size: 20px; } }
  .case12.subpage .ks-small-heading {
    font-size: 38px; }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .ks-small-heading {
        font-size: 32px; } }
    @media only screen and (max-width: 768px) {
      .case12.subpage .ks-small-heading {
        font-size: 28px; } }
  .case12.subpage .ks-heading {
    font-size: 48px; }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .ks-heading {
        font-size: 40px; } }
    @media only screen and (max-width: 768px) {
      .case12.subpage .ks-heading {
        font-size: 32px; } }
  .case12.subpage span,
  .case12.subpage p,
  .case12.subpage h1 {
    /* Colors */
    /* Weight */
    /* Text transform */ }
    .case12.subpage span.color-white,
    .case12.subpage p.color-white,
    .case12.subpage h1.color-white {
      color: #fff; }
    .case12.subpage span.color-black,
    .case12.subpage p.color-black,
    .case12.subpage h1.color-black {
      color: #000; }
    .case12.subpage span.color-red,
    .case12.subpage p.color-red,
    .case12.subpage h1.color-red {
      color: #ed2327; }
    .case12.subpage span.font-extra-light,
    .case12.subpage p.font-extra-light,
    .case12.subpage h1.font-extra-light {
      font-weight: 100; }
    .case12.subpage span.font-light,
    .case12.subpage p.font-light,
    .case12.subpage h1.font-light {
      font-weight: 300; }
    .case12.subpage span.font-reqular,
    .case12.subpage p.font-reqular,
    .case12.subpage h1.font-reqular {
      font-weight: 400; }
    .case12.subpage span.font-medium,
    .case12.subpage p.font-medium,
    .case12.subpage h1.font-medium {
      font-weight: 500; }
    .case12.subpage span.font-semi-bold,
    .case12.subpage p.font-semi-bold,
    .case12.subpage h1.font-semi-bold {
      font-weight: 600; }
    .case12.subpage span.uppercase,
    .case12.subpage p.uppercase,
    .case12.subpage h1.uppercase {
      text-transform: uppercase; }
    .case12.subpage span.palanquin,
    .case12.subpage p.palanquin,
    .case12.subpage h1.palanquin {
      font-family: "Palanquin", sans-serif; }
  .case12.subpage .relative {
    position: relative; }
  @media only screen and (max-width: 1279px) {
    .case12.subpage .no-height-mobile {
      height: auto !important; } }
  .case12.subpage .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case12.subpage body {
    overflow-x: hidden !important; }
  .case12.subpage .hello-ks {
    position: relative;
    text-align: center;
    overflow: hidden; }
    .case12.subpage .hello-ks .mask {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #333;
      opacity: .85;
      z-index: 0; }
    .case12.subpage .hello-ks .top-video {
      z-index: -1; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .hello-ks .top-video {
          display: none; } }
    .case12.subpage .hello-ks h1,
    .case12.subpage .hello-ks p {
      position: relative;
      z-index: 100; }
    .case12.subpage .hello-ks h1 {
      font-size: 65px;
      letter-spacing: 1px; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .hello-ks h1 {
          font-size: 56px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .hello-ks h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .hello-ks h1 {
          font-size: 36px; } }
    .case12.subpage .hello-ks img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -20px;
      width: 100%;
      max-width: 680px;
      margin: 0 auto; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .hello-ks img {
          max-width: 540px; } }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .hello-ks img {
          max-width: 420px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .hello-ks img {
          max-width: 300px; } }
  @media only screen and (max-width: 768px) {
    .case12.subpage .brief-ks {
      padding: 45px 25px; } }
  .case12.subpage .brief-ks-box {
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .case12.subpage .brief-ks-box {
        text-align: center; } }
    .case12.subpage .brief-ks-box > div:nth-child(1) {
      order: 1; }
      .case12.subpage .brief-ks-box > div:nth-child(1) img {
        max-width: 270px; }
        @media only screen and (max-width: 1440px) {
          .case12.subpage .brief-ks-box > div:nth-child(1) img {
            max-width: 200px; } }
    .case12.subpage .brief-ks-box > div:nth-child(2) {
      width: 100%;
      max-width: 650px;
      margin: 0 auto 0 0;
      order: 2; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .brief-ks-box > div:nth-child(2) {
          margin: 0 auto;
          order: 3; } }
    .case12.subpage .brief-ks-box > div:nth-child(3) {
      order: 3;
      margin-top: -15px; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .brief-ks-box > div:nth-child(3) {
          margin-top: auto;
          order: 2; } }
      .case12.subpage .brief-ks-box > div:nth-child(3) img {
        max-width: 160px; }
        @media only screen and (max-width: 1440px) {
          .case12.subpage .brief-ks-box > div:nth-child(3) img {
            max-width: 120px; } }
        @media only screen and (max-width: 1279px) {
          .case12.subpage .brief-ks-box > div:nth-child(3) img {
            margin-bottom: 25px; } }
    .case12.subpage .brief-ks-box > div:nth-child(4) {
      order: 4;
      width: 100%;
      max-width: 650px;
      margin: 0 auto 0 0; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .brief-ks-box > div:nth-child(4) {
          margin: 0 auto; } }
    .case12.subpage .brief-ks-box img {
      display: block;
      width: 100%;
      margin: 0 auto; }
    .case12.subpage .brief-ks-box .ks-small-description {
      margin-bottom: 25px; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .brief-ks-box .ks-small-description {
          margin-bottom: 15px; } }
    .case12.subpage .brief-ks-box .ks-large-description {
      margin-bottom: 25px; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .brief-ks-box .ks-large-description {
          margin-bottom: 15px; } }
    .case12.subpage .brief-ks-box .ks-description {
      margin-bottom: 70px; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .brief-ks-box .ks-description {
          margin-bottom: 45px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .brief-ks-box .ks-description {
          margin-bottom: 15px; } }
    .case12.subpage .brief-ks-box .more-about-ks {
      text-align: left; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .brief-ks-box .more-about-ks {
          text-align: center; } }
      .case12.subpage .brief-ks-box .more-about-ks .ks-description {
        margin-bottom: 0; }
      .case12.subpage .brief-ks-box .more-about-ks .brief-numbers {
        font-size: 56px; }
        @media only screen and (max-width: 1440px) {
          .case12.subpage .brief-ks-box .more-about-ks .brief-numbers {
            font-size: 48px; } }
        @media only screen and (max-width: 1279px) {
          .case12.subpage .brief-ks-box .more-about-ks .brief-numbers {
            font-size: 36px; } }
  .case12.subpage .recipes-for-dishes,
  .case12.subpage .searching {
    background-color: #f2f2f2;
    text-align: center; }
    .case12.subpage .recipes-for-dishes .ks-description,
    .case12.subpage .searching .ks-description {
      width: 100%;
      max-width: 780px;
      margin: 0 auto 70px; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .recipes-for-dishes .ks-description,
        .case12.subpage .searching .ks-description {
          margin: 0 auto 30px; } }
    .case12.subpage .recipes-for-dishes .ks-slider .ks-slide img,
    .case12.subpage .searching .ks-slider .ks-slide img {
      width: 100%;
      max-width: 920px;
      margin: 0 auto; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .recipes-for-dishes .ks-slider .ks-slide img,
        .case12.subpage .searching .ks-slider .ks-slide img {
          max-width: 80%; } }
  .case12.subpage .ks-pagination {
    position: absolute;
    top: 50%;
    width: 100%; }
    .case12.subpage .ks-pagination .pagination-arrow {
      position: absolute;
      cursor: pointer;
      transition: .3s ease; }
      .case12.subpage .ks-pagination .pagination-arrow.left {
        left: -35px; }
        @media only screen and (max-width: 1440px) {
          .case12.subpage .ks-pagination .pagination-arrow.left {
            left: -10px; } }
        .case12.subpage .ks-pagination .pagination-arrow.left:hover {
          transform: translateX(-5px); }
      .case12.subpage .ks-pagination .pagination-arrow.right {
        right: -35px; }
        .case12.subpage .ks-pagination .pagination-arrow.right:hover {
          transform: translateX(5px); }
      .case12.subpage .ks-pagination .pagination-arrow svg {
        width: 55px;
        height: 15px; }
        @media only screen and (min-width: 1441px) {
          .case12.subpage .ks-pagination .pagination-arrow svg {
            width: 35px;
            height: 10px; } }
  .case12.subpage .options-section {
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .case12.subpage .options-section {
        text-align: center; } }
    .case12.subpage .options-section .ks-description {
      width: 100%;
      max-width: 385px;
      margin: 0 auto 85px 0; }
      @media only screen and (max-width: 1440px) {
        .case12.subpage .options-section .ks-description {
          margin: 0 auto 65px 0; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .options-section .ks-description {
          margin: 0 auto 45px; } }
    .case12.subpage .options-section .claim-wrap {
      color: #7b7b7b;
      text-align: left; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .options-section .claim-wrap {
          text-align: center; } }
      .case12.subpage .options-section .claim-wrap .typed-cursor {
        display: none; }
      .case12.subpage .options-section .claim-wrap .claim-title {
        text-align: left; }
        @media only screen and (max-width: 768px) {
          .case12.subpage .options-section .claim-wrap .claim-title {
            text-align: center;
            margin-bottom: 10px; } }
      .case12.subpage .options-section .claim-wrap .claim {
        position: relative;
        font-size: 24px;
        text-align: left;
        min-height: 28px; }
        @media only screen and (max-width: 1440px) {
          .case12.subpage .options-section .claim-wrap .claim {
            font-size: 20px; } }
        @media only screen and (max-width: 1279px) {
          .case12.subpage .options-section .claim-wrap .claim {
            font-size: 18px; } }
        @media only screen and (max-width: 768px) {
          .case12.subpage .options-section .claim-wrap .claim {
            margin-bottom: 20px;
            text-align: center; } }
        .case12.subpage .options-section .claim-wrap .claim:before {
          content: '';
          position: absolute;
          width: 210px;
          height: 1px;
          background-color: #000;
          left: 0;
          right: auto;
          margin: 0 auto;
          bottom: -10px; }
          @media only screen and (max-width: 768px) {
            .case12.subpage .options-section .claim-wrap .claim:before {
              right: 0; } }
      .case12.subpage .options-section .claim-wrap .claim-button {
        display: inline-block;
        font-size: 15px;
        padding: 15px 22px 17px;
        background-color: #ed2327;
        border-radius: 2px;
        color: #fff;
        border: 1px solid #ed2327;
        transition: 0.3s ease; }
        .case12.subpage .options-section .claim-wrap .claim-button p {
          white-space: nowrap;
          margin: 0; }
      .case12.subpage .options-section .claim-wrap .ripple {
        width: 0;
        height: 0;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        transform: scale(0);
        position: absolute;
        opacity: 1; }
      .case12.subpage .options-section .claim-wrap .rippleEffect {
        animation: rippleDrop .6s linear; }

@keyframes rippleDrop {
  100% {
    transform: scale(2);
    opacity: 0; } }
    .case12.subpage .options-section .cursor-container svg {
      position: absolute;
      width: 15px;
      height: 15px; }
    .case12.subpage .options-section .cursor-container .normal-cursor {
      left: -125px;
      bottom: -40px; }
    .case12.subpage .options-section .cursor-container .pointer-cursor {
      opacity: 0;
      bottom: -5px;
      left: -7px; }
    @media only screen and (max-width: 768px) {
      .case12.subpage .options-section .iphone-container {
        height: 300px; } }
    .case12.subpage .options-section .iphone-box {
      position: absolute;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      bottom: 0;
      left: auto;
      right: 0;
      height: 100vh; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .options-section .iphone-box {
          max-width: 300px;
          height: auto;
          left: 0; } }
      .case12.subpage .options-section .iphone-box .iphone-box-img2 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        margin: 0 auto; }
        @media only screen and (max-width: 1279px) {
          .case12.subpage .options-section .iphone-box .iphone-box-img2 {
            max-width: 220px; } }
      .case12.subpage .options-section .iphone-box .iphone-box-img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        transform: translateY(100%); }
        @media only screen and (max-width: 1279px) {
          .case12.subpage .options-section .iphone-box .iphone-box-img {
            max-width: 220px; } }
      .case12.subpage .options-section .iphone-box .iphone-box-message {
        position: absolute;
        left: 51px;
        bottom: 270px;
        width: 0;
        max-width: 200px; }
        @media only screen and (max-width: 1279px) {
          .case12.subpage .options-section .iphone-box .iphone-box-message {
            left: 155px;
            bottom: 115px;
            max-width: 100px; } }
        @media only screen and (max-width: 768px) {
          .case12.subpage .options-section .iphone-box .iphone-box-message {
            left: 65px; } }
  .case12.subpage .add-recipe-section {
    overflow: hidden; }
  @media only screen and (max-width: 1440px) {
    .case12.subpage .add-recipe .add-recipe-img {
      height: 380px; } }
  @media only screen and (max-width: 768px) {
    .case12.subpage .add-recipe .add-recipe-img {
      height: 280px; } }
  .case12.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: 680px; }
    @media only screen and (max-width: 1440px) {
      .case12.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
        transform: translateY(-50%) scale(0.7);
        transform-origin: left; } }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
        right: 0;
        top: 85px;
        bottom: auto;
        margin: auto;
        max-width: 380px; } }
    @media only screen and (max-width: 768px) {
      .case12.subpage .add-recipe .add-recipe-img .add-recipe-wrapper {
        top: 172px;
        max-width: 220px; } }
    .case12.subpage .add-recipe .add-recipe-img .add-recipe-wrapper .add-recipe-zoom {
      position: absolute;
      top: 50%;
      right: -70px;
      transform: translateY(-50%);
      margin-left: 50px;
      height: 488px;
      transform-origin: 50% 50%; }
      @media only screen and (max-width: 768px) {
        .case12.subpage .add-recipe .add-recipe-img .add-recipe-wrapper .add-recipe-zoom {
          height: 386px; } }
  .case12.subpage .add-recipe .add-recipe-title {
    width: 100%;
    max-width: 435px;
    margin-left: 120px; }
    @media only screen and (max-width: 1440px) {
      .case12.subpage .add-recipe .add-recipe-title {
        margin-left: 0; } }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .add-recipe .add-recipe-title {
        text-align: center;
        margin: 0 auto;
        padding: 0 25px; } }
  .case12.subpage .searching {
    background-image: url("../img/kompan-smaku/searching-bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 100%; }
    @media only screen and (max-width: 1440px) {
      .case12.subpage .searching {
        background-size: 700px; } }
    @media only screen and (max-width: 1279px) {
      .case12.subpage .searching {
        background-image: none; } }
    .case12.subpage .searching .searching-bg {
      position: absolute;
      bottom: 0;
      left: 0; }
    .case12.subpage .searching .searching-img {
      display: none; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .searching .searching-img {
          display: block;
          width: 100%;
          max-width: 420px;
          margin: 0 15px 45px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .searching .searching-img {
          max-width: 250px;
          margin: 0 5% 0 auto; } }
    .case12.subpage .searching .searching-title {
      font-size: 56px; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .searching .searching-title {
          font-size: 46px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .searching .searching-title {
          font-size: 36px; } }
    .case12.subpage .searching .searching-title-box {
      width: 100%;
      max-width: 395px;
      margin: 0 auto;
      text-align: left; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .searching .searching-title-box {
          text-align: center; } }
  .case12.subpage .calendar {
    text-align: center; }
    .case12.subpage .calendar .calendar-desc {
      margin-top: -280px; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .calendar .calendar-desc {
          margin-top: -180px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .calendar .calendar-desc {
          margin-top: -50px; } }
    .case12.subpage .calendar .calendar-image {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 930px;
      height: 460px;
      margin: auto auto 0 auto; }
      @media only screen and (max-width: 1279px) {
        .case12.subpage .calendar .calendar-image {
          max-width: 500px;
          height: 248px; } }
      @media only screen and (max-width: 768px) {
        .case12.subpage .calendar .calendar-image {
          max-width: 280px;
          height: 138px; } }
      .case12.subpage .calendar .calendar-image img {
        width: 100%;
        margin: 0 auto; }
  .case12.subpage .footer-wrapper {
    background: #fff;
    color: #2f2f2f; }
    .case12.subpage .footer-wrapper a {
      color: #000; }
  @media only screen and (max-width: 768px) {
    .case12.subpage .data-option-section {
      padding: 45px 0; } }
  @media only screen and (max-width: 768px) {
    .case12.subpage .add-recipe-section {
      margin: 45px 0; } }

.brief-bs .wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap; }

.brief-bs .column--left {
  width: 70%;
  padding-right: 100px; }

.brief-bs .column--right {
  width: 30%; }

.brief-bs__title {
  color: #e55915;
  font-size: 40px;
  margin-bottom: 50px; }

.brief-bs__desc {
  font-size: 18px; }
  .brief-bs__desc--challenge {
    color: #e55915;
    margin-top: 50px; }

@media (max-width: 1280px) {
  .brief-bs__title {
    font-size: 34px;
    margin-bottom: 40px; }
  .brief-bs__desc {
    font-size: 16px; }
    .brief-bs__desc--challenge {
      margin-top: 40px; } }

@media (max-width: 768px) {
  .brief-bs .column--left {
    width: 100%;
    padding-right: 0; }
  .brief-bs .column--right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .brief-bs__title {
    font-size: 17px;
    margin-bottom: 30px; }
  .brief-bs__desc {
    font-size: 14px; }
    .brief-bs__desc--challenge {
      margin-top: 30px; }
  .brief-bs__image {
    max-width: 60%;
    margin-top: 50px; } }

.claim-bs {
  background: url("../img/bracia-sadownicy/claim-bg.jpg") 100%/cover no-repeat; }
  .claim-bs .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .claim-bs__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px; }
  .claim-bs__desc {
    color: #fff;
    font-size: 18px; }
    .claim-bs__desc:nth-of-type(n+1) {
      margin-top: 30px; }
  .claim-bs__letters {
    max-width: 300px;
    fill: #fff;
    margin-top: 100px; }
  @media (max-width: 1280px) {
    .claim-bs__title {
      font-size: 34px;
      margin-bottom: 40px; }
    .claim-bs__desc {
      font-size: 16px; }
      .claim-bs__desc:nth-of-type(n+1) {
        margin-top: 20px; } }
  @media (max-width: 768px) {
    .claim-bs__title {
      font-size: 20px;
      margin-bottom: 20px; }
    .claim-bs__desc {
      font-size: 14px; }
      .claim-bs__desc:nth-of-type(n+1) {
        margin-top: 15px; }
    .claim-bs__letters {
      max-width: 60%;
      margin-top: 50px; } }

/* Filmy (opisy) */
.movie-bs__apples {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1; }

.movie-bs__desc:nth-of-type(2) {
  margin-bottom: 60px; }

.movie-bs__desc--large {
  color: #de5614;
  font-size: 40px;
  margin-bottom: 30px; }

.movie-bs__desc--normal {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300; }
  .movie-bs__desc--normal:nth-of-type(n+2) {
    margin-top: 30px; }

@media (max-width: 1280px) {
  .movie-bs__desc:nth-of-type(2) {
    margin-bottom: 50px; }
  .movie-bs__desc--large {
    font-size: 34px; }
  .movie-bs__desc--normal {
    font-size: 16px; }
    .movie-bs__desc--normal:nth-of-type(n+2) {
      margin-top: 20px; } }

@media (max-width: 768px) {
  .movie-bs__apples {
    max-width: 30%; }
  .movie-bs__desc:nth-of-type(2) {
    margin-bottom: 30px; }
  .movie-bs__desc--large {
    font-size: 17px; }
  .movie-bs__desc--normal {
    font-size: 14px; }
    .movie-bs__desc--normal:nth-of-type(n+2) {
      margin-top: 15px; }
  .movie-bs__desc br {
    display: none; } }

/* Filmy */
.movies-bs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-1 {
    background: url("../img/bracia-sadownicy/step-1-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-2 {
    background: url("../img/bracia-sadownicy/step-2-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-3 {
    background: url("../img/bracia-sadownicy/step-3-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-4 {
    background: url("../img/bracia-sadownicy/step-4-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-5 {
    background: url("../img/bracia-sadownicy/step-5-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-6 {
    background: url("../img/bracia-sadownicy/step-6-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-7 {
    background: url("../img/bracia-sadownicy/step-7-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-8 {
    background: url("../img/bracia-sadownicy/step-8-desktop.jpg") 100%/cover no-repeat; }
  @media (max-width: 768px) {
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-1 {
      background: url("../img/bracia-sadownicy/step-1-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-2 {
      background: url("../img/bracia-sadownicy/step-2-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-3 {
      background: url("../img/bracia-sadownicy/step-3-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-4 {
      background: url("../img/bracia-sadownicy/step-4-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-5 {
      background: url("../img/bracia-sadownicy/step-5-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-6 {
      background: url("../img/bracia-sadownicy/step-6-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-7 {
      background: url("../img/bracia-sadownicy/step-7-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="pl"] .movies-bs__slide--step-8 {
      background: url("../img/bracia-sadownicy/step-8-mobile.jpg") 100%/cover no-repeat; } }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-1 {
    background: url("../img/bracia-sadownicy/step-1-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-2 {
    background: url("../img/bracia-sadownicy/step-2-desktop.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-3 {
    background: url("../img/bracia-sadownicy/step-3-desktop-en.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-4 {
    background: url("../img/bracia-sadownicy/step-4-desktop-en.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-5 {
    background: url("../img/bracia-sadownicy/step-5-desktop-en.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-6 {
    background: url("../img/bracia-sadownicy/step-6-desktop-en.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-7 {
    background: url("../img/bracia-sadownicy/step-7-desktop-en.jpg") 100%/cover no-repeat; }
  .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-8 {
    background: url("../img/bracia-sadownicy/step-8-desktop-en.jpg") 100%/cover no-repeat; }
  @media (max-width: 768px) {
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-1 {
      background: url("../img/bracia-sadownicy/step-1-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-2 {
      background: url("../img/bracia-sadownicy/step-2-mobile.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-3 {
      background: url("../img/bracia-sadownicy/step-3-mobile-en.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-4 {
      background: url("../img/bracia-sadownicy/step-4-mobile-en.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-5 {
      background: url("../img/bracia-sadownicy/step-5-mobile-en.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-6 {
      background: url("../img/bracia-sadownicy/step-6-mobile-en.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-7 {
      background: url("../img/bracia-sadownicy/step-7-mobile-en.jpg") 100%/cover no-repeat; }
    .movies-bs__slider[current-lang="en"] .movies-bs__slide--step-8 {
      background: url("../img/bracia-sadownicy/step-8-mobile-en.jpg") 100%/cover no-repeat; } }
  .movies-bs__slide {
    width: 100%;
    min-height: 100vh;
    background-position: center !important;
    position: relative;
    overflow: hidden; }
    .movies-bs__slide::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
  .movies-bs__nav {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center; }
  .movies-bs__btn {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: relative; }
    .movies-bs__btn--active .movies-bs__circle {
      background-color: #fff; }
    .movies-bs__btn--active .movies-bs__title {
      opacity: 1;
      transition-delay: .3s; }
    .movies-bs__btn:nth-of-type(n+2) {
      margin-left: 30px; }
  .movies-bs__circle {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px #fff solid;
    position: relative;
    transition: all .3s ease-in-out; }
  .movies-bs__title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50px, -50%);
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transition: opacity .3s ease-in-out; }
  .movies-bs__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    z-index: 100; }
  .movies-bs__icon circle, .movies-bs__icon path {
    transition: all .3s ease-in-out; }
  .movies-bs__icon circle {
    fill: none;
    stroke: #f4f4f4;
    stroke-miterlimit: 10; }
  .movies-bs__icon path {
    fill: #f4f4f4; }
  .movies-bs__icon:hover circle, .movies-bs__icon:focus circle {
    stroke: #ff9f00; }
  .movies-bs__icon:hover path, .movies-bs__icon:focus path {
    fill: #ff9f00; }
  .movies-bs__back {
    position: absolute;
    top: 10%;
    right: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none; }
  @media (max-width: 1280px) {
    .movies-bs__play {
      width: 130px;
      height: 130px; } }
  @media (max-width: 768px) {
    .movies-bs__play {
      width: 70px;
      height: 70px; }
    .movies-bs__btn:nth-of-type(n+2) {
      margin-left: 20px; }
    .movies-bs__title {
      left: 50%;
      transform: translate(-50%, -30px); }
    .movies-bs__back {
      font-size: 12px; } }
  @media (max-width: 480px) {
    .movies-bs__play {
      width: 50px;
      height: 50px; } }

.movies-bs__full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 42, 42, 0.9);
  transform: scale(0);
  transition: all .3s ease-in-out;
  z-index: 200; }
  .movies-bs__full--is-open {
    transform: scale(1); }

.movies-bs__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* Social Media */
.web-bs .wrapper {
  text-align: center; }

.web-bs__title {
  color: #fff;
  font-size: 48px; }

.web-bs__desc {
  color: #fff;
  font-size: 18px;
  line-height: 1.8;
  margin: 50px 0; }

.web-bs__link {
  display: inline-block;
  margin-bottom: 200px;
  overflow: hidden; }

.web-bs__images {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end; }

.web-bs__image--2 {
  margin-left: 50px; }

@media (max-height: 1100px) and (orientation: landscape) {
  .web-bs__image {
    max-width: 220px; } }

@media (max-width: 1280px) {
  .web-bs__title {
    font-size: 40px; }
  .web-bs__desc {
    font-size: 16px; }
  .web-bs__link {
    margin-bottom: 150px; } }

@media (max-width: 768px) {
  .web-bs__title {
    font-size: 17px; }
  .web-bs__desc {
    font-size: 14px;
    margin: 30px 0; }
  .web-bs__link {
    margin-bottom: 100px; }
  .web-bs__social {
    max-width: 80%; }
  .web-bs__image {
    max-width: 150px; }
    .web-bs__image--2 {
      margin-left: 20px; } }

@media (max-width: 360px) {
  .web-bs__image {
    max-width: 100px; } }

.case13.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important; }
  .case13.subpage body {
    overflow-x: hidden !important; }
  .case13.subpage #fp-nav ul li.visited a span, .case13.subpage #fp-nav ul li a:hover span, .case13.subpage #fp-nav ul li a.active span {
    background: #ed2327; }
  .case13.subpage #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion; }
  .case13.subpage .lang {
    mix-blend-mode: exclusion; }
    .case13.subpage .lang span {
      color: #fff; }
  .case13.subpage .arrow-line {
    stroke: #fff; }
  .case13.subpage .logo-wrapper, .case13.subpage .case-arrow-back, .case13.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case13.subpage span.color-white,
  .case13.subpage p.color-white,
  .case13.subpage h1.color-white {
    color: #FFFFFF; }
  .case13.subpage span.font-light,
  .case13.subpage p.font-light,
  .case13.subpage h1.font-light {
    font-weight: 300; }
  .case13.subpage span.uppercase,
  .case13.subpage p.uppercase,
  .case13.subpage h1.uppercase {
    text-transform: uppercase; }
  .case13.subpage .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case13.subpage .hello-bdh {
    background: url("../img/tech-wizards/header_03.jpg") 100%/cover no-repeat;
    position: relative;
    text-align: center;
    overflow: hidden; }
    .case13.subpage .hello-bdh::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
    .case13.subpage .hello-bdh .bdh-small-description {
      font-size: 13px; }
      @media only screen and (max-width: 1279px) {
        .case13.subpage .hello-bdh .bdh-small-description {
          font-size: 11px; } }
    .case13.subpage .hello-bdh h1,
    .case13.subpage .hello-bdh p {
      position: relative;
      z-index: 100; }
    .case13.subpage .hello-bdh h1 {
      font-size: 65px;
      letter-spacing: 1px; }
      @media only screen and (max-width: 1440px) {
        .case13.subpage .hello-bdh h1 {
          font-size: 56px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 1279px) {
        .case13.subpage .hello-bdh h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 768px) {
        .case13.subpage .hello-bdh h1 {
          font-size: 36px; } }

.brief-bdh .wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap; }

.brief-bdh .column--left {
  width: 50%;
  padding-right: 100px; }

.brief-bdh .column--right {
  width: 50%; }

.brief-bdh__title {
  color: #053E2F;
  font-size: 24px;
  margin-bottom: 50px; }

.brief-bdh__desc {
  font-size: 18px; }

@media (max-width: 1280px) {
  .brief-bdh__title {
    font-size: 20px;
    margin-bottom: 40px; }
  .brief-bdh__desc {
    font-size: 16px; } }

@media (max-width: 768px) {
  .brief-bdh .column--left {
    width: 100%;
    padding-right: 0; }
  .brief-bdh .column--right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .brief-bdh__title {
    font-size: 14px;
    margin-bottom: 30px; }
  .brief-bdh__desc {
    font-size: 14px; }
  .brief-bdh__image {
    max-width: 60%;
    margin-top: 50px; } }

.claim-bdh {
  background: url("../img/tech-wizards/mural.png") 100%/cover no-repeat; }
  .claim-bdh .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .claim-bdh__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px; }
  .claim-bdh__desc {
    color: #fff;
    font-size: 18px; }
    .claim-bdh__desc:nth-of-type(n+1) {
      margin-top: 30px; }
  @media (max-width: 1280px) {
    .claim-bdh__title {
      font-size: 34px;
      margin-bottom: 40px; }
    .claim-bdh__desc {
      font-size: 16px; }
      .claim-bdh__desc:nth-of-type(n+1) {
        margin-top: 20px; } }
  @media (max-width: 768px) {
    .claim-bdh__title {
      font-size: 20px;
      margin-bottom: 20px; }
    .claim-bdh__desc {
      font-size: 14px; }
      .claim-bdh__desc:nth-of-type(n+1) {
        margin-top: 15px; } }

.movies-bdh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .movies-bdh__full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 42, 42, 0.9);
    transform: scale(0);
    transition: all .3s ease-in-out;
    z-index: 200; }
    .movies-bdh__full--is-open {
      transform: scale(1); }
  .movies-bdh__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-1 {
    background: url("../img/tech-wizards/step-4-desktop.jpg") 100%/cover no-repeat; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-2 {
    background: url("../img/tech-wizards/step-7-desktop.jpg") 100%/cover no-repeat; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-3 {
    background: url("../img/tech-wizards/step-1-desktop.jpg") 100%/cover no-repeat; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-4 {
    background: url("../img/tech-wizards/step-3-desktop.jpg") 100%/cover no-repeat; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-5 {
    background: url("../img/tech-wizards/step-5-desktop.jpg") 100%/cover no-repeat; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-6 {
    background: url("../img/tech-wizards/step-6-desktop.jpg") 100%/cover no-repeat; }
  .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-7 {
    background: url("../img/tech-wizards/step-2-desktop.jpg") 100%/cover no-repeat; }
  @media (max-width: 768px) {
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-1 {
      background: url("../img/tech-wizards/step-4-mobile.jpg") 100%/cover no-repeat; }
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-2 {
      background: url("../img/tech-wizards/step-7-mobile.jpg") 100%/cover no-repeat; }
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-3 {
      background: url("../img/tech-wizards/step-1-mobile.jpg") 100%/cover no-repeat; }
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-4 {
      background: url("../img/tech-wizards/step-3-mobile.jpg") 100%/cover no-repeat; }
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-5 {
      background: url("../img/tech-wizards/step-5-mobile.jpg") 100%/cover no-repeat; }
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-6 {
      background: url("../img/tech-wizards/step-6-mobile.jpg") 100%/cover no-repeat; }
    .movies-bdh__slider[current-lang="pl"] .movies-bdh__slide--step-7 {
      background: url("../img/tech-wizards/step-2-mobile.jpg") 100%/cover no-repeat; } }
  .movies-bdh__slide {
    width: 100%;
    min-height: 100vh;
    background-position: center !important;
    position: relative;
    overflow: hidden; }
    .movies-bdh__slide::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
  .movies-bdh__nav {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center; }
  .movies-bdh__btn {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: relative; }
    .movies-bdh__btn--active .movies-bdh__circle {
      background-color: #fff; }
    .movies-bdh__btn--active .movies-bdh__title {
      opacity: 1;
      transition-delay: .3s; }
    .movies-bdh__btn:nth-of-type(n+2) {
      margin-left: 30px; }
  .movies-bdh__circle {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px #fff solid;
    position: relative;
    transition: all .3s ease-in-out; }
  .movies-bdh__title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50px, -50%);
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transition: opacity .3s ease-in-out; }
  .movies-bdh__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    z-index: 100; }
  .movies-bdh__icon circle, .movies-bdh__icon path {
    transition: all .3s ease-in-out; }
  .movies-bdh__icon circle {
    fill: none;
    stroke: #f4f4f4;
    stroke-miterlimit: 10; }
  .movies-bdh__icon path {
    fill: #f4f4f4; }
  .movies-bdh__icon:hover circle, .movies-bdh__icon:focus circle {
    stroke: #053E2F; }
  .movies-bdh__icon:hover path, .movies-bdh__icon:focus path {
    fill: #053E2F; }
  .movies-bdh__back {
    position: absolute;
    top: 10%;
    right: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none; }
  @media (max-width: 1280px) {
    .movies-bdh__play {
      width: 130px;
      height: 130px; } }
  @media (max-width: 768px) {
    .movies-bdh__play {
      width: 70px;
      height: 70px; }
    .movies-bdh__btn:nth-of-type(n+2) {
      margin-left: 20px; }
    .movies-bdh__title {
      left: 50%;
      transform: translate(-50%, -30px); }
    .movies-bdh__back {
      font-size: 12px; } }
  @media (max-width: 480px) {
    .movies-bdh__play {
      width: 50px;
      height: 50px; } }

.movie-bdh__desc:nth-of-type(2) {
  margin-bottom: 60px; }

.movie-bdh__desc--large {
  color: #053E2F;
  font-size: 40px;
  margin-bottom: 30px; }

.movie-bdh__desc--normal {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300; }
  .movie-bdh__desc--normal:nth-of-type(n+2) {
    margin-top: 30px; }

@media (max-width: 1280px) {
  .movie-bdh__desc:nth-of-type(2) {
    margin-bottom: 50px; }
  .movie-bdh__desc--large {
    font-size: 34px; }
  .movie-bdh__desc--normal {
    font-size: 16px; }
    .movie-bdh__desc--normal:nth-of-type(n+2) {
      margin-top: 20px; } }

@media (max-width: 768px) {
  .movie-bdh__desc:nth-of-type(2) {
    margin-bottom: 30px; }
  .movie-bdh__desc--large {
    font-size: 17px; }
  .movie-bdh__desc--normal {
    font-size: 14px; }
    .movie-bdh__desc--normal:nth-of-type(n+2) {
      margin-top: 15px; }
  .movie-bdh__desc br {
    display: none; } }

/* Social Media */
.web-bdh {
  background-color: #053E2F; }
  .web-bdh .wrapper {
    text-align: center; }
  .web-bdh__title {
    color: #fff;
    font-size: 48px; }
  .web-bdh__desc {
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    margin: 50px 0; }
  .web-bdh__link {
    display: inline-block;
    margin-bottom: 200px;
    overflow: hidden;
    z-index: 90; }
  .web-bdh__images {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end; }
  .web-bdh__image--2 {
    margin-left: 50px; }
  @media (max-height: 1100px) and (orientation: landscape) {
    .web-bdh__image {
      max-width: 220px; } }
  @media (max-width: 1280px) {
    .web-bdh__title {
      font-size: 40px; }
    .web-bdh__desc {
      font-size: 16px; }
    .web-bdh__link {
      margin-bottom: 200px; } }
  @media (max-width: 768px) {
    .web-bdh__title {
      font-size: 17px; }
    .web-bdh__desc {
      font-size: 14px;
      margin: 30px 0; }
    .web-bdh__link {
      margin-bottom: 100px; }
    .web-bdh__social {
      max-width: 80%; }
    .web-bdh__image {
      max-width: 150px; }
      .web-bdh__image--2 {
        margin-left: 20px; } }
  @media (max-width: 360px) {
    .web-bdh__image {
      max-width: 100px; } }

.footer-wrapper {
  background: #fff;
  color: #2f2f2f; }
  .footer-wrapper a {
    color: #000; }

.case14.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important; }
  .case14.subpage body {
    overflow-x: hidden !important; }
  .case14.subpage #fp-nav ul li.visited a span, .case14.subpage #fp-nav ul li a:hover span, .case14.subpage #fp-nav ul li a.active span {
    background: #ed2327; }
  .case14.subpage #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion; }
  .case14.subpage .lang {
    mix-blend-mode: exclusion; }
    .case14.subpage .lang span {
      color: #fff; }
  .case14.subpage .arrow-line {
    stroke: #fff; }
  .case14.subpage .logo-wrapper, .case14.subpage .case-arrow-back, .case14.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case14.subpage span.color-white,
  .case14.subpage p.color-white,
  .case14.subpage h1.color-white {
    color: #FFFFFF; }
  .case14.subpage span.font-light,
  .case14.subpage p.font-light,
  .case14.subpage h1.font-light {
    font-weight: 300; }
  .case14.subpage span.uppercase,
  .case14.subpage p.uppercase,
  .case14.subpage h1.uppercase {
    text-transform: uppercase; }
  .case14.subpage .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case14.subpage .hello-bpn {
    background: url("../img/bepanthen/header.jpg") 100%/cover no-repeat;
    position: relative;
    text-align: center;
    overflow: hidden; }
    .case14.subpage .hello-bpn::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
    .case14.subpage .hello-bpn .bpn-small-description {
      font-size: 13px; }
      @media only screen and (max-width: 1279px) {
        .case14.subpage .hello-bpn .bpn-small-description {
          font-size: 11px; } }
    .case14.subpage .hello-bpn h1,
    .case14.subpage .hello-bpn p {
      position: relative;
      z-index: 100; }
    .case14.subpage .hello-bpn h1 {
      font-size: 65px;
      letter-spacing: 1px; }
      @media only screen and (max-width: 1440px) {
        .case14.subpage .hello-bpn h1 {
          font-size: 56px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 1279px) {
        .case14.subpage .hello-bpn h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 768px) {
        .case14.subpage .hello-bpn h1 {
          font-size: 36px; } }

.brief-bpn .wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap; }

.brief-bpn .column--left {
  width: 50%;
  padding-right: 100px; }

.brief-bpn .column--right {
  width: 30%; }

.brief-bpn__title {
  color: #7C2887;
  font-size: 24px;
  margin-bottom: 50px; }

.brief-bpn__desc {
  font-size: 18px; }

@media (max-width: 1280px) {
  .brief-bpn__title {
    font-size: 20px;
    margin-bottom: 40px; }
  .brief-bpn__desc {
    font-size: 16px; } }

@media (max-width: 768px) {
  .brief-bpn .column--left {
    width: 100%;
    padding-right: 0; }
  .brief-bpn .column--right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .brief-bpn__title {
    font-size: 14px;
    margin-bottom: 30px; }
  .brief-bpn__desc {
    font-size: 14px; }
  .brief-bpn__image {
    max-width: 60%;
    margin-top: 50px; } }

.claim-bpn {
  background: url("../img/bepanthen/03.jpg") 100%/cover no-repeat; }
  .claim-bpn .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .claim-bpn__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px; }
  .claim-bpn__desc {
    color: #fff;
    font-size: 18px; }
    .claim-bpn__desc:nth-of-type(n+1) {
      margin-top: 30px; }
  @media (max-width: 1280px) {
    .claim-bpn__title {
      font-size: 34px;
      margin-bottom: 40px; }
    .claim-bpn__desc {
      font-size: 16px; }
      .claim-bpn__desc:nth-of-type(n+1) {
        margin-top: 20px; } }
  @media (max-width: 768px) {
    .claim-bpn__title {
      font-size: 20px;
      margin-bottom: 20px; }
    .claim-bpn__desc {
      font-size: 14px; }
      .claim-bpn__desc:nth-of-type(n+1) {
        margin-top: 15px; } }

.movies-bpn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .movies-bpn__full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 42, 42, 0.9);
    transform: scale(0);
    transition: all .3s ease-in-out;
    z-index: 200; }
    .movies-bpn__full--is-open {
      transform: scale(1); }
  .movies-bpn__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .movies-bpn__slider[current-lang="pl"] .movies-bpn__slide--step-1 {
    background: url("../img/bepanthen/swimmer-desktop.jpg") 100%/cover no-repeat; }
  .movies-bpn__slider[current-lang="pl"] .movies-bpn__slide--step-2 {
    background: url("../img/bepanthen/family-desktop.jpg") 100%/cover no-repeat; }
  @media (max-width: 768px) {
    .movies-bpn__slider[current-lang="pl"] .movies-bpn__slide--step-1 {
      background: url("../img/bepanthen/swimmer-mobile.jpg") 100%/cover no-repeat; }
    .movies-bpn__slider[current-lang="pl"] .movies-bpn__slide--step-2 {
      background: url("../img/bepanthen/family-mobile.jpg") 100%/cover no-repeat; } }
  .movies-bpn__slide {
    width: 100%;
    min-height: 100vh;
    background-position: center !important;
    position: relative;
    overflow: hidden; }
    .movies-bpn__slide::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
  .movies-bpn__nav {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center; }
  .movies-bpn__btn {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: relative; }
    .movies-bpn__btn--active .movies-bpn__circle {
      background-color: #fff; }
    .movies-bpn__btn--active .movies-bpn__title {
      opacity: 1;
      transition-delay: .3s; }
    .movies-bpn__btn:nth-of-type(n+2) {
      margin-left: 30px; }
  .movies-bpn__circle {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px #fff solid;
    position: relative;
    transition: all .3s ease-in-out; }
  .movies-bpn__title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50px, -50%);
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transition: opacity .3s ease-in-out; }
  .movies-bpn__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    z-index: 100; }
  .movies-bpn__icon circle, .movies-bpn__icon path {
    transition: all .3s ease-in-out; }
  .movies-bpn__icon circle {
    fill: none;
    stroke: #f4f4f4;
    stroke-miterlimit: 10; }
  .movies-bpn__icon path {
    fill: #f4f4f4; }
  .movies-bpn__icon:hover circle, .movies-bpn__icon:focus circle {
    stroke: #7C2887; }
  .movies-bpn__icon:hover path, .movies-bpn__icon:focus path {
    fill: #7C2887; }
  .movies-bpn__back {
    position: absolute;
    top: 10%;
    right: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none; }
  @media (max-width: 1280px) {
    .movies-bpn__play {
      width: 130px;
      height: 130px; } }
  @media (max-width: 768px) {
    .movies-bpn__play {
      width: 70px;
      height: 70px; }
    .movies-bpn__btn:nth-of-type(n+2) {
      margin-left: 20px; }
    .movies-bpn__title {
      left: 50%;
      transform: translate(-50%, -30px); }
    .movies-bpn__back {
      font-size: 12px; } }
  @media (max-width: 480px) {
    .movies-bpn__play {
      width: 50px;
      height: 50px; } }

.movie-bpn__desc:nth-of-type(2) {
  margin-bottom: 60px; }

.movie-bpn__desc--large {
  color: #7C2887;
  font-size: 40px;
  margin-bottom: 30px; }

.movie-bpn__desc--normal {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300; }
  .movie-bpn__desc--normal:nth-of-type(n+2) {
    margin-top: 30px; }

@media (max-width: 1280px) {
  .movie-bpn__desc:nth-of-type(2) {
    margin-bottom: 50px; }
  .movie-bpn__desc--large {
    font-size: 34px; }
  .movie-bpn__desc--normal {
    font-size: 16px; }
    .movie-bpn__desc--normal:nth-of-type(n+2) {
      margin-top: 20px; } }

@media (max-width: 768px) {
  .movie-bpn__desc:nth-of-type(2) {
    margin-bottom: 30px; }
  .movie-bpn__desc--large {
    font-size: 17px; }
  .movie-bpn__desc--normal {
    font-size: 14px; }
    .movie-bpn__desc--normal:nth-of-type(n+2) {
      margin-top: 15px; }
  .movie-bpn__desc br {
    display: none; } }

/* Social Media */
.web-bpn {
  background-color: #7C2887; }
  .web-bpn .wrapper {
    text-align: center; }
  .web-bpn__title {
    color: #fff;
    font-size: 48px; }
  .web-bpn__desc {
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    margin: 50px 0; }
  .web-bpn__link {
    display: inline-block;
    margin-bottom: 200px;
    overflow: hidden; }
  .web-bpn__images {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end; }
  .web-bpn__image--2 {
    margin-left: 50px; }
  @media (max-height: 1100px) and (orientation: landscape) {
    .web-bpn__image {
      max-width: 220px; } }
  @media (max-width: 1280px) {
    .web-bpn__title {
      font-size: 40px; }
    .web-bpn__desc {
      font-size: 16px; }
    .web-bpn__link {
      margin-bottom: 200px; } }
  @media (max-width: 768px) {
    .web-bpn__title {
      font-size: 17px; }
    .web-bpn__desc {
      font-size: 14px;
      margin: 30px 0; }
    .web-bpn__link {
      margin-bottom: 100px; }
    .web-bpn__social {
      max-width: 80%; }
    .web-bpn__image {
      max-width: 150px; }
      .web-bpn__image--2 {
        margin-left: 20px; } }
  @media (max-width: 360px) {
    .web-bpn__image {
      max-width: 100px; } }

.footer-wrapper {
  background: #fff;
  color: #2f2f2f; }
  .footer-wrapper a {
    color: #000; }

.case15.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important; }
  .case15.subpage body {
    overflow-x: hidden !important; }
  .case15.subpage #fp-nav ul li.visited a span, .case15.subpage #fp-nav ul li a:hover span, .case15.subpage #fp-nav ul li a.active span {
    background: #ed2327; }
  .case15.subpage #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion; }
  .case15.subpage .lang {
    mix-blend-mode: exclusion; }
    .case15.subpage .lang span {
      color: #fff; }
  .case15.subpage .arrow-line {
    stroke: #fff; }
  .case15.subpage .logo-wrapper, .case15.subpage .case-arrow-back, .case15.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case15.subpage span.color-white,
  .case15.subpage p.color-white,
  .case15.subpage h1.color-white {
    color: #FFFFFF; }
  .case15.subpage span.font-light,
  .case15.subpage p.font-light,
  .case15.subpage h1.font-light {
    font-weight: 300; }
  .case15.subpage span.uppercase,
  .case15.subpage p.uppercase,
  .case15.subpage h1.uppercase {
    text-transform: uppercase; }
  .case15.subpage .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case15.subpage .hello-velo {
    background: url("../img/velo/header.jpg") 100%/cover no-repeat;
    position: relative;
    text-align: center;
    overflow: hidden; }
    .case15.subpage .hello-velo::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); }
    .case15.subpage .hello-velo .velo-small-description {
      font-size: 13px; }
      @media only screen and (max-width: 1279px) {
        .case15.subpage .hello-velo .velo-small-description {
          font-size: 11px; } }
    .case15.subpage .hello-velo h1,
    .case15.subpage .hello-velo p {
      position: relative;
      z-index: 100; }
    .case15.subpage .hello-velo h1 {
      font-size: 65px;
      letter-spacing: 1px; }
      @media only screen and (max-width: 1440px) {
        .case15.subpage .hello-velo h1 {
          font-size: 56px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 1279px) {
        .case15.subpage .hello-velo h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 768px) {
        .case15.subpage .hello-velo h1 {
          font-size: 36px; } }

.brief-velo .wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap; }

.brief-velo .column--left {
  width: 50%;
  padding-right: 100px; }

.brief-velo .column--right {
  width: 40%; }

.brief-velo__title {
  color: #6826BB;
  font-size: 24px;
  margin-bottom: 50px; }

.brief-velo__desc {
  font-size: 18px; }

@media (max-width: 1280px) {
  .brief-velo__title {
    font-size: 20px;
    margin-bottom: 40px; }
  .brief-velo__desc {
    font-size: 16px; } }

@media (max-width: 768px) {
  .brief-velo .column--left {
    width: 100%;
    padding-right: 0; }
  .brief-velo .column--right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .brief-velo__title {
    font-size: 14px;
    margin-bottom: 30px; }
  .brief-velo__desc {
    font-size: 14px; }
  .brief-velo__image {
    max-width: 60%;
    margin-top: 50px; } }

.claim-velo {
  background: url("../img/velo/bg.jpg") 100%/cover no-repeat; }
  .claim-velo .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .claim-velo__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px; }
  .claim-velo__desc {
    color: #fff;
    font-size: 18px; }
    .claim-velo__desc:nth-of-type(n+1) {
      margin-top: 30px; }
  @media (max-width: 1280px) {
    .claim-velo__title {
      font-size: 34px;
      margin-bottom: 40px; }
    .claim-velo__desc {
      font-size: 16px; }
      .claim-velo__desc:nth-of-type(n+1) {
        margin-top: 20px; } }
  @media (max-width: 768px) {
    .claim-velo__title {
      font-size: 20px;
      margin-bottom: 20px; }
    .claim-velo__desc {
      font-size: 14px; }
      .claim-velo__desc:nth-of-type(n+1) {
        margin-top: 15px; } }

.movie-velo__desc:nth-of-type(2) {
  margin-bottom: 60px; }

.movie-velo__desc--large {
  color: #6826BB;
  font-size: 40px;
  margin-bottom: 30px; }

.movie-velo__desc--normal {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300; }
  .movie-velo__desc--normal:nth-of-type(n+2) {
    margin-top: 30px; }

@media (max-width: 1280px) {
  .movie-velo__desc:nth-of-type(2) {
    margin-bottom: 50px; }
  .movie-velo__desc--large {
    font-size: 34px; }
  .movie-velo__desc--normal {
    font-size: 16px; }
    .movie-velo__desc--normal:nth-of-type(n+2) {
      margin-top: 20px; } }

@media (max-width: 768px) {
  .movie-velo__desc:nth-of-type(2) {
    margin-bottom: 30px; }
  .movie-velo__desc--large {
    font-size: 17px; }
  .movie-velo__desc--normal {
    font-size: 14px; }
    .movie-velo__desc--normal:nth-of-type(n+2) {
      margin-top: 15px; }
  .movie-velo__desc br {
    display: none; } }

/* Social Media */
.web-velo {
  background-color: #6826BB; }
  .web-velo .wrapper {
    text-align: center; }
  .web-velo__title {
    color: #fff;
    font-size: 48px; }
  .web-velo__desc {
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    margin: 50px 0; }
  .web-velo__link {
    display: inline-block;
    margin-bottom: 200px;
    overflow: hidden; }
  .web-velo__images {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end; }
  .web-velo__image--2 {
    margin-left: 50px; }
  @media (max-height: 1100px) and (orientation: landscape) {
    .web-velo__image {
      max-width: 550px; } }
  @media (max-width: 1280px) {
    .web-velo__title {
      font-size: 40px; }
    .web-velo__desc {
      font-size: 16px; }
    .web-velo__link {
      margin-bottom: 200px; } }
  @media (max-width: 768px) {
    .web-velo__title {
      font-size: 17px; }
    .web-velo__desc {
      font-size: 14px;
      margin: 30px 0; }
    .web-velo__link {
      margin-bottom: 100px; }
    .web-velo__social {
      max-width: 80%; }
    .web-velo__image {
      max-width: 290px; }
      .web-velo__image--2 {
        margin-left: 20px; } }
  @media (max-width: 360px) {
    .web-velo__image {
      max-width: 250px; } }

.footer-wrapper {
  background: #fff;
  color: #2f2f2f; }
  .footer-wrapper a {
    color: #000; }

.case16.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important; }
  .case16.subpage body {
    overflow-x: hidden !important; }
  .case16.subpage #fp-nav ul li.visited a span, .case16.subpage #fp-nav ul li a:hover span, .case16.subpage #fp-nav ul li a.active span {
    background: #ed2327; }
  .case16.subpage #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion; }
  .case16.subpage .lang {
    mix-blend-mode: exclusion; }
    .case16.subpage .lang span {
      color: #fff; }
  .case16.subpage .arrow-line {
    stroke: #fff; }
  .case16.subpage .logo-wrapper, .case16.subpage .case-arrow-back, .case16.subpage .menu-button {
    mix-blend-mode: exclusion; }
  .case16.subpage span.color-white,
  .case16.subpage p.color-white,
  .case16.subpage h1.color-white {
    color: #FFFFFF; }
  .case16.subpage span.font-light,
  .case16.subpage p.font-light,
  .case16.subpage h1.font-light {
    font-weight: 300; }
  .case16.subpage span.uppercase,
  .case16.subpage p.uppercase,
  .case16.subpage h1.uppercase {
    text-transform: uppercase; }
  .case16.subpage .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px; }
  .case16.subpage .hello-morliny {
    background: url("../img/morliny/header.jpg") 100%/cover no-repeat;
    position: relative;
    text-align: center;
    overflow: hidden; }
    .case16.subpage .hello-morliny::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #13132C;
      opacity: 65%; }
    .case16.subpage .hello-morliny .morliny-small-description {
      font-size: 13px; }
      @media only screen and (max-width: 1279px) {
        .case16.subpage .hello-morliny .morliny-small-description {
          font-size: 11px; } }
    .case16.subpage .hello-morliny h1,
    .case16.subpage .hello-morliny p {
      position: relative;
      z-index: 100; }
    .case16.subpage .hello-morliny h1 {
      font-size: 65px;
      letter-spacing: 1px; }
      @media only screen and (max-width: 1440px) {
        .case16.subpage .hello-morliny h1 {
          font-size: 56px;
          margin-bottom: 50px; } }
      @media only screen and (max-width: 1279px) {
        .case16.subpage .hello-morliny h1 {
          font-size: 48px; } }
      @media only screen and (max-width: 768px) {
        .case16.subpage .hello-morliny h1 {
          font-size: 36px; } }

.brief-morliny .wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap; }

.brief-morliny .column--left {
  width: 60%;
  padding-right: 100px; }

.brief-morliny .column--right {
  width: 40%; }

.brief-morliny .column--left-2 {
  width: 60%;
  padding-right: 100px; }

.brief-morliny .column--right-2 {
  width: 40%; }

.brief-morliny__title {
  color: #183479;
  font-size: 24px;
  margin-bottom: 50px; }

.brief-morliny__desc {
  font-size: 18px; }

@media (max-width: 1280px) {
  .brief-morliny__title {
    font-size: 20px;
    margin-bottom: 40px; }
  .brief-morliny__desc {
    font-size: 16px; } }

@media (max-width: 768px) {
  .brief-morliny .column--left {
    width: 100%;
    padding-right: 0; }
  .brief-morliny .column--right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .brief-morliny .column--left-2 {
    width: 100%;
    padding-right: 0; }
  .brief-morliny .column--right-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .brief-morliny .column--right-2 img {
      max-width: 50%;
      margin-top: 40px; }
  .brief-morliny__title {
    font-size: 14px;
    margin-bottom: 30px; }
  .brief-morliny__desc {
    font-size: 14px; }
  .brief-morliny__image {
    max-width: 100%;
    margin-top: 50px;
    padding-left: 40px; } }

.claim-morliny {
  background: url("../img/morliny/bg.jpg") 100%/cover no-repeat; }
  .claim-morliny .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .claim-morliny__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px; }
  .claim-morliny__desc {
    color: #fff;
    font-size: 18px; }
    .claim-morliny__desc:nth-of-type(n+1) {
      margin-top: 30px; }
  @media (max-width: 1280px) {
    .claim-morliny__title {
      font-size: 34px;
      margin-bottom: 40px; }
    .claim-morliny__desc {
      font-size: 16px; }
      .claim-morliny__desc:nth-of-type(n+1) {
        margin-top: 20px; } }
  @media (max-width: 768px) {
    .claim-morliny__title {
      font-size: 20px;
      margin-bottom: 20px; }
    .claim-morliny__desc {
      font-size: 14px; }
      .claim-morliny__desc:nth-of-type(n+1) {
        margin-top: 15px; } }

.movie-morliny__desc:nth-of-type(2) {
  margin-bottom: 60px; }

.movie-morliny__desc--large {
  color: #183479;
  font-size: 40px;
  margin-bottom: 30px; }

.movie-morliny__desc--normal {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 300; }
  .movie-morliny__desc--normal:nth-of-type(n+2) {
    margin-top: 30px; }

@media (max-width: 1280px) {
  .movie-morliny__desc:nth-of-type(2) {
    margin-bottom: 50px; }
  .movie-morliny__desc--large {
    font-size: 34px; }
  .movie-morliny__desc--normal {
    font-size: 16px; }
    .movie-morliny__desc--normal:nth-of-type(n+2) {
      margin-top: 20px; } }

@media (max-width: 768px) {
  .movie-morliny__desc:nth-of-type(2) {
    margin-bottom: 30px; }
  .movie-morliny__desc--large {
    font-size: 17px; }
  .movie-morliny__desc--normal {
    font-size: 14px; }
    .movie-morliny__desc--normal:nth-of-type(n+2) {
      margin-top: 15px; }
  .movie-morliny__desc br {
    display: none; } }

/* Social Media */
.web-morliny {
  background-color: #183479; }
  .web-morliny .wrapper {
    text-align: center; }
  .web-morliny__title {
    color: #fff;
    font-size: 48px; }
  .web-morliny__desc {
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    margin: 50px 0; }
  .web-morliny__link {
    display: inline-block;
    margin-bottom: 200px;
    overflow: hidden; }
  .web-morliny__images {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end; }
  .web-morliny__image--2 {
    margin-left: 50px; }
  @media (max-height: 1100px) and (orientation: landscape) {
    .web-morliny__image {
      max-width: 550px; } }
  @media (max-width: 1280px) {
    .web-morliny__title {
      font-size: 40px; }
    .web-morliny__desc {
      font-size: 16px; }
    .web-morliny__link {
      margin-bottom: 200px; } }
  @media (max-width: 768px) {
    .web-morliny__title {
      font-size: 17px; }
    .web-morliny__desc {
      font-size: 14px;
      margin: 30px 0; }
    .web-morliny__link {
      margin-bottom: 100px; }
    .web-morliny__social {
      max-width: 80%; }
    .web-morliny__image {
      max-width: 290px; }
      .web-morliny__image--2 {
        margin-left: 20px; } }
  @media (max-width: 360px) {
    .web-morliny__image {
      max-width: 250px; } }

.footer-wrapper {
  background: #fff;
  color: #2f2f2f; }
  .footer-wrapper a {
    color: #000; }

#main-error {
  width: 100%; }
  @media screen and (min-width: 1600px) {
    #main-error {
      height: 100vh; } }
  #main-error .error__wrapper {
    width: 100%;
    padding: 5em 0;
    font-family: 'Work Sans', sans-serif; }
    @media screen and (min-width: 1600px) {
      #main-error .error__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        padding: 0; } }
  #main-error .ship {
    padding-bottom: 4em; }
    #main-error .ship__img {
      display: block;
      margin: auto; }
  #main-error .mayday {
    padding-bottom: 6em; }
    #main-error .mayday__wrapper {
      display: flex;
      align-items: center;
      justify-content: center; }
    #main-error .mayday__img img {
      display: block; }
    #main-error .mayday__text {
      font-size: 1em;
      font-weight: bold;
      letter-spacing: 7px;
      text-transform: uppercase;
      margin: 0 1em; }
      @media screen and (min-width: 768px) {
        #main-error .mayday__text {
          font-size: 1.4em;
          margin: 0 3em; } }
  #main-error .mainText {
    padding-bottom: 3.5em; }
    #main-error .mainText__text {
      font-size: 9em;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 0.8;
      text-align: center; }
      @media screen and (min-width: 768px) {
        #main-error .mainText__text {
          font-size: 13em; } }
  #main-error .sosText {
    padding-bottom: 4em; }
    #main-error .sosText__wrapper {
      font-weight: 500;
      text-align: center;
      padding-left: 1.6em; }
    #main-error .sosText__text {
      position: relative;
      font-size: 4.5em;
      letter-spacing: 30px; }
      #main-error .sosText__text:before {
        content: 'sos';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: 0.25em;
        letter-spacing: 28px; }
  #main-error .btn__center {
    display: flex;
    justify-content: center;
    align-items: center; }
  #main-error .btn--first {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 35px;
    text-transform: uppercase;
    font-size: 1.2em;
    color: #fff;
    transition: .3s ease;
    overflow: hidden; }
    @media screen and (min-width: 1280px) {
      #main-error .btn--first {
        padding: 28px 55px; } }
    #main-error .btn--first:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      transition: .3s ease;
      transform: translateY(100%);
      z-index: -1; }
    #main-error .btn--first:hover {
      color: #000; }
      #main-error .btn--first:hover:before {
        transform: translateY(0); }
    #main-error .btn--first span {
      font-weight: 800; }

/* Mobile & responsive
   ----------------------------- */
/* Mobile
   ----------------------------- */
.motion {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999; }

@media only screen and (min-width: 1441px) {
  .mobile-padding {
    padding: 0 20px; } }

.main-slider-wrapper {
  height: 100vh;
  width: 100%;
  display: none; }
  .main-slider-wrapper .slick-dots {
    bottom: 50px; }
    @media only screen and (max-width: 768px) {
      .main-slider-wrapper .slick-dots {
        display: none !important; } }
  .main-slider-wrapper .slick-dots li.slick-active button:before {
    color: #fff;
    opacity: 1; }
  .main-slider-wrapper .slick-dots li button:before {
    font-family: 'slick';
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .main-slider-wrapper .slick-dots li button:before {
    font-size: 10px;
    line-height: 20px; }
  .main-slider-wrapper .main-slide {
    height: 100vh;
    width: 100%;
    position: relative; }
    .main-slider-wrapper .main-slide .case-info-wrapper {
      height: 100vh;
      width: 100%;
      padding: 0 20px 0 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center; }
      .main-slider-wrapper .main-slide .case-info-wrapper .case-title-mobile {
        font-size: 36px;
        letter-spacing: 4px;
        font-weight: 300;
        text-align: center; }
        @media only screen and (max-width: 768px) {
          .main-slider-wrapper .main-slide .case-info-wrapper .case-title-mobile {
            letter-spacing: 2px; } }
      .main-slider-wrapper .main-slide .case-info-wrapper .case-subtitle-mobile {
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 300;
        color: #948076;
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 20px 0; }
      .main-slider-wrapper .main-slide .case-info-wrapper .case-arrow-mobile {
        margin: 30px auto 0 auto;
        display: block;
        width: 20px;
        transform: rotate(180deg); }
    .main-slider-wrapper .main-slide.home-slide {
      background: #1c1b1d;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center; }
      .main-slider-wrapper .main-slide.home-slide.iphone .slogan {
        bottom: 90px; }
      .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon {
        position: relative;
        width: 48px;
        margin: 0 auto; }
        .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .tilt-front {
          position: absolute;
          top: 0;
          left: 0; }
          @media only screen and (max-width: 1279px) {
            .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .tilt-front {
              display: none; } }
          @media only screen and (max-width: 768px) {
            .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .tilt-front {
              display: block; } }
        @media only screen and (max-width: 1279px) {
          .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .tilt-back {
            display: none; } }
        @media only screen and (max-width: 768px) {
          .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .tilt-back {
            display: block; } }
        .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .resize-window {
          display: none; }
          @media only screen and (max-width: 1279px) {
            .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .resize-window {
              display: block; } }
          @media only screen and (max-width: 768px) {
            .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-icon .resize-window {
              display: none; } }
      .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-desc {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 300;
        text-align: center;
        margin: 20px;
        letter-spacing: 1px; }
        @media only screen and (max-width: 1279px) {
          .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-desc {
            display: none; } }
        @media only screen and (max-width: 768px) {
          .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .tilt-desc {
            display: block; } }
      .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .resize-window-desc {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 300;
        text-align: center;
        margin: 20px;
        letter-spacing: 1px;
        display: none; }
        @media only screen and (max-width: 1279px) {
          .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .resize-window-desc {
            display: block; } }
        @media only screen and (max-width: 768px) {
          .main-slider-wrapper .main-slide.home-slide .tilt-wrapper .resize-window-desc {
            display: none; } }
      .main-slider-wrapper .main-slide.home-slide .slogan {
        font-size: 32px;
        font-weight: 300;
        position: absolute;
        left: 30px;
        bottom: 30px;
        margin: 0;
        padding: 0; }
    .main-slider-wrapper .main-slide.case1-slide {
      background-color: #e4e4e4; }
      .main-slider-wrapper .main-slide.case1-slide .case-title-mobile {
        color: #000; }
      .main-slider-wrapper .main-slide.case1-slide .case-subtitle-mobile {
        color: #8a8383; }
    .main-slider-wrapper .main-slide.case2-slide {
      background-color: #1c1713; }
      .main-slider-wrapper .main-slide.case2-slide .case-arrow-mobile polygon {
        fill: #fff; }
      .main-slider-wrapper .main-slide.case2-slide .case-title-mobile {
        color: #fff; }
      .main-slider-wrapper .main-slide.case2-slide .case-subtitle-mobile {
        color: #72675b; }
    .main-slider-wrapper .main-slide.case3-slide {
      background-color: #dcbd2e; }
      .main-slider-wrapper .main-slide.case3-slide .case-title-mobile {
        color: #000; }
      .main-slider-wrapper .main-slide.case3-slide .case-subtitle-mobile {
        color: #ac7516; }
    .main-slider-wrapper .main-slide.case4-slide {
      background-color: #272a34; }
      .main-slider-wrapper .main-slide.case4-slide .case-arrow-mobile polygon {
        fill: #fff; }
      .main-slider-wrapper .main-slide.case4-slide .case-title-mobile {
        color: #fff; }
      .main-slider-wrapper .main-slide.case4-slide .case-subtitle-mobile {
        color: #545b72; }
    .main-slider-wrapper .main-slide.case5-slide {
      background-color: #d7fafc; }
      .main-slider-wrapper .main-slide.case5-slide .case-title-mobile {
        color: #000; }
      .main-slider-wrapper .main-slide.case5-slide .case-subtitle-mobile {
        color: #47a0a5; }
    .main-slider-wrapper .main-slide.case9-slide {
      background-color: #1A191C; }
      .main-slider-wrapper .main-slide.case9-slide .case-arrow-mobile polygon {
        fill: #fff; }
      .main-slider-wrapper .main-slide.case9-slide .case-title-mobile {
        color: #fff; }
      .main-slider-wrapper .main-slide.case9-slide .case-subtitle-mobile {
        color: #72675b; }
    .main-slider-wrapper .main-slide.case10-slide {
      background-color: #edf3f9; }
      .main-slider-wrapper .main-slide.case10-slide .case-title-mobile {
        color: #000; }
      .main-slider-wrapper .main-slide.case10-slide .case-subtitle-mobile {
        color: #8a8383; }
    .main-slider-wrapper .main-slide.case8-slide {
      background-color: #000; }
      .main-slider-wrapper .main-slide.case8-slide .case-arrow-mobile polygon {
        fill: #fff; }
      .main-slider-wrapper .main-slide.case8-slide .case-title-mobile {
        color: #fff; }
      .main-slider-wrapper .main-slide.case8-slide .case-subtitle-mobile {
        color: #545b72; }
    .main-slider-wrapper .main-slide.case1-slide {
      background-color: #f2f2f2; }
      .main-slider-wrapper .main-slide.case1-slide .case-title-mobile {
        color: #000; }
      .main-slider-wrapper .main-slide.case1-slide .case-subtitle-mobile {
        color: #8a8383; }

@media only screen and (max-width: 1279px) {
  .main-slider-wrapper {
    display: block; }
  .canvas, .case-title {
    display: none; }
  .logo-wrapper {
    top: 50px;
    left: 50px; }
  .menu-button {
    top: 50px;
    right: 50px; }
  .footer .features, .footer .slogan {
    display: none; }
  .footer {
    padding: 0 0 10px 30px; } }

@media only screen and (max-width: 1440px) {
  .logo-wrapper {
    top: 50px;
    left: 50px; }
  .menu-button {
    top: 35px;
    right: 50px; }
  .case-arrow-back {
    right: 105px;
    top: 42px; }
  .case-title-subpage {
    font-size: 36px; }
  .footer {
    padding: 0 50px 50px 50px; } }

@media only screen and (max-width: 768px) {
  .main-slider-wrapper {
    display: block; }
  .canvas, .case-title {
    display: none; }
  .logo-wrapper {
    top: 30px;
    left: 30px; }
  .menu-button {
    top: 13px;
    right: 15px; }
  .case-arrow-back {
    right: 70px;
    top: 20px; }
  .footer .features, .footer .slogan {
    display: none; }
  .footer {
    padding: 0 0 10px 30px; } }

/* max-width: 1600px
   ----------------------------- */
@media screen and (max-width: 1600px) {
  #fullpage-menu ul li, .fp-slidesNav ul li {
    margin-bottom: 40px; }
  #fullpage-menu ul li.visited a::after, .fp-slidesNav ul li.visited a::after {
    width: 30px; }
  #fullpage-menu ul li a:after, .fp-slidesNav ul li a:after {
    width: 30px; }
  .landing-img {
    width: 850px; } }

/* max-width: 1440px
   ----------------------------- */
@media screen and (max-width: 1440px) {
  .lang {
    z-index: 10;
    position: fixed;
    top: 53px;
    right: 112px; }
  .ludzie-subpage .video-wrapper .video-menu li .person-link {
    font-size: 16px; }
  .ludzie-subpage .video-wrapper .video-menu li {
    padding: 10px 0 0 0; } }

/* max-width: 1366px
   ----------------------------- */
@media screen and (max-width: 1366px) {
  .landing-img {
    width: 650px; }
  .case9.subpage .quiz .default-text {
    font-size: 16px;
    max-width: 500px; } }

/* max-width: 1280px
   ----------------------------- */
@media screen and (max-width: 1280px) {
  .case9.subpage .home-iron {
    width: 200px; }
  .case9.subpage .heading {
    font-size: 27px; }
  .case9.subpage .landing .wrap .default-text {
    font-size: 16px;
    line-height: 1.5; }
  .case9.subpage .landing .wrap {
    padding-top: 150px; } }

/* max-width: 1024px
   ----------------------------- */
@media screen and (max-width: 1024px) {
  .subpage .lang {
    right: 185px; }
  .case9.subpage .brief .iron {
    width: 145px; }
  .case9.subpage .quiz .default-text {
    max-width: 400px; }
  #fullpage-menu {
    display: none; }
  .section.lookbook-video {
    display: none; } }

/* max-width: 768px
   ----------------------------- */
@media screen and (max-width: 768px) {
  .subpage .lang {
    right: 75px; }
  .lang {
    z-index: 10;
    position: fixed;
    top: 30px;
    right: 83px; }
  .case9.subpage .home-iron {
    width: 130px; }
  .case9.subpage .brief .iron {
    display: none; }
  .case10.subpage .ucb-brief .ucb-description {
    padding: 0 30px; } }
