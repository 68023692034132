// Case 8
// Unicef

// ----- project colors -----
$color-basic: #262832;
$color-blue: #5799fc;
$color-light-grey: #cdd1e1;
$color-mid-grey1: #999999;
$color-mid-grey2: #cbcbcb;

// ----- Breakpoints -----

/* max-width: 1600px
   ----------------------------- */
@mixin media-breakpoint-to-1600 {
  @media screen and (max-width: 1600px) {
    @content;
  }
}

/* max-width: 1440px
   ----------------------------- */
@mixin media-breakpoint-to-1440 {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

/* max-width: 1366px
   ----------------------------- */
@mixin media-breakpoint-to-1366 {
  @media screen and (max-width: 1366px) {
    @content;
  }
}

/* max-width: 1280px
   ----------------------------- */
@mixin media-breakpoint-to-1280 {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

/* max-width: 1024px
   ----------------------------- */
@mixin media-breakpoint-to-1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

/* max-width: 768px
   ----------------------------- */
@mixin media-breakpoint-to-768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}

/* max-width: 576px
   ----------------------------- */
@mixin media-breakpoint-to-576 {
  @media screen and (max-width: 576px) {
    @content;
  }
}

// FullPage.js custom settings

.fp-tableCell {
  vertical-align: middle;
}

// General

.divclear {
  clear: both;
}

.case8.subpage {

  .logo-wrapper, .case-arrow-back, .menu-button {
    background: #000;
    mix-blend-mode: exclusion;
  }

  .arrow-line {
    stroke: #fff;
  }

  .lang {
    mix-blend-mode: exclusion;
  }

  .wrap {
    max-width: 1022px;
    width: 100%;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    position: relative;
  }
  .small-title,
  .heading,
  .default-text,
  .title {
    font-weight: 300;
    letter-spacing: 1px;
  }
  .title {
    font-size: 52px;
    color: #2c313d;
    padding-bottom: 30px;
  }
  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #262933;
    display: block;
  }
  .heading {
    padding: 40px 0 60px;
    color: $color-basic;
    line-height: 40px;
  }
  .default-text {
    font-size: 18px;
    color: #252730;
    line-height: 30px;
  }
  .text-bold {
    font-weight: 500;
  }
}


// Style


.case8.subpage {

  // Intro
  .intro-unicef {
    .wrap {
      background: #000000;
      height: 100vh;
      width: 100vw;
      position: relative;
    }
  }
  .intro-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  .unicef-logo {
    display: block;
    margin: 0 auto;
  }
  .case-title-subpage {
    color: #cdd1e1;
    letter-spacing: 0;
    font-size: 65px;
    letter-spacing: 3px;
  }
  .intro-img {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    @include laptop {
      width: 550px;
    }
  }
  // Brief
  .brief {
    .wrap {
      background: #ffffff;
    }
    .heading {
      font-size: 30px;
    }
    .claim-wrap {
      //width: 100%;
      height: auto;
      overflow: hidden;
      margin: 95px 65px;
      padding-bottom: 30px;
      position: relative;
    }
    .claim-wrap::after {
      content: "";
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 87%;
      height: 1px;
      background: #f3f3f3;
    }
    .claim {
      //width: 330px;
      float: left;
      font-family: Poppins, sans-serif;
      font-weight: 600;
      font-size: 50px;
      color: $color-blue;
      line-height: 1.1;
      position: relative;
      top: 28px;
      // typing effect
      overflow: hidden;
      margin: 0 auto;
      letter-spacing: 1px;
    }
    .typed-cursor{
      font-size: 56px;
      opacity: 1;
      line-height: 100px;
      font-weight: 200;
      color: $color-mid-grey2;
      -webkit-animation: blink 0.7s infinite;
      -moz-animation: blink 0.7s infinite;
      animation: blink 0.7s infinite;
    }
    .zoom {
      width: 100px;
      float: right;
    }
    .claim-small {
      font-size: 16px;
      color: #878787;
      text-align: center;
    }
  }
  // Audience section
  .users {
    .wrap {
      background: #000000;
      max-width: 1200px;
    }
    .icons {
      height: 220px;
      overflow: auto;
      width: 100%;
    }
    .logos {
      text-align: center;
      margin-bottom: 80px;
      position: relative;
    }
    .logo:first-of-type {
      margin-right: 100px;
    }
    .logo:last-of-type {

    }
    .plus {
      line-height: 37px;
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      position: absolute;
      left: 52%;
      top: 0;
    }
    .default-text {
      text-align: center;
      color: $color-mid-grey1;
      margin: 0 170px;
      line-height: 32px;
      font-size: 26px;
      letter-spacing: 0;
    }
    .highlight {
      font-weight: 600;
      font-size: 52px;
      color: #ffffff;
      text-align: center;
      margin: 68px 0;
    }
    .icons-block,
    .icons-block-2 {
      width: 10%;
      float: left;
      padding: 0 6px;
      img {
        float: left;
        margin: 4px 2px;
        display: none;
      }
    }
    .icons-block:first-of-type {
      margin-left: 0;
    }
    .icons-block-2 {
      margin-right: 0;
    }
    .users-total {
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      margin: 0 auto 30px 0;
      right: 0;
      img {
        margin-right: 5px;
      }
      img, p {
        display: inline-block;
        vertical-align: middle;
      }
      p {
        width: auto;
        color: $color-mid-grey1;
        font-size: 20px;
      }
    }
  }
  // Celebrities support
  .support {
    .wrap {
      padding-left: 350px;
    }
    .heading {
      font-size: 55px;
    }
    .scene-wrap {
      position: absolute;
      left:20%;
      width: 640px;
      top: 10%;
      height: 100%;
    }
    .scene-support {
      position: relative;
      top: 10%;
      /* width: 150%;
      height: 100%;
      top: 20%;
      li {
          float: left;
          width: 33.3333%;
      }
      li:nth-of-type(2) {
          left: -18%;
          position: relative;
          top: -10px;
      }
      li:nth-of-type(3) {
          left: -36%;
          position: relative;
          top: -20px;
      } */
    }
  }

  // Campaign mechanism section
  .mechanism {
    .screen {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .title {
      font-size: 56px;
      margin-bottom: 75px;
      padding-bottom: 0;
    }
    .default-text {
      line-height: 35px;
      padding-right: 50px;
    }
    .step-wrap {
      margin: 40px 0;
      position: relative;
      width: 31%;
      float: left;
    }
    .step {
      position: relative;
      width: 100%;
      height: 120px;
      img {
        display: none;

        @include phone {
          display: block;
        }
      }
      img.numb {
        position: relative;
        vertical-align: top;
        top: -7px;
      }
      img.base {
        position: relative;
      }
      img.shape {
        position: absolute;
      }
    }
    .step-wrap:first-of-type {
      img.shape {
        top: 30%;
        right: -10%;
      }
    }
    .step-wrap:nth-of-type(2) {
      margin-left: 3.5%;
      margin-right: 3.5%;
      img.base {
        position: relative;
        left: -3%;
      }
      img.numb {
      }
      img.shape {
        top: 45%;
        right: 8%;
      }
    }
    .step-wrap:last-of-type {
      img.base {
        float: right;
        right: 40%;
      }
      img.numb {
        right: 20%;
      }
    }
    .action {
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .name {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.5;
      height: 45px;
      padding-right:0;
      letter-spacing: 0;
    }
    .cta,
    .act {
      font-size: 12.5px;
      font-weight: 400;
      line-height: 2;
      padding-right:0;
      letter-spacing: 0.5px;
    }
    .cta {
      color: #878787;
    }
    .act {
      color: #7ab62e;
    }
  }
  // Help section
  .help {
    background-image: url('../img/unicef/children.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      text-align: center;
      color: #ffffff;
      font-family: Poppins, sans-serif;
      font-weight: 600;
      font-size: 73px;
    }
    .default-text {
      color: #ffffff;
      font-size: 45px;
      text-align: center;
      font-weight: 400;
    }
    /* .text-block {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    } */
  }

  .footer-wrapper {
    width: 100%;
    background: #f7f7f7;
    position: relative;
    margin: 0 0 0 0;
    a {
      color: #a2bb3f;
    }
    .case-footer {
      padding: 160px 20px 200px 20px;
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      @include tablet {
        text-align: center;
        padding: 80px 20px 100px 20px;
      }
      .footer-title {
        font-size: 30px;
        color: #2f2f2f;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 0 90px 0;
        line-height: 48px;
        @include phone {
          font-size: 22px;
          line-height: 30px;
        }
      }
      .col1 {
        position: relative;
        &:after {
          content: " ";
          display: block;
          height: 100px;
          width: 1px;
          background: #d8d8d8;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -50px;
        }
        @include tablet {
          &:after {
            display: none;
          }
        }
      }
      .contact {
        color: #2f2f2f;
        font-size: 21px;
        margin: 20px 0 0 0;
        @include tablet {
          font-size: 18px;
        }
        .position {
          font-size: 12px;
        }
      }
    }
  }
}



// Responsive

@include media-breakpoint-to-1600 {
  .case8.subpage {
    .intro-text {
      top: 23%;
    }
    .support {
      .scene-wrap {
        left: 5%;
      }
    }
  }
}

@include media-breakpoint-to-1440 {
  .case8.subpage .users .logos {
    margin-bottom: 55px;
  }
  .case8.subpage .users .default-text {
    margin: 0 170px;
    line-height: 1.5;
    font-size: 20px;
  }
  .case8.subpage .users .highlight {
    font-size: 44px;
    margin: 32px 0;
  }
  .case8.subpage .mechanism .title {
    font-size: 42px;
    margin-bottom: 45px;
  }
  .case8.subpage .mechanism .default-text {
    line-height: 1.5;
    padding-right: 100px;
    font-size: 16px;
  }
  .case8.subpage .mechanism .screen {
    max-height: 400px;
  }
  .case8.subpage .brief .heading {
    font-size: 20px;
    padding: 20px 0 30px;
  }
  .case8.subpage .default-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .case8.subpage .brief .claim-wrap {
    margin: 35px 65px;
    padding-bottom: 20px;
  }
  .case8.subpage .support .scene-wrap {
    width: 250px;
  }
}

@include media-breakpoint-to-1280 {
  .case8.subpage {
    .wrap {
      padding-right: 70px;
      padding-left: 30px;
    }
    // Campaign mechanism section
    .users {
      .icons-block, .icons-block-2 {
        img {
          width: 14%;
        }
      }
      .logos {
        margin-bottom: 50px;
      }
      .highlight {
        margin: 50px 0;
      }
    }
    .support {
      .wrap {
        padding-left: 25%;
        padding-right: 220px;
      }
    }
  }
}

@include media-breakpoint-to-1024 {
  .case8.subpage {
    .intro-text {
      top: 30%;
    }
    .intro-img {
      width: 400px;
    }
    // Campaign mechanism section
    .mechanism {
      .screen {
        display: none;
      }
      .title {
        margin-bottom: 30px;
        padding-top: 120px;
      }
    }
    .brief {
      .wrap {
        padding-right: 180px;
        padding-top: 80px;
      }
      .claim-wrap {
        margin: 35px 65px;
      }
    }
    .users {
      .default-text {
        margin: 0 100px;
      }
      .highlight {
        margin: 30px 0;
      }
      .icons-block, .icons-block-2 {
        padding: 0;
        img {
          width: 14%;
        }
      }
    }
    .support {
      .wrap {
        max-width: 800px;
      }
      .heading {
        line-height: 60px;
      }
      .scene-wrap {
        width: 270px;
      }
    }
    .help {
      .default-text {
        font-size: 35px;
      }
    }
  }
}

@include media-breakpoint-to-768 {

  .case8.subpage {
    .fp-section,
    .fp-slide,
    .fp-tableCell{
      height: auto !important;
    }
    .heading {
      font-size: 24px;
    }
    .title {
      font-size: 36px;
    }
    // Brief section
    // padding on small screens

    .intro-unicef {
      .wrap {
        height: 650px;
        width: 100%;
      }
      .intro-img {
        width: 75%;
      }
      .case-title-subpage {
        font-size: 35px;
      }
    }

    .brief {
      .wrap {
        padding: 140px 30px 100px 30px;
      }
      .claim-wrap {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 10px;
        //margin: 35px 0;
      }
      .claim-wrap::after {
        bottom: 0;
        width: 100%;
      }
      .claim {
        width: auto;
        font-size: 30px;
      }
      .claim-small {
        margin-top: 20px;
      }
      .typed-cursor {
        line-height: 70px;
        font-size: 40px;
      }
      .zoom {
        width: 70px;
      }
    }
    // Audience section
    .users {
      .wrap {
        padding: 120px 30px 30px 30px;
      }
      .logo:first-of-type {
        margin: 0 auto 10px auto;
      }
      .logo {
        margin: 0 auto;
      }
      .default-text {
        margin: 0;
      }
      .icons {
        height: auto;
      }
      .plus {
        position: static;
      }
      .highlight {
        font-size: 40px;
      }
      .icons-block,
      .icons-block-2 {
        width: 16%;
        margin: 0 1px;
        padding: 0;
        &:first-of-type {
          margin: 0 1px;
        }
      }
      .users-total {
        position: relative;
        margin-top: 30px;
        img {
          display: inline-block !important;
        }
      }
    }
    // Campaign mechanism section
    .mechanism {
      .wrap {
        padding: 0 30px;
      }
      .default-text {
        padding-right: 0;
      }
      .step-wrap {
        width: 100%;
        float: none;
        height: 120px;
        margin: 70px 0;
      }
      .title {
        font-size: 36px;
      }
      .step {
        width: 31%;
        float: left;
      }
      .step-wrap:first-of-type,
      .step-wrap:nth-of-type(2) {
        img.shape {
          display: none !important;
        }
      }
      .step-wrap:nth-of-type(2),
      .step-wrap:last-of-type {
        img.numb,
        img.base {
          left: 0;
          right: 0;
          float: none;
        }
      }
      .step-wrap:nth-of-type(2) {
        margin: 0;
      }
      .action {
        width: 63%;
        float: left;
        margin: 0;
        margin-left: 5%;
        margin-top: 10px;
      }
      .name {
        height: auto;
      }
      .divclear {
        display: none;
      }
    }
    // Help section
    .help {
      //height: 100vh !important;
      .wrap {
        padding: 150px 30px;
      }
      .title {
        font-size: 30px;
        padding-bottom: 0;
      }
      .default-text {
        font-size: 18px;
      }
    }
    .support {
      .heading {
        font-size: 36px;
        padding: 60px 0 30px 0;
      }
      .scene-wrap {
        width: 250px;
        top: 50px;
        padding: 30px;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
      }
      .scene-support {
        width: 100%;
        height: auto;
        top:0;
        left: 0;
        transform: translateX(-20%);
      }
      .wrap {
        position: static;
        width: 100%;
        max-width: 100%;
        padding: 30px;
        padding-bottom: 80px;
        padding-top:350px;
      }
    }
  }
}

@include media-breakpoint-to-576 {
  .case8.subpage {
    .intro-unicef {
      .wrap {
        height: 570px;
      }
    }
    .users {
      .icons-block, .icons-block-2 {
        padding: 0;
        margin: 0;
        img {
          width: 10%;
        }
      }
    }
    .brief {
      .typed-cursor {
        line-height: 65px;
        font-size: 30px;
      }
      .claim {
        font-size: 18px;
      }
      .zoom {
        width: 60px;
      }
      .claim-wrap {
        margin: 35px 0;
      }
    }
  }

}