/* Buttons
   ----------------------------- */

.btn-fill {
    background: #ff3600;
    padding: 32px 70px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    border: 0;
    display: inline-block;
    &:hover {
        color: #fff;
        background: #ff3600;
        text-decoration: none;
    }
    &:focus {
        color: #fff;
        text-decoration: none;
        &:hover {
            color: $color1;;
            text-decoration: none;
        }
    }
    &:active {
        color: #fff;
        text-decoration: none;
    }
}

.btn-border {
    background: none;
    padding: 17px 25%;
    border: solid 1px #626262;
    color: #626262;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    transition: 0.6s ease;
    &:hover {
        color: #fff;
        background: none;
        text-decoration: none;
        border: solid 1px #fff;
    }
    &:focus {
        color: #626262;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
    &:active {
        color: #fff;
        text-decoration: none;
    }
}

.btn-text {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  display: block;
  margin: 10px 0 0 0;
  transition: 0.4s ease;
  @include tablet {
    margin: 10px 0 50px 0;
  }
  &:hover {
    i {
      margin-left: 20px;
    }
  }
  i {
    position: relative;
    top: 1px;
    margin-left: 10px;
    transition: 0.4s ease;
  }
}