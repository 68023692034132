// Case 5
// Gostar

.case7.subpage {

  .logo-wrapper, .case-arrow-back, .menu-button {
    background: black;
    mix-blend-mode: exclusion;
  }

  .case-subtitle-subpage {
      color: #fff;
  }

  .arrow-line {
      stroke: #fff;
  }

  .lang {
    mix-blend-mode: exclusion;
    span {
      color: #fff;
    }
  }

  .small-title {
      font-size: 13px;
      text-transform: uppercase;
      color: #262933;
      font-weight: 300;
      letter-spacing: 1px;
      display: block;
  }

  .section-title {
      font-size: 40px;
      color: #232435;
      font-weight: 300;
      letter-spacing: 1px;
      @include phone {
          font-size: 28px;
      }
  }

  .heading {
      font-size: 30px;
      color: #262933;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 40px;
  }

  .default-text {
      font-size: 18px;
      color: #262933;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 30px;
  }

  .section {
      max-width: 850px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      @include tablet {
          padding: 0 30px;
      }
  }

  .intro {
      width: 100%;
      height: 100vh;
      position: relative;
      overflow-y: visible;
      background: #24273a;
      &:after {
        background: #24273a;
      }
      .intro-bg {
          background-image: url('../img/gostar/intro_bg.jpg');
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
      .kwiatek {
          position: absolute;
          bottom: -75px;
          left: 49%;
          transform: translateX(-50%);
          z-index: 2;
          @include phone {
              bottom: -35px;
              left: 64%;
          }
      }
      .pudelko {
          position: absolute;
          bottom: -121px;
          left: 66%;
          transform: translateX(-50%);
          z-index: 2;
          @include phone {
              bottom: -41px;
              left: 79%;
          }
      }
  }
  .main-content {
      background: #fff;
  }
  .brief {
      padding: 200px 0 100px 0;
      position: relative;
      @include phone {
          padding: 100px 0 50px 0;
      }
      .photo1 {
          position: absolute;
          right: 0;
          bottom: -100px;
          z-index: 1;
      }
      .heading {
          padding: 40px 0 60px 0;
      }
  }

  .kreacja {
      width: 100%;
      padding: 60px 0 0 0;
      .small-title {
          margin: 0 0 50px 0;
      }
      .gold {
          color: #e9c679;
          font-size: 50px;
          line-height: 62px;
          font-weight: 300;
          @include phone {
              font-size: 36px;
              line-height: 45px;
          }
      }
      img {
          @include phone {
              margin: 40px auto 0 auto;
              display: block;
          }
      }
  }

  .ksiega-znaku {
      width: 100%;
      background: url('../img/gostar/ksiega_znaku_bg.jpg');
      background-size: 100%;
      background-position: center 100px;
      background-repeat: no-repeat;
      margin: 200px 0 100px 0;
      @include phone {
          margin: 100px 0 50px 0;
      }
      .logo-boxes-wrapper {
          margin: 0 0 80px 0;
          .logo-box {
              width: 100%;
              height: 195px;
              background: #232435;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              -webkit-justify-content: center;
              justify-content: center;
              &.center {
                  background: #f4f0e8;
              }
              &.right {
                  background: #e9c679;
              }
          }
      }
      .default-text {
          margin: 80px 0;
      }
      .folder-wrapper {
          position: relative;
          .okulary {
              position: absolute;
              top: -70px;
              right: -250px;
              z-index: 1;
              @include phone {
                  display: none;
              }
          }
          .folder {
              margin: 0 auto;
              max-width: initial;
              position: relative;
              top: 60px;
              left: -100px;
              @include phone {
                  top: 0;
                  left: 0;
                  max-width: 100%;
              }
          }
      }
  }

  .nowoczesna-strona {
      width: 100%;
      position: relative;
      .nowoczesna {
          position: absolute;
          top: 0;
          right: 0;
          @include laptop {
              right: -150px;
          }
          @include laptop {
              right: -200px;
          }
          @include phone {
              position: relative;
              right: 0;
              padding: 40px 0 0 40px;
          }
      }
      .section-title {
          margin: 60px 0 0 0;
      }
      .default-text {
          margin: 50px 0 0 0;
          a {
              color: #e9c679;
              font-weight: 500;
              &:hover {
                  color: #35baf6;
              }
          }
      }
  }

  .druk-folderow {
      width: 100%;
      background: url('../img/gostar/ksiega_znaku_bg.jpg');
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      padding: 100px 0 100px 0;
      margin: 500px 0 0 0;
      position: relative;
      @include phone {
          background-size: cover;
          margin: 50px 0 0 0;
      }
      .folder2 {
          position: absolute;
          left: 0;
          top: -100px;
          @include laptop {
              left: -100px;
          }
          @include tablet {
              left: -180px;
          }
          @include phone {
              position: relative;
              top: 0;
              margin: 40px 0 0 0;
          }
      }
      .podkowa {
          position: absolute;
          right: -70px;
          top: -100px;
          @include tablet {
              top: -150px;
          }
          @include phone {
              display: none;
          }
      }
      .default-text {
          margin: 80px 0 0 0;
      }
  }

  .wizualizacja {
      width: 100%;
      padding: 280px 0 0 0;
      @include phone {
          padding: 70px 0 0 0;
      }
      .section-title {
          text-align: center;
          max-width: 740px;
          width: 100%;
          margin: 0 auto;
          padding: 0 0 100px 0;
      }
      .wizualizacja-photo {
          width: 100%;
          margin: 100px 0 0 0;
          vertical-align: bottom;
          @include phone {
              margin: 0;
          }
      }
  } 
  .gostarVideo__title{
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      margin-top:150px;
  }

  .video-wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 150px auto 0 auto;
    .embed-container { 
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden; 
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      height: 100%; 
      }
  }

  .gostarVideo{
      background: url(../img/frame_gostar.png);
      background-size: contain;
      background-repeat: no-repeat;
      padding: 65px 6px 11px 6px;
      margin-bottom:150px;
      @include phone{
          padding: 43px 6px 11px 6px;
      }
  }
    

  .footer-wrapper {
    width: 100%;
    background: #eff2f7;
    position: relative; 
    margin: 0 0 0 0;
    .case-footer {
        padding: 160px 20px 200px 20px;
        max-width: 600px;
        @include tablet {
          text-align: center;
          padding: 80px 20px 100px 20px;
        }
        .footer-title {
          font-size: 30px;
          color: #2f2f2f;
          font-weight: 300;
          letter-spacing: 1px;
          text-align: center;
          margin: 0 0 90px 0;
          line-height: 48px;
          @include phone {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .col1 {
          position: relative;
          &:after {
              content: " ";
              display: block;
              height: 100px;
              width: 1px;
              background: #bfc8d6;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -50px;
          }
          @include tablet {
              &:after {
                  display: none;
              }
          }
        }
        .contact {
          color: #2f2f2f;
          font-size: 21px;
          margin: 20px 0 0 0;
          @include tablet {
              font-size: 18px;
          }
          .position {
              font-size: 12px;
          }
      }
    }
  }
}