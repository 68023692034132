// Footer

.footer {
  width: 100%;
  color: #575757;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 100px 100px 100px;
  font-size: 10px;
  text-transform: uppercase;
  overflow: auto;
  .copyrights {
    float: left;
    .cookies-button {
      border: none;
      background: transparent;
      color: #575757;
      font-size: 10px;
      padding: 0 20px
    }
  }
  .slogan {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .features {
    float: right;
    .footer-icon {
      width: 22px;
      display: inline-block;
      margin-left: 10px;
      padding: 5px;
      .social-icon {
        fill: #fff;
      }
      svg {
        position: relative;
        bottom: 0;
        transition: 0.3s ease;
      }
      &:hover {
        svg {
          bottom: 5px;
        }
      }
      &.facebook {
        width: 17px;
      }
    }
  }
}

.landscape-lock {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background: #1c1b1d;
  display: none;

  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  @media  (max-device-width: 480px) and (orientation: landscape) {
    display: -webkit-flex;
    display: flex;
  }
  .tilt-wrapper {
    .tilt-icon {
      position: relative;
      width: 48px;
      margin: 0 auto;
      .rotate-front {
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 46px;
        @include tablet {
          display: block;
        }
      }
    }
    .tilt-desc {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 300;
      text-align: center;
      margin: 20px;
      letter-spacing: 1px;
    }
  }
}