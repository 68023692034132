/* Core styles
   ----------------------------- */

body {
  color: #fff;
  background: #1c1b1d;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.container {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

a {
  color: $color1;
  text-decoration: none;
}

a:hover {
  color: $color2;
  text-decoration: none;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

/* Custom styles
   ----------------------------- */

img {
  vertical-align: bottom;
}

.slick-initialized { 
  visibility: visible; 
}

.no-scroll {
  overflow: hidden;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

textarea:focus, input:focus{
  outline: 0;
}

.form-control:focus, select:focus, textarea:focus, input:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control {
  border: solid 1px $color1;
  height: 85px;
  background: transparent;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 35px;
  color: #fff;
  font-size: 16px;
  &:focus {
      border: solid 3px $color1;
  }
}

.form-control::-moz-placeholder {
  color: $color1;
  font-size: 16px;
}

.form-control:-ms-input-placeholder {
  color: $color1;
  font-size: 16px;
}

.form-control::-webkit-input-placeholder {
  color: $color1;
  font-size: 16px;
}

.form-control:focus::-webkit-input-placeholder { color:transparent; }

svg {
  overflow: visible;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.slick-dots {
  bottom: -50px;
}

.slick-slide {
  outline: 0;
}

.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

iframe[name='google_conversion_frame'] { 
  height: 0 !important;
  width: 0 !important; 
  line-height: 0 !important;
  font-size: 0 !important;
  margin-top: -13px;
  float: left;
}

.slick-dots li button:before {
  font-size: 10px !important;
  line-height: 20px !important;
}