/* Mobile
   ----------------------------- */
.motion {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 999;
}

.mobile-padding {
  @include desktop {
    padding: 0 20px;
  }
}

.main-slider-wrapper {
  height: 100vh;
  width: 100%;
  display: none;
  .slick-dots {
    bottom: 50px;
    @include phone {
      display: none !important;
    }
  }
  .slick-dots li.slick-active button:before {
    color: #fff;
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: 'slick';
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li button:before
  {
    font-size: 10px;
    line-height: 20px;
  }
  .main-slide {
    height: 100vh;
    width: 100%;
    position: relative;
    .case-info-wrapper {
      height: 100vh;
      width: 100%;
      padding: 0 20px 0 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center;
      .case-title-mobile {
        font-size: 36px;
        letter-spacing: 4px;
        font-weight: 300;
        text-align: center;
        @include phone {
          letter-spacing: 2px;
        }
      }
      .case-subtitle-mobile {
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 300;
        color: #948076;
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 20px 0;
      }
      .case-arrow-mobile {
        margin: 30px auto 0 auto;
        display: block;
        width: 20px;
        transform: rotate(180deg);
      }
    }
    &.home-slide {
      background: #1c1b1d;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      &.iphone {
        .slogan {
          bottom: 90px;
        }
      }
      .tilt-wrapper {
        .tilt-icon {
          position: relative;
          width: 48px;
          // height: 60px;
          margin: 0 auto;
          .tilt-front {
            position: absolute;
            top: 0;
            left: 0;
            @include tablet {
              display: none;
            }
            @include phone {
              display: block;
            }
          }
          .tilt-back {
            @include tablet {
              display: none;
            }
            @include phone {
              display: block;
            }
          }
          .resize-window {
            display: none;
            @include tablet {
              display: block;
            }
            @include phone {
              display: none;
            }
          }
        }
        .tilt-desc {
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 300;
          text-align: center;
          margin: 20px;
          letter-spacing: 1px;
          @include tablet {
            display: none;
          }
          @include phone {
            display: block;
          }
        }
        .resize-window-desc {
          font-size: 11px;
          text-transform: uppercase;
          font-weight: 300;
          text-align: center;
          margin: 20px;
          letter-spacing: 1px;
          display: none;
          @include tablet {
            display: block;
          }
          @include phone {
            display: none;
          }
        }
      }
      .slogan {
        font-size: 32px;
        font-weight: 300;
        position: absolute;
        left: 30px;
        bottom: 30px;
        margin: 0;
        padding: 0;
      }
    }
    &.case1-slide {
      background-color: #e4e4e4;
      .case-title-mobile {
        color: #000;
      }
      .case-subtitle-mobile {
        color: #8a8383;
      }
    }
    &.case2-slide {
      background-color: #1c1713;
      .case-arrow-mobile {
        polygon {
          fill: #fff;
        }
      }
      .case-title-mobile {
        color: #fff;
      }
      .case-subtitle-mobile {
        color: #72675b;
      }
    }
    &.case3-slide {
      background-color: #dcbd2e;
      .case-title-mobile {
        color: #000;
      }
      .case-subtitle-mobile {
        color: #ac7516;
      }
    }
    &.case4-slide {
      background-color: #272a34;
      .case-arrow-mobile {
        polygon {
          fill: #fff;
        }
      }
      .case-title-mobile {
        color: #fff;
      }
      .case-subtitle-mobile {
        color: #545b72;
      }
    }
    &.case5-slide {
      background-color: #d7fafc;
      .case-title-mobile {
        color: #000;
      }
      .case-subtitle-mobile {
        color: #47a0a5;
      }
    }
    &.case9-slide {
      background-color: #1A191C;
      .case-arrow-mobile {
        polygon {
          fill: #fff;
        }
      }
      .case-title-mobile {
        color: #fff;
      }
      .case-subtitle-mobile {
        color: #72675b;
      }
    }
    &.case10-slide {
      background-color: #edf3f9;
      .case-title-mobile {
        color: #000;
      }
      .case-subtitle-mobile {
        color: #8a8383;
      }
    }
    &.case8-slide {
      background-color: #000;
      .case-arrow-mobile {
        polygon {
          fill: #fff;
        }
      }
      .case-title-mobile {
        color: #fff;
      }
      .case-subtitle-mobile {
        color: #545b72;
      }
    }
    &.case1-slide {
      background-color: #f2f2f2;
      .case-title-mobile {
        color: #000;
      }
      .case-subtitle-mobile {
        color: #8a8383;
      }
    }
  }
}