// Case 9
// Żelazka tefal

.case9.subpage {
  $color-basic: #ec6816;

  #fp-nav ul li.visited a span, #fp-nav ul li a:hover span, #fp-nav ul li a.active span  {
    background: $color-basic;
  }

  .logo-wrapper, .case-arrow-back, .menu-button {
    mix-blend-mode: exclusion;
  }

  .lang {
    mix-blend-mode: exclusion;
  }

  .case-title-subpage {
    color: $color-basic;
  }

  .case-subtitle-subpage {
    color: #fff;
  }

  .arrow-line {
    stroke: #fff;
  }

  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }

  .section-title {
    font-size: 40px;
    color: $color-basic;
    font-weight: 300;
    letter-spacing: 1px;
    @include phone {
        font-size: 28px;
    }
  }

  .heading {
    font-size: 36px;
    color: $color-basic;
    font-weight: 300;
    letter-spacing: 1px;

    @include tablet {
      font-size: 32px;
    }

    @include phone {
      font-size: 26px;
    }
  }

  .default-text {
    font-size: 18px;
    color: #333333;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;

    @include tablet {
      font-size: 16px;
      line-height: 26px;
    }
  }
  
  .section {
    position: relative;
  }

  // Intro
  .intro {
    padding: 0;
    overflow: hidden;
    &:after {
      background: #1c1b1d;
    }
    @include phone {
      height: 600px !important;
    }
  }

  #steam-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  #steam-canvas2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .home-iron {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  // Brief
  .brief {
    background-color: #fff;
    & > .fp-tableCell {
      position: relative;
      overflow: hidden;
    }

    .wrap {
      position: relative;
      max-width: 800px;
      margin: 0 auto;
      z-index: 100;

      @include tablet {
        max-width: 600px;
      }

      @include phone {
        max-width: 320px;
        margin: 0  auto;
        //padding: 50px 25px 50px 0;
        padding: 50px 25px 50px;
        text-align: center;
      }
    }

    .heading {
      margin: 60px 0;

      @include tablet {
        margin: 40px 0;
      }

      @include phone {
        margin: 25px 0;
      }
    }

    .iron {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include phone {
        max-width: 120px;
      }
    }

    .slogan {
      font-size: 50px;
      color: #323232;
      font-weight: 600;
      //letter-spacing: 1px;
      line-height: 1.5;
      margin-top: 65px;

      @include tablet {
        margin-top: 45px;
        font-size: 42px;
      }

      @include phone {
        margin-top: 25px;
        font-size: 36px;
      }

      span {
        color: #e82c2e;
        font-weight: 600;
      }
    }
  }

  // Calc collector
  .calc-collector {
    background-image: url('../img/zelazka_tefal/boomerang.gif');
    background-size: cover;

    @include tablet {
      padding: 100px 0;
    }

    @include phone {
      padding: 50px 0;
    }

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.44);
    }
    .wrap {
      text-align: center;
      position: relative;
      z-index: 2;
    }
    .heading {
      font-size: 80px;
      color: #fff;
      font-weight: 700;
      letter-spacing: 1px;

      @include tablet {
        font-size: 66px;
      }

      @include phone {
        font-size: 34px;
      }
    }
    .default-text {
      font-size: 33px;
      color: $color-basic;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1.5;

      @include tablet {
        font-size: 27px;
      }

      @include phone {
        font-size: 23px;
      }
    }
    .slogan {
      font-size: 28px;
      color: #fff;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 1.5;

      @include tablet {
        font-size: 24px;
      }

      @include phone {
        font-size: 20px;
      }
    }
  }

  // Landing page
  .landing {
    background-color: #191919;
    .fp-tableCell {
      display: block;
    }
    .wrap {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 200px;

      @include tablet {
        padding: 75px 25px;
      }

      @include phone {
        padding: 45px 25px;
      }

      .heading {
        font-size: 46px;
        color: $color-basic;
        font-weight: 300;
        letter-spacing: 1px;

        @include tablet {
          font-size: 40px;
          padding-bottom: 25px;
        }

        @include phone {
          font-size: 32px;
        }
      }

      .default-text {
        font-size: 18px;
        color: #666666;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 30px;
        max-width: 900px;
        text-align: center;

        @include tablet {
          font-size: 16px;
          line-height: 26px;
          padding-bottom: 25px;
        }
      }
    }
  }

  // Quiz
  .quiz {
    background-color: #fff;

    @include tablet {
      padding: 0 45px;
    }

    @include phone {
      padding: 0 25px;
    }

    .quiz-slider {
      background-image: url(../img/zelazka_tefal/frame.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      padding: 75px 8px 17px 8px;
      width: 100%;
      max-width: 829px;
      margin-left: -70px;

      @include phone {
        margin-left: auto;
      }
    }

    .quiz-desc {
      //@include tablet {
      //  text-align: center;
      //  padding-top: 100px;
      //  padding-bottom: 65px;
      //}

      @include phone {
        text-align: center;
        padding-top: 75px;
        padding-bottom: 45px;
      }
    }

    .heading {
      font-size: 56px;
      color: $color-basic;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 40px;
    }
    .default-text {
      font-size: 18px;
      color: #191919;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 1.5;
      max-width: 540px;

      @include tablet {
        max-width: 400px;
      }
      @include phone {
        margin: 0 auto;
      }
    }
    .logos-wrapper {
      margin-top: 40px;
      .quiz-logo {
        display: inline-block;

        &:nth-child(1),
        &:nth-child(2) {
          padding-right: 40px;
        }
      }
    }
  }

  // Facebook
  .facebook {
    background-color: #191919;
    overflow: hidden;
    .wrap {
      max-width: 1440px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;

      @include laptop {
        max-width: 900px;
      }

      //@include tablet {
      //  max-width: 800px;
      //}

      @include tablet {
        padding: 45px 20px;
      }

      .inner-wrapper {
        display: flex;
        margin: 0 auto -105px;

        @include tablet {
          flex-direction: column;
          margin: 0 auto -245px;
        }
      }
    }
    .text-wrapper {
      margin: -80px auto 0;
      padding-left: 150px;
      display: inline-block;

      @include laptop {
        margin: -122px auto 0;
      }
      //@include tablet {
      //  margin: 0 auto;
      //}
      @include tablet {
        order: 1;
        text-align: center;
        padding: 0 0 45px;
        margin: 0 auto;
      }
    }
    .heading {
      font-size: 56px;
      color: $color-basic;
      font-weight: 300;
      letter-spacing: 1px;
      margin-bottom: 40px;

      //@include tablet {
      //  font-size: 48px;
      //}

      @include tablet {
        font-size: 40px;
        font-size: 48px;
      }

    }
    .default-text {
      font-size: 18px;
      color: #fff;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 1.5;
      max-width: 630px;

      @include tablet {
        font-size: 16px;
      }
    }
    .phone-wrapper {
      position: relative;
      display: inline-block;

      @include tablet {
        margin: 0 auto;
        max-width: 280px;
        order: 2;
      }
    }
    .ads-wrapper {
      position: absolute;
      left: 45px;
      top: 228px;
      width: 1150px;
      @include laptop {
        top: 180px;
        width: 835px;
        left: 33px;
      }

      //@include tablet {
      //  left: 50px;
      //  width: 140px;
      //}
      //
      //@include tablet {
      //  top: 180px;
      //  left: 70px;
      //}

      @include tablet {
        top: 180px;
        left: 25px;
        width: 229px;
      }

      .ads-wrapper__item {
        transform: translateY(500%);
        @include laptop {
          height: 205px;
        }
        @include tablet {
          height: initial;
        }
      }
    }
  }

  .footer-wrapper {
    background: #fff;
    color: #2f2f2f;
    a {
      color: $color-basic;
    }
  }

}