// Menu pages

.loaded-content {
  width: 100%;
  height: 100%;
  position: relative;
  .title {
    margin-left: 50px;
    font-size: 70px;
    font-weight: 300;
    line-height: 85px;
    @include laptop {
      font-size: 45px;
      line-height: 65px;
    }
    @include tablet {
      font-size: 36px;
      line-height: 40px;
    }
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
}

.ludzie-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  .title-abs {
    font-size: 70px;
    font-weight: 300;
    line-height: 85px;
    position: relative;
    z-index: 2;
    top: 100px;
    left: 100px;
    @include tablet {
      top: 75px;
      left: 75px;
    }
  }
  .position {
    display: inline-block;
    padding: 0 0 0 30px;
    font-size: 12px;
    color: #7b7878;
    text-align: left;
  }
  .video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    height: 100%;
    width: 100%;
    #video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: auto;
      z-index: -1000;
      overflow: hidden;
      background-image: url('../img/loader.gif');
      background-repeat: no-repeat;
      background-size: 140px 140px;
      background-position: center;
      object-fit: cover;
    }
    .video-menu {
      position: absolute;
      top: 57%;
      transform: translateY(-50%);
      left: 100px;
      width: 300px;
      list-style: none;
      @include tablet {
        bottom: 75px;
        left: 75px;
      }
      @media screen and ( max-height: 530px ){
        display: none;
      }

      &__wrapper {
        width: 100%;
        .slick-list {
          width: 100%;
        }

        .slick-slide {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
        }
      }

      li {
        width: 100%;
        padding: 10px 0 5px 0;
        @media screen and ( max-height: 660px ){
          padding: 10px 0 0 0;
        }
        @media screen and ( max-height: 600px ){
          padding: 5px 0 0 0;
        }
        .person-link {
          font-size: 20px;
          color: #fff;
          position: relative;
          @media screen and ( max-height: 750px ){
            font-size: 14px;
          }

          &:focus {
            outline: 0 !important;
          }
          span {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            color: #7b7878;
            overflow: hidden;
            &.active {
              animation: progressAnimation 10s linear;
            }
          }
          @keyframes progressAnimation {
            from {width: 0;}
            to {width: 100%;}
          }
        }
      }
    }
  }
}

.praca-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px;

  .title-sub {
    font-size: 24px;
    max-width: 60%;
    margin-left: 50px;
    @media (max-width: 768px) {
      margin-left: 0;
      max-width: 80%;
    }
  }
  .desc {
    font-size: 18px;
    max-width: 60%;
    margin-left: 50px;
    @media (max-width: 768px) {
      margin-left: 0;
      max-width: 80%;
    }
  }
  .btn-border {
    margin-left: 50px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
  @include tablet {
    padding: 75px 200px 75px 75px;
  }
  @include phone {
    padding: 30px;
  }
  .small-text {
    font-size: 12px;
    line-height: 22px;
    color: #444444;
    max-width: 800px;
    margin: 30px 0 0 0;
    &a {
      text-decoration: underline;
    }
    span {
      font-style: italic;
    }
  }
  p {
    margin: 0 0 20px 0;
  }
  #accordion-jobs {
    margin: 80px 0 0 0;
    a {
      color: #757575;
    }
    ul {
      list-style: circle;
      margin: 20px 0;
      li {
        padding: 3px 0 3px 7px;
        margin: 0 0 0 20px;
      }
    }
  }
  .ui-state-active {
    background: none;
    border: 0;
  }
  .ui-state-default {
    color: #fff;
    background: none;
    border: 0;
  }
  .ui-accordion .ui-accordion-content {
    padding: 20px 20px 20px 62px;
    background: none;
    border: 0;
    color: #fff;
    @include phone {
      padding: 20px;
    }
  }
  .ui-accordion .ui-accordion-header {
    padding: 10px 10px 10px 20px;
    text-transform: uppercase;
    outline: 0;
    position: relative;
  }
  .ui-state-active .ui-icon, .ui-button:active .ui-icon {
    background-image: url("../img/accordion_arrow.png");
    padding: 0 25px;
  }
  .ui-icon-triangle-1-s {
    background-position: 0 5px;
    position: absolute;
    top: 13px;
    left: 0;
  }
  .ui-icon, .ui-widget-content .ui-icon {
    background-image: url("../img/accordion_arrow_closed.png");
    padding: 0 25px;
  }
  .ui-icon-triangle-1-e {
    background-position: 0 5px;
    position: absolute;
    top: 13px;
    left: 0;
  }
  .jobs-footer {
    margin: 80px 0 0 0;
    max-width: 1000px;
    .job-desc {
      font-size: 12px;
      line-height: 22px;
      @include phone {
        margin: 20px 0;
      }
      span {
        color: #717171;
      }
    }
  }
}

.clients-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px;
  @include tablet {
    padding: 75px 200px 75px 75px;
  }
  @include phone {
    padding: 30px;
  }
  .clients-logo {
    margin: 150px 0 0 0;
    max-width: 900px;
    @include laptop {
      margin: 50px 0 0 0;
    }
    .client-logo-wrapper {
      width: 100%;
      height: 150px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      justify-content: center;
      @include phone {
        height: 100px;
      }
      img {
        @include phone {
          width: 80px;
        }
      }
      .logo-brand {
         max-width: 120px;
        &--vertical {
          max-height: 75px;
        }
      }
    }
    .wmf-logo {
      @include phone {
        width: 40px !important;
      }
    }
    .ucb-logo {
      @include phone {
        width: 50px !important;
      }
    }
    .pfizer-logo {
      @include phone {
        width: 60px !important;
      }
    }
  }
}

.agency-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px;
  @include tablet {
    padding: 75px 200px 75px 75px;
  }
  @include phone {
    padding: 30px;
  }
  .cm-pin {
    width: 18px;
    margin: 40px 0 0 0;
    .pin-svg {
      fill: #fff;
    }
  }
  .about-us {
    font-size: 16px;
    color: #787878;
    line-height: 30px;
    font-weight: 300;
    max-width: 860px;
    margin: 60px 0 0 0;
    span {
      font-weight: 500;
    }
  }
  .about-us-more {
    font-size: 24px;
    color: #fff;
    line-height: 40px;
    font-weight: 300;
    margin: 80px 0 0 0;
    span {
      font-weight: 700;
    }
  }
}

.portfolio-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  .menu-case-client {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 300;
    color: #444147;
    text-transform: uppercase;
    text-align: center;
    padding: 150px 0 0 0;
    position: relative;
    z-index: 1;
  }
  .menu-case-title {
    text-align: center;
    font-size: 30px;
    letter-spacing: 5px;
    color: #fff;
    font-weight: 200;
    padding: 60px 0 0 0;
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 1;
    min-height: 165px;
    transition: 0.3s ease;
    @include tablet {
      font-size: 26px;
    }
  }
  .portfolio-slider {
    width: 100%;
    padding-right: 250px;
    @include phone {
      padding: 0;
    }
    .slick-dots {
      bottom: 50px;
    }
    .slick-dots li.slick-active button:before {
      color: #fff;
      opacity: 1;
    }
    .slick-dots li button:before {
      font-family: 'slick';
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: '•';
      text-align: center;
      opacity: .25;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .slick-dots li button:before
    {
      font-size: 10px;
      line-height: 20px;
    }
    .item {
      display: block;
      height: 100vh;
      padding: 0 20px;
      background: #19181a;
      border-right: solid 1px #2d2c2d;
      .menu-case-photo {
        width: initial;
        margin: 100px auto 0 auto;
        transition: 0.3s ease;
        max-width: 160px;
        max-height: 180px;
      }
      &:hover {
        .menu-case-title {
          padding: 40px 0 0 0;
        }
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.contact-subpage {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 250px 100px 100px;
  @include tablet {
    padding: 75px 200px 75px 75px;
  }
  @include phone {
    padding: 30px;
  }
  .row {
    padding: 8% 0 0 0;
    max-width: 900px;
  }
  .icon-wrapper {
    height: 70px;
    @include phone {
      height: 50px;
      margin-top: 50px;
    }
    .map-trigger {
      cursor: pointer;
      position: relative;
      top: 0;
      transition: 0.3s ease;
    }
  }
  .map-trigger-wrapper {
    display: inline-block;
    cursor: pointer;
    &:hover {
      .map-trigger {
        top: -10px;
      }
    }
  }
  a {
    color: #757575;
    &:hover {
      text-decoration: underline;
    }
  }
  address {
    font-style: normal;
    line-height: 28px;
  }
  .info {
    line-height: 28px;
  }
  .contact-footer {
    font-size: 12px;
    line-height: 20px;
    color: #444444;
    max-width: 800px;
    margin: 50px 0 0 0;
  }
  .map-link {
    display: none;
    @include phone {
      display: block;
    }
    svg {
      width: 10px;
      position: relative;
      top: 2px;
      left: 6px;
      path {
        fill: none;
        stroke: #757575;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
    }
  }
}
.map-popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #eee;
  z-index: 12;
  display: none;
  #googleMapPopup {
    width: 100%;
    height: 100%;
  }
}
.close-map-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  background: #19181a;
  z-index: 21;
  cursor: pointer;
  &:hover {
    .bars {
      transform: scale(1.3) translate(-50%,-50%);
      .bar {
        background: #fff;
      }
    }
  }
  .bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    transform: translate(-50%,-50%);
    transition: 0.3s ease;
    /* Force Hardware Acceleration */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    .bar {
      height: 1px;
      width: 24px;
      background: #fff;
      margin-bottom: 10px;
      position: relative;
      top: 0;
      transition: 0.4s ease;
      transform-origin: center center;
      &:first-child {
        width: 21px;
      }
      &:last-child {
        width: 17px;
        margin-bottom: 0;
        top: 0;
        opacity: 1;
      }
    }
  }
  &.open {
    .bar {
      transform: rotate(45deg);
      &:first-child {
        width: 24px;
        top: 11px;
        transform: rotate(-45deg);
      }
      &:last-child {
        top: -10px;
        opacity: 0;
      }
    }
  }
}

.portfolio-content {
  width: 100%;
  position: relative;
  .portfolio-item {
    width: 50%;
    height: 187px;
    float: left;
    background: #eee;
    display: block;
    .portfolio-name {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 300;
      color: #948076;
      text-transform: uppercase;
      text-align: center;
      margin: 30px 0 15px 0;
    }
    .portfolio-title {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 200;
      text-align: center;
      padding: 0 10px;
    }

    &.zelazka {
      background: url('../img/realizacje_zelazka.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.tech-wizards {
      background: url('../img/tech-wizards/header_03.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.bepanthen {
      background: url('../img/bepanthen/header.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.velo {
      background: url('../img/velo/header.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.morliny {
      background: url('../img/morliny/header.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.zyrtec {
      background: url('../img/realizacje_zyrtec.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.unicef {
      background: url('../img/realizacje_unicef.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.kompan {
      background: url('../img/realizacje_kompan.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }

    &.sferis {
      background: url('../img/realizacje_sferis.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.bloniapark {
      background: url('../img/realizacje_blonia.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.patio {
      background: url('../img/realizacje_patio.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #000;
      }
      .portfolio-title {
        color: #000;
      }
    }
    &.chiesi {
      background: url('../img/realizacje_chiesi.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #000;
      }
      .portfolio-title {
        color: #000;
      }
    }
    &.lekam {
      background: url('../img/realizacje_lekam.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #000;
      }
      .portfolio-title {
        color: #000;
      }
    }
    &.genexo {
      background: url('../img/realizacje_zdrowisie.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #000;
      }
      .portfolio-title {
        color: #000;
      }
    }
    &.gostar {
      background: url('../img/realizacje_gostar.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.bracia-sadownicy {
      background: url('../img/realizacje_bracia_sadownicy.jpg') no-repeat center center / cover;
      .portfolio-name {
        color: #fff;
      }
      .portfolio-title {
        color: #fff;
      }
    }
    &.go-top {
      background: #1c1b1d;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      .pin-trigger {
        width: 25px;
        overflow: visible;
        .pin-svg {
          fill: #fff;
        }
      }
    }
  }
}

.team-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: wrap;
  .team-item{
    position: relative;
    &__mask{
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 2;
    }
    &__content{
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      color: #fff;
    }
    &__name{
      font-size: 24px;
    }
    &__position{
      font-size: 15px;
    }
  }
  .team-item {
    width: 50%;
    float: left;
    background: #2d2d2d;
    display: block;
    img {
      display: block;
      width: 100%;
      z-index: 1;
    }
    .team-name {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 300;
      color: #948076;
      text-transform: uppercase;
      text-align: center;
      margin: 30px 0 15px 0;
    }
    .team-title {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 200;
      text-align: center;
      padding: 0 10px;
    }
  }
}

.video-slick-arrows {
  position: absolute;
  left: 0;
  transition: .3s ease;
  height: 100%;
  z-index: 80;
  cursor: pointer;

  &__top {
    position: absolute;
    transition: .3s ease;
    top: -80px;

    &:hover {
      transform: translateY(-4px);
    }
  }

  &__bottom {
    transition: .3s ease;
    position: absolute;
    bottom: -100px;

    &:hover {
      transform: translateY(4px);
    }
  }

  svg {
    width: 18px;
  }
}

#video-viewport {
  img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: block;
    object-fit: cover;
    height: 100%;
  }
}
