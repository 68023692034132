// Case 4
// Chiesi App

.case4.subpage {
  height: auto;
  padding: 0;
  overflow: hidden;
  .copyrights, .case-title, .slogan, .case-title-subpage {
    color: #fff;
  }
  .arrow-line {
   stroke: #fff;
 }
  .logo-svg {
    fill: #fff;
  }
  .case-subtitle-subpage {
    color: #6a6d75;
  }
  .bar {
    background: #fff;
  }
  .main-content {
    background-color: #272a34;
  }

  .main-photo {
      max-width: 1160px;
      position: absolute;
      left: 50%;
      bottom: -350px;
      transform: translateX(-50%);
      @include tablet {
        bottom: -330px;
        width: 850px;
      }
      @include phone {
        bottom: -50px;
        width: 250px;
      }
  }
  
  .section {
    max-width: 850px;
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 30px;
    }
  }
  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #787d90;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }
  .lead {
    font-size: 30px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 40px;
    @include phone {
      font-size: 24px;
      line-height: 36px;
    }
  }
  .default-text {
    font-size: 18px;
    color: #787d90;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;
  }
  .title {
    font-size: 56px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .intro {
    &:after {
      background: #272a34;
    }
  }
  .brief {
      padding: 480px 0 200px 0;
      @include phone {
        padding: 80px 0 100px 0;
      }
      .lead {
          margin: 50px 0 60px 0;
      }
  }
  .ipad {
      position: relative;
      .title {
          max-width: 550px;
          padding: 100px 0 80px 0;
          @include phone {
            font-size: 34px;
            padding: 0 0 80px 0;
          }
      }
      .photo1 {
        position: absolute;
        right: 0;
        top: 0;
        @include laptop {
          right: -100px;
        }
        @include tablet {
          right: -250px;
        }
        @include phone {
          position: initial;
          margin: 80px 0 0 30px;
        }
      }
  }
  .menu-bar {
      .section {
          padding: 400px 0 0 300px;
          @include phone {
            padding: 100px 20px 0 20px;
          }
          .title {
            padding: 0 0 50px 0;
            @include phone {
              font-size: 34px;
            }
          }
          .photo2 {
            position: absolute;
            left: -350px;
            top: 450px;
            @include phone {
              position: initial;
              margin: 40px 0 0 0;
            }
          }
      }
  }
  .remote {
      margin: 300px 0 0 0;
      padding: 0 200px 0 0;
      position: relative;
      @include phone {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
      }
      .title {
          max-width: 550px;
          padding: 100px 0 80px 0;
          @include phone {
            font-size: 34px;
          }
      }
      .photo3 {
        position: absolute;
        right: 0;
        top: 0;
        @include laptop {
          right: -200px;
        }
        @include tablet {
          right: -250px;
          top: -100px;
        }
        @include phone {
          position: initial;
          margin: 70px 0 0 0;
        }
      }
      .icons {
          margin: 50px 0 0 0;
      }
  }
  .support {
      padding: 300px 0 150px 0;
      border-bottom: solid 1px #1f2129;
      overflow: auto;
      @include phone {
        padding: 50px 0 50px 0;
      }
      .support-icon {
        @include phone {
          margin: 0 auto 40px auto;
          width: 110px;
          display: block;
        }
      }
      .title {
          margin: 0 0 50px 0;
          @include phone {
            font-size: 34px;
          }
      }
  }
  .screenshots {
      padding: 200px 0 80px 0;
      @include phone {
        padding: 50px 0 50px 0;
      }
      .title {
        text-align: center;
        padding: 0 0 100px 0;
        @include phone {
          font-size: 34px;
        }
      }
      .screenshot-img {
        margin: 0 auto;
        display: block;
        padding: 0 0 60px 0;
      }
  }
  
  .footer-wrapper {
      width: 100%;
      position: relative;
      background: #1f2129;

      a {
        color: #35baf6;
      }

      .case-footer {
        padding: 160px 20px 200px 20px;
        max-width: 600px;
        @include tablet {
          text-align: center;
          padding: 80px 20px 100px 20px;
        }
        .footer-title {
          font-size: 30px;
          color: #fff;
          font-weight: 300;
          letter-spacing: 1px;
          text-align: center;
          margin: 0 0 90px 0;
          line-height: 48px;
          @include phone {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .col1 {
          position: relative;
          &:after {
              content: " ";
              display: block;
              height: 100px;
              width: 1px;
              background: #333d4c;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -50px;
          }
          @include tablet {
            &:after {
              display: none;
            }
          }
        }
        .contact {
          color: #fff;
          font-size: 21px;
          margin: 20px 0 0 0;
          @include tablet {
            font-size: 18px;
          }
          .position {
              font-size: 12px;
          }
        }
      }
    }
}