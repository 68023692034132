//************************
//    VARIABLES
//************************
$gl-gridName:   grid !default;
$gl-colName:   col !default;
$gl-attributeName: class !default;
$gl-gutter:    1rem !default; // Total left + right
$gl-gutter-vertical:    1rem !default;

$gl-mq-list: (
  lg: "screen and (max-width: 80em)", // max 1280px
  md: "screen and (max-width: 64em)", // max 1024px
  sm: "screen and (max-width: 48em)", // max 768px
  xs: "screen and (max-width: 35.5em)" // up to 568px
) !default;


$glp_col-1: (100%/12);
$glp_col-2: ($glp_col-1*2);
$glp_col-3: ($glp_col-1*3);
$glp_col-4: ($glp_col-1*4);
$glp_col-5: ($glp_col-1*5);
$glp_col-6: ($glp_col-1*6);
$glp_col-7: ($glp_col-1*7);
$glp_col-8: ($glp_col-1*8);
$glp_col-9: ($glp_col-1*9);
$glp_col-10: ($glp_col-1*10);
$glp_col-11: ($glp_col-1*11);
$glp_col-12: 100%;

$glp_col-5-12: (100%/5);
$glp_col-7-12: (100%/7);
$glp_col-8-12: (100%/8);
$glp_col-9-12: (100%/9);
$glp_col-10-12: (100%/10);
$glp_col-11-12: (100%/11);

// Function to generate the grid by number
@mixin makeGridByNumber($grid)
{
  [#{$gl-attributeName}*="#{$grid}-1"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-1"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-12;
    max-width: $glp_col-12;
  }

  [#{$gl-attributeName}*="#{$grid}-2"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-2"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-6;
    max-width: $glp_col-6;
  }

  [#{$gl-attributeName}*="#{$grid}-3"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-3"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-4;
    max-width: $glp_col-4;
  }

  [#{$gl-attributeName}*="#{$grid}-4"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-4"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-3;
    max-width: $glp_col-3;
  }
  [#{$gl-attributeName}*="#{$grid}-5"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-5"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-5-12;
    max-width: $glp_col-5-12;
  }
  [#{$gl-attributeName}*="#{$grid}-6"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-6"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-2;
    max-width: $glp_col-2;
  }
  [#{$gl-attributeName}*="#{$grid}-7"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-7"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-7-12;
    max-width: $glp_col-7-12;
  }
  [#{$gl-attributeName}*="#{$grid}-8"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-8"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-8-12;
    max-width: $glp_col-8-12;
  }
  [#{$gl-attributeName}*="#{$grid}-9"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-9"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-9-12;
    max-width: $glp_col-9-12;
  }
  [#{$gl-attributeName}*="#{$grid}-10"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-10"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-10-12;
    max-width: $glp_col-10-12;
  }
  [#{$gl-attributeName}*="#{$grid}-11"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-11"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-11-12;
    max-width: $glp_col-11-12;
  }
  [#{$gl-attributeName}*="#{$grid}-12"] > [#{$gl-attributeName}~="#{$gl-colName}"],
  [#{$gl-attributeName}*="#{$grid}-12"] > [#{$gl-attributeName}*="#{$gl-colName}-"] {
    flex-basis: $glp_col-1;
    max-width: $glp_col-1;
  }
}

// Function to generate the grid by columns
@mixin makeCol($col)
{
    [#{$gl-attributeName}*='#{$gl-gridName}'] {
        > [#{$gl-attributeName}*="#{$col}-1"]{ flex-basis: $glp_col-1; max-width: $glp_col-1; }
        > [#{$gl-attributeName}*="#{$col}-2"]{ flex-basis: $glp_col-2; max-width: $glp_col-2;  }
        > [#{$gl-attributeName}*="#{$col}-3"]{ flex-basis: $glp_col-3; max-width: $glp_col-3;  }
        > [#{$gl-attributeName}*="#{$col}-4"]{ flex-basis: $glp_col-4; max-width: $glp_col-4; }
        > [#{$gl-attributeName}*="#{$col}-5"]{ flex-basis: $glp_col-5; max-width: $glp_col-5; }
        > [#{$gl-attributeName}*="#{$col}-6"]{ flex-basis: $glp_col-6; max-width: $glp_col-6; }
        > [#{$gl-attributeName}*="#{$col}-7"]{ flex-basis: $glp_col-7; max-width: $glp_col-7; }
        > [#{$gl-attributeName}*="#{$col}-8"]{ flex-basis: $glp_col-8; max-width: $glp_col-8; }
        > [#{$gl-attributeName}*="#{$col}-9"]{ flex-basis: $glp_col-9; max-width: $glp_col-9; }
        > [#{$gl-attributeName}*="#{$col}-10"]{ flex-basis: $glp_col-10; max-width: $glp_col-10; }
        > [#{$gl-attributeName}*="#{$col}-11"]{ flex-basis: $glp_col-11; max-width: $glp_col-11; }
        > [#{$gl-attributeName}*="#{$col}-12"]{ flex-basis: $glp_col-12; max-width: $glp_col-12; }
    }
}

// Function to generate the -first & -last base on mq keys (as _lg-first)
@mixin makeFirstLast($mq)
{
  [#{$gl-attributeName}*='#{$gl-gridName}'] {
    [#{$gl-attributeName}*="#{$mq}-first"]{
      order: -1;
    }
    [#{$gl-attributeName}*="#{$mq}-last"]{
      order: 1;
    }
  }
}

// Function to generate the offset
@mixin makeOff($off)
{
    [#{$gl-attributeName}*="#{$gl-gridName}"]{
        > [data-push-left*="#{$off}-0"]{ margin-left: 0; }
        > [data-push-left*="#{$off}-1"]{ margin-left: $glp_col-1; }
        > [data-push-left*="#{$off}-2"]{ margin-left: $glp_col-2; }
        > [data-push-left*="#{$off}-3"]{ margin-left: $glp_col-3; }
        > [data-push-left*="#{$off}-4"]{ margin-left: $glp_col-4; }
        > [data-push-left*="#{$off}-5"]{ margin-left: $glp_col-5; }
        > [data-push-left*="#{$off}-6"]{ margin-left: $glp_col-6; }
        > [data-push-left*="#{$off}-7"]{ margin-left: $glp_col-7; }
        > [data-push-left*="#{$off}-8"]{ margin-left: $glp_col-8; }
        > [data-push-left*="#{$off}-9"]{ margin-left: $glp_col-9; }
        > [data-push-left*="#{$off}-10"]{ margin-left: $glp_col-10; }
        > [data-push-left*="#{$off}-11"]{ margin-left: $glp_col-11; }

        > [data-push-right*="#{$off}-0"]{ margin-right: 0; }
        > [data-push-right*="#{$off}-1"]{ margin-right: $glp_col-1; }
        > [data-push-right*="#{$off}-2"]{ margin-right: $glp_col-2; }
        > [data-push-right*="#{$off}-3"]{ margin-right: $glp_col-3; }
        > [data-push-right*="#{$off}-4"]{ margin-right: $glp_col-4; }
        > [data-push-right*="#{$off}-5"]{ margin-right: $glp_col-5; }
        > [data-push-right*="#{$off}-6"]{ margin-right: $glp_col-6; }
        > [data-push-right*="#{$off}-7"]{ margin-right: $glp_col-7; }
        > [data-push-right*="#{$off}-8"]{ margin-right: $glp_col-8; }
        > [data-push-right*="#{$off}-9"]{ margin-right: $glp_col-9; }
        > [data-push-right*="#{$off}-10"]{ margin-right: $glp_col-10; }
        > [data-push-right*="#{$off}-11"]{ margin-right: $glp_col-11; }
    }
}