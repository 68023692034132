// Case 1
// Sferis

.case1.subpage {

  .logo-wrapper, .case-arrow-back, .menu-button {
    background: black;
    mix-blend-mode: exclusion;
  }

  .copyrights, .case-title, .slogan {
    color: #fff;
  }

  .logo-svg {
    fill: #fff;
  }

  .arrow-line {
    stroke: #fff;
  }

  .lang {
    mix-blend-mode: exclusion;
  }

  .case-title-subpage {
    font-size: 50px;
    font-weight: 500;
    @include tablet {
      font-size: 30px;
      padding: 7px 30px;
      margin-top: 100px;
    }
    @include phone {
      font-size: 20px;
      padding: 7px 30px;
      margin-top: 30px;
    }
    span {
      position: relative;
      display: inline-block;
      font-weight: 500;
      letter-spacing: 1px;
      padding: 10px 45px;
      margin: 0 0 14px 0;
      &:after {
        content: "";
        background: #a2bb3f;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        -webkit-border-top-left-radius: 25px;
        -webkit-border-bottom-right-radius: 25px;
        -moz-border-radius-topleft: 25px;
        -moz-border-radius-bottomright: 25px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px;
      }
      @include phone {
        padding: 7px 20px;
      }
    }
  }

  .case-subtitle-subpage {
    color: #fff;
  }

  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }

  .section-title {
    font-size: 43px;
    color: #a2bb3f;
    font-weight: 300;
    letter-spacing: 1px;
    @include tablet {
      font-size: 28px;
    }
  }

  .section-title-small {
    font-size: 24px;
    color: #262832;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .heading {
    font-size: 30px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 40px;

    img {
      vertical-align: inherit;
    }
  }

  .default-text {
    font-size: 18px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;
  }

  .section {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 30px;
    }
  }

  .main-content {
    background: #fff;
  }

  .intro {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: visible;
    background: #e4e4e4;
    &:after {
      background: rgb(162, 187, 63);
    }
    .intro-bg {
      background-image: url('../img/sferis/sferis_bg.jpg');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .samsung1 {
      position: absolute;
      bottom: -140px;
      left: 52%;
      transform: translateX(-50%);
      z-index: 2;
      @include tablet {
        width: 110px;
      }
      @include phone {
        width: 80px;
        bottom: -40px;
      }
    }
    .samsung2 {
      position: absolute;
      bottom: -140px;
      left: 46%;
      transform: translateX(-50%);
      z-index: 1;
      @include tablet {
        width: 100px;
      }
      @include phone {
        width: 70px;
        bottom: -40px;
      }
    }
  }

  .brief {
    padding: 230px 0 100px 0;
    position: relative;
    @include phone {
      padding: 100px 0 50px 0;
    }
    .frame1 {
      position: absolute;
      top: 200px;
      right: 0;
      @include laptop {
        right: -250px;
      }
      @include tablet {
        display: none;
      }
    }
    .heading {
      padding: 40px 0 80px 0;
    }
    .slogan {
      padding: 120px 0;
      font-size: 47px;
      @include tablet {
        font-size: 26px;
        padding: 60px 0;
      }
      @include phone {
        font-size: 20px;
      }
      .zawsze1 {
        color: #000;
        font-weight: 500;
        padding: 18px 45px;
        @include phone {
          padding: 10px 25px;
        }
      }
      .zawsze2, .zawsze3 {
        font-weight: 700;
        color: #fff;
        position: relative;
        display: inline-block;
        letter-spacing: 1px;
        padding: 10px 45px;
        margin: 0 0 14px 0;
        z-index: 1;
        @include phone {
          padding: 10px 25px;
        }
        &:after {
          content: "";
          background: #a2bb3f;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 100%;
          -webkit-border-top-left-radius: 25px;
          -webkit-border-bottom-right-radius: 25px;
          -moz-border-radius-topleft: 25px;
          -moz-border-radius-bottomright: 25px;
          border-top-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }
        span {
          font-weight: 700;
        }
      }
    }
    .padding-top {
      padding: 100px 0 100px 0;
      @include tablet {
        padding: 50px 0;
      }
      .default-text {
        padding: 0 40px 0 0;
      }
    }
    .section-title-small  {
      margin: 120px 0 50px 0;
    }
  }

  .typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
  }
  @keyframes blink{
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
  }
  @-webkit-keyframes blink{
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
  }
  @-moz-keyframes blink{
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
  }

  .slider {
    width: 100%;
    overflow: visible;
    position: relative;
    @include tablet {
      display: none;
    }
    .background {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 563px;
      background: #f4f4f4;
    }
    .item {
      width: 100%;
      height: 663px;
      position: relative;
      overflow: visible;
      z-index: 2;
      outline: 0;
    }
    .section {
      max-width: 1170px;
      z-index: 3;
    }
    .sferis-slider {
      position: relative;
      z-index: 2;
      &.lang-pl {
        background: url('../img/sferis/slider_bg.jpg') no-repeat bottom center;
      }
      &.lang-en {
        background: url('../img/sferis/slider_bg_en.jpg') no-repeat bottom center;
      }
    }
    .slide-samsung {
      .slogan {
        font-size: 27px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        left: 160px;
        top: 284px;
        display: inline-block;
        letter-spacing: 1px;
        padding: 9px 35px;
        margin: 0 0 14px 0;
        z-index: 1;
        &:after {
          content: "";
          background: #a2bb3f;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 100%;
          -webkit-border-top-left-radius: 25px;
          -webkit-border-bottom-right-radius: 25px;
          -moz-border-radius-topleft: 25px;
          -moz-border-radius-bottomright: 25px;
          border-top-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
      .samsung-logo {
        position: absolute;
        left: 160px;
        bottom: 80px;
        width: initial;
      }
      .phone {
        position: absolute;
        right: -100px;
        top: 50px;
        width: initial;
      }
    }
    .slide-lenovo {
      .slogan {
        font-size: 27px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        left: 160px;
        top: 284px;
        display: inline-block;
        letter-spacing: 1px;
        padding: 9px 35px;
        margin: 0 0 14px 0;
        z-index: 1;
        &:after {
          content: "";
          background: #a2bb3f;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 100%;
          -webkit-border-top-left-radius: 25px;
          -webkit-border-bottom-right-radius: 25px;
          -moz-border-radius-topleft: 25px;
          -moz-border-radius-bottomright: 25px;
          border-top-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
      .samsung-logo {
        position: absolute;
        left: 160px;
        bottom: 80px;
        width: initial;
      }
      .phone {
        position: absolute;
        right: 0;
        top: 50px;
        width: initial;
      }
    }
    .slide-huawei {
      .slogan {
        font-size: 27px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        left: 160px;
        top: 284px;
        display: inline-block;
        letter-spacing: 1px;
        padding: 9px 35px;
        margin: 0 0 14px 0;
        z-index: 1;
        &:after {
          content: "";
          background: #a2bb3f;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          width: 100%;
          height: 100%;
          -webkit-border-top-left-radius: 25px;
          -webkit-border-bottom-right-radius: 25px;
          -moz-border-radius-topleft: 25px;
          -moz-border-radius-bottomright: 25px;
          border-top-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
      .samsung-logo {
        position: absolute;
        left: 160px;
        bottom: 80px;
        width: initial;
      }
      .phone {
        position: absolute;
        right: 0;
        top: 150px;
        width: initial;
      }
    }
  }

  .owl-theme .owl-controls {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-tap-highlight-color: transparent;
  }

  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
  }

  .owl-carousel .owl-controls .owl-dot, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    background: none;
    border: solid 2px #a2bb3f;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #a2bb3f;
  }

  .kampania-mediowa {
    width: 100%;
    background: url('../img/sferis/kampania_bg.jpg') no-repeat top 730px center;
    padding: 100px 0 0 0;
    @include tablet {
      background: url('../img/sferis/kampania_bg.jpg') no-repeat top 230px center;
      padding: 50px 0 0 0;
    }
    .frame2 {
      position: relative;
      margin: 0 0 0 0;
    }
    .logo-samsung {
      position: relative;
      margin: 0 auto 80px auto;
    }
    .title {
      font-size: 67px;
      color: #fff;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 80px 0 160px 0;
      @include tablet {
        font-size: 40px;
        margin: 40px 0 80px 0;
      }
      @include phone {
        font-size: 30px;
        margin: 30px 0 70px 0;
      }
    }
  }

  .sferis-stats {
    width: 100%;
    .title {
      font-size: 50px;
      color: #2f2f2f;
      font-weight: 300;
      letter-spacing: 1px;
      text-align: center;
      margin: 80px 0 80px 0;
    }
    .icon-wrapper {
      min-height: 80px;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
    }
    .stats-number {
      font-size: 52px;
      color: #a2bb3f;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      margin: 0 0 20px 0;
    }
    .stats-text {
      font-size: 16px;
      color: #000;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      margin: 0;
    }
  }
 
  .footer-wrapper {
    width: 100%;
    background: #f7f7f7;
    position: relative;
    margin: 100px 0 0 0;
    a {
      color: #a2bb3f;
    }
    .case-footer {
      padding: 160px 20px 200px 20px;
      max-width: 600px;
      @include tablet {
        text-align: center;
        padding: 80px 20px 100px 20px;
      }
      .footer-title {
        font-size: 30px;
        color: #2f2f2f;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 0 90px 0;
        line-height: 48px;
        @include phone {
          font-size: 22px;
          line-height: 30px;
        }
      }
      .col1 {
        position: relative;
        &:after {
            content: " ";
            display: block;
            height: 100px;
            width: 1px;
            background: #d8d8d8;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -50px;
        }
        @include tablet {
          &:after {
            display: none;
          }
        }
      }
      .contact {
        color: #2f2f2f;
        font-size: 21px;
        margin: 20px 0 0 0;
        @include tablet {
          font-size: 18px;
        }
        .position {
            font-size: 12px;
        }
      }
    }
  }
}