// Case 6
// Zdrowisie

.case6.subpage {
  .copyrights, .case-title, .slogan, .case-title-subpage {
    color: #252730;
  }
  .bar {
    background: #252730;
  }
  .logo-svg {
    fill: #252730;
  }
  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }
  .lang {
    span {
      color: #262933;
    }
  }

  .lead {
    font-size: 30px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 40px;
  }
  .default-text {
    font-size: 18px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;
  }
  .title {
    font-size: 56px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .section {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 30px;
    }
  }
  .main-content {
    background: #fff;
  }

  .intro {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: visible;
    background: #b4d9cc;
    &:after {
      background: #b4d9cc;
    }
    .trawa {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    .zdrowis {
      position: absolute;
      bottom: -120px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      @include phone {
        bottom: -20px;
        width: 100px;
      }
    }
    .chmura1 {
      width: 120px;
      position: absolute;
      bottom: 200px;
      right: calc(100% - 120px);
    }
    .chmura2 {
      position: absolute;
      bottom: 300px;
      left: calc(100% - 120px);
    }
  }

  .brief {
    padding: 140px 0 100px 0;
    position: relative;
    .photo1 {
      position: absolute;
      right: 0;
      bottom: -100px;
      z-index: 1;
      @include laptop {
        width: 30%;
        bottom: 200px;
      }
      @include phone {
        display: none;
      }
    }
    .lead {
      padding: 40px 0 60px 0;
    }
  }
  .technologia-html5 {
    width: 100%;
    height: 247px;
    background-image: url('../img/zdrowisie/technologia_bg.jpg');
    background-size: cover;
    background-position: center center;
    position: relative;
    .tech-ipad {
      position: absolute;
      top: 60px;
      left: -250px;
      max-width: initial;
      @include phone {
        position: relative;
        top: 0;
        left: 0;
        display: none;
      }
    }
    .technologia-title {
      font-size: 22px;
      color: #3f6b61;
      padding: 73px 0;
      span {
        font-size: 64px;
      }
    }
  }
  .opis {
    width: 100%;
    padding: 0 0 100px 0;
    position: relative;
    .lead {
      font-size: 48px;
      margin: 100px 0 0 0;
      line-height: 55px;
      @include phone {
        font-size: 30px;
        margin: 100px 0 0 0;
        line-height: 36px;
      }
    }
    .default-text {
      margin: 70px 0 0 0;
    }
  }
  .przedstaw-sie {
    width: 100%;
    background: #f8f8f8;
    position: relative;
    overflow: hidden;
    .ipad {
      position: absolute;
      right: -100px;
      bottom: 0;
      max-width: initial;
      @include phone {
        position: relative;
        max-width: 100%;
        right: 0;
      }
    }
    .lead {
      font-size: 48px;
      margin: 100px 0 80px 0;
      line-height: 55px;
      @include phone {
        font-size: 30px;
        margin: 100px 0 80px 0;
        line-height: 36px;
      }
    }
    .default-text {
      margin: 0 0 100px 0;
      width: 100%;
      max-width: 280px;
    }
  }
  .nie-znosza {
    width: 100%;
    padding: 150px 0;
    text-align: center;
    position: relative;
    .zdrowis2 {
      position: absolute;
      right: -220px;
      top: -400px;
      max-width: initial;
    }
    .lead {
      font-size: 36px;
    }
    img {
      padding: 60px 30px 0 0;
      &:last-child {
        padding: 60px 0 0 0;
      }
    }
  }
  .dobra-zabawa {
    width: 100%;
    position: relative;
    .chmury {
      position: absolute;
      top: 300px;
      left: 50%;
      transform: translateX(-50%);
      max-width: initial;
      z-index: 2;
    }
    .zabawa-w-dzien {
      width: 100%;
      background: #f2f2f2;
      text-align: center;
      .heading {
        font-size: 30px;
        color: #232435;
        font-weight: 500;
        margin: 0 0 110px 0;
        position: relative;
        top: 100px;
      }
      img {
        position: relative;
        top: 100px;
        z-index: 1;
        @include phone {
          top: 0;
          margin: 40px 0 0 0;
          padding: 0 30px;
        }
      }
    }
    .zabawa-w-nocy {
      width: 100%;
      background: #303a4d;
      text-align: center;
      height: 808px;
      .heading {
        font-size: 30px;
        color: #fff;
        font-weight: 500;
        margin: 0 0 110px 0;
        position: relative;
        top: 100px;
      }
      img {
        position: relative;
        top: 100px;
        @include phone {
          padding: 0 30px;
        }
      }
    }
  }

  .footer-wrapper {
      width: 100%;
      background: #f7f7f7;
      position: relative;
      margin: 350px 0 0 0;
      a {
        color: #b4d9cc;
      }
      .lizak {
        position: absolute;
        left: 50%;
        top: -100px;
        transform: translateX(-50%);
        @include tablet {
          width: 50px;
          top: -60px;
        }
      }
      .case-footer {
        padding: 160px 20px 200px 20px;
        max-width: 600px;
        @include tablet {
          text-align: center;
          padding: 80px 20px 100px 20px;
        }
        .footer-title {
          font-size: 30px;
          color: #2f2f2f;
          font-weight: 300;
          letter-spacing: 1px;
          text-align: center;
          margin: 0 0 90px 0;
          line-height: 48px;
          @include phone {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .col1 {
          position: relative;
          &:after {
              content: " ";
              display: block;
              height: 100px;
              width: 1px;
              background: #d2d3d4;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -50px;
          }
          @include tablet {
            &:after {
              display: none;
            }
          }
        }
        .contact {
          color: #2f2f2f;
          font-size: 21px;
          margin: 20px 0 0 0;
          @include tablet {
            font-size: 18px;
          }
          .position {
              font-size: 12px;
          }
        }
      }
    }
}