/* Hello */

.hello-bs {
	background: url("../img/bracia-sadownicy/header-bg.jpg") 100%/cover no-repeat;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
	}
}

// Case 12
// Bracia Sadownicy

.case12.subpage {
	$color1: #045593;
	$color2: #67ab52;

	$white: #fff;
	$grey-light: #f2f2f2;
	$light-grey: #cdd1e1;

	$middle-blue: #5799fc;
	$deep-blue: #262832;

	$red: #ed2327;

	$grey-black: #333;
	$black: #000;

	$work-sans: 'Work Sans', sans-serif;
	$palanquin: 'Palanquin', sans-serif;

	$font-extra-light: 100;
	$font-light: 300;
	$font-reqular: 400;
	$font-medium: 500;
	$font-semi-bold: 600;

	color: #262832;
	line-height: 1.42857;
	background-color: #fff !important;

	#fp-nav ul li.visited a span, #fp-nav ul li a:hover span, #fp-nav ul li a.active span  {
		background: #ed2327;
	}
	#fp-nav ul li .fp-tooltip {
		color: #000;
		mix-blend-mode: exclusion;
	}

	.lang {
		mix-blend-mode: exclusion;
		span {
			color: #fff;
		}
	}

	p {
		//margin: 0 0 10px;
	}

	.arrow-line {
		stroke: #fff;
	}

	.logo-wrapper, .case-arrow-back, .menu-button {
		mix-blend-mode: exclusion;
	}

	.ks-small-description {
		font-size: 13px;

		@include tablet {
			font-size: 11px;
		}
	}

	.ks-description {
		font-size: 18px;

		@include tablet {
			font-size: 16px;
		}

		@include phone {
			font-size: 14px;
		}
	}

	.ks-large-description {
		font-size: 32px;

		@include tablet {
			font-size: 26px;
		}

		@include phone {
			font-size: 20px;
		}
	}

	.ks-small-heading {
		font-size: 38px;

		@include tablet {
			font-size: 32px;
		}

		@include phone {
			font-size: 28px;
		}
	}

	.ks-heading {
		font-size: 48px;

		@include tablet {
			font-size: 40px;
		}

		@include phone {
			font-size: 32px;
		}
	}

	span,
	p,
	h1 {
		/* Colors */
		&.color-white {
			color: $white;
		}

		&.color-black {
			color: $black;
		}

		&.color-red {
			color: $red;
		}

		/* Weight */
		&.font-extra-light {
			font-weight: $font-extra-light;
		}

		&.font-light {
			font-weight: $font-light;
		}

		&.font-reqular {
			font-weight: $font-reqular;
		}

		&.font-medium {
			font-weight: $font-medium;
		}

		&.font-semi-bold {
			font-weight: $font-semi-bold;
		}

		/* Text transform */
		&.uppercase {
			text-transform: uppercase;
		}

		&.palanquin {
			font-family: $palanquin;
		}
	}

	.relative {
		position: relative;
	}

	.no-height-mobile {
		@include tablet {
			height: auto !important;
		}
	}

	.content-section {
		width: 100%;
		max-width: 1120px;
		margin: 0 auto;
		padding-left: 25px;
		padding-right: 25px;
	}

	body {
		overflow-x: hidden !important;
	}


	// Hello section
	.hello-ks {
		position: relative;
		text-align: center;
		overflow: hidden;
		.mask {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #333;
			opacity: .85;
			z-index: 0;
		}

		.top-video {
			z-index: -1;

			@include tablet {
				display: none;
			}
		}

		h1,
		p {position: relative;
			z-index: 100;
		}

		h1 {
			font-size: 65px;
			letter-spacing: 1px;

			@include laptop {
				font-size: 56px;
				margin-bottom: 50px;
			}

			@include tablet {
				font-size: 48px;
			}

			@include phone {
				font-size: 36px;
			}
		}

		img {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -20px;
			width: 100%;
			max-width: 680px;
			margin: 0 auto;

			@include laptop {
				max-width: 540px;
			}

			@include tablet {
				max-width: 420px;
			}

			@include phone {
				max-width: 300px;
			}
		}
	}

	//Brief
	.brief-ks {
		@include phone {
			padding: 45px 25px;
		}
	}

	.brief-ks-box {
		text-align: left;

		@include phone {
			text-align: center;
		}

		> div {
			&:nth-child(1) {
				order: 1;
				//i-compan
				img {
					max-width: 270px;

					@include laptop {
						max-width: 200px;
					}
				}
			}

			&:nth-child(2) {
				width: 100%;
				max-width: 650px;
				margin: 0 auto 0 0;
				order: 2;

				@include phone {
					margin: 0 auto;
					order: 3;
				}
			}

			&:nth-child(3) {
				order: 3;
				margin-top: -15px;

				@include phone {
					margin-top: auto;
					order: 2;
				}
				//logo
				img {
					max-width: 160px;

					@include laptop {
						max-width: 120px;
					}

					@include tablet {
						margin-bottom: 25px;
					}
				}
			}

			&:nth-child(4) {
				order: 4;
				width: 100%;
				max-width: 650px;
				margin: 0 auto 0 0;

				@include phone {
					margin: 0 auto;
				}
			}
		}

		img {
			display: block;
			width: 100%;
			margin: 0 auto;
		}

		.ks-small-description {
			margin-bottom: 25px;

			@include laptop {
				margin-bottom: 15px;
			}
		}

		.ks-large-description {
			margin-bottom: 25px;

			@include laptop {
				margin-bottom: 15px;
			}
		}

		.ks-description {
			margin-bottom: 70px;

			@include laptop {
				margin-bottom: 45px;
			}

			@include phone {
				margin-bottom: 15px;
			}
		}

		.more-about-ks {
			text-align: left;

			@include phone {
				text-align: center;
			}

			.ks-description {
				margin-bottom: 0;
			}

			.brief-numbers {
				font-size: 56px;

				@include laptop {
					font-size: 48px;
				}

				@include tablet {
					font-size: 36px;
				}
			}
		}
	}
	// Pecipes for dishes
	.recipes-for-dishes,
	.searching {
		background-color: $grey-light;
		text-align: center;

		.ks-description {
			width: 100%;
			max-width: 780px;
			margin: 0 auto 70px;

			@include phone {
				margin: 0 auto 30px;
			}
		}

		.ks-slider {
			.ks-slide {
				img {
					width: 100%;
					max-width: 920px;
					margin: 0 auto;

					@include laptop {
						max-width: 80%;
					}
				}
			}
		}
	}
	.ks-pagination {
		position: absolute;
		top: 50%;
		width: 100%;

		.pagination-arrow {
			position: absolute;
			cursor: pointer;
			transition: .3s ease;

			&.left {
				left: -35px;

				@include laptop {
					left: -10px;
				}

				&:hover {
					transform: translateX(-5px);
				}
			}

			&.right {
				right: -35px;

				&:hover {
					transform: translateX(5px);
				}
			}

			svg {
				width: 55px;
				height: 15px;

				@include desktop {
					width: 35px;
					height: 10px;
				}
			}
		}
	}
	// Options section
	.options-section {
		text-align: left;

		@include phone {
			text-align: center;
		}

		.ks-description {
			width: 100%;
			max-width: 385px;
			margin: 0 auto 85px 0;

			@include laptop {
				margin: 0 auto 65px 0;
			}

			@include phone {
				margin: 0 auto 45px;
			}
		}

		.claim-wrap {
			color: #7b7b7b;
			text-align: left;

			@include tablet {
				text-align: center;
			}

			.typed-cursor {
				display: none;
			}

			.claim-title {
				text-align: left;

				@include phone {
					text-align: center;
					margin-bottom: 10px;
				}
			}

			.claim {
				position: relative;
				font-size: 24px;
				text-align: left;
				min-height: 28px;

				@include laptop {
					font-size: 20px;
				}

				@include tablet {
					font-size: 18px;
				}

				@include phone {
					margin-bottom: 20px;
					text-align: center;
				}

				&:before {
					content: '';
					position: absolute;
					width: 210px;
					height: 1px;
					background-color: $black;
					left: 0;
					right: auto;
					margin: 0 auto;
					bottom: -10px;

					@include phone {
						right: 0;
					}
				}
			}

			.claim-button {
				display: inline-block;
				font-size: 15px;
				padding: 15px 22px 17px;
				background-color: $red;
				border-radius: 2px;
				color: $white;
				border: 1px solid $red;
				transition: 0.3s ease;

				p {
					white-space: nowrap;
					margin: 0;
				}
			}

			.ripple {
				width: 0;
				height: 0;
				border-radius: 50%;
				background: rgba(255, 255, 255, .4);
				transform: scale(0);
				position: absolute;
				opacity: 1;

			}
			.rippleEffect {
				animation: rippleDrop .6s linear;
			}

			@keyframes rippleDrop {
				100% {
					transform: scale(2);
					opacity: 0;
				}
			}
		}

		//Cursor container
		.cursor-container {
			svg {
				position: absolute;
				width: 15px;
				height: 15px;
			}

			.normal-cursor {
				left: -125px;
				bottom: -40px;
			}

			.pointer-cursor {
				opacity: 0;
				bottom: -5px;
				left: -7px;
			}
		}

		.iphone-container {
			@include phone {
				height: 300px;
			}
		}

		.iphone-box {
			position: absolute;
			width: 100%;
			max-width: 480px;
			margin: 0 auto;
			bottom: 0;
			left: auto;
			right: 0;
			height: 100vh;

			@include phone {
				max-width: 300px;
				height: auto;
				left: 0;
			}

			.iphone-box-img2 {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				max-width: 100%;
				margin: 0 auto;
				@include tablet {
					max-width: 220px;
				}
			}

			.iphone-box-img {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				max-width: 100%;
				margin: 0 auto;
				transform: translateY(100%);
				@include tablet {
					max-width: 220px;
				}
			}

			.iphone-box-message {
				position: absolute;
				left: 51px;
				bottom: 270px;
				width: 0;
				max-width: 200px;

				@include tablet {
					left: 155px;
					bottom: 115px;
					max-width: 100px;
				}

				@include phone {
					left: 65px;
				}
			}
		}
	}
	// Add recipe
	.add-recipe-section {
		overflow: hidden;
	}
	.add-recipe {
		.add-recipe-img {
			@include laptop {
				height: 380px;
			}

			@include phone {
				height: 280px;
			}

			.add-recipe-wrapper {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				max-width: 680px;

				@include laptop {
					transform: translateY(-50%) scale(0.7);
					transform-origin: left;
				}

				@include tablet {
					right: 0;
					top: 85px;
					bottom: auto;
					margin: auto;
					max-width: 380px;
				}

				@include phone {
					top: 172px;
					max-width: 220px;
				}
				.add-recipe-zoom {
					position: absolute;
					top: 50%;
					right: -70px;
					transform: translateY(-50%);
					margin-left: 50px;
					height: 488px;
					transform-origin: 50% 50%;

					@include phone {
						height: 386px;
					}
				}
			}
		}

		.add-recipe-title {
			width: 100%;
			max-width: 435px;
			margin-left: 120px;

			@include laptop {
				margin-left: 0;
			}

			@include tablet {
				text-align: center;
				margin: 0 auto;
				padding: 0 25px;
			}
		}
	}

	// Searching
	.searching {
		background-image: url("../img/kompan-smaku/searching-bg.jpg");
		background-repeat: no-repeat;
		background-position: 0 100%;
		@include laptop {
			background-size: 700px;
		}
		@include tablet {
			background-image: none;
		}
		.searching-bg {
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.searching-img {
			display: none;

			@include tablet {
				display: block;
				width: 100%;
				max-width: 420px;
				margin: 0 15px 45px;
			}

			@include phone {
				max-width: 250px;
				margin: 0 5% 0 auto;
			}
		}

		.searching-title {
			font-size: 56px;

			@include tablet {
				font-size: 46px;
			}

			@include phone {
				font-size: 36px;
			}
		}

		.searching-title-box {
			width: 100%;
			max-width: 395px;
			margin: 0 auto;
			text-align: left;

			@include tablet {
				text-align: center;
			}
		}
	}

	// Calendar
	.calendar {
		text-align: center;

		.calendar-desc {
			margin-top: -280px;

			@include tablet {
				margin-top: -180px;
			}

			@include phone {
				margin-top: -50px;
			}
		}

		.calendar-image {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			max-width: 930px;
			height: 460px;
			margin: auto auto 0 auto;

			@include tablet {
				max-width: 500px;
				height: 248px;
			}

			@include phone {
				max-width: 280px;
				height: 138px;
			}

			img {
				width: 100%;
				margin: 0 auto;
			}
		}
	}
	.footer-wrapper {
		background: #fff;
		color: #2f2f2f;
		a {
			color: #000;
		}
	}

	.data-option-section {
		@include phone {
			padding: 45px 0;
		}
	}

	.add-recipe-section {
		@include phone {
			margin: 45px 0;
		}
	}
}

.brief-bs {
	.wrapper {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
	}
	.column {
		//opacity: 0;
		&--left {
			width: 70%;
			padding-right: 100px;
		}
		&--right {
			width: 30%;
		}
	}
	&__title {
		color: #e55915;
		font-size: 40px;
		margin-bottom: 50px;
	}
	&__desc {
		font-size: 18px;
		&--challenge {
			color: #e55915;
			margin-top: 50px;
		}
	}
	@media (max-width: 1280px) {
		&__title {
			font-size: 34px;
			margin-bottom: 40px;
		}
		&__desc {
			font-size: 16px;
			&--challenge {
				margin-top: 40px;
			}
		}
	}
	@media (max-width: 768px) {
		.column {
			&--left {
				width: 100%;
				padding-right: 0;
			}
			&--right {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&__title {
			font-size: 17px;
			margin-bottom: 30px;
		}
		&__desc {
			font-size: 14px;
			&--challenge {
				margin-top: 30px;
			}
		}
		&__image {
			max-width: 60%;
			margin-top: 50px;
		}
	}
}

.claim-bs {
	background: url("../img/bracia-sadownicy/claim-bg.jpg") 100%/cover no-repeat;
	.wrapper {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	&__title {
		color: #fff;
		font-size: 40px;
		margin-bottom: 50px;
	}
	&__desc {
		color: #fff;
		font-size: 18px;
		&:nth-of-type(n+1) {
			margin-top: 30px;
		}
	}
	&__letters {
		max-width: 300px;
		fill: #fff;
		margin-top: 100px;
	}
	@media (max-width: 1280px) {
		&__title {
			font-size: 34px;
			margin-bottom: 40px;
		}
		&__desc {
			font-size: 16px;
			&:nth-of-type(n+1) {
				margin-top: 20px;
			}
		}
	}
	@media (max-width: 768px) {
		&__title {
			font-size: 20px;
			margin-bottom: 20px;
		}
		&__desc {
			font-size: 14px;
			&:nth-of-type(n+1) {
				margin-top: 15px;
			}
		}
		&__letters {
			max-width: 60%;
			margin-top: 50px;
		}
	}
}

/* Filmy (opisy) */

.movie-bs {
	&__apples {
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
	}
	&__desc {
		&:nth-of-type(2) {
			margin-bottom: 60px;
		}
		&--large {
			color: #de5614;
			font-size: 40px;
			margin-bottom: 30px;
		}
		&--normal {
			color: #3b3b3b;
			font-size: 18px;
			font-weight: 300;
			&:nth-of-type(n+2) {
				margin-top: 30px;
			}
		}
	}
	@media (max-width: 1280px) {
		&__desc {
			&:nth-of-type(2) {
				margin-bottom: 50px;
			}
			&--large {
				font-size: 34px;
			}
			&--normal {
				font-size: 16px;
				&:nth-of-type(n+2) {
					margin-top: 20px;
				}
			}
		}
	}
	@media (max-width: 768px) {
		&__apples {
			max-width: 30%;
		}
		&__desc {
			&:nth-of-type(2) {
				margin-bottom: 30px;
			}
			&--large {
				font-size: 17px;
			}
			&--normal {
				font-size: 14px;
				&:nth-of-type(n+2) {
					margin-top: 15px;
				}
			}
			br {
				display: none;
			}
		}
	}
}

/* Filmy */

.movies-bs {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	&__slider[current-lang="pl"] {
		.movies-bs__slide {
			&--step-1 {
				background: url("../img/bracia-sadownicy/step-1-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-2 {
				background: url("../img/bracia-sadownicy/step-2-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-3 {
				background: url("../img/bracia-sadownicy/step-3-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-4 {
				background: url("../img/bracia-sadownicy/step-4-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-5 {
				background: url("../img/bracia-sadownicy/step-5-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-6 {
				background: url("../img/bracia-sadownicy/step-6-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-7 {
				background: url("../img/bracia-sadownicy/step-7-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-8 {
				background: url("../img/bracia-sadownicy/step-8-desktop.jpg") 100%/cover no-repeat;
			}
			@media (max-width: 768px) {
				&--step-1 {
					background: url("../img/bracia-sadownicy/step-1-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-2 {
					background: url("../img/bracia-sadownicy/step-2-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-3 {
					background: url("../img/bracia-sadownicy/step-3-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-4 {
					background: url("../img/bracia-sadownicy/step-4-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-5 {
					background: url("../img/bracia-sadownicy/step-5-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-6 {
					background: url("../img/bracia-sadownicy/step-6-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-7 {
					background: url("../img/bracia-sadownicy/step-7-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-8 {
					background: url("../img/bracia-sadownicy/step-8-mobile.jpg") 100%/cover no-repeat;
				}
			}
		}
	}
	&__slider[current-lang="en"] {
		.movies-bs__slide {
			&--step-1 {
				background: url("../img/bracia-sadownicy/step-1-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-2 {
				background: url("../img/bracia-sadownicy/step-2-desktop.jpg") 100%/cover no-repeat;
			}
			&--step-3 {
				background: url("../img/bracia-sadownicy/step-3-desktop-en.jpg") 100%/cover no-repeat;
			}
			&--step-4 {
				background: url("../img/bracia-sadownicy/step-4-desktop-en.jpg") 100%/cover no-repeat;
			}
			&--step-5 {
				background: url("../img/bracia-sadownicy/step-5-desktop-en.jpg") 100%/cover no-repeat;
			}
			&--step-6 {
				background: url("../img/bracia-sadownicy/step-6-desktop-en.jpg") 100%/cover no-repeat;
			}
			&--step-7 {
				background: url("../img/bracia-sadownicy/step-7-desktop-en.jpg") 100%/cover no-repeat;
			}
			&--step-8 {
				background: url("../img/bracia-sadownicy/step-8-desktop-en.jpg") 100%/cover no-repeat;
			}
			@media (max-width: 768px) {
				&--step-1 {
					background: url("../img/bracia-sadownicy/step-1-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-2 {
					background: url("../img/bracia-sadownicy/step-2-mobile.jpg") 100%/cover no-repeat;
				}
				&--step-3 {
					background: url("../img/bracia-sadownicy/step-3-mobile-en.jpg") 100%/cover no-repeat;
				}
				&--step-4 {
					background: url("../img/bracia-sadownicy/step-4-mobile-en.jpg") 100%/cover no-repeat;
				}
				&--step-5 {
					background: url("../img/bracia-sadownicy/step-5-mobile-en.jpg") 100%/cover no-repeat;
				}
				&--step-6 {
					background: url("../img/bracia-sadownicy/step-6-mobile-en.jpg") 100%/cover no-repeat;
				}
				&--step-7 {
					background: url("../img/bracia-sadownicy/step-7-mobile-en.jpg") 100%/cover no-repeat;
				}
				&--step-8 {
					background: url("../img/bracia-sadownicy/step-8-mobile-en.jpg") 100%/cover no-repeat;
				}
			}
		}
	}
	&__slide {
		width: 100%;
		min-height: 100vh;
		background-position: center !important;
		position: relative;
		overflow: hidden;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .5);
		}
	}
	&__nav {
		position: absolute;
		left: 50%;
		bottom: 10%;
		transform: translate(-50%, 0);
		display: flex;
		justify-content: center;
	}
	&__btn {
		border: none;
		background-color: transparent;
		outline: none;
		cursor: pointer;
		transition: all .3s ease-in-out;
		position: relative;
		&--active {
			.movies-bs__circle {
				background-color: #fff;
			}
			.movies-bs__title {
				opacity: 1;
				transition-delay: .3s;
			}
		}
		&:nth-of-type(n+2) {
			margin-left: 30px;
		}
	}
	&__circle {
		width: 12px;
		height: 12px;
		background-color: transparent;
		border-radius: 50%;
		border: 1px #fff solid;
		position: relative;
		transition: all .3s ease-in-out;
	}
	&__title {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(50px, -50%);
		color: #fff;
		font-size: 11px;
		text-transform: uppercase;
		white-space: nowrap;
		opacity: 0;
		transition: opacity .3s ease-in-out;
	}
	&__play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 150px;
		background-color: transparent;
		border: none;
		padding: 0;
		outline: none;
		z-index: 100;
	}
	&__icon {
		circle, path {
			transition: all .3s ease-in-out;
		}
		circle {
			fill: none;
			stroke: #f4f4f4;
			stroke-miterlimit: 10;
		}
		path {
			fill: #f4f4f4;
		}
		&:hover, &:focus {
			circle {
				stroke: #ff9f00;
			}
			path {
				fill: #ff9f00;
			}
		}
	}
	&__back {
		position: absolute;
		top: 10%;
		right: 50px;
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
		background-color: transparent;
		border: none;
		padding: 0;
		outline: none;
	}
	@media (max-width: 1280px) {
		&__play {
			width: 130px;
			height: 130px;
		}
	}
	@media (max-width: 768px) {
		&__play {
			width: 70px;
			height: 70px;
		}
		&__btn {
			&:nth-of-type(n+2) {
				margin-left: 20px;
			}
		}
		&__title {
			left: 50%;
			transform: translate(-50%, -30px);
		}
		&__back {
			font-size: 12px;
		}
	}
	@media (max-width: 480px) {
		&__play {
			width: 50px;
			height: 50px;
		}
	}
}

.movies-bs {
	&__full {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(42, 42, 42, .9);
		transform: scale(0);
		transition: all .3s ease-in-out;
		z-index: 200;
		&--is-open {
			transform: scale(1);
		}
	}
	&__content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* Social Media */

.web-bs {
	.wrapper {
		text-align: center;
	}
	&__title {
		color: #fff;
		font-size: 48px;
	}
	&__desc {
		color: #fff;
		font-size: 18px;
		line-height: 1.8;
		margin: 50px 0;
	}
	&__link {
		display: inline-block;
		margin-bottom: 200px;
		overflow: hidden;
	}
	&__images {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-end;
	}
	&__image {
		&--2 {
			margin-left: 50px;
		}
	}
	@media (max-height: 1100px) and (orientation: landscape) {
		&__image {
			max-width: 220px;
		}
	}
	@media (max-width: 1280px) {
		&__title {
			font-size: 40px;
		}
		&__desc {
			font-size: 16px;
		}
		&__link {
			margin-bottom: 150px;
		}
	}
	@media (max-width: 768px) {
		&__title {
			font-size: 17px;
		}
		&__desc {
			font-size: 14px;
			margin: 30px 0;
		}
		&__link {
			margin-bottom: 100px;
		}
		&__social {
			max-width: 80%;
		}
		&__image {
			max-width: 150px;
			&--2 {
				margin-left: 20px;
			}
		}
	}
	@media (max-width: 360px) {
		&__image {
			max-width: 100px;
		}
	}
}