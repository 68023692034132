// Case 16
// Morliny

// Variables
$white: #fff;
$font-light: 300;


.case16.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important;

  // Elements
  body {
    overflow-x: hidden !important;
  }

  #fp-nav ul li.visited a span, #fp-nav ul li a:hover span, #fp-nav ul li a.active span {
    background: #ed2327;
  }

  #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion;
  }

  .lang {
    mix-blend-mode: exclusion;

    span {
      color: #fff;
    }
  }

  .arrow-line {
    stroke: #fff;
  }

  .logo-wrapper, .case-arrow-back, .menu-button {
    mix-blend-mode: exclusion;
  }

  span,
  p,
  h1 {
    &.color-white {
      color: #FFFFFF;
    }

    &.font-light {
      font-weight: $font-light;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }

  .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
  }


  // Hello section
  .hello-morliny {
    background: url("../img/morliny/header.jpg") 100%/cover no-repeat;
    position: relative;
    text-align: center;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #13132C;
      opacity: 65%;
    }

    .morliny-small-description {
      font-size: 13px;

      @include tablet {
        font-size: 11px;
      }
    }

    h1,
    p {
      position: relative;
      z-index: 100;
    }

    h1 {
      font-size: 65px;
      letter-spacing: 1px;

      @include laptop {
        font-size: 56px;
        margin-bottom: 50px;
      }

      @include tablet {
        font-size: 48px;
      }

      @include phone {
        font-size: 36px;
      }
    }
  }
}

// Brief section
.brief-morliny {
  .wrapper {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  .column {
    &--left {
      width: 60%;
      padding-right: 100px;
    }

    &--right {
      width: 40%;
    }

    &--left-2 {
      width: 60%;
      padding-right: 100px;
    }

    &--right-2 {
      width: 40%;
    }
  }

  &__title {
    color: #183479;
    font-size: 24px;
    margin-bottom: 50px;
  }

  &__desc {
    font-size: 18px;
  }

  @media (max-width: 1280px) {
    &__title {
      font-size: 20px;
      margin-bottom: 40px;
    }

    &__desc {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .column {
      &--left {
        width: 100%;
        padding-right: 0;
      }

      &--right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--left-2 {
        width: 100%;
        padding-right: 0;
      }

      &--right-2 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 50%;
          margin-top: 40px;
        }
      }


    }
    &__title {
      font-size: 14px;
      margin-bottom: 30px;
    }
    &__desc {
      font-size: 14px;

    }
    &__image {
      max-width: 100%;
      margin-top: 50px;
      padding-left: 40px;
    }
  }
}

.claim-morliny {
  background: url("../img/morliny/bg.jpg") 100%/cover no-repeat;

  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px;
  }

  &__desc {
    color: #fff;
    font-size: 18px;

    &:nth-of-type(n+1) {
      margin-top: 30px;
    }
  }

  @media (max-width: 1280px) {
    &__title {
      font-size: 34px;
      margin-bottom: 40px;
    }
    &__desc {
      font-size: 16px;

      &:nth-of-type(n+1) {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 20px;
      margin-bottom: 20px;
    }
    &__desc {
      font-size: 14px;

      &:nth-of-type(n+1) {
        margin-top: 15px;
      }
    }
  }
}

// Movies description
.movie-morliny {
  &__desc {
    &:nth-of-type(2) {
      margin-bottom: 60px;
    }

    &--large {
      color: #183479;
      font-size: 40px;
      margin-bottom: 30px;
    }

    &--normal {
      color: #3b3b3b;
      font-size: 18px;
      font-weight: 300;

      &:nth-of-type(n+2) {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 1280px) {
    &__desc {
      &:nth-of-type(2) {
        margin-bottom: 50px;
      }

      &--large {
        font-size: 34px;
      }

      &--normal {
        font-size: 16px;

        &:nth-of-type(n+2) {
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    &__desc {
      &:nth-of-type(2) {
        margin-bottom: 30px;
      }

      &--large {
        font-size: 17px;
      }

      &--normal {
        font-size: 14px;

        &:nth-of-type(n+2) {
          margin-top: 15px;
        }
      }

      br {
        display: none;
      }
    }
  }
}


/* Social Media */
.web-morliny {
  background-color: #183479;

  .wrapper {
    text-align: center;
  }

  &__title {
    color: #fff;
    font-size: 48px;
  }

  &__desc {
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    margin: 50px 0;
  }

  &__link {
    display: inline-block;
    margin-bottom: 200px;
    overflow: hidden;
  }

  &__images {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }

  &__image {
    &--2 {
      margin-left: 50px;
    }
  }

  @media (max-height: 1100px) and (orientation: landscape) {
    &__image {
      max-width: 550px;
    }
  }
  @media (max-width: 1280px) {
    &__title {
      font-size: 40px;
    }
    &__desc {
      font-size: 16px;
    }
    &__link {
      margin-bottom: 200px;
    }
  }
  @media (max-width: 768px) {
    &__title {
      font-size: 17px;
    }
    &__desc {
      font-size: 14px;
      margin: 30px 0;
    }
    &__link {
      margin-bottom: 100px;
    }
    &__social {
      max-width: 80%;
    }
    &__image {
      max-width: 290px;

      &--2 {
        margin-left: 20px;
      }
    }
  }
  @media (max-width: 360px) {
    &__image {
      max-width: 250px;
    }
  }
}

.footer-wrapper {
  background: #fff;
  color: #2f2f2f;

  a {
    color: #000;
  }
}