// Case 10
// Zyrtec UCB

.case10.subpage  {
  color: rgb(38, 40, 50);
  p {
    margin: 0 0 10px;
  }

  .logo-wrapper, .case-arrow-back, .menu-button {
    mix-blend-mode: exclusion;
  }

  .arrow-line {
    stroke: #fff;
  }

  .lang {
    mix-blend-mode: exclusion;
    span {
      color: #fff;
    }
  }

  /* Variables
   ----------------------------- */

  $white: #fff;
  $extra-light-grey: #f2f9fc;
  $light-grey: #cdd1e1;
  $middle-grey: #97989e;

  $extra-light-blue: #edf3f9;
  $middle-blue: #5799fc;
  $normal-blue: #105d98;
  $several-blue: #005aaa;
  $color1: #045593;
  $light-deep-blue: #1f242f;
  $deep-blue: #262832;
  $dark-blue: #0b1720;

  $light-dark-blue: #202430;
  $black: #000;

  $color2: #67ab52;
  $middle-green: #5ca14e;

  $work-sans: 'Work Sans', sans-serif;

  $font-extra-light: 100;
  $font-light: 300;
  $font-reqular: 400;
  $font-medium: 500;
  $font-semi-bold: 600;
  $font-bold: 700;
  $font-extra-bold: 800;

  .ucb-btn {
    font-weight: 800;
    text-align: center;
    color: $white;
    padding: 14px 50px;
    background-color: $normal-blue;
    border-radius: 25px;
    transition: .3s ease;
    text-decoration: none;

    @include tablet {
      padding: 11px 27px;
    }

    &:hover,
    &:focus {
      background-color: $white;
      color: $normal-blue;
      text-decoration: none;
    }
  }

  // Helpers

  .ucb-small-description {
    font-size: 13px;

    @include tablet {
      font-size: 11px;
    }
  }

  .ucb-description {
    font-size: 18px;

    @include tablet {
      font-size: 16px;
    }

    @include phone {
      font-size: 14px;
    }
  }

  .ucb-large-description {
    font-size: 20px;

    @include tablet {
      font-size: 18px;
    }

    @include phone {
      font-size: 16px;
    }
  }

  .ucb-small-header {
    font-size: 36px;

    @include tablet {
      font-size: 32px;
    }

    @include phone {
      font-size: 28px;
    }
  }

  .ucb-header {
    font-size: 48px;

    @include tablet {
      font-size: 40px;
    }

    @include phone {
      font-size: 32px;
    }
  }

  span,
  p,
  h1 {
    /* Colors */
    &.color-white {
      color: $white;
    }

    &.color-dark {
      color: $black;
    }

    /* Weight */
    &.font-extra-light {
      font-weight: $font-extra-light;
    }

    &.font-light {
      font-weight: $font-light;
    }

    &.font-reqular {
      font-weight: $font-reqular;
    }

    &.font-medium {
      font-weight: $font-medium;
    }

    &.font-semi-bold {
      font-weight: $font-semi-bold;
    }

    &.font-bold {
      font-weight: $font-bold;
    }

    &.font-extra-bold {
      font-weight: $font-extra-bold;
    }

    /* Text transform */
    &.uppercase {
      text-transform: uppercase;
    }
  }

  .relative {
    position: relative;
  }

  .no-height-mobile {
    @include tablet {
      height: auto !important;
    }
  }

  body {
    overflow-x: hidden !important;
  }

  letter-spacing: 1px;

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .top-video {
    z-index: -1;

    @include tablet {
      display: none;
    }
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hello-ucb-bg {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $dark-blue;
      opacity: .85;

      @include tablet {
        content: none;
      }
    }

    @include tablet {
      background-image: url("../img/zyrtec/zyrtec_ucb.png") !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .hello-ucb {
    text-align: center;
    z-index: 100;

    > .upc-small-description,
    h1 {
      position: relative;
      letter-spacing: 2px;
    }

    h1 {
      font-size: 65px;
      letter-spacing: 8px;
      margin-bottom: 10%;

      @include phone {
        font-size: 36px;
      }

      @include tablet {
        font-size: 48px;
        margin-bottom: 20%;
        letter-spacing: 5px;
      }
    }

    .know-more {
      font-size: 16px;
      margin-bottom: 45px;

      @include tablet {
        margin-bottom: 35px;
        font-size: 14px;
      }
    }

    .ucb-btn-box {
      position: absolute;
      bottom: 15%;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;

      @include tablet {
        position: relative;
      }
    }
  }

  //  Section 2
  .ucb-brief {
    width: 100%;
    max-width: 1066px;
    margin: 0 auto;
    padding-top: 145px;
    padding-bottom: 175px;
    text-align: left;
    padding-left: auto;
    padding-right: auto;

    @include laptop {
      text-align: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    @include tablet {
      text-align: center;
      padding-top: 105px;
      padding-bottom: 125px;
    }

    @include phone {
      padding-top: 85px;
      padding-bottom: 85px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .ucb-small-description {
      margin-bottom: 55px;

      @include phone {
        margin-bottom: 15px;
      }

      @include tablet {
        margin-bottom: 35px;
      }
    }

    .ucb-small-headers {
      margin-bottom: 50px;
      font-size: 30px;
      line-height: 1.3;

      @include phone {
        margin-bottom: 20px;
        font-size: 24px;
      }

      @include tablet {
        font-size: 17px;
        margin-bottom: 50px;
      }
    }

    .ucb-description {
      margin-bottom: 95px;
      color: $light-deep-blue;
      line-height: 2;

      @include phone {
        margin-bottom: 45px;
      }

      @include tablet {
        margin-bottom: 65px;
      }
    }
  }

  .ucb-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include phone {
      flex-direction: column;
    }

    .ucb-col {
      margin: 0;
      text-align: center;

      @include phone {
        margin-bottom: 15px;
      }

      &:nth-child(1) {
        @include tablet {
          flex: 1 1 100%;
          width: 100%;
        }

        flex: 1 1 40%;
        width: 40%;
        margin-top: 35px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @include tablet {
          flex: 1 1 100%;
          width: 100%;
        }

        flex: 1 1 20%;
        width: 20%;
      }

      > img {
        display: block;
        margin: 0 auto 25px 0;

        @include phone {
          margin: 0 auto 10px;
        }
      }

      > img + .zyrtec-desc {
        text-align: left;
        margin: 0;
        max-width: 250px;

        @include phone {
          text-align: center;
        }

        @include tablet {
          margin: 0 auto;
        }
      }
    }
  }

  .zyrtec-desc {
    font-size: 14px;

    @include tablet {
      font-size: 12px;
    }
  }

  .ucb-number {
    color: $several-blue;
    font-size: 52px;

    @include phone {
      font-size: 32px;
    }

    @include tablet {
      font-size: 42px;
    }
  }

  // Section 3
  .ucb-communication {
    color: $light-dark-blue;
    text-align: left;
    max-width: 90%;
    margin: auto;

    @include tablet {
      max-width: 100%;
      text-align: center;
    }

    .ucb-header {
      margin: 0 auto 25px 0;

      @include tablet {
        margin-bottom: 15px;
      }
    }

    .ubc-large-description,
    .ucb-header {
      max-width: 380px;
      line-height: 1.8;

      @include tablet {
        margin: 0 auto;
        max-width: 300px;
      }
    }
  }

  // SVG
  .ubc-svg-box {
    height: 300px;
    width: 400px;
    margin: 0 auto;

    @include tablet {
      width: 300px;
    }

    @include phone {
      margin-top: 45px;
    }

    svg {
      width: 100%;
      margin: 0 auto;
    }
  }

  .ubc-svg-green,
  .ubc-svg-blue {
    position: absolute;

    svg {
      width: 100%;
      opacity: 0;

      @include phone {
        opacity: 1;
      }
    }

    p {
      position: absolute;
      margin: 0 auto;
      text-align: left;
      line-height: 1.2;
      opacity: 0;

      @include phone {
        opacity: 1;
      }
    }
  }

  .ubc-svg-green {
    top: -45px;
    left: 0;
    right: 60px;

    @include phone {
      left: 0;
      right: 60px;
    }

    @include tablet {
      top: 0;
      left: 0;
      right: 60px;
    }

    svg {
      max-width: 320px;

      @include tablet {
        max-width: 250px;
      }

      @include phone {
        max-width: 180px;
      }
    }

    p {
      top: 105px;
      right: auto;
      left: 70px;
      color: #caeaee;
      font-size: 42px;

      @include tablet {
        left: 54px;
        top: 85px;
        font-size: 32px;
      }

      @include phone {
        left: 64px;
        top: 58px;
        font-size: 24px;
      }
    }
  }

  .ubc-svg-blue {
    top: 116px;
    left: 208px;
    right: 0;

    @include tablet {
      top: 114px;
      left: 148px;
      right: 0;
    }

    @include phone {
      top: 78px;
      left: 105px;
      right: 0;
    }

    svg {
      transform: rotate(-180deg);
      max-width: 210px;

      @include tablet {
        max-width: 150px;
      }

      @include phone {
        max-width: 120px;

        transform: rotate(-5deg);
      }
    }

    p {
      color: #96d5d1;
      font-size: 24px;
      top: 87px;
      left: 34px;
      //left: auto;
      right: auto;
      //right: 52px;

      @include tablet {
        top: 68px;
        right: 34px;
        font-size: 20px;
      }

      @include phone {
        top: 45px;
        right: auto;
        left: 64px;
        //right: 62px;
        font-size: 16px;
      }
    }
  }

  // Section 4
  .ubc-fb {
    text-align: center;

    .ubc-fb-title {
      width: 100%;
      max-width: 740px;
      margin-bottom: 100px;

      @include tablet {
        margin-bottom: 50px;
      }

      @include phone {
        margin-bottom: 25px;
      }
    }
  }

  .ubc-second-box,
  .ubc-fb-title {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px;
  }

  .ubc-second-box {
    width: 100%;
    max-width: 1250px;
    height: 480px;
    margin: 0 auto;
    bottom: 0;

    @include laptop {
      max-width: 1050px;
      height: 350px;
    }
  }

  .ucb-fb-title {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    //top: 140px;
    height: 300px;

    @include tablet {
      //top: 100px
    }

    @include phone {
      top: 15%;
    }

    .ucb-large-description {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .ubc-phone-container {
    width: 100%;
    max-width: 1250px;
  }

  .ubc-slider-phone {
    text-align: center;
    text-transform: uppercase;
  }

  .ubc-pagination {
    position: absolute;
    top: 65%;
    width: 100%;

    @include desktop {
      top: 50%;
    }

    > .pagination-arrow {
      position: absolute;
      cursor: pointer;
      transition: .3s ease;

      &.left {
        left: -35px;

        @include desktop {
          left: -15px;
        }

        @include phone {
          left: 0;
        }

        &:hover {
          transform: translateX(-5px);
        }
      }

      &.right {
        right: -35px;

        @include desktop {
          right: -15px;
        }

        @include tablet {
          right: 0;
        }

        @include phone {
          right: -15px;
        }

        &:hover {
          transform: translateX(5px);
        }
      }

      svg {
        width: 55px;
        height: 15px;

        @include desktop {
          width: 35px;
          height: 10px;
        }
      }
    }
  }

  .ubc-iphone-box {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    //margin-bottom: -56px;

    @include laptop {
      max-width: 220px;
    }

    @include tablet {
      margin-bottom: 0;
    }

    @include phone {
      margin-bottom: -25px;
      //margin-bottom: -125px;
    }
  }

  .ubc-slide-phone {
    p {
      margin-bottom: 40px;

      @include tablet {
        margin-bottom: 30px;
      }
    }

    svg {
      margin-bottom: -165px;

      @include desktop {
        margin-bottom: -125px;
      }

      @include laptop {
        margin-bottom: -121px;
      }

      @include tablet {
        margin-bottom: -121px;
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0 auto;
      max-width: 262px;

      @include laptop {
        max-width: 190px;
      }

      @include phone {
        max-width: 193px;
      }
    }
  }

  // Section 5
  .ubc-page {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    @include tablet {
      max-width: 950px;
    }

    // Sections
    .ubc-page-img,
    .ubc-page-description {
      width: 100%;
      margin: 0 auto;
      letter-spacing: 0.5px;
    }
    // END Sections

    .ubc-page-img {
      max-width: 667px;

      @include tablet {
        max-width: 460px;
      }

      @include phone {
        max-width: 320px;
        margin-bottom: 50px;
      }
    }

    .ubc-page-description {
      max-width: 400px;
      text-align: left;

      @include tablet {
        max-width: 360px;
      }

      @include phone {
        max-width: 320px;
        text-align: center;
      }

      .ucb-small-header {
        margin-bottom: 30px;
        color: $light-dark-blue;

        @include phone {
          margin-bottom: 15px;
        }
      }

      .ucb-description {
        margin-bottom: 50px;
        color: $light-deep-blue;
        line-height: 1.7;

        @include tablet {
          margin-bottom: 35px;
        }

        @include phone {
          margin-bottom: 20px;
        }
      }

      a {
        color: #005496;
        text-decoration: none;
        transition: .3s ease;

        &:hover {
          color: $normal-blue;
        }
      }
    }
  }
  // Section 6
  .ucb-post {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;

    @include tablet {
      max-width: 950px;
    }

    .ucb-post-description {
      max-width: 545px;
      text-align: left;
      color: $black;
      line-height: 2;
      margin: 0 auto;
      padding-left: 25px;
      padding-right: 25px;

      @include tablet {
        max-width: 360px;
        order: 1;
      }

      @include phone {
        max-width: 320px;
        margin-bottom: 50px;
        text-align: center;
        order: 2;
      }

      .ucb-header {
        margin-bottom: 25px;

        @include tablet {
          margin-bottom: 10px;
        }
      }
    }

    .ucb-post-img-box {
      height: 100vh;
      order: 2;

      @include phone {
        height: 100%;
        order: 1;
      }
    }

    .ucb-post-img {
      margin-left: auto;
      margin-right: -30px;
      max-width: 500px;

      @include laptop {
        //position: absolute;
        //left: auto;
        //right: 0;
        //top: 0;
        //margin: 0;
        width: 400px;
        margin: 0 auto;
      }

      @include phone {
        position: relative;
        max-width: 320px;
        margin-bottom: 30px;
      }
    }
  }
  // Section 7
  .ucb-csr {
    background-image: url("../img/zyrtec/ucb-kid-2.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 100%;

    @include phone {
      background-image: url("");
    }

    .ucb-csr-img {
      height: 100vh;
      width: 100%;

      @include phone {
        background-image: url("../img/zyrtec/ucb-kid.png");
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px;
        margin-bottom: 50px;
      }

      // svg
      .ubc-svg-green,
      .ubc-svg-blue {
        top: auto;
        left: auto;
        visibility: hidden;

        @include phone {
          visibility: visible;
        }
      }

      .ubc-svg-green {

        @include tablet {
          right: 10px;
          bottom: 45px;
        }

        @include phone {
          right: 60px;
          bottom: 0;
        }
      }

      .ubc-svg-blue {
        right: 0;
        bottom: -9px;

        .font-medium {
          text-align: center;
          top:  50px;
          right: 25px;
        }
      }

      // Like and Share
      .like-share-box {
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        position: absolute;
        right: 0;
        left: 30px;
        max-width: 120px;
        text-align: center;

        @include phone {
          font-size: 20px;
        }

        &.like-part {
          top: 55px;
        }

        &.share-part {
          top: 88px;
        }

        svg {
          width: 17px;
        }
      }
    }

    .ucb-csr-description {
      width: 100%;
      max-width: 400px;
      height: 340px;
      margin: auto;
      text-align: left;
      padding-left: 25px;
      padding-right: 25px;


      @include tablet {
        max-width: 360px;
      }

      @include phone {
        max-width: 320px;
        height: auto;
        margin-bottom: 50px;
        text-align: center;
      }

      .ucb-small-header {
        margin-bottom: 30px;
        color: $light-dark-blue;

        @include phone {
          margin-bottom: 15px;
        }
      }

      .ucb-description {
        margin-bottom: 50px;
        color: $light-deep-blue;
        line-height: 2;

        @include tablet {
          margin-bottom: 35px;
        }

        @include phone {
          margin-bottom: 20px;
        }
      }
    }
  }
  // Section 8
  .lookbook-video {
    overflow: hidden;
  }
  .ucb-yt {
    //position: relative;
    width: 100%;
    height: 100vh;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $dark-blue;
      opacity: .65;
      z-index: 100;
    }

    .embed-responsive-item {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .mbl-video-svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: 100;
      text-align: center;
      transition: .3s ease;

      svg {
        width: 100px;
        margin-bottom: 30px;
        transition: .3s ease;
        cursor: pointer;

        @include tablet {
          width: 75px;
          margin-bottom: 10px;
        }

        &:hover {
          transform: scale(1.1);
        }
      }

      .see-yt {
        font-size: 12px;
        margin-bottom: 40px;

        @include phone {
          margin-bottom: 25px;
        }
      }

      p {
        line-height: 1;
        white-space: nowrap;
      }
    }
  }
  // Section 9
  .ucb-mag-box {
    padding-top: 45px;
  }

  .ucb-mag {
    width: 100%;
    max-width: 1065px;
    margin: -100px auto 0;
    padding: 0 25px;
    text-align: center;

    @include tablet {
      margin: -50px auto 0;
    }

    .ucb-header {
      margin-bottom: 30px;

      @include tablet {
        margin-bottom: 15px;
      }
    }

    .ucb-description {
      margin-bottom: 60px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 1.5;

      @include laptop {
        margin-bottom: 40px;
      }
    }

    .ucb-mag-logo {
      @include laptop {
        width: 100%;
        max-width: 120px;
        margin: 0 auto;
      }
    }

    .ucb-mag-image {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto auto 0;
      max-width: 650px;

      @include laptop {
        max-width: 450px;
      }

      @include phone {
        max-width: 250px;
      }
    }
  }
  .footer-wrapper {
    background: rgb(242, 249, 252);
    color: #2f2f2f;
    a {
      color: #005aaa;
    }
  }

  .macbook-section {
    @include phone {
      padding: 25px 15px;
    }
  }
}

