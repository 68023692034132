// Homepage

body.dark-ui {
  .logo-wrapper .logo-svg {
    fill: #000;
  }
  .menu-button .bar {
    background: #000;
  }
  .lang a span {
    color: #000;
  }
  .slick-dots li.slick-active button:before {
    color: #000;
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: 'slick';
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li button:before
  {
    font-size: 10px;
    line-height: 20px;
  }
}

#page-wrapper {
  width: 100%;
  //height: 100vh;
  height: 100.1vh;
  background-color: #1c1b1d;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition: background-color 0.5s ease;
  &.subpage {
    display: block;
    height: auto;
  }
  &.menu-page {
    padding-top: 75px;
    &:after {
      content: "";
      display: none;
      background: #1c1b1d;
      width: 100%;
      height: 75px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      @include phone {
        display: block;
      }
    }
  }
}

#page-wrapper.home-homepage {
  .social-icon {
    fill: #575757;
  }
  .case-arrow {
    display: none;
  }
}
#page-wrapper.case1-homepage {
  height: 100vh;
  background-color: #1A191C;
  .copyrights, .cookies-button, .case-title, .slogan {
    color: #fff;
  }
  .duck-svg, .sound-svg, .logo-svg {
    fill: #fff;
  }
  .case-subtitle {
    color: #72675b;
  }
}

#page-wrapper.case2-homepage {
  height: 100vh;
  background-color: #edf3f9;
  .copyrights, .cookies-button, .case-title, .slogan, .case-title-subpage {
    color: #252730;
  }
  .social-icon, .logo-svg, .pin-svg {
    fill: #252730 !important;
  }
  .dot-svg, .arrow-line {
    stroke: #252730 !important;
  }
  .bar {
    background: #252730;
  }
  .pulse {
    border: solid 1px #252730;
  }
  .case-subtitle {
    color: #8a8383;
  }
  .lang a {
    color: #000;
  }

  .lang span {
    color: #252730;
  }
}

#page-wrapper.case3-homepage {
  height: 100vh;
  background-color: #000;
  .case-subtitle {
    color: #545b72;
  }
  .case-title, .copyrights, .cookies-button, .slogan {
    color: #fff;
  }
}

#page-wrapper.case4-homepage {
  height: 100vh;
  background-color: #f2f2f2;

  .copyrights, .cookies-button .case-title, .slogan, .case-title-subpage {
    color: #252730;
  }
  .social-icon, .logo-svg, .pin-svg {
    fill: #252730 !important;
  }
  .dot-svg, .arrow-line {
    stroke: #252730 !important;
  }
  .bar {
    background: #252730;
  }
  .pulse {
    border: solid 1px #252730;
  }
  .case-subtitle {
    color: #8a8383;
  }
  .lang a {
    color: #000;
  }
  .lang span {
    color: #252730;
  }
}

#page-wrapper.case5-homepage {
  height: 100vh;
  background-color: #000r;
  .copyrights, .cookies-button, .case-title, .slogan {
    color: #fff;
  }
  .duck-svg, .sound-svg, .logo-svg {
    fill: #fff;
  }
  .case-subtitle {
    color: #72675b;
  }
}

.case-title {
  position: absolute;
  left: 50%;
  top: 29%;
  color: #1c1b1d;
  transform: translateX(-50%);
  font-size: 60px;
  letter-spacing: 5px;
  font-weight: 200;
  text-align: center;
  // visibility: hidden;
  opacity: 0;
  @include laptop {
    font-size: 50px;
  }
}

.case-subtitle {
  position: absolute;
  left: 50%;
  top: 22%;
  transform: translateX(-50%);
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #948076;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px 0;
  visibility: hidden;
  opacity: 0;
  @include tablet {
    display: none;
  }
}

.case-arrow {
  position: absolute;
  left: 50%;
  top: 70%;
  width: 55px;
  height: 55px;
  padding: 15px;
  display: block;
  opacity: 0;
  transform: translateX(-50%);
  @include tablet {
    display: none;
  }
  svg {
    display: block;
  }
  .arrow-line {
   fill: none;
   stroke: #fff;
   stroke-width: 1.1;
   stroke-linecap: round;
   stroke-linejoin: round;
   stroke-miterlimit: 10;
  }
}

.case-title-subpage {
  text-align: center;
  font-size: 60px;
  letter-spacing: 5px;
  font-weight: 200;
  padding: 20px 0 0 0;
  margin: 0 0 100px 0;
  width: 100%;
  position: relative;
  z-index: 1;
}

.case-subtitle-subpage {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #948076;
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  position: relative;
  z-index: 1;
  @include tablet {
    padding: 0;
  }
}

/*
 * Pulse animation
*/

@keyframes pulsate {
  0% {transform: scale(0.2, 0.2); opacity: 0.0;}
  50% {opacity: 8.0;}
  100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}

.time {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.canvas {
  width: 1170px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    transform: scale(0.8);
  }
  .btn-case {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: none;
    position: absolute;
    left: -25px;
    top: -23px;
    z-index: 5;
    cursor: pointer;
    &:hover {
      .pulse {
        display: block;
      }
    }
    .pulse {
      border: 1px solid #fff;
      border-radius: 50%;
      height: 38px;
      width: 38px;
      animation: pulsate 1.5s ease-out;
      animation-iteration-count: infinite;
      opacity: 0.0;
      position: absolute;
      top: 15%;
      left: 12%;
      //transform: translate(-50%,-50%);
      z-index: 3;
      display: none;
    }
  }
  .btn-case1 {
    left: 206px;
  }
  .btn-case2 {
    left: 444px;
  }
  .btn-case3 {
    left: 675px;
  }
  .btn-case4 {
    left: 913px;
  }
  .btn-case5 {
    left: 1145px;
  }
  #case-line {
    position: relative;
    z-index: 1;
  }
  .dot-svg {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    stroke-width: 1px;
    stroke-miterlimit: 10;
    opacity: 0.5;
  }
  .case-svg {
    stroke-width: 2px;
    stroke-miterlimit: 10;
    opacity: 0.7;
  }
  .pin-wrapper {
    width: 1195px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    #controls {
      width: 100%;
      .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
        background: #eee;
        border: 0;
      }
      .ui-widget-content {
        border: 0;
        background: rgba(255, 255, 255, 0);
        color: #333;
        height: 1px;
      }
      .ui-slider-handle {
        border: 0;
        outline: 0;
        background: none;
        cursor: pointer;
      }
    }
  }
  .pin-trigger {
    width: 25px;
    overflow: visible;
    .pin-svg {
      fill: #fff;
    }
  }
}

.main-content {
  width: 100%;
}

.non-ios {
  display: none;
  &--visible {
    display: block;
  }
}

.ios {
  display: none;
  .tilt-back {
    animation: animate 1.5s infinite;
    @keyframes animate {
      0% { transform: translateX(0) }
      50% { transform: translateX(-.3rem) }
      100% { transform: translateX(0) }
    }
  }
  &--visible {
    display: block;
  }
}