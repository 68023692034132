// Header

.logo-wrapper {
  width: 135px;
  position: fixed;
  top: 100px;
  left: 100px;
  cursor: pointer;
  z-index: 10;
  display: block;
  overflow: visible !important;
  svg {
    position: relative;
    z-index: 2;
  }
  .logo-svg {
      fill: #fff;
  }
}

.subpage {
  .lang {
    right: 235px;
  }
}

.lang {
  z-index: 10;
  position: fixed;
  top: 103px;
  right: 170px;

  &__button {
    cursor: pointer;
    margin-top: 10px;
    transition: .3s ease;

    &:nth-child(1) {
      margin-right: 8px;
    }

    &:nth-child(2) {
      margin-right: 8px;
    }

    &:hover {
      transform: translateY(-3px);
    }

    &.is-active {
      span {
        font-weight: 800;
      }
    }
  }
  &__list {
    position: absolute;
    top: -10px;
    //opacity: 0;
    //visibility: hidden;

    display: flex;
    left: -56px;
  }
  &__active {
    span {
      cursor: pointer;
    }
  }
  &--case {
    right: 240px;
  }
  a {
    margin-top:10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    &:last-child {
      //margin-left: 10px;
    }
    span {
      position: relative;
      bottom: 0;
      transition: 0.3s;
    }
    //&:hover {
    //  span {
    //    bottom: 5px;
    //  }
    //}
  }
}


.btn-menu {
  display: block;
  position: fixed;
  top: 135px;
  right: 100px;
  cursor: pointer;
  margin-top: 5px;
  padding: 15px;
  z-index: 11;
  svg {
    width: 23px;
  }
  .circle, .linesX {
    visibility: hidden;
  }
}

.case-arrow-back {
  position: fixed;
  right: 170px;
  top: 92px;
  width: 40px;
  height: 40px;
  display: block;
  z-index: 10;
  cursor: pointer;
  @include phone {
    display: none;
  }
  svg {
    display: block;
  }
  .arrow-line {
   fill:none;
   stroke: #000;
   stroke-width: 1.3;
   stroke-linecap: round;
   stroke-linejoin: round;
   stroke-miterlimit: 10;
  }
  &.light {
    .arrow-line {
      stroke: #fff;
    }
  }
}

.menu-button {
  display: block;
  position: fixed;
  top: 85px;
  right: 100px;
  cursor: pointer;
  margin-top: 5px;
  padding: 15px;
  z-index: 110;
  touch-action: manipulation;
  // @include phone {
  //   display: none;
  // }
  .bar {
    display: block;
    width: 23px;
    height: 2px;
    background: #fff;
    position: relative;
    left: 0;
    top: 0;
    transition: 0.3s ease;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  &.open {
    .bar {
      &:first-child {
        transform: rotate(45deg);
        top: 5px;
      }
      &:last-child {
        transform: rotate(-45deg);
        top: -5px;
      }
    }
    &:hover {
      .bar {
        left: 0;
        background: rgb(167, 167, 167) !important;
        &:first-child {
          left: 0;
        }
      }
    }
  }
  &:hover {
    .bar {
      left: 4px;
      &:first-child {
        left: -4px;
      }
    }
  }
}