// Case 3
// Patio

.case3.subpage {
  .copyrights, .case-title, .slogan, .case-title-subpage, .case-subtitle-subpage {
    color: #252730;
  }
  .bar {
    background: #252730;
  }
  .logo-svg {
    fill: #252730;
  }
  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }

  .lang {
    span {
      color: #262933;
    }
  }

  .lead {
    font-size: 30px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 40px;
    @include phone {
      font-size: 24px;
    }
  }
  .default-text {
    font-size: 18px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;
  }

  .title {
    font-size: 56px;
    color: #262933;
    font-weight: 300;
    letter-spacing: 1px;
    @include tablet {
      font-size: 36px;
    }
  }

  .case-title-subpage {
    @include phone {
      font-size: 26px;
    }
  }

  .section {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 30px;
    }
  }

  .main-content {
    background: #fff;
  }

  .intro {
    overflow-y: visible;
    background: #dcbd2e;
    text-align: center;
    padding: 75px 20px 20px 20px;
    .intro-car {
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      padding: 25px;
    }
    .intro-confetti {
      position: absolute;
      bottom: 120px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
    &:after {
      background: #dcbd2e;
    }
  }

  .brief {
    padding: 140px 0 100px 0;
    position: relative;
    .photo1 {
      position: absolute;
      right: 0;
      bottom: -100px;
      z-index: 1;
    }
    .lead {
      padding: 40px 0 60px 0;
    }
  }

  .icons {
    width: 100%;
    .tab-link {
      margin-bottom: 50px;
      display: block;
     &.active {
      svg {
        path {
          fill: #dcbd2c;
        }
        circle {
          fill: #dcbd2c;
        }
        path {
          fill: #dcbd2c;
        }
      }
    }
    .icon-title {
        &:after {
          display: block;
        }
      }
    }
    .icon-wrapper {
      height: 125px;
      text-align: center;
      @include phone {
        height: 50px;
      }
      .outdoor {
        width: 66px;
        @include phone {
          width: 30px;
        }
      }
    }
    .icon-title {
      font-size: 24px;
      font-weight: 600;
      color: #000;
      text-align: center;
      position: relative;
      @include phone {
        font-size: 12px;
        font-weight: 400;
      }
      &:after {
        content: "";
        display: none;
        width: 50px;
        height: 2px;
        background: #dcbd2c;
        position: absolute;
        left: 50%;
        bottom: -15px;
        transform: translateX(-50%);
      }
    }
    .tab-content {
      color: #000;
      .tab-pane {
        display: none;
        &.active {
          display: block;
        }
        img {
          margin: 50px auto 0 auto;
          display: block;
        }
      }
    }
  }

  .lottery {
    padding: 100px 0 0 0;
    position: relative;
    .kupon {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 44%;
      max-width: 855px;
      @include phone {
        right: -50px;
        top: 100px;
      }
    }
    .pen {
      position: absolute;
      top: 550px;
      right: 0;
      z-index: 1;
      width: 47%;
      max-width: 895px;
      @include laptop {
        top: 350px;
        right: -50px;
      }
      @include phone {
        top: 200px;
      }
    }
    .title {
      padding: 0 0 80px 0;
    }
  }

  .safe-lottery {
    width: 100%;
    padding: 200px 0;
    background: url('../img/patio/kupon_bg.jpg');
    background-size: cover;
    margin: 240px 0 0 0;
    position: relative;
    @include phone {
      margin: 120px 0 0 0;
      padding: 100px 0;
    }
    .photo1 {
      @include phone {
        margin: 0 0 30px 0;
      }
    }
    .title {
      @include phone {
        margin-bottom: 30px;
      }
    }
    .confetti2 {
      position: absolute;
      left: 0;
      top: -350px;
    }
  }

  .awards {
    width: 100%;
    padding: 250px 0;
    margin: 0 0 0 0;
    position: relative;
    @include phone {
      padding: 70px 0;
    }
    .title {
      margin-bottom: 80px;
      @include phone {
        margin-bottom: 40px;
      }
    }
    .awards-car {
      position: absolute;
      left: 0;
      top: 300px;
      @include laptop {
        left: -200px;
      }
      @include phone {
        display: none;
      }
    }
    .awards-tv {
      position: absolute;
      right: 300px;
      top: 350px;
      z-index: 1;
      @include laptop {
        right: 100px;
      }
      @include phone {
        display: none;
      }
    }
    .awards-scooter {
      position: absolute;
      right: 0;
      top: 100px;
      z-index: 2;
      @include laptop {
        right: -100px;
      }
      @include phone {
        display: none;
      }
    }
  }

  .big-opening {
    width: 100%;
    padding: 100px 0;
    margin: 0 0 0 0;
    position: relative;
    @include phone {
      padding: 70px 0;
    }
    .title {
      margin-bottom: 80px;
      @include phone {
        margin-bottom: 40px;
      }
    }
  }
  
  .slider {
    position: relative;
    margin-bottom: 550px;
    @include phone {
      margin-bottom: 200px;
    }
    // .slider-coupon {
    //   position: absolute;
    //   left: 50%;
    //   bottom: -800px;
    //   z-index: 2;
    //   transform: translateX(-50%);
    //   @include phone {
    //     bottom: 0;
    //   }
    // }
    .patio-slider {
      width: 100%;
      padding: 100px 0 0 0;
      margin: 0 0 0 0;
      position: relative;
      @include phone {
        padding: 50px 0 0 0;
      }
      .photo {
        width: 100%;
        // background: url('../img/patio/slide1.jpg') no-repeat bottom center;
      }
    }
  }

  .owl-theme .owl-controls {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-tap-highlight-color: transparent;
  }

  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
  }

  .owl-carousel .owl-controls .owl-dot, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    background: none;
    border: solid 2px #5a5a5a;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #5a5a5a;
  }

  // Audio player

  .audiojs {
    width: 100%;
    margin: 150px 0 100px 0;
    height: 36px;
    position: relative;
    background: transparent;
    overflow: visible;
    font-family: monospace;
    font-size: 12px;
    background-image: none;
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow:none;
    -o-box-shadow: none;
    box-shadow: none;
    @include phone {
      margin: 50px 0 0 0;
    }
    .scrubber{
        width: 80%;
        position: absolute;
        top: 4px;
        height: 7px;
        width: 80%;
        border:none; 
        right: 0;
    }
    .play-pause {
        width: 25px;
        position: absolute;
        left: 120px;
        height: 40px;
        padding: 4px 6px;
        margin: 0px;
        float: left;
        overflow: hidden;
        border-right: none;
        @include phone {
          left: 0;
        }
        .play {
            background: url("../img/play.jpg") 6px 5px no-repeat;
        }
        .pause{ 
            background: url("../img/pause.jpg") 6px 6px no-repeat;
        }
    }

    .progress {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 7px;
      width: 0px;
      background: #dcbd2c;
      z-index: 1;
      background-image: none;
      background-image: -moz-linear-gradient(center top, #ccc 0%, #ddd 50%, #ccc 51%, #ccc 100%);
    }
    /* .progress:after {
        content: " ";
        position: absolute;
        background: red;
        width: 5px;
        height: 20px;
        top: -10px;
        right: 0;
        z-index: 9999;
        display: block;
    }*/
    .time {
      float: none;
      height: 36px;
      line-height: 36px;
      margin: 0px 0px 0px 6px;
      padding: 0px 6px 0px 12px;
      border-left: none;
      color: #000;
      font-family: 'Work Sans', sans-serif;
      text-shadow: none;
      left: 50px;
      top: 17px;
      @include phone {
        left: 40px;
        top: -15px;
      }
      em {
        color: #000;
      }
    }
  }

  .footer-wrapper {
    width: 100%;
    background: #f7f7f7;
    position: relative;
    margin: 100px 0 0 0;
    .footer-coupon {
      position: absolute;
      left: 50%;
      top: -440px;
      z-index: 2;
      transform: translateX(-50%);
      @include phone {
        top: -120px;
        left: 53%;
      }
    }
    .case-footer {
      padding: 160px 20px 200px 20px;
      max-width: 600px;
      @include tablet {
        text-align: center;
        padding: 80px 20px 100px 20px;
      }
      .footer-title {
        font-size: 30px;
        color: #2f2f2f;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
        margin: 0 0 90px 0;
        line-height: 48px;
        @include phone {
          font-size: 22px;
          line-height: 30px;
        }
      }
      .col1 {
        position: relative;
        &:after {
            content: " ";
            display: block;
            height: 100px;
            width: 1px;
            background: #d2d2d2;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -50px;
        }
        @include tablet {
          &:after {
            display: none;
          }
        }
      }
      .contact {
        color: #2f2f2f;
        font-size: 21px;
        margin: 20px 0 0 0;

        @include tablet {
          font-size: 18px;
        }
        .position {
            font-size: 12px;
        }
      }
    }
  }
}