@include tablet {
  .main-slider-wrapper {
    display: block;
  }
  .canvas, .case-title {
    display: none;
  }
  .logo-wrapper {
    top: 50px;
    left: 50px;
  }
  .menu-button {
    top: 50px;
    right: 50px;
  }
  .footer .features, .footer .slogan {
    display: none;
  }
  .footer {
    padding: 0 0 10px 30px;
  }
}

@include laptop {
  .logo-wrapper {
    top: 50px;
    left: 50px;
  }
  .menu-button {
    top: 35px;
    right: 50px;
  }
  .case-arrow-back {
    right: 105px;
    top: 42px;
  }
  .case-title-subpage {
    font-size: 36px;
  }
  .footer {
    padding: 0 50px 50px 50px;
  }
}

@include phone {
  .main-slider-wrapper {
    display: block;
  }
  .canvas, .case-title {
    display: none;
  }
  .logo-wrapper {
    top: 30px;
    left: 30px;
  }
  .menu-button {
    top: 13px;
    right: 15px;
  }
  .case-arrow-back {
    right: 70px;
    top: 20px;
  }
  .footer .features, .footer .slogan {
    display: none;
  }
  .footer {
    padding: 0 0 10px 30px;
  }
}

/* max-width: 1600px
   ----------------------------- */
@media screen and (max-width: 1600px) {

  #fullpage-menu ul li, .fp-slidesNav ul li {
    margin-bottom: 40px;
  }

  #fullpage-menu ul li.visited a::after, .fp-slidesNav ul li.visited a::after {
    width: 30px;
  }

  #fullpage-menu ul li a:after, .fp-slidesNav ul li a:after {
    width: 30px;
  }

  // Tefal zelazka
  .landing-img {
    width: 850px;
  }

}

/* max-width: 1440px
   ----------------------------- */
@media screen and (max-width: 1440px) {

  .lang {
    z-index: 10;
    position: fixed;
    top: 53px;
    right: 112px;
  }

  .ludzie-subpage .video-wrapper .video-menu li .person-link {
    font-size: 16px;
  }

  .ludzie-subpage .video-wrapper .video-menu li {
    padding: 10px 0 0 0;
  }

}

/* max-width: 1366px
   ----------------------------- */
@media screen and (max-width: 1366px) {

  // Zelazka Tefal
  .landing-img {
    width: 650px;
  }
  .case9.subpage .quiz .default-text {
    font-size: 16px;
    max-width: 500px;
  }

}

/* max-width: 1280px
   ----------------------------- */
@media screen and (max-width: 1280px) {

  // Tefal zelazka
  .case9.subpage .home-iron {
    width: 200px;
  }
  .case9.subpage .heading {
    font-size: 27px;
  }
  .case9.subpage .landing .wrap .default-text {
    font-size: 16px;
    line-height: 1.5;
  }
  .case9.subpage .landing .wrap {
    padding-top: 150px;
  }

}

/* max-width: 1024px
   ----------------------------- */
@media screen and (max-width: 1024px) {

  .subpage {
    .lang {
      right: 185px;
    }
  }

  // Zelazka Tefal
  .case9.subpage .brief .iron {
    width: 145px;
  }
  .case9.subpage .quiz .default-text {
    max-width: 400px;
  }
  #fullpage-menu {
    display: none;
  }
  .section.lookbook-video {
    display: none;
  }

}

/* max-width: 768px
   ----------------------------- */
@media screen and (max-width: 768px) {

  .subpage {
    .lang {
      right: 75px;
    }
  }

  .lang {
    z-index: 10;
    position: fixed;
    top: 30px;
    right: 83px;
  }

  // Zelazka tefal
  .case9.subpage .home-iron {
    width: 130px;
  }
  .case9.subpage .brief .iron {
    display: none;
  }

  // Zyrtec
  .case10.subpage .ucb-brief .ucb-description {
    padding: 0 30px;
  }

}