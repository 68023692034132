// Case 13
// Bayer Digital Hub

// Variables
$white: #fff;
$font-light: 300;


.case13.subpage {
  color: #262832;
  line-height: 1.42857;
  background-color: #fff !important;

  // Elements
  body {
    overflow-x: hidden !important;
  }

  #fp-nav ul li.visited a span, #fp-nav ul li a:hover span, #fp-nav ul li a.active span {
    background: #ed2327;
  }

  #fp-nav ul li .fp-tooltip {
    color: #000;
    mix-blend-mode: exclusion;
  }

  .lang {
    mix-blend-mode: exclusion;

    span {
      color: #fff;
    }
  }

  .arrow-line {
    stroke: #fff;
  }

  .logo-wrapper, .case-arrow-back, .menu-button {
    mix-blend-mode: exclusion;
  }

  span,
  p,
  h1 {
    &.color-white {
      color: #FFFFFF;
    }

    &.font-light {
      font-weight: $font-light;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }

  .content-section {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
  }


  // Hello section
  .hello-bdh {
    background: url("../img/tech-wizards/header_03.jpg") 100%/cover no-repeat;
    position: relative;
    text-align: center;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
    }

    .bdh-small-description {
      font-size: 13px;

      @include tablet {
        font-size: 11px;
      }
    }

    h1,
    p {
      position: relative;
      z-index: 100;
    }

    h1 {
      font-size: 65px;
      letter-spacing: 1px;

      @include laptop {
        font-size: 56px;
        margin-bottom: 50px;
      }

      @include tablet {
        font-size: 48px;
      }

      @include phone {
        font-size: 36px;
      }
    }
  }
}

// Brief section
.brief-bdh {
  .wrapper {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  .column {
    &--left {
      width: 50%;
      padding-right: 100px;
    }

    &--right {
      width: 50%;
    }
  }

  &__title {
    color: #053E2F;
    font-size: 24px;
    margin-bottom: 50px;
  }

  &__desc {
    font-size: 18px;
  }

  @media (max-width: 1280px) {
    &__title {
      font-size: 20px;
      margin-bottom: 40px;
    }

    &__desc {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .column {
      &--left {
        width: 100%;
        padding-right: 0;
      }

      &--right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__title {
      font-size: 14px;
      margin-bottom: 30px;
    }
    &__desc {
      font-size: 14px;

    }
    &__image {
      max-width: 60%;
      margin-top: 50px;
    }
  }
}

.claim-bdh {
  background: url("../img/tech-wizards/mural.png") 100%/cover no-repeat;

  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px;
  }

  &__desc {
    color: #fff;
    font-size: 18px;

    &:nth-of-type(n+1) {
      margin-top: 30px;
    }
  }

  @media (max-width: 1280px) {
    &__title {
      font-size: 34px;
      margin-bottom: 40px;
    }
    &__desc {
      font-size: 16px;

      &:nth-of-type(n+1) {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-size: 20px;
      margin-bottom: 20px;
    }
    &__desc {
      font-size: 14px;

      &:nth-of-type(n+1) {
        margin-top: 15px;
      }
    }
  }
}


// Movies section
.movies-bdh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 42, 42, .9);
    transform: scale(0);
    transition: all .3s ease-in-out;
    z-index: 200;

    &--is-open {
      transform: scale(1);
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__slider[current-lang="pl"] {
    .movies-bdh__slide {
      &--step-1 {
        background: url("../img/tech-wizards/step-4-desktop.jpg") 100%/cover no-repeat;
      }

      &--step-2 {
        background: url("../img/tech-wizards/step-7-desktop.jpg") 100%/cover no-repeat;
      }

      &--step-3 {
        background: url("../img/tech-wizards/step-1-desktop.jpg") 100%/cover no-repeat;
      }

      &--step-4 {
        background: url("../img/tech-wizards/step-3-desktop.jpg") 100%/cover no-repeat;
      }

      &--step-5 {
        background: url("../img/tech-wizards/step-5-desktop.jpg") 100%/cover no-repeat;
      }

      &--step-6 {
        background: url("../img/tech-wizards/step-6-desktop.jpg") 100%/cover no-repeat;
      }

      &--step-7 {
        background: url("../img/tech-wizards/step-2-desktop.jpg") 100%/cover no-repeat;
      }

      @media (max-width: 768px) {
        &--step-1 {
          background: url("../img/tech-wizards/step-4-mobile.jpg") 100%/cover no-repeat;
        }
        &--step-2 {
          background: url("../img/tech-wizards/step-7-mobile.jpg") 100%/cover no-repeat;
        }
        &--step-3 {
          background: url("../img/tech-wizards/step-1-mobile.jpg") 100%/cover no-repeat;
        }
        &--step-4 {
          background: url("../img/tech-wizards/step-3-mobile.jpg") 100%/cover no-repeat;
        }
        &--step-5 {
          background: url("../img/tech-wizards/step-5-mobile.jpg") 100%/cover no-repeat;
        }
        &--step-6 {
          background: url("../img/tech-wizards/step-6-mobile.jpg") 100%/cover no-repeat;
        }
        &--step-7 {
          background: url("../img/tech-wizards/step-2-mobile.jpg") 100%/cover no-repeat;
        }
      }
    }
  }

  &__slide {
    width: 100%;
    min-height: 100vh;
    background-position: center !important;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
    }
  }

  &__nav {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
  }

  &__btn {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: relative;

    &--active {
      .movies-bdh__circle {
        background-color: #fff;
      }

      .movies-bdh__title {
        opacity: 1;
        transition-delay: .3s;
      }
    }

    &:nth-of-type(n+2) {
      margin-left: 30px;
    }
  }

  &__circle {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px #fff solid;
    position: relative;
    transition: all .3s ease-in-out;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50px, -50%);
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    z-index: 100;
  }

  &__icon {
    circle, path {
      transition: all .3s ease-in-out;
    }

    circle {
      fill: none;
      stroke: #f4f4f4;
      stroke-miterlimit: 10;
    }

    path {
      fill: #f4f4f4;
    }

    &:hover, &:focus {
      circle {
        stroke: #053E2F;
      }

      path {
        fill: #053E2F;
      }
    }
  }

  &__back {
    position: absolute;
    top: 10%;
    right: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
  }

  @media (max-width: 1280px) {
    &__play {
      width: 130px;
      height: 130px;
    }
  }

  @media (max-width: 768px) {
    &__play {
      width: 70px;
      height: 70px;
    }
    &__btn {
      &:nth-of-type(n+2) {
        margin-left: 20px;
      }
    }
    &__title {
      left: 50%;
      transform: translate(-50%, -30px);
    }
    &__back {
      font-size: 12px;
    }
  }
  @media (max-width: 480px) {
    &__play {
      width: 50px;
      height: 50px;
    }
  }
}

// Movies description
.movie-bdh {
  &__desc {
    &:nth-of-type(2) {
      margin-bottom: 60px;
    }

    &--large {
      color: #053E2F;
      font-size: 40px;
      margin-bottom: 30px;
    }

    &--normal {
      color: #3b3b3b;
      font-size: 18px;
      font-weight: 300;

      &:nth-of-type(n+2) {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 1280px) {
    &__desc {
      &:nth-of-type(2) {
        margin-bottom: 50px;
      }

      &--large {
        font-size: 34px;
      }

      &--normal {
        font-size: 16px;

        &:nth-of-type(n+2) {
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    &__desc {
      &:nth-of-type(2) {
        margin-bottom: 30px;
      }

      &--large {
        font-size: 17px;
      }

      &--normal {
        font-size: 14px;

        &:nth-of-type(n+2) {
          margin-top: 15px;
        }
      }

      br {
        display: none;
      }
    }
  }
}


/* Social Media */
.web-bdh {
  background-color: #053E2F;

  .wrapper {
    text-align: center;
  }

  &__title {
    color: #fff;
    font-size: 48px;
  }

  &__desc {
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    margin: 50px 0;
  }

  &__link {
    display: inline-block;
    margin-bottom: 200px;
    overflow: hidden;
    z-index: 90;
  }

  &__images {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }

  &__image {
    &--2 {
      margin-left: 50px;
    }
  }

  @media (max-height: 1100px) and (orientation: landscape) {
    &__image {
      max-width: 220px;
    }
  }
  @media (max-width: 1280px) {
    &__title {
      font-size: 40px;
    }
    &__desc {
      font-size: 16px;
    }
    &__link {
      margin-bottom: 200px;
    }
  }
  @media (max-width: 768px) {
    &__title {
      font-size: 17px;
    }
    &__desc {
      font-size: 14px;
      margin: 30px 0;
    }
    &__link {
      margin-bottom: 100px;
    }
    &__social {
      max-width: 80%;
    }
    &__image {
      max-width: 150px;

      &--2 {
        margin-left: 20px;
      }
    }
  }
  @media (max-width: 360px) {
    &__image {
      max-width: 100px;
    }
  }
}

.footer-wrapper {
  background: #fff;
  color: #2f2f2f;

  a {
    color: #000;
  }
}