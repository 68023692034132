/* Menu
   ----------------------------- */

.menu-wrapper {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: none;

  .menu-wrapper-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 2;

    .loaded-content {
      @include phone {
        display: none;
      }
    }
  }

  .menu-wrapper-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 240px;
    height: 100%;
    z-index: 2;
    @include tablet {
      width: 200px;
    }
    @include phone {
      display: none;
    }

    #main-menu {
      position: absolute;
      bottom: 10%;
      right: 100px;
      text-align: right;
      @include tablet {
        right: 75px;
        bottom: 75px;
      }

      div {
        margin: 40px 0;
        @media screen and (max-height: 620px) {
          margin: 20px 0;
        }

        &:hover a {
          left: -10px;
        }
      }

      a {
        color: #fff;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: 1px;
        position: relative;
        left: 0;
        transition: 0.2s ease-out;
      }
    }
  }

  .nav-mobile {
    width: 100%;
    text-align: center;
    display: none;
    position: relative;
    z-index: 4;
    @include phone {
      display: block;
    }

    ul {
      width: 100%;
      list-style: none;
      margin-top: 50px;

      li {
        width: 100%;
        display: block;
        border-bottom: solid 1px #29282b;

        &:last-child {
          border: 0;
        }

        a {
          width: 100%;
          display: block;
          color: #fff;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 16px;
          letter-spacing: 1px;
          position: relative;
          padding: 20px 20px;
          right: 0;
          touch-action: manipulation;
          transition: 0.2s ease-out;

          &:hover {
            background: #0c0c0c;
          }
        }
      }
    }
  }
}

.menu-svg {
  position: fixed;
  bottom: -1px;
  left: -1px;
  pointer-events: none;
  z-index: 11;
  overflow: hidden;

  path {
    transform-origin: 0px 0px 0px;
    fill: #19181a;
  }
}

.contact-info {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 80px;
  display: grid;
  grid-template-rows: 1fr 50px 1fr;

  @include phone {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .contact-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include phone {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .contact-column {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include phone {
        width: 100%;
      }

      .contact-icon-wrapper {
        margin-bottom: 15px;
        height: 50px;
      }

      .country {
        font-weight: bold;
        font-size: 16px;
      }



    }

  }
  .contact-line {
    @include phone {
      display: none;
    }

  }

}

.maps-wrapper {
  border: 1px solid red;
}

.mCSB_dragger_bar {
  position: relative;

  &:after {
    content: url(../img/scroll_handler.png);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.6s ease-out;
  }

  &.down:after {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}