// Case 5
// Lekam

.case5.subpage {
  .copyrights, .case-title, .slogan, .case-title-subpage,
  .lang__list {
    color: #252730;
  }
  .bar {
    background: #252730;
  }
  .logo-svg {
    fill: #252730;
  }

  .small-title {
    font-size: 13px;
    text-transform: uppercase;
    color: #252730;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }

  .section-title {
    font-size: 44px;
    color: #2c313d;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .section-text {
    font-size: 44px;
    color: #2c313d;
    font-weight: 300;
    letter-spacing: 1px;
    @include laptop {
      font-size: 36px;
    }
    @include phone {
      font-size: 25px;
    }
  }

  .heading {
    font-size: 30px;
    color: #252730;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 40px;
  }

  .default-text {
    font-size: 18px;
    color: #252730;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;
  }

  .section {
    max-width: 850px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 30px;
    }
  }

  .intro {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: visible;
    background: #fff;
    .woman {
      position: absolute;
      bottom: 20px;
      left: 70%;
      transform: translateX(-50%);
      z-index: 1;
      @include phone {
        bottom: 20px;
        left: initial;
        width: 145px;
        right: 0;
      }
    }
    .grass {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
    .lifebuoy {
      position: absolute;
      bottom: -50px;
      left: 80%;
      transform: translateX(-50%);
      z-index: 3;
      @include phone {
        bottom: -15px;
        width: 70px;
        left: initial;
        right: 0;
      }
    }
    .plane {
      position: absolute;
      bottom: 321px;
      left: 20%;
      transform: translateX(-50%);
      z-index: 2;
      @include phone {
        bottom: 400px;
        left: 20%;
        width: 70px;
      }
    }
  }

  .main-content {
    background: #fff;
  }

  .brief {
    padding: 200px 0 100px 0;
    position: relative;
    @include phone {
      padding: 100px 0 50px 0;
    }
    .photo1 {
      position: absolute;
      right: 0;
      bottom: -100px;
      z-index: 1;
    }
    .heading {
      padding: 40px 0 60px 0;
    }
    .padding-top {
      padding-top: 120px;
    }
    img {
      @include phone {
        margin: 0 auto;
        display: block;
        padding: 0 0 40px 0;
      }
    }
  }

  .key-visual {
    margin: 150px 0;
    padding: 80px 0;
    background: #f5f5f5;
    @include phone {
      margin: 0 0;
      padding: 0 30px;
    }
    .section-title {
      margin: 100px 0 70px 0;
      @include phone {
        margin: 50px 0 70px 0;
      }
    }
    .slider-wrapper {
      max-width: 474px;
      height: auto;
      background: url('../img/lekam/frame1.png');
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 24px 25px;
      @include phone {
        margin: 50px 0 0 0;
      }
    }
  }

  .www {
    margin: 300px 0;
    @include phone {
      margin: 100px 0;
      padding: 0 30px;
    }
    .section-title {
      margin: 100px 0 70px 0;
      @include phone {
        margin: 50px 0 70px 0;
      }
    }
    .slider-wrapper {
      width: 690px;
      height: auto;
      padding: 0;
      position: relative;
      background: url('../img/lekam/www.jpg');
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 0 7px;
      @include laptop {
        width: 550px;
      }
      @include phone {
        margin-top: 40px;
        width: 100%;
      }
      // .frame {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: 10;
      //   user-drag: none; 
      //   user-select: none;
      //   -moz-user-select: none;
      //   -webkit-user-drag: none;
      //   -webkit-user-select: none;
      //   -ms-user-select: none;
      // }
      .key-visual-slider {
        .item {
          border-radius:30px;
        }
      }
    }
  }

  .btl {
    margin: 150px 0;
    padding: 80px 0;
    background: #f5f5f5;
    @include phone {
      margin: 50px 0;
      padding: 0 30px;
    }
    .section-title {
      margin: 100px 0 70px 0;
      @include phone {
        margin: 50px 0 70px 0;
      }
    }
  }

  .alfabet-zdrowia {
    width: 100%;
    padding: 250px 200px;
    @include phone {
      padding: 50px 20px;
    }
    .section-text {
      @include laptop {
        padding-left: 50px;
      }
      @include phone {
        padding: 0;
      }
    }
    img {
      @include phone {
        margin: 0 auto 30px auto;
        display: block;
        width: 170px;
      }
    }
  }

  .owl-theme .owl-controls {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-tap-highlight-color: transparent;
  }

  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
  }

  .owl-carousel .owl-controls .owl-dot, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    background: none;
    border: solid 2px #5a5a5a;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #5a5a5a;
  }

  .fb-posts {
    margin: 50px 20px;
    text-align: center;
    .fb-post {
      margin-right: 44px;
      @include laptop {
        display: block;
        margin: 0 auto 30px auto;
      }
    }
  }

  .fb-adress {
    text-align: center;
    font-size: 33px;
    padding: 200px 20px;
    @include phone {
      padding: 50px 20px;
      font-size: 16px;
    }
    a {
      color: #2c313d;
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      font-weight: 700;
    }
  }
 
  .footer-wrapper {
      width: 100%;
      background: #f7f7f7;
      position: relative;
      margin: 100px 0 0 0;
      a {
        color: #ca2b25;
      }
      .case-footer {
        padding: 160px 20px 200px 20px;
        max-width: 600px;
        @include tablet {
          text-align: center;
          padding: 80px 20px 100px 20px;
        }
        .footer-title {
          font-size: 30px;
          color: #2f2f2f;
          font-weight: 300;
          letter-spacing: 1px;
          text-align: center;
          margin: 0 0 90px 0;
          line-height: 48px;
          @include phone {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .col1 {
          position: relative;
          &:after {
              content: " ";
              display: block;
              height: 100px;
              width: 1px;
              background: #d8d8d8;
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -50px;
          }
          @include tablet {
            &:after {
              display: none;
            }
          }
        }
        .contact {
          color: #2f2f2f;
          font-size: 21px;
          margin: 20px 0 0 0;
          @include tablet {
            font-size: 18px;
          }
          .position {
              font-size: 12px;
          }
        }
      }
    }
}